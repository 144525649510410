import '@/filters';
import '@/plugins/LogioVue2Tabulator';
import '@/plugins/sentry';
import { authenticate, createKeycloakInstance, getToken } from '@/plugins/keycloak';
import { i18n, setI18n } from '@/plugins/i18n';
import type { KeycloakConfig, MessengerConfig } from '@/model/Interfaces/Config';
import App from '@/App.vue';
import { AppRouter } from '@/router';
import appStore from '@/store/modules/AppStore';
import { CONSTANTS } from '@/constants';
import ErrorPage from '@/views/ErrorPage.vue';
import ErrorService from '@/services/ErrorService/ErrorService';
import { fetchConfig } from '@/plugins/config';
import { MessageType } from '@/model/Enums/MessageType';
import registerServiceWorker from '@/registerServiceWorker';
import { setHttp } from '@/plugins/http';
import { socketCreate } from '@/plugins/socket';
import SplashScreen from '@/components/SplashScreen.vue';
import store from '@/store';
import Vue, { VNode } from 'vue';
import type VueRouter from 'vue-router';
import vuetify from '@/plugins/vuetify';

if (process.env.VUE_APP_MODE === 'production') {
    Vue.config.silent = true;
    Vue.config.devtools = false;
    Vue.config.productionTip = false;
    Vue.config.performance = false;
} else {
    Vue.config.silent = false;
    Vue.config.devtools = true;
    Vue.config.productionTip = true;
    Vue.config.performance = true;
}

let appInstance: Vue;
let splashScreenInstance: Vue;
let errorPageInstance: Vue;
let connection: { api: string; keycloak: KeycloakConfig; messenger: MessengerConfig } | null = null;

const createAppElement = (appName: string): void => {
    const element = document.createElement('div');
    element.setAttribute('id', appName);
    document.body.appendChild(element);
};

const destroyInstance = (instance: Vue): void => {
    if (instance) {
        instance.$destroy();
        instance.$el.parentNode?.removeChild(instance.$el);
    }
};

const createAppInstance = (router: VueRouter): void => {
    createAppElement('app');
    appInstance = new Vue({
        i18n,
        render: (h): VNode => h(App),
        router,
        store,
        vuetify
    });
    appInstance.$mount('#app');

};

const createSplashScreen = (): void => {
    createAppElement('splashScreen');
    splashScreenInstance = new Vue({
        render: (h): VNode => h(SplashScreen),
        store,
        vuetify
    });
    splashScreenInstance.$mount('#splashScreen');
};

const createErrorPage = (error: unknown): void => {
    destroyInstance(appInstance);
    destroyInstance(splashScreenInstance);
    createAppElement('errorPage');
    errorPageInstance = new Vue({
        i18n,
        render: (h): VNode => h(ErrorPage, { props: { error } }),
        vuetify
    });
    errorPageInstance.$mount('#errorPage');
};

const init = async (): Promise<void> => {
    try {
        destroyInstance(appInstance);
        destroyInstance(splashScreenInstance);
        destroyInstance(errorPageInstance);
        registerServiceWorker();
        createSplashScreen();
        const config = await fetchConfig();
        const path = window.location.href;
        for (const c of config.connections) {
            if (path.startsWith(c.host)) {
                connection = {
                    api: c.api,
                    keycloak: c.keycloak,
                    messenger: c.messenger
                };
                break;
            }
        }
        if (connection) {
            createKeycloakInstance(connection.keycloak);
            await authenticate();
            const token = getToken();
            await setI18n(token.locale, config.defaultLocale);
            setHttp(connection.api, token.bearer);
            socketCreate(connection.messenger, token.bearer);
            await appStore.auth({ token });
            await appStore.fetchApiConfig();
            await appStore.fetchUserSettings();
            destroyInstance(splashScreenInstance);
            const appRouter = new AppRouter();
            createAppInstance(appRouter.router);
        } else {
            throw new Error(`${CONSTANTS.ERRORS.NOT_VALID_CONFIG}: not valid connection`);
        }
    } catch (error) {
        createErrorPage(error);
        await ErrorService.dispatch(error, {
            context: 'cannot start application',
            messageType: MessageType.Console
        });
    }
};

void init();
