import { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import ColumnFilterClass from '@/model/Data/Filters/ColumnFilterClass';

interface TextFilterValue {
    texts: Array<string>;
}

class TextFilterClass extends ColumnFilterClass<TextFilterValue> {
    public value: TextFilterValue = {
        texts: []
    };

    public constructor(public readonly key: string, filter?: TextFilterClass | TextFilterValue) {
        super(key);
        if (filter instanceof TextFilterClass) {
            this.value = { ...filter.value };
        } else if (filter) {
            this.value = { ...filter };
        }
    }

    public get filter(): Expression | null {
        if (this.value.texts.length) {
            const expressions = this.value.texts.map((item) => PrettyExpressionBuilder.equal(this.key, item));
            return PrettyExpressionBuilder.or(...expressions);
        } else {
            return null;
        }
    }

    public clone(): TextFilterClass {
        return new TextFilterClass(this.key, this);
    }
}

export default TextFilterClass;
