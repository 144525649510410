



















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import appStore from '@/store/modules/AppStore';
import type { NavigationRouteConfig } from '@/router/model/NavigationRouteConfig';
import type { Route } from 'vue-router';
import SystemStatusMenu from '@/components/SystemStatusMenu.vue';
import UserMenu from '@/components/UserMenu.vue';

@Component({
    components: { SystemStatusMenu, UserMenu }
})
export default class Navigation extends Vue {
    // Data
    protected active: string | null = null;

    protected get appName(): string {
        return this.$config ? this.$config.name : 'PWNG';
    }

    protected get childIconSize(): number {
        return appStore.elements.childIconSize;
    }

    protected get iconSize(): number {
        return appStore.elements.iconSize;
    }

    protected get logoSize(): number {
        return appStore.elements.logoSize;
    }

    // Computed
    protected get navigation(): Array<NavigationRouteConfig> {
        return this.$router.options.routes as Array<NavigationRouteConfig>;
    }

    protected get navigationMiniWidth(): number {
        return appStore.elements.navigationMiniWidth;
    }

    protected get navigationOpenWidth(): number {
        return appStore.elements.navigationOpenWidth;
    }

    protected get mini(): boolean {
        return this.$vuetify.breakpoint.mdAndUp && appStore.mini;
    }

    protected set mini(mini: boolean) {
        void appStore.changeMini(mini);
    }

    protected get nav(): boolean {
        return appStore.nav;
    }

    protected set nav(nav: boolean) {
        appStore.setNav(nav);
    }

    @Watch('$route', { immediate: true })
    protected onRouteChange(route: Route): void {
        if (typeof route !== 'undefined') {
            if (route.matched.length) {
                const [parent] = route.matched;
                const [item] = this.navigation.filter((parentRoute) => parentRoute.name === parent.name);
                if (item.name) {
                    this.active = item.name;
                }
            }
        }
    }
}
