













































































































































import { Expression, PrettyExpressionBuilder, Resource } from '@bednic/json-api-client';
import type { NavigationGuardNext, Route } from 'vue-router';
import { TableHeader, TableHeaderRenderer } from '@/model/Interfaces/TableHeader';
import { TableHeaderFilterType } from '@/model/Interfaces/TableHeader';
import type { AbcValue } from '@/model/Entity/AbcValue';
import abcValueStore from '@/store/modules/AbcValueStore';
import Actions from '@/components/common/Table/actions/Actions.vue';
import { ActionType } from '@/model/Enums/ActionType';
import ActiveFilters from '@/components/common/Table/ActiveFilters.vue';
import appStore from '@/store/modules/AppStore';
import type { AxiosResponse } from 'axios';
import ColumnView from '@/components/common/Table/ColumnViews.vue';
import { Component, Vue } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import type { DataStore } from '@/store/DataStore';
import EditModeButton from '@/components/common/Table/EditModeButton.vue';
import ErrorService from '@/services/ErrorService/ErrorService';
import type { ExportConfig } from '@/services/ExportTableService';
import ExportTable from '@/components/common/Table/ExportTable.vue';
import type { Flag } from '@/model/Entity/Flag';
import flagStore from '@/store/modules/FlagStore';
import { InvalidCheckoutErrorHandler } from '@/services/ErrorService/ErrorHandlers/InvalidCheckoutErrorHandler';
import LTable from '@/components/common/Table/Table.vue';
import { Message } from '@/model/Classes/Message';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import { mixins } from 'vue-class-component';
import { NotUpToDateErrorHandler } from '@/services/ErrorService/ErrorHandlers/NotUpToDateErrorHandler';
import type { OrderByItems } from '@/model/Entity/OrderByItems';
import PageSizeSelect from '@/components/common/Table/PageSizeSelect.vue';
import Pagination from '@/components/common/Table/Pagination.vue';
import ProductCard from '@/components/common/Table/ProductCard.vue';
import type { State } from '@/model/Entity/State';
import stateStore from '@/store/modules/StateStore';
import Summary from '@/components/common/Table/Summary.vue';
import TableMixin from '@/mixins/TableMixin';
import type { User } from '@/model/Entity/User';
import userStore from '@/store/modules/UserStore';
import UserViews from '@/components/common/Table/userViews/UserViews.vue';

enum ColDefs {
    Abc = 'abc',
    AssignedTo = 'assignedTo',
    AvailableSupply = 'availableSupply',
    DelayLength = 'delayLength',
    DeliveryDate = 'deliveryDate',
    FlagIds = 'flagIds',
    FutureMoveAmount = 'futureMoveAmount',
    Max = 'max',
    Min = 'min',
    Moq = 'moq',
    OfcAmount = 'ofcAmount',
    OrderDate = 'orderDate',
    OrderValue = 'orderValue',
    OrderedAmount = 'orderedAmount',
    OriginalAmount = 'originalAmount',
    ProductForecastAvg3Month = 'productForecastAvg3Month',
    ProductName = 'productName',
    ProductNote = 'productNote',
    ProductShortcut = 'productShortcut',
    ProductUnit = 'productUnit',
    PurchasePrice = 'purchasePrice',
    RealServiceLevel3Month = 'realServiceLevel3Month',
    SalePrice = 'salePrice',
    SkuForecast12Month = 'skuForecast12Month',
    SkuId = 'skuId',
    SlSale = 'slSale',
    StateId = 'stateId',
    StockoutDate = 'stockoutDate',
    SupplierCurrency = 'supplierCurrency',
    SupplierName = 'supplierName',
    SupplierValue = 'supplierValue',
    Volume = 'volume',
    WarehouseName = 'warehouseName',
    Weight = 'weight'
}

interface UpdateRequestBody {
    data: {
        assignedTo?: string | null;
        stateId?: string | null;
    };
    filter: string;
    meta: {
        count: number;
    };
}

interface ActionTriggerPayload {
    actionType: ActionType;
    count: number;
    updatedResource: Resource;
}

const module = CONSTANTS.MODULES.ORDERS_BY_ITEMS;

Component.registerHooks(['beforeRouteLeave']);

@Component({
    // eslint-disable-next-line no-undef
    components: {
        Actions,
        ActiveFilters,
        ColumnView,
        EditModeButton,
        ExportTable,
        LTable,
        PageSizeSelect,
        Pagination,
        ProductCard,
        Summary,
        UserViews
    }
})
export default class OrdersByItems extends mixins<TableMixin<OrderByItems>>(TableMixin) {
    // Data
    protected headers: Array<TableHeader> = [
        {
            contentAlign: 'center',
            default: true,
            filterEnumText: 'attributes.name',
            filterStoreInit: (): DataStore<State> => stateStore,
            filterType: TableHeaderFilterType.Enum,
            renderer: TableHeaderRenderer.State,
            sortable: true,
            text: `tables.${module}.${ColDefs.StateId}`,
            tooltip: `tables.${module}.${ColDefs.StateId}Tooltip`,
            value: ColDefs.StateId,
            width: 55
        },
        {
            default: true,
            filterAutocompleteURL: CONSTANTS.API.FILTERS.PRODUCT_SHORTCUTS_NAMES,
            filterType: TableHeaderFilterType.Text,
            onClickLink: (item: OrderByItems): void => {
                this.openProductCard(item);
            },
            renderer: TableHeaderRenderer.Text,
            sortable: true,
            text: `tables.${module}.${ColDefs.ProductShortcut}`,
            value: ColDefs.ProductShortcut,
            width: 150
        },
        {
            default: true,
            filterAutocompleteURL: CONSTANTS.API.FILTERS.PRODUCTS_NAMES,
            filterType: TableHeaderFilterType.Text,
            onClickLink: (item: OrderByItems): void => {
                this.openProductCard(item);
            },
            renderer: TableHeaderRenderer.Text,
            sortable: true,
            text: `tables.${module}.${ColDefs.ProductName}`,
            value: ColDefs.ProductName,
            width: 160
        },
        {
            default: true,
            filterAutocompleteURL: CONSTANTS.API.SUPPLIERS,
            filterType: TableHeaderFilterType.ResourceText,
            renderer: TableHeaderRenderer.Text,
            resourceFilterOptions: {
                additionalFields: ['shortcut', 'ico', 'address'],
                containsKey: 'name',
                filterBy: 'supplierId'
            },
            sortable: true,
            text: `tables.${module}.${ColDefs.SupplierName}`,
            value: ColDefs.SupplierName,
            width: 160
        },
        {
            default: true,
            filterEnumText: 'attributes.name',
            filterStoreInit: (): DataStore<User> => userStore,
            filterType: TableHeaderFilterType.Enum,
            renderer: TableHeaderRenderer.User,
            sortable: true,
            text: `tables.${module}.${ColDefs.AssignedTo}`,
            value: ColDefs.AssignedTo,
            width: 120
        },
        {
            default: true,
            editable: true,
            filterType: TableHeaderFilterType.Number,
            min: 0,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            restorable: true,
            restoreIsVisibleFunction: (item: OrderByItems): boolean => this.restoreOrderedAmountVisible(item),
            restoreValue: (item: OrderByItems): number | undefined => this.restoreOrderedAmountValue(item),
            rules: (): Array<(value: string) => string | boolean> => this.orderedAmountValidation(),
            sortable: true,
            text: `tables.${module}.${ColDefs.OrderedAmount}`,
            value: ColDefs.OrderedAmount,
            width: 100
        },
        {
            contentAlign: 'center',
            default: true,
            filterAutocompleteURL: CONSTANTS.API.FILTERS.PRODUCT_UNITS_NAMES,
            filterType: TableHeaderFilterType.Text,
            renderer: TableHeaderRenderer.Text,
            sortable: true,
            text: `tables.${module}.${ColDefs.ProductUnit}`,
            value: ColDefs.ProductUnit,
            width: 55
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            showCurrency: true,
            sortable: true,
            text: `tables.${module}.${ColDefs.OrderValue}`,
            value: ColDefs.OrderValue,
            width: 80
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.AvailableSupply}`,
            value: ColDefs.AvailableSupply,
            width: 70
        },
        {
            contentAlign: 'right',
            default: true,
            filterType: TableHeaderFilterType.Date,
            renderer: TableHeaderRenderer.StockoutDate,
            sortable: true,
            text: `tables.${module}.${ColDefs.StockoutDate}`,
            tooltip: `tables.${module}.${ColDefs.StockoutDate}Tooltip`,
            value: ColDefs.StockoutDate,
            width: 70
        },
        {
            default: true,
            filterEnumText: 'attributes.label',
            filterKey: 'flagIds',
            filterStoreInit: (): DataStore<Flag> => flagStore,
            filterType: TableHeaderFilterType.EnumMultiple,
            renderer: TableHeaderRenderer.Flag,
            sortable: false,
            text: `tables.${module}.${ColDefs.FlagIds}`,
            value: ColDefs.FlagIds,
            width: 250
        },
        {
            contentAlign: 'center',
            default: true,
            filterBooleanOptions: {
                falsyText: this.$tc('components.filters.productNoteFilter.falsyText'),
                truthyText: this.$tc('components.filters.productNoteFilter.truthyText'),
                type: 'string'
            },
            filterType: TableHeaderFilterType.Boolean,
            onClickLink: (item: OrderByItems): void => {
                this.openProductCard(item);
            },
            renderer: TableHeaderRenderer.ProductNote,
            sortable: true,
            text: `tables.${module}.${ColDefs.ProductNote}`,
            value: ColDefs.ProductNote,
            width: 100
        },
        {
            filterAutocompleteURL: CONSTANTS.API.FILTERS.SKU_IDS,
            filterType: TableHeaderFilterType.Text,
            renderer: TableHeaderRenderer.Text,
            sortable: true,
            text: `tables.${module}.${ColDefs.SkuId}`,
            value: ColDefs.SkuId,
            width: 70
        },
        {
            contentAlign: 'center',
            filterEmptyValues: [0],
            filterType: TableHeaderFilterType.Number,
            prependIcon: 'mdi mdi-alarm',
            renderer: TableHeaderRenderer.DelayLength,
            sortable: true,
            text: `tables.${module}.${ColDefs.DelayLength}`,
            tooltip: `tables.${module}.${ColDefs.DelayLength}Tooltip`,
            value: ColDefs.DelayLength,
            width: 70
        },
        {
            filterAutocompleteURL: CONSTANTS.API.FILTERS.WAREHOUSES_NAMES,
            filterType: TableHeaderFilterType.Text,
            renderer: TableHeaderRenderer.Text,
            sortable: true,
            text: `tables.${module}.${ColDefs.WarehouseName}`,
            value: ColDefs.WarehouseName,
            width: 140
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.OriginalAmount}`,
            value: ColDefs.OriginalAmount,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.Moq}`,
            tooltip: `tables.${module}.${ColDefs.Moq}Tooltip`,
            value: ColDefs.Moq,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.Min}`,
            value: ColDefs.Min,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.Max}`,
            value: ColDefs.Max,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.SupplierValue}`,
            value: ColDefs.SupplierValue,
            width: 80
        },
        {
            contentAlign: 'center',
            filterAutocompleteURL: CONSTANTS.API.FILTERS.CURRENCIES,
            filterType: TableHeaderFilterType.Text,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Text,
            sortable: true,
            text: `tables.${module}.${ColDefs.SupplierCurrency}`,
            value: ColDefs.SupplierCurrency,
            width: 60
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            showCurrency: true,
            sortable: true,
            text: `tables.${module}.${ColDefs.PurchasePrice}`,
            value: ColDefs.PurchasePrice,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            showCurrency: true,
            sortable: true,
            text: `tables.${module}.${ColDefs.SalePrice}`,
            value: ColDefs.SalePrice,
            width: 70
        },
        {
            renderer: TableHeaderRenderer.Date,
            sortable: true,
            text: `tables.${module}.${ColDefs.OrderDate}`,
            value: ColDefs.OrderDate,
            width: 90
        },
        {
            filterType: TableHeaderFilterType.Date,
            renderer: TableHeaderRenderer.Date,
            sortable: true,
            text: `tables.${module}.${ColDefs.DeliveryDate}`,
            tooltip: `tables.${module}.${ColDefs.DeliveryDate}Tooltip`,
            value: ColDefs.DeliveryDate,
            width: 90
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.Weight}`,
            value: ColDefs.Weight,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.Volume}`,
            value: ColDefs.Volume,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.SkuForecast12Month}`,
            tooltip: `tables.${module}.${ColDefs.SkuForecast12Month}Tooltip`,
            value: ColDefs.SkuForecast12Month,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.ProductForecastAvg3Month}`,
            tooltip: `tables.${module}.${ColDefs.ProductForecastAvg3Month}`,
            value: ColDefs.ProductForecastAvg3Month,
            width: 70
        },
        {
            contentAlign: 'center',
            filterEnumText: 'id',
            filterStoreInit: (): DataStore<AbcValue> => abcValueStore,
            filterType: TableHeaderFilterType.Enum,
            renderer: TableHeaderRenderer.Abc,
            sortable: true,
            text: `tables.${module}.${ColDefs.Abc}`,
            value: ColDefs.Abc,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.FutureMoveAmount}`,
            value: ColDefs.FutureMoveAmount,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.OfcAmount}`,
            value: ColDefs.OfcAmount,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            max: 1,
            min: 0,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'percent' },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.SlSale}`,
            value: ColDefs.SlSale,
            width: 70
        },
        {
            filterType: TableHeaderFilterType.Number,
            max: 1,
            min: 0,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'percent' },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.RealServiceLevel3Month}`,
            tooltip: `tables.${module}.${ColDefs.RealServiceLevel3Month}Tooltip`,
            value: ColDefs.RealServiceLevel3Month,
            width: 70
        }
    ];

    protected isDialogOn = false;

    protected isUpdating = false;

    protected items: Array<OrderByItems> = [];

    protected requiredSparseFields = ['warehouseId', 'productId', 'pwAmount', 'stockoutDateIsAvailable'];

    protected requireSummary = true;

    protected tableKey = module;

    protected url = CONSTANTS.API.ORDERS;

    // Navigation Guards
    public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<OrdersByItems>): void {
        const leaveRoute = (): void => {
            this.items = [];
            this.isEditMode = false;
            next();
        };
        if (this.canLeaveEditMode) {
            leaveRoute();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    leaveRoute();
                }
            });
        }
    }

    protected get canUpdateOrders(): boolean {
        return this.$ac.can(CONSTANTS.PERMISSIONS.UPDATE_ORDERS);
    }

    // Computed Getters
    protected get exportConfig(): ExportConfig {
        const sorts: Array<string> = [];
        Object.keys(this.sort).forEach((key) => {
            if (this.sort[key] !== null) {
                sorts.push(`${this.sort[key] ? '-' : ''}${key}`);
            }
        });
        const sort = sorts.join(',');
        return {
            filename: `export_objednavky_${this.ordersSemiGlobalFilter.join('_')}`,
            filter: {
                columns: this.selectedColumns.filter((column) => column.selected).map((column) => column.columnName),
                filter: this.params.filter,
                sort: sorts.length ? sort : ''
            },
            url: CONSTANTS.API.ORDER_EXPORTS
        };
    }

    protected get ordersSemiGlobalFilter(): Array<string> {
        return appStore.ordersSemiGlobalFilter.dateRange;
    }

    protected get pwDateRange(): Array<string> {
        return [appStore.pwDate, appStore.pwDate];
    }

    protected get semiGlobalFilter(): Expression | null {
        if (this.ordersSemiGlobalFilter.length) {
            return PrettyExpressionBuilder.and(
                PrettyExpressionBuilder.greaterThenOrEqual('orderDate', this.ordersSemiGlobalFilter[0]),
                PrettyExpressionBuilder.lowerThenOrEqual('orderDate', this.ordersSemiGlobalFilter[1])
            );
        } else {
            return null;
        }
    }

    // Sync Methods
    protected getAbc(abc: string): AbcValue | null {
        return abcValueStore.getOne(abc);
    }

    protected orderedAmountValidation(): Array<(value: string) => string | boolean> {
        return [
            (value: string): string | boolean => {
                return Number(value) >= 0;
            }
        ];
    }

    protected openProductCard(item: OrderByItems): void {
        const productInfoComponent = this.$refs.productInfo as { openProductInfo: (item: string) => void } & Vue;

        if (!this.isEditMode) {
            productInfoComponent.openProductInfo(String(item.attributes.productId));
        } else {
            if (this.canLeaveEditMode) {
                this.isEditMode = false;
                productInfoComponent.openProductInfo(String(item.attributes.productId));
            } else {
                void messagesStore.dispatchLeaveUpdateConfirm({
                    confirmCallbackFunc: () => {
                        this.isEditMode = false;
                        productInfoComponent.openProductInfo(String(item.attributes.productId));
                    }
                });
            }
        }
    }

    protected restoreOrderedAmountValue(item: OrderByItems): number | undefined {
        return item.attributes.pwAmount;
    }

    protected restoreOrderedAmountVisible(item: OrderByItems): boolean {
        return item.attributes.pwAmount !== item.attributes.orderedAmount;
    }

    // Async Methods
    protected async onActionTriggered(payload: ActionTriggerPayload): Promise<void> {
        this.isUpdating = true;
        const requestBody: UpdateRequestBody = {
            data: {},
            filter: '',
            meta: { count: payload.count }
        };
        if (payload.actionType === ActionType.UpdateState) {
            requestBody.data.stateId = payload.updatedResource.id;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (payload.actionType === ActionType.Delegate) {
            requestBody.data.assignedTo = payload.updatedResource.id;
        }
        if (this.selected.length) {
            const selectedIds = this.selected.join(', ');
            requestBody.filter = `id in (${selectedIds})`;
        } else {
            requestBody.filter = this.params.filter;
        }
        try {
            await this.axios.patch('orders', requestBody);
            const message = new Message(`${payload.count} ${this.$tc('messages.successItemsUpdated', payload.count)}`, undefined, {
                messageLevel: MessageLevel.Success,
                messageType: MessageType.Notification,
                timeout: 5000
            });
            messagesStore.sendMessage(message);
        } catch (error) {
            const messageKey = `messages.error${payload.actionType.charAt(0).toUpperCase()}${payload.actionType.substr(
                1,
                payload.actionType.length - 1
            )}`;
            if (payload.updatedResource.attributes) {
                const value = payload.updatedResource.attributes.name as string;
                const invalidCheckoutErrorHandler = new InvalidCheckoutErrorHandler(
                    messageKey,
                    value,
                    (payload) => this.onActionTriggered(payload),
                    payload.actionType,
                    payload.updatedResource
                );
                await ErrorService.dispatch(error, {
                    actions: {
                        actionText: this.$tc('messages.errorEditFailedActionText'),
                        confirmFunc: async (): Promise<void> => {
                            await this.onActionTriggered({
                                actionType: payload.actionType,
                                count: payload.count,
                                updatedResource: payload.updatedResource
                            });
                        }
                    },
                    context: this.$tc(messageKey),
                    customHandlers: [invalidCheckoutErrorHandler],
                    messageType: MessageType.Dialog
                });
            }
        } finally {
            this.isUpdating = false;
            (this.$refs.actions as { onCancel: () => void } & Vue).onCancel();
            await this.fetchData();
        }
    }

    protected async updateItemFunction(payload: {
        confirmFunc: () => void;
        header: TableHeader;
        item: OrderByItems;
        original: OrderByItems;
        secondaryFunc: () => void;
        value: unknown;
    }): Promise<AxiosResponse<OrderByItems>> {
        try {
            const response = {
                data: { [payload.header.value]: payload.value },
                meta: { original: payload.original }
            };
            return (await this.axios.patch<AxiosResponse<OrderByItems>>(`${this.url}/${payload.item.id}`, response)).data;
        } catch (error) {
            const messageKey = 'messages.errorEditConflict';
            const notUpToDateErrorHandler = new NotUpToDateErrorHandler(
                messageKey,
                this.headers,
                payload.value,
                payload.header,
                payload.confirmFunc,
                payload.secondaryFunc
            );
            const errors = await ErrorService.dispatch(error, {
                actions: {
                    actionText: this.$tc('messages.errorEditFailedActionText'),
                    confirmFunc: payload.confirmFunc,
                    secondaryFunc: payload.secondaryFunc
                },
                context: this.$tc('messages.errorEditFailed'),
                customHandlers: [notUpToDateErrorHandler],
                messageType: MessageType.Dialog
            });
            return Promise.reject(errors[0]);
        }
    }
}
