var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-editing-form d-flex"},[_c('div',{staticClass:"narrow-input"},[_c('v-text-field',{staticClass:"mx-1 my-2",attrs:{"value":_vm.originalValueRounded,"dense":"","disabled":!_vm.selectedPoints.length || _vm.disabled,"readonly":"","flat":"","hide-details":"","label":_vm.selectedPoints.length <= 1
                    ? _vm.$tc('components.salesPlanning.originalValue')
                    : _vm.$tc('components.salesPlanning.originalSumOfValues'),"outlined":"","type":"number"}})],1),_c('v-btn',{staticClass:"mx-1 my-2 px-1",attrs:{"disabled":!_vm.selectedPoints.length || _vm.disabled,"min-width":"40","outlined":""},on:{"click":_vm.toggleOperator}},[(_vm.selectedOperator === 'plus')?_c('v-icon',[_vm._v("mdi mdi-plus")]):_c('v-icon',[_vm._v("mdi mdi-minus")])],1),_c('div',{staticClass:"narrow-input"},[_c('v-text-field',{ref:"operandInput",staticClass:"mx-1 my-2",attrs:{"dense":"","disabled":!_vm.selectedPoints.length || _vm.disabled,"error":_vm.checkIfInputHasInvalidValue(_vm.operandInput),"flat":"","hide-details":"","label":_vm.reactiveOperandLabel,"outlined":"","rules":[_vm.numberInputRules.noExponent, _vm.numberInputRules.maxTwoDecimals],"type":"number"},on:{"input":_vm.onEditingFormChanged},model:{value:(_vm.operand),callback:function ($$v) {_vm.operand=$$v},expression:"operand"}})],1),_c('v-btn',{staticClass:"mx-1 my-2 px-1",attrs:{"disabled":!_vm.selectedPoints.length || _vm.disabled,"min-width":"40","outlined":""},on:{"click":_vm.toggleOperandUnits}},[(_vm.selectedOperandUnits === 'mj')?_c('span',[_vm._v(" "+_vm._s(_vm.$tc('components.salesPlanning.unitsShortcut'))+" ")]):_c('span',[_vm._v("%")])]),_c('div',{staticClass:"narrow-input"},[_c('v-text-field',{ref:"finalValueInput",staticClass:"mx-1 my-2",attrs:{"dense":"","disabled":!_vm.selectedPoints.length || _vm.disabled,"error":_vm.checkIfInputHasInvalidValue(_vm.finalValueInput),"flat":"","hide-details":"","label":_vm.reactiveFinalValueLabel,"outlined":"","rules":[_vm.numberInputRules.noExponent, _vm.numberInputRules.maxTwoDecimals],"type":"number"},on:{"input":_vm.onFinalValueInput},model:{value:(_vm.finalValueSync),callback:function ($$v) {_vm.finalValueSync=$$v},expression:"finalValueSync"}})],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onTooltip = ref.on;
return [_c('div',_vm._g({},onTooltip),[_c('v-btn',{staticClass:"mx-1 my-2 px-1",attrs:{"color":"secondary","disabled":_vm.originalValueRounded === _vm.finalValueSync || _vm.disabled,"min-width":"50"},on:{"click":_vm.onRestoreSelectedPointsClicked}},[_c('v-icon',{attrs:{"color":"icon"}},[_vm._v("mdi mdi-backup-restore")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$tc('components.salesPlanning.restoreSelectedPoints')))])]),_c('v-divider',{staticClass:"divider-vertical graph-edit-form my-2",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onTooltip = ref.on;
return [_c('div',_vm._g({},onTooltip),[_c('v-btn',{staticClass:"mx-1 my-2 px-1",attrs:{"color":"secondary","disabled":!_vm.isEditedSeriesDirty || _vm.disabled,"min-width":"50"},on:{"click":_vm.onRestoreAllPointsClicked}},[_c('v-icon',{attrs:{"color":"icon"}},[_vm._v("mdi mdi-restore")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$tc('components.salesPlanning.restoreAll')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onTooltip = ref.on;
return [_c('div',_vm._g({},onTooltip),[_c('v-btn',{staticClass:"mx-1 my-2",attrs:{"color":"primary","disabled":!_vm.isEditedSeriesDirty || _vm.disabled,"min-width":"50"},on:{"click":_vm.onConfirmChanges}},[(!_vm.disabled)?_c('v-icon',[_vm._v("mdi mdi-check")]):_c('v-icon',[_vm._v("mdi mdi-spin mdi-loading")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$tc('components.salesPlanning.confirmChanges')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }