import { CompoundDocument, PrettyExpressionBuilder } from '@bednic/json-api-client';
import axios from '@/plugins/http';
import type { ClientKeycloakTokenParsed } from '@/model/Interfaces/ClientKeycloakTokenParsed';
import { CONSTANTS } from '@/constants';
import type { User } from '@/model/Entity/User';

export default class AuthService {
    public permissions: Array<string> = [];

    public user!: User;

    public constructor(public readonly token: ClientKeycloakTokenParsed) {}

    public get userRoles(): Array<string> {
        return this.token.resource_access && typeof this.token.resource_access[this.token.clientId] !== 'undefined'
            ? this.token.resource_access[this.token.clientId].roles
            : [];
    }

    public async fetchUser(preferredUsername: string): Promise<User> {
        const doc = new CompoundDocument<User>('users', axios);
        doc.filter().andWhere(PrettyExpressionBuilder.equal('username', preferredUsername));
        await doc.self();
        const data = doc.data;
        const user = !data ? null : !Array.isArray(data) ? data : data[0];
        if (user) {
            return user;
        } else {
            throw new Error(`No user as ${this.token.preferred_username}`);
        }
    }

    public async fetchUserPermissions(): Promise<Array<string>> {
        const permissionsResponse = await axios.get<{ data: Array<string> }>(CONSTANTS.API.USER_PERMISSIONS);
        const permissions = permissionsResponse.data.data;
        if (permissions.length && permissions.includes(CONSTANTS.PERMISSIONS.APPLICATION)) {
            return permissions;
        } else {
            throw new Error(CONSTANTS.ERRORS.NO_ACCESS);
        }
    }

    public async auth(): Promise<void> {
        if (!this.userRoles.length) {
            throw new Error(CONSTANTS.ERRORS.NO_ROLES);
        }
        if (this.token.preferred_username) {
            this.user = await this.fetchUser(this.token.preferred_username);
            this.permissions = await this.fetchUserPermissions();
        } else {
            throw new Error('Not valid token');
        }
    }
}
