




















































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import messagesStore from '@/store/modules/MessagesStore';
import moment from 'moment';
import _ from 'lodash';

enum InputType {
    DateFrom = 'dateFrom',
    DateTo = 'dateTo'
}

type DateFromInputComponent = {
    focus: () => void;
} & Vue;

@Component({ components: { ConfirmationButtons } })
export default class DateRangePicker extends Vue {
    // Props
    @Prop({ default: true, required: false })
    protected canBeChanged!: boolean;

    @Prop({ required: true, type: String })
    protected currentDate!: string;

    @Prop({ default: false, required: true })
    protected dateRange!: Array<string>;

    @Prop({ default: false, required: false, type: Boolean })
    protected disabled!: boolean;

    @Prop({ default: false, required: false, type: Boolean })
    protected months!: boolean;

    // Data
    protected activeInput: InputType = InputType.DateFrom;

    protected currentValue: Array<string> = [];

    protected inputType = InputType;

    protected isDialogOpened = false;

    protected shownMonth = '';

    // Computed
    protected get formattedDateFrom(): string | null {
        if (this.currentValue.length) {
            if (this.months) {
                return moment(this.currentValue[0]).startOf('month').format('YYYY-MM-DD');
            } else {
                return this.currentValue[0];
            }
        }
        return null;
    }

    protected get formattedDateRange(): Array<Date> {
        return this.dateRange.map((date: string) => {
            return new Date(date);
        });
    }

    protected get formattedDateTo(): string | null {
        if (this.currentValue.length === 2) {
            if (this.months) {
                return moment(this.currentValue[1]).endOf('month').format('YYYY-MM-DD');
            } else {
                return this.currentValue[1];
            }
        }
        return null;
    }

    protected get isValid(): boolean {
        return this.currentValue.length === 2 && !_.isEqual(this.dateRange, this.currentValue);
    }

    // Sync Methods
    public closeDialog(): void {
        this.isDialogOpened = false;
    }

    protected changeDateToMonth(isoDate: string): string {
        return isoDate.slice(0, 7);
    }

    protected dateRangeSelected(): void {
        this.sortDateOrder();
        if (this.months) {
            this.shownMonth = this.changeDateToMonth(this.currentValue[0]);
        }
    }

    protected resetCurrentValue(): void {
        if (typeof this.dateRange !== 'undefined') {
            if (this.months) {
                this.currentValue = this.dateRange.map((item) => this.changeDateToMonth(item));
            } else {
                if (this.dateRange.length) {
                    this.currentValue = this.dateRange;
                } else {
                    this.currentValue = [];
                }
            }
        }
    }

    protected sortDateOrder(): void {
        if (this.currentValue[0] > this.currentValue[1]) {
            this.currentValue = [this.currentValue[1], this.currentValue[0]];
        }
    }

    // Emitters
    @Emit('date-range-change')
    protected changeDateFilter(): Array<string> {
        return this.currentValue;
    }

    // Event Handlers
    protected onPickDate(date: string): void {
        if (this.activeInput === this.inputType.DateFrom) {
            this.currentValue = [];
            this.currentValue[0] = date;
            (this.$refs.dateToInput as DateFromInputComponent).focus();
            this.activeInput = this.inputType.DateTo;
        } else {
            // Reset of dateTo as workaround for bug - changing this.dateRange as well
            const dateFrom = this.currentValue[0];
            this.currentValue = [];
            this.currentValue[0] = dateFrom;

            this.currentValue[1] = date;
            (this.$refs.dateFromInput as DateFromInputComponent).focus();
            this.activeInput = this.inputType.DateFrom;
            this.$nextTick(() => this.dateRangeSelected());
        }
    }

    protected onPickMonth(month: string): void {
        if (this.months) {
            if (this.activeInput === this.inputType.DateFrom) {
                this.currentValue = [];
                this.currentValue[0] = month;
                (this.$refs.dateToInput as HTMLInputElement).focus();
                this.activeInput = this.inputType.DateTo;
            } else {
                // Reset of dateTo as workaround for bug - changing this.dateRange as well
                const dateFrom = this.currentValue[0];
                this.currentValue = [];
                this.currentValue[0] = dateFrom;

                this.currentValue[1] = month;
                (this.$refs.dateFromInput as DateFromInputComponent).focus();
                this.activeInput = this.inputType.DateFrom;
                this.$nextTick(() => this.dateRangeSelected());
            }
        }
    }

    @Watch('dateRange', { immediate: true })
    protected onDateRangeChange(): void {
        this.resetCurrentValue();
    }

    protected onConfirm(): void {
        if (this.canBeChanged) {
            this.changeDateFilter();
            this.closeDialog();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    this.changeDateFilter();
                    this.closeDialog();
                }
            });
        }
    }

    protected onDateFromInputFocus(): void {
        this.activeInput = InputType.DateFrom;
    }

    protected onDateToInputFocus(): void {
        this.activeInput = InputType.DateTo;
    }

    protected openDialog(): void {
        this.isDialogOpened = true;
    }

    protected onCancel(): void {
        this.resetCurrentValue();
        this.closeDialog();
    }
}
