import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Keycloak, { KeycloakInstance } from 'keycloak-js';
import type { ClientKeycloakTokenParsed } from '@/model/Interfaces/ClientKeycloakTokenParsed';
import { CONSTANTS } from '@/constants';
import { i18n } from '@/plugins/i18n';
import type { KeycloakConfig } from '@/model/Interfaces/Config';
import { makeInterceptor } from '@/plugins/http';
import { Message } from '@/model/Classes/Message';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import { socketUpdateBearer } from '@/plugins/socket';
import Vue from 'vue';

let keycloakInstance: KeycloakInstance;

export const createKeycloakInstance = (keycloak: KeycloakConfig): KeycloakInstance => {
    keycloakInstance = Keycloak({
        clientId: keycloak.resource,
        realm: keycloak.realm,
        url: keycloak['auth-server-url']
    });
    keycloakInstance.onTokenExpired = async (): Promise<void> => {
        await refreshToken();
        const token = getToken();
        makeInterceptor(token.bearer);
    };
    Vue.use(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        Vue.prototype.$keycloak = keycloakInstance;
    });
    return keycloakInstance;
};

export const authenticate = async (): Promise<void> => {
    try {
        const authenticated = await keycloakInstance.init({
            checkLoginIframe: false,
            onLoad: 'login-required',
            redirectUri: window.location.href
        });
        if (!authenticated) {
            throw new Error(CONSTANTS.ERRORS.NOT_AUTHENTICATED);
        }
    } catch (error) {
        if (error instanceof Error) {
            if (error.message === CONSTANTS.ERRORS.NOT_AUTHENTICATED) {
                throw new Error(CONSTANTS.ERRORS.NOT_AUTHENTICATED);
            }
            throw new Error(CONSTANTS.ERRORS.KEYCLOAK(error));
        }
    }
};

export const refreshToken = async (): Promise<boolean> => {
    try {
        const refreshed = await keycloakInstance.updateToken(5);
        if (refreshed) {
            messagesStore.sendMessage(
                new Message(i18n.tc('messages.successTokenRefreshed'), undefined, {
                    messageLevel: MessageLevel.Success,
                    messageType: MessageType.Console
                })
            );
        } else {
            messagesStore.sendMessage(
                new Message('messages.warningTokenStillValid', undefined, {
                    messageLevel: MessageLevel.Warning,
                    messageType: MessageType.Console
                })
            );
        }
        return refreshed;
    } catch (error) {
        return Promise.reject(new Error(CONSTANTS.ERRORS.REFRESH_TOKEN));
    }
};

export const updateTokenOnRequestFailed = async (
    originalRequest: AxiosRequestConfig
): Promise<void | AxiosResponse<unknown>> => {
    try {
        await refreshToken();
        const token = getToken();
        socketUpdateBearer(token.bearer);
        makeInterceptor(token.bearer);
        return await axios(originalRequest);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getToken = (): ClientKeycloakTokenParsed => {
    const tokenParsed = keycloakInstance.tokenParsed as ClientKeycloakTokenParsed;
    tokenParsed.bearer = keycloakInstance.token as string;
    tokenParsed.clientId = keycloakInstance.clientId as string;
    return tokenParsed;
};
