


















import { Component, Model, Prop, Vue } from 'vue-property-decorator';

interface ProgressItem {
    amount: number;
    color: string;
    label: string;
    percentage: number;
}

@Component
export default class StackedProgressBar extends Vue {
    @Prop({ default: null, required: false, type: String })
    protected sort!: 'asc' | 'desc' | null;

    @Model('change', { default: {}, required: false, type: Array })
    protected data!: Array<ProgressItem>;

    protected get resolvedData(): Array<ProgressItem> {
        const totalCount: number = this.data.reduce((total, curr) => total + curr.amount, 0);
        let data = [...this.data];

        // Sort
        if (this.sort) {
            const signification = this.sort === 'asc' ? -1 : 1;
            data.sort((i1, i2) => signification * (i2.amount - i1.amount));
        }

        return data.map((data) => ({
            ...data,
            percentage: data.amount / totalCount
        }));
    }
}
