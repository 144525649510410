import { AppRouter } from '@/router';
import ChangeListSummary from '@/views/ChangeListSummary.vue';
import { CONSTANTS } from '@/constants';
import Forecast from '@/views/Forecast.vue';
import type { NavigationRouteConfig } from './model/NavigationRouteConfig';
import SalesPlanningWrapper from '@/views/SalesPlanningWrapper.vue';

const SalesPlanningModule: Record<string, NavigationRouteConfig> = {
    [CONSTANTS.ROUTES.SALES_PLANNING.NAME]: {
        children: [
            {
                component: Forecast,
                icon: 'mdi-weather-partly-cloudy',
                isInNavigation: true,
                name: CONSTANTS.ROUTES.SALES_PLANNING.CHILDREN.FORECAST.NAME,
                path: CONSTANTS.ROUTES.SALES_PLANNING.CHILDREN.FORECAST.PATH,
                requiredPermissions: []
            },
            {
                component: ChangeListSummary,
                icon: 'mdi-package-variant',
                isInNavigation: true,
                name: CONSTANTS.ROUTES.SALES_PLANNING.CHILDREN.CHANGE_LIST_SUMMARY.NAME,
                path: CONSTANTS.ROUTES.SALES_PLANNING.CHILDREN.CHANGE_LIST_SUMMARY.PATH,
                requiredPermissions: []
            }
        ],
        component: SalesPlanningWrapper,
        icon: '$vuetify.icons.logio-bar-chart',
        isInNavigation: true,
        name: CONSTANTS.ROUTES.SALES_PLANNING.NAME,
        path: CONSTANTS.ROUTES.SALES_PLANNING.PATH,
        redirect: () =>
            AppRouter.activeChildRoute(CONSTANTS.ROUTES.SALES_PLANNING.PATH, {
                name: CONSTANTS.ROUTES.SALES_PLANNING.CHILDREN.FORECAST.NAME,
                path: CONSTANTS.ROUTES.SALES_PLANNING.CHILDREN.FORECAST.PATH
            }),
        requiredPermissions: [CONSTANTS.PERMISSIONS.READ_MODULE_SP]
    }
};

export default SalesPlanningModule;
