





















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import type { PaginationType } from '@/model/Interfaces/PaginationType';

@Component
export default class PageSizeSelect extends Vue {
    // Data
    @Prop({ default: true, required: false })
    protected canBeChanged!: boolean;

    @Prop({ default: false, required: false })
    protected dense!: boolean;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @PropSync('pagination', { required: true, type: Object })
    protected paginationSync!: PaginationType;

    // Sync Methods
    protected changePageSize(value: number): void {
        this.$set(this.paginationSync, 'pageSize', value);
    }
}
