













import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import appStore from '@/store/modules/AppStore';

@Component
export default class EditModeButton extends Vue {
    @Prop({ default: false, required: false, type: Boolean })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected isEditMode!: boolean;

    // Computed
    protected get isDisabled(): boolean {
        return appStore.isReadOnlyMode || this.disabled;
    }

    @Emit('isEditModeChange')
    protected onEditModeChange(): boolean {
        return true;
    }
}
