





























































import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import type NumberFilterClass from '@/model/Data/Filters/NumberFilterClass';
import type { TableHeader } from '@/model/Interfaces/TableHeader';

@Component
export default class NumberFilter extends Vue {
    //Data
    @Prop()
    protected confirmBtnDisabled!: boolean;

    @Prop({ required: true })
    protected currentFilter!: NumberFilterClass;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected header!: TableHeader;

    protected filterOptions = CONSTANTS.FILTERS.FILTER_OPTIONS;

    @PropSync('isValid', { required: true, type: Boolean })
    protected valid!: boolean;

    // Lifecycle Hooks
    public mounted(): void {
        this.onFilterChanged();
    }

    // Computed
    protected get validations(): Record<string, unknown> {
        return {
            from: [
                (value: string): string | boolean =>
                    !value ||
                    (!this.currentFilter.value.to.length && !!value.length) ||
                    (this.currentFilter.value.to.length && Number(value) <= Number(this.currentFilter.value.to)) ||
                    this.translate('components.filters.number.validationFrom')
            ],
            to: [
                (value: string): string | boolean =>
                    !value ||
                    (!this.currentFilter.value.from.length && !!value.length) ||
                    (this.currentFilter.value.from.length && Number(value) >= Number(this.currentFilter.value.from)) ||
                    this.translate('components.filters.number.validationTo')
            ]
        };
    }

    // Sync Methods
    protected translate(key: string): string {
        return this.$te(key) ? this.$tc(key).toString() : 'Not valid';
    }

    // Emitters
    @Emit()
    protected filterChanged(): NumberFilterClass {
        return this.currentFilter;
    }

    // Event Handlers
    protected onEnterPressed(): void {
        if (!this.confirmBtnDisabled) {
            this.$emit('confirm');
        }
    }

    protected onFilterChanged(): void {
        this.filterChanged();
    }

    protected onRadioClicked(id: number): void {
        const radio = document.getElementById(`radio${id}`);
        if (radio) {
            radio.focus();
        }
    }
}
