











































import { Component, Emit } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import UserViewsDialog from '@/components/common/Table/userViews/UserViewsDialog';

@Component({
    // eslint-disable-next-line no-undef
    components: { ConfirmationButtons }
})
export default class CreateDialog extends UserViewsDialog {
    // Data
    protected isFormValid = false;

    protected newName = '';

    // Methods
    protected onCancel(): void {
        this.syncedActiveDialog = null;
        this.newName = '';
    }

    @Emit('createView')
    protected createView(): string {
        return this.newName;
    }

    protected onConfirm(): void {
        if (this.isFormValid) {
            this.createView();
            this.onCancel();
        }
    }
}
