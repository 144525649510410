import { Action, getModule, Module } from 'vuex-module-decorators';
import { CONSTANTS } from '@/constants';
import { DataStore } from '@/store/DataStore';
import EntityService from '@/services/EntityService';
import type { State } from '@/model/Entity/State';
import store from '@/store';

const module = 'stateStore';

export interface StateStoreState {
    entityService: EntityService;
    items: Array<State>;
}

@Module({
    dynamic: true,
    name: module,
    namespaced: true,
    store
})
class StateStore extends DataStore<State> implements StateStoreState {
    public entityService = new EntityService(CONSTANTS.API.STATES);

    // TODO DELETE WHEN COLOR DATA AVAILABLE ON BACKEND
    @Action({ rawError: true })
    public async fetchAll(): Promise<void> {
        await super.fetchAll();

        const colors: Record<string, string> = {
            '1': '#90A4AE', // Navržená
            '2': '#FF8F00', // Autorizovaná
            '3': '#B71C1C', // Zamítnutá
            '4': '#00B8D4' // Exportovaná
        };

        // Extend State by one of the colors
        this.context.commit(
            'setItems',
            this.items.map((state) => ({
                ...state,
                attributes: {
                    ...state.attributes,
                    color: colors[state.id]
                }
            }))
        );
    }
}

export default getModule(StateStore);
