




/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import {
    DependencyTypes,
    PwngLineChartDependency,
    PwngLineChartDragEvent,
    PwngLineChartDropEvent,
    PwngLineChartHideEvent,
    PwngLineChartPoint,
    PwngLineChartSelectEvent,
    PwngLineChartSeries,
    PwngLineChartUnselectEvent
} from '@/model/types/PwngLineChart';
import Highcharts, { Chart, Point } from 'highcharts';
import type { ChartOptions } from '@/components/charts/ChartOptions';
import dragDropInit from 'highcharts/modules/draggable-points';
import exportingInit from 'highcharts/modules/exporting';
import { Chart as HighchartsVue } from 'highcharts-vue';
import noDataInit from 'highcharts/modules/no-data-to-display';

@Component({
    components: { highcharts: HighchartsVue }
})
export default class LineChart extends Vue {
    // Props and Data
    @Prop({ required: true, type: Object })
    protected chartOptions!: ChartOptions;

    @Prop({ required: true, type: Array })
    protected seriesSetup!: Array<PwngLineChartSeries>;

    protected chart!: Chart;

    protected isTooltipEnabled = true;

    @PropSync('selectedPoints')
    protected selectedPointsSync!: Array<Point>;

    @PropSync('series')
    protected seriesSync!: Array<PwngLineChartSeries>;

    public created(): void {
        this.initChart();
    }

    // Computed
    protected get chartOptionsWithSeries(): ChartOptions {
        let options = { ...this.chartOptions };
        options.series = this.seriesSync;
        options.tooltip = {
            enabled: this.isTooltipEnabled,
            ...(this.chartOptions.tooltip || {})
        };
        this.setNestedValue(
            'plotOptions.series.point.events.drag',
            (event: PwngLineChartDragEvent): void => {
                this.isTooltipEnabled = false;
                this.selectPointOnDrag(event);
                this.pointDrag(event.newPoint ? Number(event.newPoint.y) : 0);
            },
            options
        );
        this.setNestedValue(
            'plotOptions.series.point.events.drop',
            (event: PwngLineChartDropEvent): void => {
                this.isTooltipEnabled = true;
                this.updateDataOnDrop(event);
            },
            options
        );
        this.setNestedValue(
            'plotOptions.series.point.events.select',
            (event: PwngLineChartSelectEvent): void => {
                this.updateSelectedPointsOnSelect(event);
            },
            options
        );
        this.setNestedValue(
            'plotOptions.series.point.events.unselect',
            (event: PwngLineChartUnselectEvent): void => {
                this.updateSelectedPointsOnUnselect(event);
            },
            options
        );
        this.setNestedValue(
            'plotOptions.series.events.hide',
            (event: PwngLineChartHideEvent): void => {
                this.unselectPointsOnHideSeries(event);
            },
            options
        );
        return options;
    }

    // Sync methods
    public hideLoading(): void {
        this.chart.hideLoading();
    }

    public showLoading(): void {
        this.chart.showLoading(this.$tc('components.highcharts.loading'));
    }

    protected getSeriesById(id: string, isOriginal?: boolean): PwngLineChartSeries | undefined {
        if (isOriginal) {
            return this.seriesSync.find((series) => series.id === `${id}Original`);
        }
        return this.seriesSync.find((series) => series.id === id);
    }

    protected initChart(): void {
        noDataInit(Highcharts);
        dragDropInit(Highcharts);
        if (this.chartOptions.export) {
            exportingInit(Highcharts);
        }
        Highcharts.setOptions({
            lang: {
                decimalPoint: this.$tc('components.highcharts.decimalPoint'),
                downloadJPEG: this.$tc('components.highcharts.downloadJPEG'),
                downloadPDF: this.$tc('components.highcharts.downloadPDF'),
                downloadPNG: this.$tc('components.highcharts.downloadPNG'),
                downloadSVG: this.$tc('components.highcharts.downloadSVG'),
                exitFullscreen: this.$tc('components.highcharts.exitFullscreen'),
                loading: this.$tc('components.highcharts.loading'),
                months: [
                    this.$tc('components.highcharts.months.january'),
                    this.$tc('components.highcharts.months.february'),
                    this.$tc('components.highcharts.months.march'),
                    this.$tc('components.highcharts.months.april'),
                    this.$tc('components.highcharts.months.may'),
                    this.$tc('components.highcharts.months.june'),
                    this.$tc('components.highcharts.months.july'),
                    this.$tc('components.highcharts.months.august'),
                    this.$tc('components.highcharts.months.semptember'),
                    this.$tc('components.highcharts.months.october'),
                    this.$tc('components.highcharts.months.november'),
                    this.$tc('components.highcharts.months.december')
                ],
                noData: this.$tc('components.highcharts.noData'),
                printChart: this.$tc('components.highcharts.printChart'),
                viewFullscreen: this.$tc('components.highcharts.viewFullscreen')
            }
        });
    }

    protected removeHighchartsCredits(): void {
        const highchartsCredits = document.getElementsByClassName('highcharts-credits');
        if (highchartsCredits.length) {
            const element = highchartsCredits[0];
            element.remove();
        }
    }

    protected selectPointOnDrag(event: PwngLineChartDragEvent): void {
        if (this.selectedPointsSync.length !== 1 || this.selectedPointsSync[0] !== event.target) {
            this.selectedPointsSync.forEach((point) => {
                point.select(false, false);
            });
            event.target.select(true, false);
        }
    }

    protected setNestedValue(propertyPath: string | Array<string>, value: unknown, obj: Record<string, unknown>): void {
        let properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split('.');
        if (properties.length > 1) {
            if (!Object.prototype.hasOwnProperty.call(obj, properties[0]) || typeof obj[properties[0]] !== 'object') {
                obj[properties[0]] = {};
            }
            this.setNestedValue(properties.slice(1), value, obj[properties[0]] as Record<string, unknown>);
        } else {
            obj[properties[0]] = value;
        }
    }

    protected unselectPointsOnHideSeries(event: PwngLineChartHideEvent): void {
        this.selectedPointsSync.forEach((point) => {
            if (point.series.options.id === event.target.options.id) {
                point.select(false, false);
            }
        });
    }

    protected updateDataOnDrop(event: PwngLineChartDropEvent): void {
        const seriesId = event.target.series.options.id;
        const pointIndex = event.target.index;
        const targetSeries = this.seriesSync.find((series) => series.id === seriesId);
        if (targetSeries && event.newPoint && event.newPoint.y) {
            targetSeries.data[pointIndex].y = event.newPoint.y;
        }
        this.dragDrop();
    }

    protected updateSelectedPointsOnSelect(event: PwngLineChartSelectEvent): void {
        this.selectedPointsSync.forEach((point) => {
            if (!event.target.series.data.includes(point)) {
                point.select(false, false);
                this.selectedPointsSync.splice(this.selectedPointsSync.indexOf(point), 1);
            }
        });
        this.selectedPointsSync.push(event.target);
    }

    protected updateSelectedPointsOnUnselect(event: PwngLineChartUnselectEvent): void {
        const pointToRemove = this.selectedPointsSync.find((point) => {
            return point.x === event.target.x && point.y === event.target.y;
        });
        if (pointToRemove) {
            this.selectedPointsSync.splice(this.selectedPointsSync.indexOf(pointToRemove), 1);
        }
    }

    // Event handlers
    @Watch('seriesSetup')
    protected onSeriesMapChanged(): void {
        let updatedSeries = this.seriesSync.filter((series) => {
            if (!this.seriesSetup.length) {
                return false;
            }
            return this.seriesSetup.some((item) => {
                return item.id == series.id || `${item.id}Original` == series.id;
            });
        });
        for (const seriesTemplate of this.seriesSetup) {
            if (!updatedSeries.some((series) => series.id === seriesTemplate.id || series.id === `${seriesTemplate.id}Original`)) {
                if (seriesTemplate.custom && seriesTemplate.custom.originalSeries) {
                    const originalSeries = seriesTemplate.custom.originalSeries;
                    updatedSeries.push({
                        allowPointSelect: false,
                        color: seriesTemplate.color,
                        dashStyle: originalSeries.dashStyle,
                        data: JSON.parse(JSON.stringify(seriesTemplate.data)) as Array<PwngLineChartPoint>,
                        dragDrop: {
                            dragMinY: 0,
                            draggableY: false
                        },
                        id: `${seriesTemplate.id}Original`,
                        marker: originalSeries.marker,
                        name: originalSeries.name,
                        type: 'line',
                        zIndex: originalSeries.zIndex
                    });
                }
                updatedSeries.push(seriesTemplate);
            }
        }
        this.seriesSync = updatedSeries;
    }

    @Watch('seriesSync', { deep: true })
    protected onSeriesSyncChanged(value: Array<PwngLineChartSeries>): void {
        value.forEach((series: PwngLineChartSeries) => {
            if (series.custom?.originalSeries && series.custom.dependentSeries && series.custom.dependentSeries.length) {
                series.data.forEach((point: PwngLineChartPoint, index: number) => {
                    const originalSeries = this.getSeriesById(series.id, true);
                    const originalPointValue = originalSeries?.data[index].y;
                    if (originalPointValue !== undefined) {
                        const difference = point.y - originalPointValue;
                        series.custom?.dependentSeries?.forEach((dependency: PwngLineChartDependency) => {
                            const dependentSeries = this.getSeriesById(dependency.dependentSeriesId);
                            const dependentOriginalSeries = this.getSeriesById(dependency.dependentSeriesId, true);
                            if (dependentSeries?.data[index] && dependentOriginalSeries?.data[index]) {
                                if (dependency.type === DependencyTypes.Binded) {
                                    dependentSeries.data[index].y = dependentOriginalSeries.data[index].y + difference;
                                }
                                // Therefore dependency type equals polarized
                                else {
                                    dependentSeries.data[index].y = dependentOriginalSeries.data[index].y - difference;
                                }
                            }
                        });
                    }
                });
            }
        });
    }

    // Emitters
    @Emit('rendered')
    protected onRendered(chart: Chart): Chart {
        this.chart = chart;
        this.removeHighchartsCredits();
        return chart;
    }

    @Emit('pointDrag')
    protected pointDrag(value: number): number {
        return value;
    }

    @Emit('dragDrop')
    protected dragDrop(): boolean {
        return true;
    }
}
