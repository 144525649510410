




















































































































































import { TableHeaderFilterType, TableHeaderRenderer } from '@/model/Interfaces/TableHeader';
import { Component, Ref } from 'vue-property-decorator';
import type { Expression } from '@bednic/json-api-client';
import CompoundDocument from '@bednic/json-api-client';
import ActiveFilters from '@/components/common/Table/ActiveFilters.vue';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';
import authorStore from '@/store/modules/AuthorStore';
import type { ChangeList } from '@/model/Entity/ChangeList';
import type { ChangeListState } from '@/model/Entity/ChangeListState';
import changeListStateStore from '@/store/modules/ChangeListStateStore';
import ColumnView from '@/components/common/Table/ColumnViews.vue';
import { CONSTANTS } from '@/constants';
import type { DataStore } from '@/store/DataStore';
import EditModeButton from '@/components/common/Table/EditModeButton.vue';
import ErrorService from '@/services/ErrorService/ErrorService';
import LTable from '@/components/common/Table/Table.vue';
import { MessageType } from '@/model/Enums/MessageType';
import { mixins } from 'vue-class-component';
import PageSizeSelect from '@/components/common/Table/PageSizeSelect.vue';
import Pagination from '@/components/common/Table/Pagination.vue';
import TableActionDialog from '@/components/common/Table/TableActions/TableActionDialog.vue';
import TableActions from '@/components/common/Table/TableActions/TableActions.vue';
import type { TableHeader } from '@/model/Interfaces/TableHeader';
import TableMixin from '@/mixins/TableMixin';
import type { User } from '@/model/Entity/User';
import userStore from '@/store/modules/UserStore';
import UserViews from '@/components/common/Table/userViews/UserViews.vue';

enum ColDefs {
    AuthorId = 'authorId',
    ChangeCount = 'changeCount',
    CreatedAt = 'createdAt',
    Name = 'name',
    StateId = 'stateId',
    UpdatedAt = 'updatedAt'
}

const module = CONSTANTS.MODULES.CHANGE_LIST_SUMMARY;

@Component({
    components: {
        ActiveFilters,
        ColumnView,
        EditModeButton,
        LTable,
        PageSizeSelect,
        Pagination,
        TableActionDialog,
        TableActions,
        UserViews
    }
})
export default class ChangeListSummary extends mixins<TableMixin<ChangeList>>(TableMixin) {
    // Refs
    @Ref('mainContainerRef')
    private readonly mainContainerRef!: HTMLElement;

    @Ref('otherContentRef')
    protected otherContentRef!: HTMLElement;

    // Data
    protected deleteDialog = false;

    protected headers: Array<TableHeader> = [
        {
            contentAlign: 'center',
            default: true,
            filterEnumText: 'attributes.name',
            filterStoreInit: (): DataStore<ChangeListState> => changeListStateStore,
            filterType: TableHeaderFilterType.Enum,
            renderer: TableHeaderRenderer.State,
            sortable: true,
            text: `tables.${module}.${ColDefs.StateId}`,
            value: ColDefs.StateId,
            width: 55
        },
        {
            default: true,
            editable: true,
            filterAutocompleteURL: CONSTANTS.API.SP_CHANGE_LISTS,
            filterType: TableHeaderFilterType.ResourceText,
            renderer: TableHeaderRenderer.Text,
            resourceFilterOptions: {
                additionalFields: [],
                containsKey: 'name',
                filterBy: 'id'
            },
            sortable: true,
            text: `tables.${module}.${ColDefs.Name}`,
            value: ColDefs.Name,
            width: 160
        },
        {
            default: true,
            filterEnumText: 'attributes.name',
            filterStoreInit: (): DataStore<User> => authorStore,
            filterType: TableHeaderFilterType.Enum,
            renderer: TableHeaderRenderer.User,
            sortable: true,
            text: `tables.${module}.${ColDefs.AuthorId}`,
            value: ColDefs.AuthorId,
            width: 120
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 0, minimumFractionDigits: 0 },
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.ChangeCount}`,
            value: ColDefs.ChangeCount,
            width: 70
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Date,
            renderer: TableHeaderRenderer.Date,
            sortable: true,
            text: `tables.${module}.${ColDefs.CreatedAt}`,
            value: ColDefs.CreatedAt,
            width: 90
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Date,
            renderer: TableHeaderRenderer.Date,
            sortable: true,
            text: `tables.${module}.${ColDefs.UpdatedAt}`,
            value: ColDefs.UpdatedAt,
            width: 90
        }
    ];

    protected isDeleting = false;

    protected items: Array<ChangeList> = [];

    protected tableKey = module;

    protected url = CONSTANTS.API.SP_CHANGE_LISTS;

    // Lifecycle Hooks
    public mounted(): void {
        this.setElements();
    }

    public async beforeCreate(): Promise<void> {
        await Promise.all([authorStore.fetchAll(), changeListStateStore.fetchAll(), userStore.fetchAll()]);
    }

    protected get canWriteChangeList(): boolean {
        return this.$ac.can(CONSTANTS.PERMISSIONS.WRITE_SP_CHANGE_LIST);
    }

    // Getters
    protected get globalFilterAsExpressions(): Array<Expression> {
        return [];
    }

    protected get hasNoFilters(): boolean {
        return false;
    }

    protected get selectedItemsCount(): number {
        if (this.selected.length) {
            return this.selected.length;
        }
        return this.total;
    }

    protected get tableHeight(): number {
        const tableCardPadding = 0;
        const tableHeight =
            this.$vuetify.breakpoint.height +
            (this.tableMarginTop - this.tableMarginTop * 2) -
            (appStore.elements.mainContainerPadding +
                appStore.elements.globalFilterHeight +
                appStore.elements.toolbarHeight +
                appStore.elements.tableActionButtonsHeight +
                appStore.elements.tableFooterHeight +
                tableCardPadding);
        return tableHeight < appStore.elements.minTableHeight ? appStore.elements.minTableHeight : tableHeight;
    }

    // Methods
    protected onOpenActionDialog(): void {
        this.deleteDialog = true;
        appStore.setActiveMenus({ [ActiveMenus.Actions]: false });
    }

    protected async onDeleteSelectedChanges(): Promise<void> {
        try {
            this.isDeleting = true;
            await Promise.all(
                this.selected.map(async (id) => {
                    const selectedChange = new CompoundDocument(`${CONSTANTS.API.SP_CHANGE_LISTS}/${id}`, this.axios);
                    return selectedChange.delete();
                })
            );
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorDeleteChangeList'),
                messageType: MessageType.Notification
            });
        } finally {
            this.selected = [];
            await this.fetchData();
            this.isDeleting = false;
            this.deleteDialog = false;
        }
    }
}
