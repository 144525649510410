























































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import draggable from 'vuedraggable';
import messagesStore from '@/store/modules/MessagesStore';

export interface Column {
    columnName: string;
    selected: boolean;
    text: string;
}

@Component({
    // eslint-disable-next-line no-undef
    components: { ConfirmationButtons, draggable }
})
export default class ColumnViews extends Vue {
    @Prop({ default: true, required: false })
    protected canBeChanged!: boolean;

    @Prop({ required: true, type: Array })
    protected savedColumns!: Array<Column>;

    protected isDirty = false;

    protected selectedColumns: Array<Column> = [];

    // Computed
    protected get isAllSelected(): boolean {
        return this.selectedColumns.every((item) => item.selected);
    }

    protected get isAnySelected(): boolean {
        return this.selectedColumns.some((item) => item.selected) && this.selectedColumns.some((item) => !item.selected);
    }

    protected get isOpen(): boolean {
        return appStore.activeMenu(ActiveMenus.ColumnViews);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [ActiveMenus.ColumnViews]: value });
    }

    // Sync Methods
    protected checkIfDirty(): void {
        this.isDirty = JSON.stringify(this.selectedColumns) !== JSON.stringify(this.savedColumns);
    }

    // Emitters
    @Emit('changeSelectedColumns')
    protected changeSelectedColumns(): Array<Column> {
        return this.selectedColumns;
    }

    @Emit('reloadPage')
    protected reloadPage(): boolean {
        return true;
    }

    // Event Handlers
    protected onCancel(): void {
        this.selectedColumns = this.savedColumns.map((item) => ({
            columnName: item.columnName,
            selected: item.selected,
            text: item.text
        }));
        this.isOpen = false;
    }

    protected onConfirm(): void {
        if (this.canBeChanged) {
            this.changeSelectedColumns();
            this.reloadPage();
            this.isOpen = false;
            this.isDirty = false;
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    this.changeSelectedColumns();
                    this.reloadPage();
                    this.isOpen = false;
                    this.isDirty = false;
                }
            });
        }
    }

    protected onToggleSelectedAll(): void {
        if (this.selectedColumns.some((item) => item.selected)) {
            this.selectedColumns.forEach((item) => (item.selected = false));
        } else {
            this.selectedColumns.forEach((item) => (item.selected = true));
        }
        this.checkIfDirty();
    }

    protected onToggleSelectedOne(columnName: string): void {
        const column = this.selectedColumns.find((item) => item.columnName === columnName);
        if (column) {
            column.selected = !column.selected;
        }
        this.checkIfDirty();
    }

    @Watch('savedColumns', { deep: true, immediate: true })
    public onSavedColumnsChange(savedColumns?: Array<Column>): void {
        if (typeof savedColumns !== 'undefined') {
            this.selectedColumns = savedColumns.map((item) => ({
                columnName: item.columnName,
                selected: item.selected,
                text: item.text
            }));
        }
    }

    @Watch('selectedColumns')
    protected onSelectedColumnsChanged(): void {
        this.checkIfDirty();
    }
}
