import '@mdi/font/css/materialdesignicons.css';
import { i18n } from '@/plugins/i18n';
import { icons } from '@/styles/icons';
import { Ripple } from 'vuetify/lib/directives';
import SvgIcon from '@/components/common/SvgIcon.vue';
import themes from '@/styles/themes';
import type { UserVuetifyPreset } from 'vuetify/types/services/presets';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

const iconsValues = Object.fromEntries(
    Object.entries(icons).map(([key, value]) => [
        key,
        {
            component: SvgIcon,
            props: value
        }
    ])
);

const options: UserVuetifyPreset = {
    directives: { Ripple },
    icons: {
        iconfont: 'mdi',
        values: iconsValues
    },
    lang: {
        current: i18n.locale,
        t: (key: string, ...params: Array<string | number>): string => i18n.t(key, params).toString()
    },
    theme: {
        options: {
            customProperties: true
        },
        themes
    }
};

Vue.use(Vuetify, options);

export default new Vuetify(options);
