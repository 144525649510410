































import { Component, Prop, Vue } from 'vue-property-decorator';
import AppStore from '@/store/modules/AppStore';
import type { SummaryEntity } from '@/mixins/TableMixin';

@Component
export default class Summary extends Vue {
    // Data
    @Prop({ required: true })
    protected isLoading!: boolean;

    @Prop({ required: true })
    protected summary!: SummaryEntity;

    // Computed getters
    protected get currency(): string {
        return AppStore.currency;
    }
}
