


























































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { TableHeader, TableHeaderFilterType } from '@/model/Interfaces/TableHeader';
import appStore from '@/store/modules/AppStore';
import BooleanFilter from '@/components/common/Table/filters/BooleanFilter.vue';
import BooleanFilterClass from '@/model/Data/Filters/BooleanFilterClass';
import type { ColumnFilterType } from '@/model/Entity/ColumnFilterType';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import DateFilter from '@/components/common/Table/filters/DateFilter.vue';
import DateFilterClass from '@/model/Data/Filters/DateFilterClass';
import EnumFilter from '@/components/common/Table/filters/EnumFilter.vue';
import EnumFilterClass from '@/model/Data/Filters/EnumFilterClass';
import EnumMultipleFilter from '@/components/common/Table/filters/EnumMultipleFilter.vue';
import EnumMultipleFilterClass from '@/model/Data/Filters/EnumMultipleFilterClass';
import messagesStore from '@/store/modules/MessagesStore';
import NumberFilter from '@/components/common/Table/filters/NumberFilter.vue';
import NumberFilterClass from '@/model/Data/Filters/NumberFilterClass';
import ResourceTextFilter from '@/components/common/Table/filters/ResourceTextFilter.vue';
import ResourceTextFilterClass from '@/model/Data/Filters/ResourceTextFilterClass';
import type TableFilters from '@/model/Data/Filters/TableFilters';
import TextFilter from '@/components/common/Table/filters/TextFilter.vue';
import TextFilterClass from '@/model/Data/Filters/TextFilterClass';

@Component({
    // eslint-disable-next-line no-undef
    components: {
        BooleanFilter,
        ConfirmationButtons,
        DateFilter,
        EnumFilter,
        EnumMultipleFilter,
        NumberFilter,
        ResourceTextFilter,
        TextFilter
    }
})
export default class ColumnFilter extends Vue {
    // Data
    @Prop({ default: true, required: false })
    protected canBeFilterChanged!: boolean;

    @Prop({ default: false, required: false, type: Boolean })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected header!: TableHeader;

    @Prop({ required: true })
    protected tableFilters!: TableFilters;

    protected currentFilter: ColumnFilterType | null = null;

    protected defaultFilter!: ColumnFilterType;

    protected isValid = true;

    protected savedFilter: ColumnFilterType | null = null;

    protected tableHeaderFilterType = TableHeaderFilterType;

    // Computed
    protected get cardWidth(): number {
        return this.filterType === TableHeaderFilterType.Number || this.filterType === TableHeaderFilterType.Date ? 300 : 500;
    }

    protected get filterBy(): string {
        return this.header.resourceFilterOptions ? this.header.resourceFilterOptions.filterBy : '';
    }

    protected get filterKey(): string {
        return this.header.filterKey || this.header.value;
    }

    protected get filterType(): string | undefined {
        return this.header.filterType;
    }

    protected get isDirty(): boolean {
        if (this.currentFilter && this.savedFilter) {
            return JSON.stringify(this.currentFilter.value) !== JSON.stringify(this.savedFilter.value);
        } else {
            return false;
        }
    }

    protected get isFilterOn(): boolean {
        const persistedFilter = this.tableFilters.getColumnFilter(this.filterKey);
        return !!persistedFilter;
    }

    protected get isOpen(): boolean {
        return appStore.activeMenu(`columnFilter${this.header.value}`);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [`columnFilter${this.header.value}`]: value });
    }

    // Sync Methods
    protected createFilter(key: string, alternativeKey: string): ColumnFilterType | null {
        switch (this.filterType) {
            case TableHeaderFilterType.Number: {
                const numberFilter = new NumberFilterClass(key);
                if (this.header.numberOptions?.style === 'percent') {
                    numberFilter.value.isPercent = true;
                }
                if (this.header.filterEmptyValues) {
                    numberFilter.value.emptyValues = this.header.filterEmptyValues;
                }
                return numberFilter;
            }
            case TableHeaderFilterType.Date: {
                const dateFilter = new DateFilterClass(key);
                if (this.header.filterEmptyValues) {
                    dateFilter.value.emptyValues = this.header.filterEmptyValues;
                }
                return dateFilter;
            }
            case TableHeaderFilterType.Text:
                return new TextFilterClass(key);
            case TableHeaderFilterType.Enum:
                return new EnumFilterClass(key);
            case TableHeaderFilterType.EnumMultiple:
                return new EnumMultipleFilterClass(key);
            case TableHeaderFilterType.Boolean:
                return new BooleanFilterClass(key, {
                    type: this.header.filterBooleanOptions?.type || 'boolean',
                    value: null
                });
            case TableHeaderFilterType.ResourceText:
                return new ResourceTextFilterClass(alternativeKey ? alternativeKey : key);
            default:
                return null;
        }
    }

    protected changeGuard(action: () => void, cancelCallbackFunc?: () => void, confirmCallbackFunc?: () => void): void {
        if (this.canBeFilterChanged) {
            action();
            if (confirmCallbackFunc) {
                confirmCallbackFunc();
            }
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                cancelCallbackFunc,
                confirmCallbackFunc: () => {
                    action();
                    if (confirmCallbackFunc) {
                        confirmCallbackFunc();
                    }
                }
            });
        }
    }

    public resetFilter(): void {
        if (typeof this.defaultFilter !== 'undefined') {
            this.currentFilter = this.defaultFilter.clone();
            this.savedFilter = this.defaultFilter.clone();
        }
    }

    protected openFilter(): void {
        const defaultFilter = this.createFilter(this.filterKey, this.filterBy);
        if (defaultFilter) {
            this.defaultFilter = defaultFilter.clone();
            const persistedFilter = this.tableFilters.getColumnFilter(this.filterKey);
            if (persistedFilter) {
                this.currentFilter = persistedFilter.clone();
                this.savedFilter = persistedFilter.clone();
            } else {
                this.currentFilter = defaultFilter.clone();
                this.savedFilter = defaultFilter.clone();
            }
        }
        appStore.setActiveMenus({ [`columnFilter${this.header.value}`]: true });
    }

    protected closeFilter(): void {
        if (this.savedFilter) {
            this.currentFilter = this.savedFilter.clone();
        }
        appStore.setActiveMenu({ [`columnFilter${this.header.value}`]: false });
    }

    // Emitters
    @Emit()
    protected changeFilter(filter: ColumnFilterType | null): { filter: ColumnFilterType | null; key: string } {
        this.closeFilter();
        return { filter: filter, key: this.filterKey };
    }

    // Event Handlers
    protected onUpdateCurrentFilter(currentFilter: ColumnFilterType | null): void {
        this.currentFilter = currentFilter;
    }

    protected onConfirm(): void {
        this.changeGuard(
            () => {
                if (this.currentFilter && this.currentFilter.express() !== this.defaultFilter.express()) {
                    this.savedFilter = this.currentFilter.clone();
                } else {
                    this.resetFilter();
                }
            },
            undefined,
            () => {
                if (this.currentFilter && this.currentFilter.express() !== this.defaultFilter.express()) {
                    this.changeFilter(this.currentFilter);
                } else {
                    this.changeFilter(null);
                }
            }
        );
    }

    protected onCancel(): void {
        this.changeGuard(() => {
            this.resetFilter();
            this.changeFilter(null);
        });
    }
}
