









































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import ActionDialog from '@/components/common/Table/actions/ActionDialog.vue';
import { ActionType } from '@/model/Enums/ActionType';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import Delegate from '@/components/common/Table/actions/Delegate.vue';
import type { Resource } from '@bednic/json-api-client';
import type { State } from '@/model/Entity/State';
import UpdateState from '@/components/common/Table/actions/UpdateState.vue';
import type { User } from '@/model/Entity/User';

@Component({
    // eslint-disable-next-line no-undef
    components: { ActionDialog, ConfirmationButtons, Delegate, UpdateState }
})
export default class Actions extends Vue {
    @Prop({ default: false, required: false, type: Boolean })
    protected changeState!: boolean;

    // Props
    @Prop({ default: false, required: false, type: Boolean })
    protected delegate!: boolean;

    @Prop({ default: false, required: false, type: Boolean })
    protected disabled!: boolean;

    @Prop({ required: true, type: Number })
    protected filteredOrdersCount!: number;

    @Prop({ default: false, required: true, type: Boolean })
    protected isUpdating!: boolean;

    @Prop({ required: true, type: Array })
    protected selectedOrders!: Array<Record<string, unknown>>;

    // Data
    protected isDelegateDialogOn = false;

    protected isUpdateStateDialogOn = false;

    protected selectedState: State | null = null;

    protected selectedUser: User | null = null;

    // Computed getters
    protected get isDisabled(): boolean {
        return appStore.isReadOnlyMode || this.disabled;
    }

    protected get selectedOrdersCount(): number {
        if (this.selectedOrders.length) {
            return this.selectedOrders.length;
        }
        return this.filteredOrdersCount;
    }

    // Computed getters and setters
    protected get isOpen(): boolean {
        return appStore.activeMenu(ActiveMenus.Actions);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [ActiveMenus.Actions]: value });
    }

    // Sync Methods
    public onCancel(): void {
        this.isDelegateDialogOn = false;
        this.isUpdateStateDialogOn = false;
    }

    @Emit('action-triggered')
    protected actionTriggered(
        actionType: ActionType,
        count: number,
        updatedResource: Resource
    ): { actionType: ActionType; count: number; updatedResource: Resource } {
        return {
            actionType,
            count,
            updatedResource
        };
    }

    // Event Handlers
    protected onDelegateConfirm(): void {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.selectedUser && this.selectedUser.id !== null) {
            this.actionTriggered(ActionType.Delegate, this.selectedOrdersCount, this.selectedUser);
        }
    }

    protected onUpdateStateConfirm(): void {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.selectedState && this.selectedState.id !== null) {
            this.actionTriggered(ActionType.UpdateState, this.selectedOrdersCount, this.selectedState);
        }
    }

    protected onOpenDelegateDialog(): void {
        this.isDelegateDialogOn = true;
        this.isOpen = false;
    }

    protected onOpenUpdateStateDialog(): void {
        this.isUpdateStateDialogOn = true;
        this.isOpen = false;
    }
}
