















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ConfirmationButtons extends Vue {
    // Data
    @Prop({ default: 'default', required: false, type: String })
    protected cancelBtnColor!: string;

    @Prop({ default: false, type: Boolean })
    protected cancelBtnDisabled!: boolean;

    @Prop({ required: false, type: String })
    protected cancelBtnText!: string;

    @Prop({ default: 'primary', required: false, type: String })
    protected confirmBtnColor!: string;

    @Prop({ default: false, type: Boolean })
    protected confirmBtnDisabled!: boolean;

    @Prop({ required: false, type: String })
    protected confirmBtnText!: string;

    @Prop({ default: false, required: false, type: Boolean })
    protected disabled!: boolean;

    @Prop({ default: false, required: false, type: Boolean })
    protected hideCancelBtn!: boolean;

    @Prop({ default: false, required: false, type: Boolean })
    protected hideConfirmBtn!: boolean;

    @Prop({ default: true, required: false, type: Boolean })
    protected hideSecondaryBtn!: boolean;

    @Prop({ default: 'default', required: false, type: String })
    protected secondaryBtnColor!: string;

    @Prop({ default: false, type: Boolean })
    protected secondaryBtnDisabled!: boolean;

    @Prop({ required: false, type: String })
    protected secondaryBtnText!: string;

    @Emit('cancel')
    protected onCancel(): boolean {
        return true;
    }

    @Emit('secondary')
    protected onSecondary(): boolean {
        return true;
    }

    @Emit('confirm')
    protected onConfirm(): boolean {
        return true;
    }
}
