import { AppRouter } from '@/router';
import { CONSTANTS } from '@/constants';
import type { NavigationRouteConfig } from './model/NavigationRouteConfig';
import Orders from '@/views/Orders.vue';
import OrdersByItems from '@/views/OrdersByItems.vue';
import OrdersBySuppliers from '@/views/OrdersBySuppliers.vue';

const ordersModule: Record<string, NavigationRouteConfig> = {
    [CONSTANTS.ROUTES.ORDERS.NAME]: {
        children: [
            {
                component: OrdersBySuppliers,
                icon: 'mdi mdi-truck',
                isInNavigation: true,
                name: CONSTANTS.ROUTES.ORDERS.CHILDREN.BY_SUPPLIERS.NAME,
                path: CONSTANTS.ROUTES.ORDERS.CHILDREN.BY_SUPPLIERS.PATH,
                requiredPermissions: []
            },
            {
                component: OrdersByItems,
                icon: '$vuetify.icons.logio-box',
                isInNavigation: true,
                name: CONSTANTS.ROUTES.ORDERS.CHILDREN.BY_ITEMS.NAME,
                path: CONSTANTS.ROUTES.ORDERS.CHILDREN.BY_ITEMS.PATH,
                requiredPermissions: []
            }
        ],
        component: Orders,
        icon: '$vuetify.icons.logio-orders',
        isInNavigation: true,
        name: CONSTANTS.ROUTES.ORDERS.NAME,
        path: CONSTANTS.ROUTES.ORDERS.PATH,
        redirect: () =>
            AppRouter.activeChildRoute(CONSTANTS.ROUTES.ORDERS.PATH, {
                name: CONSTANTS.ROUTES.ORDERS.CHILDREN.BY_SUPPLIERS.NAME,
                path: CONSTANTS.ROUTES.ORDERS.CHILDREN.BY_SUPPLIERS.PATH
            }),
        requiredPermissions: [CONSTANTS.PERMISSIONS.READ_MODULE_ORDERS]
    }
};

export default ordersModule;
