

































import { Component, Vue } from 'vue-property-decorator';
import CompoundDocument, { PrettyExpressionBuilder, Resource } from '@bednic/json-api-client';
import { TreeCategory, TreeCategoryType } from '@/model/Entity/TreeCategory';
import _ from 'lodash';
import appStore from '@/store/modules/AppStore';
import axios from 'axios';
import { CONSTANTS } from '@/constants';
import ErrorService from '@/services/ErrorService/ErrorService';
import type { GlobalFilter as IGlobalFilter } from '@/model/Interfaces/GlobalFilter';
import { MessageType } from '@/model/Enums/MessageType';
import type { NonTreeCategory } from '@/model/Entity/NonTreeCategory';
import TreeView from '@/components/common/TreeView.vue';
import { v4 as uid } from 'uuid';

enum PayloadType {
    Product = 'product',
    Warehouse = 'warehouse'
}

@Component({
    // eslint-disable-next-line no-undef
    components: { TreeView }
})
export default class GlobalFilter extends Vue {
    protected isProductCategoriesLoading = false;

    protected isWarehouseCategoriesLoading = false;

    protected productCategoriesActive: Array<TreeCategory> = [];

    protected productCategoriesOpen: Array<TreeCategory> = [];

    protected productCategoriesSelected: Array<TreeCategory> = [];

    protected productCategoriesTree: Array<TreeCategory> = [];

    protected warehouseCategoriesActive: Array<TreeCategory> = [];

    protected warehouseCategoriesOpen: Array<TreeCategory> = [];

    protected warehouseCategoriesSelected: Array<TreeCategory> = [];

    protected warehouseCategoriesTree: Array<TreeCategory> = [];

    protected async mounted(): Promise<void> {
        await this.initCategories(appStore.globalFilter);
    }

    protected get canLeaveEditMode(): boolean {
        return appStore.canLeaveEditMode;
    }

    protected get globalFilter(): IGlobalFilter {
        return appStore.globalFilter;
    }

    protected setCategoriesTree(payload: { tree: Array<TreeCategory>; type: PayloadType }): void {
        if (payload.type === PayloadType.Warehouse) {
            this.warehouseCategoriesTree = [...payload.tree];
        }
        if (payload.type === PayloadType.Product) {
            this.productCategoriesTree = [...payload.tree];
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    protected mapCategoryToTreeCategory(category: any): TreeCategory {
        const treeCategory: TreeCategory = {
            children: typeof category.attributes.isLeaf === 'undefined' ? undefined : [],
            childrenLink: category.relationships?.children ? `${category.relationships.children.links.related as string}` : '',
            id: `${category.id}`,
            isLeaf: typeof category.attributes.isLeaf === 'undefined' ? true : category.attributes.isLeaf,
            isRoot: category.attributes.isRoot,
            key: uid(),
            name: category.attributes.name,
            path: [],
            type: category.type,
            typeId: category.attributes.typeId
        };
        treeCategory.path.push(treeCategory);
        return treeCategory;
    }

    // Sync Methods
    protected cancelEditMode(): void {
        if (appStore.canLeaveEditMode) {
            appStore.setIsEditMode(false);
        }
    }

    protected async initCategories(globalFilter: IGlobalFilter | null): Promise<void> {
        try {
            this.isWarehouseCategoriesLoading = true;
            this.isProductCategoriesLoading = true;
            await this.fetchRoot(PayloadType.Warehouse);
            await this.fetchRoot(PayloadType.Product);
            if (globalFilter) {
                if (globalFilter.warehouseCategoryIds.length) {
                    const tree = _.cloneDeep(this.warehouseCategoriesTree);
                    await this.initChildren(tree, globalFilter.warehouseCategoryIds, PayloadType.Warehouse);
                    this.setCategoriesTree({ tree, type: PayloadType.Warehouse });
                }
                if (globalFilter.productCategoryIds.length) {
                    const tree = _.cloneDeep(this.productCategoriesTree);
                    await this.initChildren(tree, globalFilter.productCategoryIds, PayloadType.Product);
                    this.setCategoriesTree({ tree, type: PayloadType.Product });
                }
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorCategoryLoading'),
                messageType: MessageType.Notification
            });
        } finally {
            this.isWarehouseCategoriesLoading = false;
            this.isProductCategoriesLoading = false;
        }
    }

    protected async initChildren(rootTree: Array<TreeCategory>, categoryIds: Array<number>, type: PayloadType): Promise<void> {
        let tree: Array<TreeCategory> = [...rootTree];
        for (const categoryId of categoryIds) {
            if (Array.isArray(tree)) {
                const parent: TreeCategory | undefined = tree.find((category) => Number(category.id) === categoryId);
                if (parent) {
                    if (type === PayloadType.Warehouse) {
                        this.warehouseCategoriesOpen.push(parent);
                        this.warehouseCategoriesActive = [parent];
                        this.warehouseCategoriesSelected = [parent];
                    }
                    if (type === PayloadType.Product) {
                        this.productCategoriesOpen.push(parent);
                        this.productCategoriesActive = [parent];
                        this.productCategoriesSelected = [parent];
                    }
                    await this.fetchChildren(parent);
                    if (parent.children) {
                        tree = parent.children;
                    }
                }
            }
        }
    }

    protected async fetchRoot(type: PayloadType): Promise<void> {
        const url = type === PayloadType.Warehouse ? CONSTANTS.API.WAREHOUSE_CATEGORIES : CONSTANTS.API.PRODUCT_CATEGORIES;
        const doc: CompoundDocument<NonTreeCategory> = new CompoundDocument<NonTreeCategory>(url, axios);
        doc.addCustomQueryParam('filter', 'isRoot');
        const categories = await this.fetchCategories<NonTreeCategory>(doc);
        if (Array.isArray(categories)) {
            const tree = categories.map((category) => this.mapCategoryToTreeCategory(category));
            this.setCategoriesTree({ tree, type });
        }
    }

    protected async fetchCategories<T extends Resource>(doc: CompoundDocument<T>): Promise<Array<T> | T | null> {
        doc.filter().setLimit(0);
        await doc.self();
        const meta = doc.meta;
        if (meta) {
            const total = meta.total as number;
            doc.filter().setLimit(total);
            const response = await doc.self();
            return response.data;
        } else {
            return null;
        }
    }

    protected async fetchChildren(parentTreeCategory: TreeCategory): Promise<void> {
        try {
            if (parentTreeCategory.isLeaf) {
                if (parentTreeCategory.type === TreeCategoryType.WarehouseCategories) {
                    await this.fetchLeaf({ parentTreeCategory, type: PayloadType.Warehouse });
                }
                if (parentTreeCategory.type === TreeCategoryType.ProductCategories) {
                    await this.fetchLeaf({ parentTreeCategory, type: PayloadType.Product });
                }
            } else {
                const doc: CompoundDocument<NonTreeCategory> = new CompoundDocument<NonTreeCategory>(
                    parentTreeCategory.childrenLink,
                    axios
                );
                doc.filter().setLimit(0);
                await doc.self();
                const meta = doc.meta;
                if (meta) {
                    const total = meta.total as number;
                    doc.filter().setLimit(total);
                    await doc.self();
                    const categories = doc.data;
                    if (Array.isArray(categories)) {
                        const treeCategories = categories.map((category) => this.mapCategoryToTreeCategory(category));
                        treeCategories.forEach((category) => {
                            category.path = [...parentTreeCategory.path];
                            category.path.push(category);
                        });
                        parentTreeCategory.children = treeCategories;
                    }
                }
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorCategoryLoading'),
                messageType: MessageType.Notification
            });
        }
    }

    protected async fetchLeaf<T extends Resource>(payload: { parentTreeCategory: TreeCategory; type: PayloadType }): Promise<void> {
        const url = payload.type === PayloadType.Warehouse ? CONSTANTS.API.WAREHOUSES : CONSTANTS.API.PRODUCTS;
        const doc: CompoundDocument<T> = new CompoundDocument<T>(url, axios);
        const filters = payload.parentTreeCategory.path.map((category) => PrettyExpressionBuilder.has('categoryIds', category.id));
        doc.filter()
            .setLimit(0)
            .where(PrettyExpressionBuilder.and(...filters));
        await doc.self();
        const meta = doc.meta;
        if (meta) {
            const total = meta.total as number;
            doc.filter().setLimit(total);
            await doc.self();
            const categories = doc.data as Array<T>;
            if (Array.isArray(categories)) {
                const treeCategories = categories.map((category) => this.mapCategoryToTreeCategory(category));
                treeCategories.forEach((category) => {
                    if (payload.type === PayloadType.Warehouse) {
                        if (String(this.globalFilter.warehouseId) === category.id) {
                            this.warehouseCategoriesActive = [category];
                            this.warehouseCategoriesSelected = [category];
                        }
                    }
                    if (payload.type === PayloadType.Product) {
                        if (String(this.globalFilter.productId) === category.id) {
                            this.productCategoriesActive = [category];
                            this.productCategoriesSelected = [category];
                        }
                    }
                    category.path = [...payload.parentTreeCategory.path];
                    category.path.push(category);
                });
                payload.parentTreeCategory.children = treeCategories;
            }
        }
    }

    // Event handlers
    protected async onWarehouseCategoryChange(selected: Array<TreeCategory>): Promise<void> {
        let warehouseCategoryIds: Array<number> = [];
        let warehouseId = null;
        if (selected.length) {
            const path = selected[0].path;
            path.forEach((category) => {
                if (category.type === TreeCategoryType.Warehouses) {
                    warehouseId = Number(category.id);
                } else {
                    warehouseCategoryIds.push(Number(category.id));
                }
            });
        }
        await appStore.changeGlobalFilter({ warehouseCategoryIds, warehouseId });
        this.cancelEditMode();
    }

    protected async onProductCategoryChange(selected: Array<TreeCategory>): Promise<void> {
        let productCategoryIds: Array<number> = [];
        let productId = null;
        if (selected.length) {
            const path = selected[0].path;
            path.forEach((category) => {
                if (category.type === TreeCategoryType.Products) {
                    productId = Number(category.id);
                } else {
                    productCategoryIds.push(Number(category.id));
                }
            });
        }
        await appStore.changeGlobalFilter({ productCategoryIds, productId });
        this.cancelEditMode();
    }
}
