import appStore from '@/store/modules/AppStore';
import type { AxiosInstance } from 'axios';
import { CompoundDocument } from '@bednic/json-api-client';
import { CONSTANTS } from '@/constants';
import type { UserSettings } from '@/model/Entity/UserSettings';

export default class UserSettingsService {
    public url = CONSTANTS.API.USER_SETTINGS;

    public constructor(public readonly client: AxiosInstance | Storage) {}

    private get userId(): string {
        return appStore.user && appStore.user.id ? appStore.user.id : '';
    }

    private generateUserSettings<T>(key: string, item: T): UserSettings {
        return {
            attributes: {
                value: JSON.stringify(item)
            },
            id: this.getKey(key),
            type: this.url
        } as UserSettings;
    }

    private getKey(key: string): string {
        return `${this.userId}-${key}`;
    }

    private getPath(key: string): string {
        return `${this.url}/${this.userId}-${key}`;
    }

    public async create<T>(key: string, item: T): Promise<T | null> {
        if (this.client instanceof Storage) {
            this.client.setItem(this.getKey(key), JSON.stringify(item));
        } else {
            const doc = new CompoundDocument<UserSettings>(this.url, this.client);
            const data = this.generateUserSettings(key, item);
            await doc.create(data);
        }
        return item;
    }

    public async createOrUpdate<T>(key: string, item: T): Promise<T | null> {
        try {
            return await this.update<T>(key, item);
        } catch (error) {
            return await this.create<T>(key, item);
        }
    }

    public async delete<T>(key: string): Promise<T | null> {
        const currentItem = this.get<T>(key);
        if (this.client instanceof Storage) {
            this.client.removeItem(this.getKey(key));
        } else {
            const doc = new CompoundDocument<UserSettings>(this.getPath(key), this.client);
            await doc.delete();
        }
        return currentItem;
    }

    public async get<T>(key: string): Promise<T | null> {
        if (this.client instanceof Storage) {
            const item = this.client.getItem(this.getKey(key));
            return item ? JSON.parse(item) as T : null;
        } else {
            const doc = new CompoundDocument<UserSettings>(this.getPath(key), this.client);
            await doc.self();
            const data = doc.data;
            if (data) {
                if (!Array.isArray(data)) {
                    return JSON.parse(data.attributes.value) as T;
                } else {
                    return JSON.parse(data[0].attributes.value) as T;
                }
            } else {
                return null;
            }
        }
    }

    public async update<T>(key: string, item: T): Promise<T | null> {
        if (this.client instanceof Storage) {
            this.client.setItem(this.getKey(key), JSON.stringify(item));
        } else {
            const doc = new CompoundDocument<UserSettings>(this.getPath(key), this.client);
            const data = this.generateUserSettings(key, item);
            await doc.update(data);
        }
        return item;
    }
}
