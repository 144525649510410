


































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import ExportTableService, { ExportTableFilter, TableExport } from '@/services/ExportTableService';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';

@Component
export default class ExportTable extends Vue {
    // Data
    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true, type: Object })
    protected exportConfig!: {
        filename: string;
        filter: ExportTableFilter;
        url: string;
    };

    @Prop({ default: () => ['csv', 'xlsx'], required: false })
    protected formats!: Array<string>;

    @Inject('exportTableService')
    protected exportTableService!: typeof ExportTableService;

    // Computed
    protected get isOpen(): boolean {
        return appStore.activeMenu(ActiveMenus.ExportTable);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [ActiveMenus.ExportTable]: value });
    }

    // Async Methods
    protected createExport(format: string): void {
        // FIXME data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {
            attributes: {
                ...this.exportConfig.filter,
                format
            }
        };
        const filename = `${this.exportConfig.filename}.${format}`;
        const tableExport = new TableExport(data, this.exportConfig.url, filename, format);
        this.exportTableService.createExport(tableExport);
    }

    protected onClose(): void {
        this.isOpen = false;
    }

    protected async onExportTable(format: string): Promise<void> {
        this.onClose();
        this.createExport(format);
    }
}
