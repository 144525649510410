import { getModule, Module } from 'vuex-module-decorators';
import type { AbcValue } from '@/model/Entity/AbcValue';
import { CONSTANTS } from '@/constants';
import { DataStore } from '@/store/DataStore';
import EntityService from '@/services/EntityService';
import store from '@/store';

const module = 'abcValueStore';

export interface AbcValueStoreState {
    entityService: EntityService;
    items: Array<AbcValue>;
}

@Module({
    dynamic: true,
    name: module,
    namespaced: true,
    store
})
class AbcValueStore extends DataStore<AbcValue> implements AbcValueStoreState {
    public entityService = new EntityService(CONSTANTS.API.ABC_VALUES);
}

export default getModule(AbcValueStore);
