import colors from 'vuetify/lib/util/colors';
import type { VuetifyThemeVariant } from 'vuetify/types/services/theme';

enum CustomThemeProps {
    Dull = 'dull',
    Icon = 'icon',
    ScrollHover = 'scroll-hover',
    ScrollThumb = 'scroll-thumb',
    ScrollThumbHover = 'scroll-thumb-hover',
    TableFilterOff = 'table-filter-off',
    TableFilterOn = 'table-filter-on',
    TableOddRow = 'table-odd-row'
}

interface Theme extends Partial<VuetifyThemeVariant> {
    [CustomThemeProps.Dull]: string;
    [CustomThemeProps.Icon]: string;
    [CustomThemeProps.ScrollHover]: string;
    [CustomThemeProps.ScrollThumb]: string;
    [CustomThemeProps.ScrollThumbHover]: string;
    [CustomThemeProps.TableFilterOff]: string;
    [CustomThemeProps.TableFilterOn]: string;
    [CustomThemeProps.TableOddRow]: string;
    accent: string;
    background: string;
    disabled: string;
    error: string;
    info: string;
    primary: string;
    secondary: string;
    success: string;
    warning: string;
}

const light: Theme = {
    [CustomThemeProps.Dull]: colors.grey.lighten1,
    [CustomThemeProps.ScrollHover]: '#f4f4f4',
    [CustomThemeProps.ScrollThumb]: '#babac0',
    [CustomThemeProps.ScrollThumbHover]: '#a0a0a5',
    [CustomThemeProps.TableOddRow]: colors.grey.lighten4,
    [CustomThemeProps.Icon]: colors.grey.darken1,
    [CustomThemeProps.TableFilterOn]: colors.shades.white,
    [CustomThemeProps.TableFilterOff]: colors.lightGreen.darken2,
    accent: colors.lightGreen.lighten1,
    background: colors.shades.white,
    disabled: colors.grey.lighten2,
    error: colors.red.lighten1,
    info: colors.lightBlue.lighten1,
    primary: colors.lightGreen.base,
    secondary: colors.grey.lighten4,
    success: colors.green.lighten1,
    warning: colors.orange.lighten1
};

const dark: Theme = {
    [CustomThemeProps.Dull]: colors.grey.lighten1,
    [CustomThemeProps.ScrollHover]: colors.grey.darken3,
    [CustomThemeProps.ScrollThumb]: '#babac0',
    [CustomThemeProps.ScrollThumbHover]: '#a0a0a5',
    [CustomThemeProps.TableOddRow]: colors.grey.darken3,
    [CustomThemeProps.Icon]: colors.grey.lighten4,
    [CustomThemeProps.TableFilterOn]: colors.grey.lighten4,
    [CustomThemeProps.TableFilterOff]: colors.lightGreen.darken4,
    accent: colors.purple.darken4,
    background: colors.grey.darken4,
    disabled: colors.grey.darken1,
    error: colors.red.lighten1,
    info: colors.lightBlue.lighten1,
    primary: colors.lightGreen.darken3,
    secondary: colors.blueGrey.darken4,
    success: colors.green.lighten1,
    warning: colors.orange.lighten1
};

export default { dark, light };
