/* eslint-disable @typescript-eslint/no-explicit-any,
@typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types,
@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
*/
import ApplicationError from '@/model/Classes/ApplicationError';
import { ErrorHandler } from '@/services/ErrorService/ErrorService';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import type { MessageType } from '@/model/Enums/MessageType';

export class CancelRequestErrorHandler extends ErrorHandler {
    public constructor(public readonly url: string) {
        super();
    }

    public handle(error: any, context?: string, messageType?: MessageType): Array<ApplicationError> {
        const errorTitle: string = error.message || error.error.message;
        const title = context ? context : errorTitle;
        const detail = context ? `${errorTitle} - url: ${this.url}` : `url: ${this.url}`;
        const applicationError = new ApplicationError(title, detail, { error, messageLevel: MessageLevel.Warning });
        return [applicationError];
    }

    public accept(error: { message: string, error: { message: string } }): boolean {
        return (
            error.message === 'Request interrupted manually.' ||
            error.error?.message === 'Request interrupted manually.'
        );
    }
}
