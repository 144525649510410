import type { AxiosResponse, Canceler } from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import CompoundDocument, { Expression, PrettyExpressionBuilder, Resource } from '@bednic/json-api-client';
import appStore from '@/store/modules/AppStore';
import { CancelRequestErrorHandler } from '@/services/ErrorService/ErrorHandlers/CancelRequestErrorHandler';
import type { ColumnFilterType } from '@/model/Entity/ColumnFilterType';
import ErrorService from '@/services/ErrorService/ErrorService';
import type { GlobalFilter } from '@/model/Interfaces/GlobalFilter';
import { i18n } from '@/plugins/i18n';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import { NotUpToDateErrorHandler } from '@/services/ErrorService/ErrorHandlers/NotUpToDateErrorHandler';
import type { OrderByItems } from '@/model/Entity/OrderByItems';
import TableFilters from '@/model/Data/Filters/TableFilters';
import type { TableHeader } from '@/model/Interfaces/TableHeader';
import UserSettingsService from '@/services/UserSettingsService';
import type { UserView } from '@/model/Interfaces/UserView';
import type { ViewValue } from '@/model/Interfaces/ViewValue';

interface CurrentSettings {
    columnFilters: Record<string, ColumnFilterType | null>;
    currentPage: number;
    pageSize: number;
    selectedColumns: Array<{ columnName: string; selected: boolean; text: string }>;
    selectedUserViewName: string | null;
    sort: Record<string, boolean | null>;
    userViews: Array<UserView>;
}

export interface SummaryEntity {
    amount: number;
    value: number;
    volume: number;
    weight: number;
}

export interface RouteColumnFilters {
    [tableKey: string]: {
        [filterName: string]: unknown;
    } | null;
}

interface UserViewsComponent extends Vue {
    resetSelected: () => void
}

interface OverviewComponent extends Vue {
    closeOverview: () => void
}

@Component
export default class TableMixin<T extends Resource> extends Vue {
    @Ref('tableActionButtonsRef')
    protected tableActionButtonsRef!: HTMLElement;

    @Ref('tableFooterRef')
    protected tableFooterRef!: HTMLElement;

    @Ref('tableRef')
    protected tableRef!: Vue;

    // Data
    protected currentPage = 1;

    protected debounce = 0;

    protected debounceInterval = 400;

    protected doc!: CompoundDocument<T>;

    protected elements = { padding: 24, tableHeader: 0 };

    protected fetchCancelers: Array<Canceler> = [];

    protected headers: Array<TableHeader> = [];

    protected isAnyFlagOverviewOpen = false;

    protected isLoading = false;

    protected isLoadingTableSettings = false;

    protected isMounted = true;

    protected isSummaryLoading = false;

    protected items: Array<T> = [];

    protected pageSize = 10;

    protected pageSizes = [10, 20, 50, 100];

    protected requiredSparseFields: Array<string> = [];

    protected requireSummary = false;

    protected selected: Array<string> = [];

    protected selectedColumns: Array<{ columnName: string; selected: boolean; text: string }> = [];

    protected selectedUserViewName: string | null = null;

    protected sort: Record<string, boolean | null> = {};

    protected summary: SummaryEntity = {
        amount: 0,
        value: 0,
        volume: 0,
        weight: 0
    };

    protected tableFilters: TableFilters = new TableFilters(this.headers);

    protected tableKey = '';

    protected tableOptions: unknown = {
        calculateWidths: false,
        class: '',
        dense: true,
        disableFiltering: true,
        disableSort: true,
        fixedHeader: true,
        footerProps: {
            itemsPerPageOptions: [10, 20, 50, 100]
        },
        hideDefaultFooter: true,
        hideDefaultHeader: true,
        itemKey: 'id',
        showSelect: true,
        singleSelect: false
    };

    protected total = 0;

    protected url = '';

    protected userSettingsDebounce = 0;

    protected userSettingsManager = new UserSettingsService(this.axios);

    protected userViews: Array<UserView> = [];

    // Lifecycle Hooks
    public async created(): Promise<void> {
        await this.onCreated();
    }

    public mounted(): void {
        this.setElements();
    }

    public beforeDestroy(): void {
        this.resetTable();
    }

    // Computed
    protected get canLeaveEditMode(): boolean {
        return appStore.canLeaveEditMode;
    }

    protected get currentSettings(): CurrentSettings {
        return {
            columnFilters: this.tableFilters.columnFiltersValues,
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            selectedColumns: this.selectedColumns,
            selectedUserViewName: this.selectedUserViewName,
            sort: this.sort,
            userViews: this.userViews
        };
    }

    protected get defaultColumns(): Array<{ columnName: string; selected: boolean; text: string }> {
        return this.headers.map((header) => ({
            columnName: header.value,
            selected: !!header.default,
            text: header.text
        }));
    }

    protected get exportColumns(): Array<string> {
        return this.selectedColumns.map((header) => header.columnName);
    }

    protected get globalFilter(): GlobalFilter {
        return appStore.globalFilter;
    }

    protected get globalFilterAsExpressions(): Array<Expression> {
        const globalFilters: Array<Expression> = [];
        if (this.globalFilter.warehouseCategoryIds.length) {
            const filters: Array<Expression> = [];
            this.globalFilter.warehouseCategoryIds.forEach((categoryId) => {
                filters.push(PrettyExpressionBuilder.has('warehouseCategoryIds', categoryId));
            });
            if (this.globalFilter.warehouseId !== null) {
                filters.push(PrettyExpressionBuilder.equal('warehouseId', this.globalFilter.warehouseId));
            }
            globalFilters.push(PrettyExpressionBuilder.and(...filters));
        }
        if (this.globalFilter.productCategoryIds.length) {
            const filters: Array<Expression> = [];
            this.globalFilter.productCategoryIds.forEach((categoryId) => {
                filters.push(PrettyExpressionBuilder.has('productCategoryIds', categoryId));
            });
            if (this.globalFilter.productId) {
                filters.push(PrettyExpressionBuilder.equal('productId', this.globalFilter.productId));
            }
            globalFilters.push(PrettyExpressionBuilder.and(...filters));
        }
        if (this.semiGlobalFilter) {
            globalFilters.push(this.semiGlobalFilter);
        }
        return globalFilters;
    }

    protected get hasNoFilters(): boolean {
        return (
            !appStore.globalFilter.warehouseCategoryIds.length &&
            !appStore.globalFilter.warehouseId &&
            !appStore.globalFilter.productCategoryIds.length &&
            !appStore.globalFilter.productId
        );
    }

    protected get params(): Record<string, string> {
        const filter = this.globalFilterAsExpressions.concat(this.tableFilters.columnFiltersAsExpressions);
        return { filter: filter.length ? PrettyExpressionBuilder.and(...filter).express() : '' };
    }

    protected get selectedHeaders(): Array<TableHeader> {
        const filteredHeaders: Array<TableHeader> = [];
        this.selectedColumns
            .filter((column) => column.selected)
            .forEach((column) => {
                const header = this.headers.find((tableHeader) => tableHeader.value === column.columnName);
                if (header) {
                    filteredHeaders.push(header);
                }
            });
        return filteredHeaders;
    }

    protected get selectedItemsIdsCount(): number {
        if (this.selected.length) {
            return this.selected.length;
        }
        return this.total;
    }

    protected get semiGlobalFilter(): Expression | null {
        return null;
    }

    protected get sparseFields(): Array<string> {
        const fields = this.selectedColumns.map((header) => header.columnName);
        return [...fields, ...this.requiredSparseFields];
    }

    protected get tableHeight(): number {
        const tableCardPadding = 8;
        const tableHeight =
            this.$vuetify.breakpoint.height +
            (this.tableMarginTop - this.tableMarginTop * 2) -
            (appStore.elements.mainContainerPadding +
                appStore.elements.globalFilterHeight +
                appStore.elements.toolbarHeight +
                appStore.elements.tableActionButtonsHeight +
                appStore.elements.tableFooterHeight +
                tableCardPadding);
        return tableHeight < appStore.elements.minTableHeight ? appStore.elements.minTableHeight : tableHeight;
    }

    protected get tableMarginTop(): number {
        return this.$vuetify.breakpoint.mdAndUp ? -appStore.elements.globalFilterHeight + 6 : 0;
    }

    protected get validations(): Record<string, boolean> {
        return appStore.validations;
    }

    // Computed Getters and Setters
    protected get isEditMode(): boolean {
        return appStore.isEditMode;
    }

    protected set isEditMode(isEditMode: boolean) {
        appStore.setIsEditMode(isEditMode);
    }

    protected get isReadonlyMode(): boolean {
        return appStore.isReadOnlyMode;
    }

    protected set isReadonlyMode(isReadonlyMode: boolean) {
        appStore.setIsReadOnlyMode(isReadonlyMode);
    }

    // Sync Methods
    protected getDataAsArray(data: T | Array<T> | null): Array<T> {
        return data ? Array.isArray(data) ? data : [data] : [];
    }

    protected setElements(): void {
        this.$nextTick(() => {
            const dividerHeight = 1;
            appStore.setElements({
                tableActionButtonsHeight: this.tableActionButtonsRef.clientHeight,
                tableFooterHeight: this.tableFooterRef.clientHeight + dividerHeight
            });
        });
    }

    protected reloadPageEventListener(event: BeforeUnloadEvent): void {
        event.returnValue = `${this.$tc('components.confirmBox.leaveEditModeTitle')} ${this.$tc(
            'components.confirmBox.leaveEditModeSubtitle'
        )}`;
    }

    protected unselectUserView(): void {
        if (this.$refs.userViews) {
            (this.$refs.userViews as UserViewsComponent).resetSelected();
        }
    }

    protected resetPagination(): void {
        this.currentPage = 1;
        this.total = 0;
    }

    protected resetTable(): void {
        if (typeof this.doc !== 'undefined') {
            this.doc.abort();
        }
        this.resetPagination();
        this.items = [];
        this.selected = [];
        this.summary = {
            amount: 0,
            value: 0,
            volume: 0,
            weight: 0
        };
    }

    protected onRefreshRow(item: T): void {
        const index = this.items.findIndex((el) => el.id === item.id);
        if (index > -1) {
            this.items = Object.assign([], this.items, { [index]: item });
        }
    }

    // Sync Event Handlers
    protected onCloseAllFlagOverviews(): void {
        Object.keys(this.$refs).forEach((key) => {
            if (key.startsWith('cell_') && key.endsWith('_flagIds')) {
                (this.$refs[key] as OverviewComponent).closeOverview();
            }
        });
    }

    @Watch('validations', { immediate: true })
    protected onValidationsChange(validations: Record<string, boolean>): void {
        let valid = true;
        Object.values(validations).forEach((value) => {
            if (!value) {
                valid = false;
            }
        });
        if (!valid) {
            window.addEventListener('beforeunload', (ev: BeforeUnloadEvent) => this.reloadPageEventListener(ev));
        } else {
            window.removeEventListener('beforeunload', (ev: BeforeUnloadEvent) => this.reloadPageEventListener(ev));
        }
        if (appStore.isEditMode) {
            appStore.setCanLeaveEditMode(valid);
        }
    }

    @Watch('semiGlobalFilter', { deep: true, immediate: true })
    protected onSemiGlobalFilterChange(current?: Expression | null, previous?: Expression | null): void {
        void this.onFilterChange(current, previous);
    }

    @Watch('globalFilter', { deep: true, immediate: true })
    protected onGlobalFilterChange(current?: GlobalFilter, previous?: GlobalFilter): void {
        void this.onFilterChange(current, previous);
    }

    // Async Methods
    protected async fetchData(): Promise<void> {
        if (this.hasNoFilters || !this.selectedHeaders.length) {
            this.resetTable();
            return;
        }
        const offset = this.pageSize * (this.currentPage - 1);
        const limit = this.selectedColumns.length ? this.pageSize : 0;
        const sparseFields = this.sparseFields;
        this.doc = new CompoundDocument<T>(this.url, this.axios);
        this.doc.filter().setLimit(limit);
        this.doc.filter().setOffset(offset);
        Object.keys(this.sort).forEach((key) => {
            if (this.sort[key] === null) {
                this.doc.filter().removeSortBy(key);
            } else {
                this.doc.filter().addSortBy(key, !!this.sort[key]);
            }
        });
        Object.keys(this.params).forEach((key) => {
            this.doc.addCustomQueryParam(key, this.params[key]);
        });
        if (sparseFields.length) {
            this.doc.filter().sparseFieldsFor(this.url, sparseFields);
        }
        try {
            this.items = [];
            this.isLoading = true;
            await this.doc.self();
            this.items = this.getDataAsArray(this.doc.data);
            if (this.doc.meta) {
                this.total = this.doc.meta.total as number;
            }
        } catch (error: any) {
            const cancelRequestErrorHandler = new CancelRequestErrorHandler(this.url);
            if (!cancelRequestErrorHandler.accept(error)) {
                this.items = [];
            }
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.errorLoadingTable'),
                customHandlers: [cancelRequestErrorHandler],
                messageType: MessageType.Notification
            });
        } finally {
            this.isLoading = false;
            await this.$vuetify.goTo(0, { container: this.tableRef.$el.children[0] as HTMLElement });
        }
        if (this.requireSummary) {
            await this.getSummary();
        }
    }

    protected fetchDataDebounced(): void {
        if (this.debounce) {
            if (typeof this.doc !== 'undefined') {
                this.doc.abort();
            }
            clearTimeout(this.debounce);
        }
        this.debounce = window.setTimeout(() => {
            void this.fetchData();
        }, this.debounceInterval);
    }

    protected async fetchRowFunction(payload: { itemId: string }): Promise<CompoundDocument<T>> {
        const doc = new CompoundDocument<T>(this.url, this.axios);
        doc.addCustomQueryParam('filter', `(id eq ${payload.itemId})`);
        return doc.self();
    }

    protected async getSummary(): Promise<void> {
        try {
            const summary = await this.axios.get<{ data: SummaryEntity }>(`${this.url}-summary`, { params: this.params });
            this.summary = { ...summary.data.data };
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.errorLoadingSummary'),
                messageType: MessageType.Notification
            });
        } finally {
            this.isSummaryLoading = false;
        }
    }

    protected async createTableUserSettings(): Promise<CurrentSettings | null> {
        try {
            return await this.userSettingsManager.create<CurrentSettings>(`${this.tableKey}`, this.currentSettings);
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningTableUserSettingsNotSaved'),
                messageType: MessageType.Console
            });
            return null;
        }
    }

    protected async saveTableUserSettings(): Promise<CurrentSettings | null> {
        try {
            return await this.userSettingsManager.createOrUpdate<CurrentSettings>(
                `${this.tableKey}`,
                this.currentSettings
            );
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningTableUserSettingsNotSaved'),
                messageType: MessageType.Console
            });
            return null;
        }
    }

    protected saveTableUserSettingsDebounced(): void {
        if (this.userSettingsDebounce) {
            clearTimeout(this.userSettingsDebounce);
        }
        this.userSettingsDebounce = window.setTimeout(() => {
            void this.saveTableUserSettings();
        }, this.debounceInterval);
    }

    protected async toggleEditMode(): Promise<void> {
        if (this.canLeaveEditMode) {
            this.isEditMode = !this.isEditMode;
            if (!this.isEditMode) {
                await this.fetchData();
            }
        } else {
            await messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: async () => {
                    if (!this.isEditMode) {
                        await this.fetchData();
                    }
                }
            });
        }
    }

    protected async updateItemFunction(payload: {
        confirmFunc: () => void;
        header: TableHeader;
        item: OrderByItems;
        original: OrderByItems;
        secondaryFunc: () => void;
        value: unknown;
    }): Promise<AxiosResponse<OrderByItems>> {
        try {
            return await this.axios.patch(`${this.url}/${payload.item.id}`, {
                data: {
                    attributes: {
                        [payload.header.value]: payload.value
                    },
                    id: payload.item.id,
                    type: payload.item.type
                }
            });
        } catch (error) {
            const messageKey = 'messages.errorEditConflict';
            const notUpToDateErrorHandler = new NotUpToDateErrorHandler(
                messageKey,
                this.headers,
                payload.value,
                payload.header,
                payload.confirmFunc,
                payload.secondaryFunc
            );
            const errors = await ErrorService.dispatch(error, {
                actions: {
                    actionText: this.$tc('messages.errorEditFailedActionText'),
                    confirmFunc: payload.confirmFunc,
                    secondaryFunc: payload.secondaryFunc
                },
                context: this.$tc('messages.errorEditFailed'),
                customHandlers: [notUpToDateErrorHandler],
                messageType: MessageType.Dialog
            });
            return Promise.reject(errors[0]);
        }
    }

    // Async Event Handlers
    protected async onCreated(): Promise<void> {
        this.tableFilters = new TableFilters(this.headers);

        try {
            this.isLoadingTableSettings = true;
            const currentSettings = await this.userSettingsManager.get<CurrentSettings>(this.tableKey);
            if (currentSettings) {
                this.currentPage = currentSettings.currentPage;
                this.pageSize = currentSettings.pageSize;
                // FIXME Oprava verze
                if (typeof currentSettings.sort !== 'undefined') {
                    this.sort = currentSettings.sort;
                    delete this.sort.desc;
                    delete this.sort.field;
                }

                this.tableFilters.columnFiltersValues = currentSettings.columnFilters;
                await this.processFiltersFromRoute(currentSettings);

                this.selectedColumns = currentSettings.selectedColumns;
                this.selectedUserViewName = currentSettings.selectedUserViewName;
                this.userViews = currentSettings.userViews;
            } else {
                this.selectedColumns = this.defaultColumns;
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningTableUserSettingsNotFound'),
                messageType: MessageType.Console
            });
            this.selectedColumns = this.defaultColumns;
            await this.createTableUserSettings();
        } finally {
            this.isLoadingTableSettings = false;
        }
        await this.fetchData();
    }

    protected async processFiltersFromRoute(currentSetting: CurrentSettings): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.$route.params && this.$route.params.tableFilters) {
            const tableParamsFilters = JSON.parse(this.$route.params.tableFilters) as RouteColumnFilters;
            const tableName = this.tableKey;
            const filters = tableParamsFilters[tableName];
            if (filters) {
                const newFilters: Record<string, unknown> = {};
                for (const [columnName, filter] of Object.entries(filters)) {
                    newFilters[columnName] = filter;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const columnFilters: Record<string, any> = {
                    ...currentSetting.columnFilters,
                    ...newFilters
                };
                await this.userSettingsManager.update(tableName, {
                    ...currentSetting,
                    columnFilters
                });
                this.tableFilters.columnFiltersValues = columnFilters;
            }
        }
    }

    protected async onCreateUserView(name: string): Promise<void> {
        this.userViews.push({
            name: name,
            value: {
                columnFiltersValues: this.tableFilters.columnFiltersValues,
                pageSize: this.pageSize,
                selectedColumns: this.selectedColumns,
                sort: this.sort
            }
        });
        await this.saveTableUserSettings();
    }

    protected async onSaveUserViews(userViews: Array<UserView>): Promise<void> {
        this.userViews = userViews;
        await this.saveTableUserSettings();
    }

    protected async onSwitchToUserView(viewValue: ViewValue | null): Promise<void> {
        if (viewValue) {
            this.selectedColumns = viewValue.selectedColumns;
            this.pageSize = viewValue.pageSize;
            this.sort = viewValue.sort;
            this.tableFilters.removeAll();
            this.tableFilters.columnFiltersValues = viewValue.columnFiltersValues;
        } else {
            this.selectedColumns = this.defaultColumns;
            this.pageSize = 10;
            this.sort = {};
            this.tableFilters.removeAll();
        }
        this.currentPage = 1;
        void this.saveTableUserSettings();
        await this.fetchData();
    }

    protected onChangeSelectedColumns(
        selectedColumns: Array<{ columnName: string; selected: boolean; text: string }>
    ): void {
        const hiddenColumns = selectedColumns.filter((column) => !column.selected);
        hiddenColumns.forEach((item) => {
            this.tableFilters.remove(item.columnName);
            this.sort[item.columnName] = null;
        });
        this.selectedColumns = selectedColumns;
        this.unselectUserView();
    }

    protected async onSaveColumnView(): Promise<void> {
        void this.saveTableUserSettings();
        await this.fetchData();
    }

    protected onChangeColumnSort(header: TableHeader): void {
        const currentSort = this.sort[header.value];
        this.sort = {};
        if (currentSort === null || typeof currentSort === 'undefined') {
            this.sort[header.value] = false;
        } else if (!currentSort) {
            this.sort[header.value] = true;
        } else {
            this.sort[header.value] = null;
        }
        this.unselectUserView();
        this.saveTableUserSettingsDebounced();
        this.fetchDataDebounced();
    }

    protected async onChangeColumnFilter(currentFilter: {
        filter: ColumnFilterType | null;
        key: string;
    }): Promise<void> {
        if (currentFilter.filter) {
            this.tableFilters.add(currentFilter.key, currentFilter.filter);
        } else {
            this.tableFilters.remove(currentFilter.key);
        }
        this.unselectUserView();
        this.resetPagination();
        this.saveTableUserSettingsDebounced();
        await this.fetchData();
    }

    protected onCurrentPageChange(currentPage: number): void {
        this.currentPage = currentPage;
        this.saveTableUserSettingsDebounced();
        this.fetchDataDebounced();
    }

    protected async onPageSizeChange(pageSize: number): Promise<void> {
        this.pageSize = pageSize;
        this.unselectUserView();
        this.resetPagination();
        this.saveTableUserSettingsDebounced();
        await this.fetchData();
    }

    protected async onResetAllColumnFilters(): Promise<void> {
        this.resetPagination();
        this.tableFilters.removeAll();
        this.unselectUserView();
        this.saveTableUserSettingsDebounced();
        await this.fetchData();
    }

    protected async onFilterChange(
        current?: GlobalFilter | Expression | null,
        previous?: GlobalFilter | Expression | null
    ): Promise<void> {
        if (!(current && previous && JSON.stringify(current) === JSON.stringify(previous))) {
            if (!this.isMounted && typeof previous !== 'undefined') {
                this.resetTable();
                await this.fetchData();
            } else {
                this.isMounted = false;
            }
        }
    }
}
