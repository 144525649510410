import { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import ColumnFilterClass from '@/model/Data/Filters/ColumnFilterClass';

interface BooleanFilterValue {
    type: 'boolean' | 'string' | 'number';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: boolean | null;
}

class BooleanFilterClass extends ColumnFilterClass<BooleanFilterValue> {
    public value: BooleanFilterValue = {
        type: 'boolean',
        value: null
    };

    public constructor(key: string, filter?: BooleanFilterClass | BooleanFilterValue) {
        super(key);
        if (filter instanceof BooleanFilterClass) {
            this.value = { ...filter.value };
        } else if (filter) {
            this.value = { ...filter };
        }
    }

    public get filter(): Expression | null {
        if (this.value.value !== null) {
            if (this.value.type === 'boolean') {
                return PrettyExpressionBuilder.equal(this.key, this.value.value);
            } else if (this.value.type === 'string') {
                return this.value.value
                    ? PrettyExpressionBuilder.notEqual(this.key, '')
                    : PrettyExpressionBuilder.equal(this.key, '');
            } else {
                return this.value.value
                    ? PrettyExpressionBuilder.greaterThenOrEqual(this.key, 0)
                    : PrettyExpressionBuilder.lowerThenOrEqual(this.key, 0);
            }
        } else {
            return null;
        }
    }

    public clone(): BooleanFilterClass {
        return new BooleanFilterClass(this.key, this);
    }
}

export default BooleanFilterClass;
