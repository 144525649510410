














import CellRenderer from '@/components/common/Table/cellRenderers/CellRenderer';
import { Component } from 'vue-property-decorator'; // nosonar

@Component
export default class TextCellRenderer extends CellRenderer {}
