








import { Component, Prop, Vue } from 'vue-property-decorator';
import ErrorComponent from '@/components/ErrorComponent.vue';

@Component({ components: { ErrorComponent } })
export default class ErrorPage extends Vue {
    @Prop({ required: true })
    protected error!: unknown;
}
