






import AppStore from '@/store/modules/AppStore';
import CellRenderer from '@/components/common/Table/cellRenderers/CellRenderer';
import type { ChangeListState } from '@/model/Entity/ChangeListState';
import { Component } from 'vue-property-decorator';
import type { State } from '@/model/Entity/State';

@Component({
    name: 'state-renderer'
})
export default class StateCellRenderer extends CellRenderer {
    // Computed
    protected get state(): State | ChangeListState | null {
        if (this.header.filterStoreInit) {
            return this.header.filterStoreInit().getOne(String(this.currentValue));
        } else {
            return null;
        }
    }

    protected get tooltipDelay(): number {
        return AppStore.tooltipDelay;
    }
}
