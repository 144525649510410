import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type { ApiConfig } from '@/model/Interfaces/ApiConfig';
import AuthService from '@/services/AuthService';
import axios from 'axios';
import type { ClientKeycloakTokenParsed } from '@/model/Interfaces/ClientKeycloakTokenParsed';
import { CONSTANTS } from '@/constants';
import ErrorService from '@/services/ErrorService/ErrorService';
import type { GlobalFilter } from '@/model/Interfaces/GlobalFilter';
import { i18n } from '@/plugins/i18n';
import { MessageType } from '@/model/Enums/MessageType';
import type { OrdersSemiGlobalFilter } from '@/model/Interfaces/OrdersSemiGlobalFilter';
import store from '@/store';
import type { SwEvent } from '@/model/Interfaces/SwEvent';
import { v4 as uid } from 'uuid';
import type { User } from '@/model/Entity/User';
import UserSettingsService from '@/services/UserSettingsService';

const module = 'appStore';

export interface AppStoreState {
    activeModules: Array<string>;
    activeRoute: { name: string; path: string } | null;
    authService: AuthService | null;
    elements: {
        changeListSelectHeight: number;
        chartEditingFormHeight: number;
        globalFilterHeight: number;
        mainContainerPadding: number;
        minTableHeight: number;
        navigationWidth: number;
        stepFilterHeight: number;
        tableActionButtonsHeight: number;
        tableFooterHeight: number;
        toolbarHeight: number;
    };
    globalFilter: GlobalFilter;
    isEditMode: boolean;
    isReadOnlyMode: boolean;
    mini: boolean;
    nav: boolean;
    ordersSemiGlobalFilter: OrdersSemiGlobalFilter;
    swEvents: Array<SwEvent>;
    tooltipDelay: number;
    userSettingsManager: UserSettingsService;
}

enum AppStoreMutations {
    SET_ACTIVE_MODULES = 'setModules',
    SET_ACTIVE_ROUTE = 'setActiveRoute',
    SET_AUTH_SERVICE = 'setAuthService',
    SET_CURRENCY = 'setCurrency',
    SET_GLOBAL_FILTER = 'setGlobalFilter',
    SET_MINI = 'setMini',
    SET_NAV = 'setNav',
    SET_ORDERS_SEMI_GLOBAL_FILTER = 'setOrdersSemiGlobalFilter',
    SET_PW_DATE = 'setPwDate',
    SET_THEME = 'setTheme'
}

enum AppStoreActions {
    SAVE_USER_SETTINGS = 'saveUserSettings'
}

interface UserSettings {
    activeRoute: { name: string; path: string } | null;
    globalFilter: GlobalFilter;
    mini: boolean;
    ordersSemiGlobalFilter: OrdersSemiGlobalFilter;
    theme: string;
}

@Module({
    dynamic: true,
    name: module,
    namespaced: true,
    store
})
class AppStore extends VuexModule implements AppStoreState {
    public activeMenus: Record<string, boolean> = {};

    public activeModules: Array<string> = [];

    public activeRoute: { name: string; path: string } | null = null;

    public activeSwEvent: SwEvent | null = null;

    public authService: AuthService | null = null;

    public canLeaveEditMode = true;

    public currency = '';

    public elements = {
        changeListSelectHeight: 46,
        chartEditingFormHeight: 56,
        childIconSize: 22,
        globalFilterHeight: 46,
        iconSize: 24,
        logoSize: 36,
        mainContainerPadding: 24,
        minTableHeight: 200,
        navigationMiniWidth: 62,
        navigationOpenWidth: 200,
        navigationWidth: 62,
        stepFilterHeight: 54,
        tableActionButtonsHeight: 52,
        tableFooterHeight: 44,
        toolbarHeight: 48
    };

    public globalFilter: GlobalFilter = {
        productCategoryIds: [],
        productId: null,
        warehouseCategoryIds: [],
        warehouseId: null
    };

    public isEditMode = false;

    public isReadOnlyMode = false;

    public mini = false;

    public nav = false;

    public ordersSemiGlobalFilter: OrdersSemiGlobalFilter = { dateRange: [], isDefault: true };

    public pwDate = '';

    public swEvents: Array<SwEvent> = [];

    public theme = 'light';

    public tooltipDelay = 500;

    public userSettingsManager = new UserSettingsService(axios);

    public validations: Record<string, boolean> = {};

    public get activeMenu(): (menu: string) => boolean {
        return (menu: string): boolean => this.activeMenus[menu];
    }

    public get user(): User | null {
        return this.authService ? this.authService.user : null;
    }

    @Mutation
    public setModules(activeModules: Array<string>): void {
        this.activeModules = activeModules;
    }

    @Mutation
    public pushSwEvent(payload: { detail: ServiceWorkerRegistration | Error | null; name: string }): void {
        const swEvent = { ...payload, uid: uid() };
        if (!this.activeSwEvent) {
            this.activeSwEvent = swEvent;
        }
        this.swEvents = [...this.swEvents, swEvent];
    }

    @Mutation
    public resolveSwEvent(uid: string): void {
        this.swEvents = this.swEvents.filter((swEvent) => swEvent.uid !== uid);
        if (this.swEvents.length) {
            this.activeSwEvent = this.swEvents[0];
        } else {
            this.activeSwEvent = null;
        }
    }

    @Mutation
    public clearSwEvents(): void {
        this.swEvents = [];
        this.activeSwEvent = null;
    }

    @Mutation
    public setActiveMenu(activeMenu?: Record<string, boolean>): void {
        this.activeMenus = Object.assign({}, this.activeMenus, activeMenu);
    }

    @Mutation
    public setActiveMenus(activeMenus: Record<string, boolean>): void {
        this.activeMenus = { ...activeMenus };
    }

    @Mutation
    public setAuthService(authService: AuthService): void {
        this.authService = authService;
    }

    @Mutation
    public setCanLeaveEditMode(canLeaveEditMode: boolean): void {
        this.canLeaveEditMode = canLeaveEditMode;
    }

    @Mutation
    public setCurrency(currency: string): void {
        this.currency = currency;
    }

    @Mutation
    public setNav(nav: boolean): void {
        this.nav = nav;
    }

    @Mutation
    public setValidation(validation?: Record<string, boolean>): void {
        this.validations = Object.assign({}, this.validations, validation);
    }

    @Mutation
    public setValidations(validations: Record<string, boolean>): void {
        this.validations = validations;
    }

    @Mutation
    public setIsEditMode(isEditMode: boolean): void {
        this.isEditMode = isEditMode;
    }

    @Mutation
    public setActiveRoute(activeRoute: { name: string; path: string } | null): void {
        this.activeRoute = activeRoute;
    }

    @Mutation
    public setOrdersSemiGlobalFilter(ordersSemiGlobalFilter: OrdersSemiGlobalFilter): void {
        this.ordersSemiGlobalFilter = ordersSemiGlobalFilter;
    }

    @Mutation
    public setElements(elements: Record<string, number>): void {
        this.elements = {
            ...this.elements,
            ...elements
        };
    }

    @Mutation
    public setTheme(theme: string): void {
        this.theme = theme;
    }

    @Mutation
    public setGlobalFilter(globalFilter: GlobalFilter): void {
        this.globalFilter = { ...this.globalFilter, ...globalFilter };
    }

    @Mutation
    public setMini(mini: boolean): void {
        this.mini = mini;
    }

    @Mutation
    public setPwDate(pwDate: string): void {
        this.pwDate = pwDate;
    }

    @Mutation
    public setIsReadOnlyMode(isReadOnlyMode: boolean): void {
        this.isReadOnlyMode = isReadOnlyMode;
    }

    @Action({ rawError: true })
    public async auth(payload: { token: ClientKeycloakTokenParsed }): Promise<void> {
        const authService = new AuthService(payload.token);
        await authService.auth();
        this.context.commit(AppStoreMutations.SET_AUTH_SERVICE, authService);
    }

    @Action({ rawError: true })
    public async fetchApiConfig(): Promise<void> {
        const response = await axios.get<ApiConfig>(CONSTANTS.API.CONFIG);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (response.data) {
            this.context.commit(AppStoreMutations.SET_PW_DATE, response.data.data.pwdate.substring(0, 10));
            this.context.commit(AppStoreMutations.SET_CURRENCY, response.data.data.systemCurrency);
            // TODO define modules on API same as FE
            const activeModules = [CONSTANTS.ROUTES.ORDERS.NAME, CONSTANTS.ROUTES.ORDERS_TILES.NAME];
            if (response.data.data.moduleSpngEnabled) {
                activeModules.push(CONSTANTS.ROUTES.SALES_PLANNING.NAME);
            }
            this.context.commit(AppStoreMutations.SET_ACTIVE_MODULES, activeModules);
        } else {
            throw new Error('No Config');
        }
    }

    @Action({ rawError: true })
    public async fetchUserSettings(): Promise<void> {
        try {
            const userSettings = await this.userSettingsManager.get<UserSettings>('userSettings');
            if (userSettings) {
                this.context.commit(AppStoreMutations.SET_GLOBAL_FILTER, userSettings.globalFilter);
                this.context.commit(AppStoreMutations.SET_ACTIVE_ROUTE, userSettings.activeRoute);
                if (userSettings.ordersSemiGlobalFilter.isDefault) {
                    userSettings.ordersSemiGlobalFilter.dateRange = [this.pwDate, this.pwDate];
                }
                this.context.commit(
                    AppStoreMutations.SET_ORDERS_SEMI_GLOBAL_FILTER,
                    userSettings.ordersSemiGlobalFilter
                );
                this.context.commit(AppStoreMutations.SET_THEME, userSettings.theme);
                this.context.commit(AppStoreMutations.SET_MINI, userSettings.mini);
            } else {
                throw new Error('No User Settings');
            }
        } catch (error) {
            this.context.commit(AppStoreMutations.SET_ORDERS_SEMI_GLOBAL_FILTER, {
                dateRange: [this.pwDate, this.pwDate],
                isDefault: true
            });
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningUserSettingsNotFound'),
                messageType: MessageType.Console
            });
            await this.context.dispatch('createUserSettings');
        }
    }

    @Action({ rawError: true })
    public async changeActiveRoute(activeRoute: { name: string; path: string }): Promise<void> {
        this.context.commit(AppStoreMutations.SET_ACTIVE_ROUTE, activeRoute);
        await this.context.dispatch(AppStoreActions.SAVE_USER_SETTINGS);
    }

    @Action({ rawError: true })
    public async changeOrdersSemiGlobalFilter(dateFilter: OrdersSemiGlobalFilter): Promise<void> {
        this.context.commit(AppStoreMutations.SET_ORDERS_SEMI_GLOBAL_FILTER, dateFilter);
        await this.context.dispatch(AppStoreActions.SAVE_USER_SETTINGS);
    }

    @Action({ rawError: true })
    public async changeMini(mini: boolean): Promise<void> {
        this.context.commit(AppStoreMutations.SET_MINI, mini);
        await this.context.dispatch(AppStoreActions.SAVE_USER_SETTINGS);
    }

    @Action({ rawError: true })
    public async changeGlobalFilter(payload: {
        productCategoryIds?: Array<number>;
        productId?: number | null;
        warehouseCategoryIds?: Array<number>;
        warehouseId?: number | null;
    }): Promise<void> {
        this.context.commit(AppStoreMutations.SET_GLOBAL_FILTER, payload);
        await this.context.dispatch(AppStoreActions.SAVE_USER_SETTINGS);
    }

    @Action({ rawError: true })
    public async changeTheme(theme: string): Promise<void> {
        this.context.commit(AppStoreMutations.SET_THEME, theme);
        await this.context.dispatch(AppStoreActions.SAVE_USER_SETTINGS);
    }

    @Action({ rawError: true })
    public async resetUserSettings(): Promise<void> {
        this.context.commit(AppStoreMutations.SET_GLOBAL_FILTER, {
            productCategoryIds: [],
            productId: null,
            warehouseCategoryIds: [],
            warehouseId: null
        });
        this.context.commit(AppStoreMutations.SET_ORDERS_SEMI_GLOBAL_FILTER, { dateRange: [], isDefault: true });
        this.context.commit(AppStoreMutations.SET_THEME, 'light');
        await this.context.dispatch('saveUserSettings');
    }

    @Action({ rawError: true })
    public async saveUserSettings(): Promise<UserSettings | null> {
        try {
            return await this.userSettingsManager.createOrUpdate<UserSettings>('userSettings', {
                activeRoute: this.activeRoute,
                globalFilter: this.globalFilter,
                mini: this.mini,
                ordersSemiGlobalFilter: this.ordersSemiGlobalFilter,
                theme: this.theme
            });
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningUserSettingsNotSaved'),
                messageType: MessageType.Console
            });
            return null;
        }
    }

    @Action({ rawError: true })
    public async createUserSettings(): Promise<UserSettings | null> {
        try {
            return await this.userSettingsManager.create<UserSettings>('userSettings', {
                activeRoute: this.activeRoute,
                globalFilter: this.globalFilter,
                mini: this.mini,
                ordersSemiGlobalFilter: this.ordersSemiGlobalFilter,
                theme: this.theme
            });
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningUserSettingsNotSaved'),
                messageType: MessageType.Console
            });
            return null;
        }
    }
}

export default getModule(AppStore);
