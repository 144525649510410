
import type { DefaultNumberFilterOptions } from '@logio/vue2-tabulator';
import { Component, Emit } from 'vue-property-decorator';
import { DefaultNumberFilter, DefaultNumberFilterValue } from '@logio/vue2-tabulator';
import { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import type { TabulatorFilterResult } from '@/model/types/tabulator/TabulatorFilterResult';

type FilterValueWithExpression = DefaultNumberFilterValue & TabulatorFilterResult;

/**
 * Wrapper component for DefaultNumberFilter which adds emitting Expression to the object value.
 */
@Component
export default class NumberMenu extends DefaultNumberFilter {
    @Emit('on-filter-value-change')
    protected onFilterValueChange(filterValue: Partial<DefaultNumberFilterValue> | null): FilterValueWithExpression {
        const resolvedValue = this.resolveFilterValueChange(filterValue) as DefaultNumberFilterValue;
        return {
            ...resolvedValue,
            expression: this.transformToNumberFilterExpression(resolvedValue)
        };
    }

    protected transformToNumberFilterExpression(filterValue: Partial<DefaultNumberFilterValue> | null): Expression | null {
        if (!filterValue) return null;
        const { from, to, restriction } = filterValue;
        const emptyValues = (this.columnMenuOptions as DefaultNumberFilterOptions)?.emptyValues || [null];

        // Use field from filter options id it does exist otherwise use original field name
        const filterField = (this.columnMenuOptions as DefaultNumberFilterOptions)?.filterField || (this.column as { field: string }).field;
        const filters: Array<Expression> = [];

        if (restriction === 'onlyNull') {
            return PrettyExpressionBuilder.or(...emptyValues.map((emptyValue) => PrettyExpressionBuilder.equal(filterField, emptyValue)));
        } else {
            if (from != null && to != null) {
                // Both From and To values are filled
                filters.push(
                    PrettyExpressionBuilder.and(
                        PrettyExpressionBuilder.greaterThenOrEqual(filterField, from),
                        PrettyExpressionBuilder.lowerThenOrEqual(filterField, to)
                    )
                );
            } else if (from != null) {
                // Only if value From is filled
                filters.push(PrettyExpressionBuilder.greaterThenOrEqual(filterField, from));
            } else if (to != null) {
                // Only if value To is filled
                filters.push(PrettyExpressionBuilder.lowerThenOrEqual(filterField, to));
            }

            // if withNull restriction is set
            if (restriction === 'withNull') {
                // Only if any filters (values From or To) was set before otherwise we would filter only null values
                if (filters.length) {
                    filters.push(...emptyValues.map((emptyValue) => PrettyExpressionBuilder.equal(filterField, emptyValue)));
                }
            }

            // if withoutNull restriction is set
            if (restriction === 'withoutNull') {
                // Only if any filters (values From or To) was set before otherwise we would filter only null values
                const finalFilters = filters.length ? [PrettyExpressionBuilder.or(...filters)] : [];
                finalFilters.push(...emptyValues.map((emptyValue) => PrettyExpressionBuilder.notEqual(filterField, emptyValue)));
                return PrettyExpressionBuilder.and(...finalFilters);
            }
        }

        if (filters.length === 0) {
            return null;
        } else if (filters.length === 1) {
            return filters[0];
        } else {
            return PrettyExpressionBuilder.or(...filters);
        }
    }
}
