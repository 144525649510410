import { i18n } from '@/plugins/i18n';
import LogioTabulator from '@logio/vue2-tabulator';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';

const options = {
    i18n,
    vuetify
};

Vue.use(LogioTabulator, options);
