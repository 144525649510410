/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { Resource } from '@bednic/json-api-client';
import type { TableHeader } from '@/model/Interfaces/TableHeader';

@Component
export default class CellRenderer extends Vue {
    // Data
    @Prop({ default: false, required: true, type: Object })
    protected header!: TableHeader;

    @Prop({ default: false, required: true, type: Object })
    protected item!: Resource;

    // Computed
    protected get alignClass(): string {
        switch (this.header.contentAlign) {
            case 'left':
                return 'text-left justify-start';
            case 'center':
                return 'text-center justify-center';
            case 'right':
                return 'text-right justify-end';
            default: {
                if (this.header.renderer === 'number' || this.header.renderer === 'date') {
                    return 'text-right justify-end';
                } else {
                    return 'text-left justify-start';
                }
            }
        }
    }

    protected get currentValue(): unknown {
        if (this.item?.attributes?.[this.header.value]) {
            return this.item.attributes[this.header.value] as unknown;
        }
        return null;
    }

    protected get id(): string {
        return `cell_${this.item.id}_${this.header.value}`;
    }

    protected onClickLink(): void {
        if (this.header.onClickLink) {
            this.header.onClickLink(this.item);
        }
    }
}
