






























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import type { OrderByItems } from '@/model/Entity/OrderByItems';
import StateStore from '@/store/modules/StateStore';
import UserStore from '@/store/modules/UserStore';

@Component
export default class OrderTile extends Vue {
    @Prop({ default: null, required: false, type: String })
    protected imgSrc!: string | null;

    @Prop({ default: null, required: true, type: Object })
    protected order!: OrderByItems;

    protected stateStore = StateStore;

    protected userStore = UserStore;

    protected get abbreviation(): string {
        const supplierName = this.order.attributes.supplierName;
        const [firstWord = '  ', secondWord = ''] = (supplierName || '').split(/[^a-z0-9]+/i);
        return `${firstWord[0].toUpperCase()}${secondWord[0] ? secondWord[0].toUpperCase() : firstWord[1]}`;
    }

    protected get avatarColor(): string {
        const supplierId = this.order.attributes.supplierId as number;
        return `rgb(${((supplierId || 1) * 317) % 200}, ${((supplierId || 1) * 137) % 200}, ${((supplierId || 1) * 71) % 200})`;
    }

    protected get color(): string {
        return this.stateStore.getOne(this.order.attributes.stateId as string)?.attributes.color || '';
    }
}
