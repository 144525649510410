/* eslint-disable @typescript-eslint/no-explicit-any,
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types */
import ApplicationError from '@/model/Classes/ApplicationError';
import { captureException } from '@sentry/vue';
import { ErrorDocument } from '@bednic/json-api-client';
import type { JsonApiError } from '@/model/Interfaces/JsonApiError';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import MessagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';

export interface ErrorInterface {
    detail?: string;
    options?: {
        actions?: ErrorActions;
        data?: Record<string, any>;
        error?: Error;
        messageLevel?: MessageLevel;
        messageType?: MessageType;
    };
    title: string;
}

export interface ErrorActions {
    actionText?: string;
    cancelBtnText?: string;
    cancelFunc?: () => void;
    confirmBtnText?: string;
    confirmFunc?: () => void;
    isConfirmDelete?: boolean;
    secondaryBtnText?: string;
    secondaryFunc?: () => void;
}

/**
 * Common interface for errors handled by the application
 */
export abstract class ErrorHandler {
    /**
     * Determine if handler can handle the error
     * @param error
     */
    abstract accept(error: any): boolean;

    /**
     * Returns ApplicationError collection
     * @param error
     * @param context
     * @param messageType
     * @param actions
     */
    abstract handle(
        error: any,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): Array<ApplicationError>;
}

class VoidErrorHandler extends ErrorHandler {
    public handle(
        error: ApplicationError,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): Array<ApplicationError> {
        return [error];
    }

    public accept(error: any): boolean {
        return error instanceof ApplicationError;
    }
}

/**
 * Handle string errors
 * @example "throw 'Bad staff happens'"
 */
class StringErrorHandler extends ErrorHandler {
    public handle(
        error: string,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): Array<ApplicationError> {
        const title = context || error;
        const detail = context ? error : undefined;
        return [
            new ApplicationError(title, detail, {
                actions,
                error: Error(error),
                messageLevel: MessageLevel.Error,
                messageType
            })
        ];
    }

    public accept(error: any): boolean {
        return typeof error === 'string';
    }
}

/**
 * Handle errors which are instances of JS Core Error
 */
class UnknownErrorHandler extends ErrorHandler {
    public handle(
        error: Error,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): Array<ApplicationError> {
        const title = context || error.name;
        const detail =
            context && error.message ? `${error.name}: ${error.message}` : context ? error.name : error.message;
        return [
            new ApplicationError(title, detail, {
                actions,
                error,
                messageLevel: MessageLevel.Error,
                messageType
            })
        ];
    }

    public accept(error: any): boolean {
        return error instanceof Error;
    }
}

/**
 * Handle Axios HTTP errors
 */
class ErrorDocumentHandler extends ErrorHandler {
    public handle(
        error: any,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): Array<ApplicationError> {
        const applicationErrors: Array<ApplicationError> = [];
        if (error.errors) {
            for (const err of error.errors) {
                const title = context ? context : err.title;
                const detail = context && err.detail ? `${err.title as string}: ${err.detail as string}` : context ? err.title : err.detail;
                applicationErrors.push(
                    new ApplicationError(title as string, detail, {
                        actions,
                        data: err.meta,
                        error,
                        messageLevel: MessageLevel.Error,
                        messageType
                    })
                );
            }
        }
        return applicationErrors;
    }

    public accept(error: any): boolean {
        return error instanceof ErrorDocument;
    }
}

/**
 * Handle Axios HTTP errors
 */
class AxiosErrorHandler extends ErrorHandler {
    public handle(
        error: any,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): Array<ApplicationError> {
        const applicationErrors: Array<ApplicationError> = [];
        if (error.response && error.response.data) {
            const parseResult = this.parseErrorResponse(error, context, messageType, actions);
            const data = parseResult.data;
            if (parseResult.newError) {
                applicationErrors.push(parseResult.newError);
            }
            if (typeof data === 'object' && data !== null && Object.keys(data).length) {
                const response = error.response.data ;
                for (const responseError of response.errors) {
                    const title = this.getTitle(responseError, context);
                    const detail = this.getDetail(responseError, context);
                    applicationErrors.push(
                        new ApplicationError(title, detail, {
                            actions,
                            data: responseError.meta,
                            error,
                            messageLevel: MessageLevel.Error,
                            messageType
                        })
                    );
                }
            }
        } else {
            const title = this.getTitle(error, context);
            const detail = this.getDetail(error, context);
            applicationErrors.push(
                new ApplicationError(title, detail, {
                    actions,
                    error,
                    messageLevel: MessageLevel.Error,
                    messageType: messageType || MessageType.Notification
                })
            );
        }
        return applicationErrors;
    }

    public accept(error: any): boolean {
        return !!error.isAxiosError;
    }

    protected getTitle(responseError: JsonApiError, context?: string): string {
        return context ? context : `${responseError.title || ''}`;
    }

    protected getDetail(responseError: JsonApiError, context?: string): string {
        return (
            (context && responseError.detail
                ? `${responseError.title as string}: ${responseError.detail}`
                : context
                    ? responseError.title
                    : responseError.detail) || ''
        );
    }

    protected parseErrorResponse(
        error: any,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): { data: any; newError: ApplicationError | undefined } {
        let data: string = typeof error.response.data !== 'string' ? JSON.stringify(error.response.data) : error.response.data;
        let newError;
        try {
            data = JSON.parse(data);
        } catch (e) {
            const text: string = error.response.data;
            const startIndex = text.indexOf('<pre>') + 5;
            const endIndex = text.indexOf('</pre>');
            const detail = text.substr(startIndex, endIndex - startIndex);
            newError = new ApplicationError(context || 'ERROR', detail || undefined, {
                actions,
                error,
                messageLevel: MessageLevel.Error,
                messageType: messageType || MessageType.Notification
            });
        }
        return { data, newError };
    }
}

class ErrorService {
    private readonly handlers: Array<ErrorHandler> = [
        new VoidErrorHandler(),
        new StringErrorHandler(),
        new ErrorDocumentHandler(),
        new AxiosErrorHandler(),
        new UnknownErrorHandler()
    ];

    /**
     * Transforms any error to standardized interface.
     * If you want use custom specialized handler, pass it as second arg.
     *
     * @param error
     * @param config
     */
    public async dispatch(
        error: any,
        config?: {
            actions?: ErrorActions;
            context?: string;
            customHandlers?: Array<ErrorHandler>;
            messageType?: MessageType;
        }
    ): Promise<Array<ApplicationError>> {
        const { context, customHandlers, messageType, actions } = config
            ? config
            : {
                actions: undefined,
                context: undefined,
                customHandlers: undefined,
                messageType: undefined
            };
        let errors: Array<ApplicationError> = [];
        const handlers = customHandlers ? [...customHandlers, ...this.handlers] : this.handlers;
        for (const handler of handlers) {
            if (handler.accept(error)) {
                errors = handler.handle(error, context, messageType, actions);
                break;
            }
        }
        for (const err of errors) {
            captureException(err);
            MessagesStore.sendMessage(err);
        }
        return Promise.resolve(errors);
    }
}

export default new ErrorService();
