import { getModule, Module } from 'vuex-module-decorators';
import type { CategoryType } from '@/model/Entity/CategoryType';
import { CONSTANTS } from '@/constants';
import { DataStore } from '@/store/DataStore';
import EntityService from '@/services/EntityService';
import store from '@/store';

const module = 'CategoryTypeStore';

export interface CategoryTypeStoreState {
    entityService: EntityService;
    items: Array<CategoryType>;
}

@Module({
    dynamic: true,
    name: module,
    namespaced: true,
    store
})
class CategoryTypeStore extends DataStore<CategoryType> implements CategoryTypeStoreState {
    public entityService = new EntityService(CONSTANTS.API.SP_CATEGORY_TYPES);
}

export default getModule(CategoryTypeStore);
