import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type ApplicationError from '@/model/Classes/ApplicationError';
import appStore from '@/store/modules/AppStore';
import { i18n } from '@/plugins/i18n';
import { Message } from '@/model/Classes/Message';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import { MessageType } from '@/model/Enums/MessageType';
import store from '@/store';
import vuetify from '@/plugins/vuetify';

const module = 'messagesStore';

export interface MessagesStoreState {
    activeDialog: Message | null;
    messages: Array<Message>;
    messagesTimeout: Array<{ messageId: string; timeout: number }>;
}

enum MessagesStoreMutations {
    ADD_MESSAGE_TIMEOUT = 'addMessageTimeout',
    CLEAR_MESSAGE_TIMEOUT = 'clearMessageTimeout',
    SET_ACTIVE_DIALOG = 'setActiveDialog',
    SET_MESSAGE = 'setMessage'
}

enum MessagesStoreActions {
    RESOLVE_ACTIVE_DIALOG = 'resolveActiveDialog',
    RESOLVE_MESSAGE = 'resolveMessage',
    SEND_MESSAGE = 'sendMessage'
}

@Module({
    dynamic: true,
    name: module,
    namespaced: true,
    store
})
class MessagesStore extends VuexModule implements MessagesStoreState {
    public activeDialog: Message | null = null;

    public messages: Array<Message> = [];

    public messagesTimeout: Array<{ messageId: string; timeout: number }> = [];

    public get notifications(): Array<Message> {
        return this.messages.filter(
            (message) => message.messageType === MessageType.Notification && !message.isResolved
        );
    }

    @Mutation
    public setMessage(message: Message): void {
        const foundIndex = this.messages.findIndex((item) => item.id === message.id);
        if (foundIndex === -1) {
            this.messages = [...this.messages, message];
        } else {
            this.messages = this.messages.map((item) => item.id === message.id ? message : item);
        }
        if (message.messageType === MessageType.Dialog && !message.isResolved && !this.activeDialog) {
            this.activeDialog = { ...message };
        }
    }

    @Mutation
    protected addMessageTimeout(payload: { messageId: string; timeout: number }): void {
        this.messagesTimeout = [...this.messagesTimeout, payload];
    }

    @Mutation
    protected clearMessageTimeout(messageId: string): void {
        this.messagesTimeout = this.messagesTimeout.filter((messageTimeout) => {
            if (messageTimeout.messageId === messageId) {
                window.clearTimeout(messageTimeout.timeout);
                return false;
            } else {
                return true;
            }
        });
    }

    @Mutation
    public setActiveDialog(message: Message | null): void {
        this.activeDialog = message;
    }

    @Mutation
    public setMessageProgress(payload: { messageId: string; progress: number | undefined }): void {
        this.messages = this.messages.map((item) => {
            if (item.id === payload.messageId && item.options) {
                item.options.progress = payload.progress;
            }
            return item;
        });
    }

    @Action({ rawError: true })
    public async resolveMessage(messageId: string): Promise<void> {
        const foundMessage = this.messages.find((item) => item.id === messageId);
        if (foundMessage) {
            const message = {
                ...foundMessage,
                isResolved: true
            };
            this.context.commit(MessagesStoreMutations.SET_MESSAGE, message);
            if (
                message.messageType === MessageType.Dialog &&
                this.activeDialog &&
                this.activeDialog.id === message.id
            ) {
                await this.context.dispatch(MessagesStoreActions.RESOLVE_ACTIVE_DIALOG);
            }
        }
    }

    @Action({ rawError: true })
    public resolveActiveDialog(): void {
        if (this.activeDialog) {
            this.context.commit(MessagesStoreMutations.SET_ACTIVE_DIALOG, null);
            const nextDialog = this.messages.filter(
                (message) => message.messageType === MessageType.Dialog && !message.isResolved
            )[0];
            if (typeof nextDialog !== 'undefined') {
                window.setTimeout(() => {
                    this.context.commit(MessagesStoreMutations.SET_ACTIVE_DIALOG, nextDialog);
                }, 500);
            }
        }
    }

    @Action({ rawError: true })
    public sendMessage(message: Message | ApplicationError): void {
        const foundMessage = this.messages.find((item) => item.id === message.id);
        if (foundMessage) {
            if (foundMessage.options?.timeout) {
                this.context.commit(MessagesStoreMutations.CLEAR_MESSAGE_TIMEOUT, foundMessage.id);
            }
            const updatedMessage = foundMessage.isResolved
                ? { ...message, count: 1, isResolved: false }
                : { ...message, count: foundMessage.count + 1 };
            this.context.commit(MessagesStoreMutations.SET_MESSAGE, updatedMessage);
        } else {
            this.context.commit(MessagesStoreMutations.SET_MESSAGE, { ...message, count: 1, isResolved: false });
        }
        if (message instanceof Message && message.options && message.options.timeout) {
            const timeout = window.setTimeout(() => {
                void this.context.dispatch(MessagesStoreActions.RESOLVE_MESSAGE, message.id);
            }, message.options.timeout);
            this.context.commit(MessagesStoreMutations.ADD_MESSAGE_TIMEOUT, { messageId: message.id, timeout });
        }
        if (message.messageType === MessageType.Console || process.env.VUE_APP_MODE !== 'production') {
            {
                let text = `%c${message.messageLevel.toUpperCase()}: ${message.title}`;
                if (message.detail) {
                    text += `\n${message.detail}`;
                }
                // Dont delete log
                console.log(text, `color: ${vuetify.framework.theme.currentTheme[message.messageLevel as string] as string}`);
                if (message.messageLevel === MessageLevel.Error) {
                    console.error(message.error?.stack || message);
                }
            }
        }
    }

    @Action({ rawError: true })
    public async dispatchLeaveUpdateConfirm(payload?: {
        cancelCallbackFunc?: () => void;
        confirmCallbackFunc?: () => void;
    }): Promise<void> {
        const message = new Message(
            i18n.tc('components.confirmBox.leaveEditModeTitle'),
            i18n.tc('components.confirmBox.leaveEditModeDetail'),
            {
                actions: {
                    cancelBtnText: i18n.tc('components.confirmBox.cancel'),
                    cancelFunc: (): void => {
                        if (payload?.cancelCallbackFunc) {
                            payload.cancelCallbackFunc();
                        }
                    },
                    confirmBtnColor: 'error',
                    confirmBtnText: i18n.tc('components.confirmBox.confirm'),
                    confirmFunc: (): void => {
                        appStore.setIsEditMode(false);
                        appStore.setValidations({});
                        if (payload?.confirmCallbackFunc) {
                            payload.confirmCallbackFunc();
                        }
                    }
                },
                messageLevel: MessageLevel.Error,
                messageType: MessageType.Dialog
            }
        );
        await this.context.dispatch(MessagesStoreActions.SEND_MESSAGE, message);
    }
}

export default getModule(MessagesStore);
