import { CONSTANTS } from '@/constants';
import type { NavigationRouteConfig } from './model/NavigationRouteConfig';
import OrdersTiles from '@/views/OrdersTiles.vue';

const ordersTilesModule: Record<string, NavigationRouteConfig> = {
    [CONSTANTS.ROUTES.ORDERS_TILES.NAME]: {
        component: OrdersTiles,
        icon: 'mdi mdi-apps',
        isInNavigation: true,
        name: CONSTANTS.ROUTES.ORDERS_TILES.NAME,
        path: CONSTANTS.ROUTES.ORDERS_TILES.PATH,
        requiredPermissions: []
    }
};

export default ordersTilesModule;
