/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Actions } from '@/model/Interfaces/Actions';
import ApplicationError from '@/model/Classes/ApplicationError';
import type { MessageLevel } from '@/model/Enums/MessageLevel';
import type { MessageType } from '@/model/Enums/MessageType';

export class Message extends ApplicationError {
    public count = 1;

    public constructor(
        public title: string,
        public detail?: string,
        public options?: {
            actions?: Actions;
            data?: Record<string, any>;
            messageLevel: MessageLevel;
            messageType: MessageType;
            progress?: number;
            timeout?: number;
        }
    ) {
        super(title, detail, options);
    }
}
