














import { Component, Vue } from 'vue-property-decorator';
import appStore from '@/store/modules/AppStore';
import type { NavigationRouteConfig } from '@/router/model/NavigationRouteConfig';

@Component
export default class Toolbar extends Vue {
    // Lifecycle Hooks
    public mounted(): void {
        const theme = appStore.theme;
        if (theme === 'light') {
            this.$vuetify.theme.dark = false;
        } else if (theme === 'dark') {
            this.$vuetify.theme.dark = true;
        }
    }

    // Computed
    protected get isProduction(): boolean {
        return process.env.VUE_APP_MODE === 'production';
    }

    protected get route(): NavigationRouteConfig | null {
        let foundRoute: NavigationRouteConfig | null = null;
        if (this.$router.options.routes?.length) {
            for (const route of this.$router.options.routes) {
                foundRoute = this.findRoute(route as NavigationRouteConfig);
                if (foundRoute) {
                    break;
                }
            }
        }
        return foundRoute;
    }

    protected get title(): string {
        const title = this.$tc(`routes.titles.${this.$route.name as string}`).toString();
        return title ? title.charAt(0).toUpperCase() + title.substring(1) : '';
    }

    protected get toolbarHeight(): number {
        return appStore.elements.toolbarHeight;
    }

    // Computed Getters and Setters
    protected get isDark(): boolean {
        return this.$vuetify.theme.dark;
    }

    protected set isDark(isDark: boolean) {
        this.$vuetify.theme.dark = isDark;
        if (isDark) {
            void appStore.changeTheme('dark');
        } else {
            void appStore.changeTheme('light');
        }
    }

    protected get nav(): boolean {
        return appStore.nav;
    }

    protected set nav(nav: boolean) {
        appStore.setNav(nav);
    }

    protected get mini(): boolean {
        return appStore.mini;
    }

    protected set mini(mini: boolean) {
        void appStore.changeMini(mini);
    }

    protected findRoute(route: NavigationRouteConfig): NavigationRouteConfig | null {
        let foundRoute: NavigationRouteConfig | null = null;
        if (route.name === this.$route.name) {
            foundRoute = route;
        } else if (route.children?.length) {
            for (const child of route.children) {
                foundRoute = this.findRoute(child);
                if (foundRoute) {
                    break;
                }
            }
        }
        return foundRoute;
    }

    protected toggleNav(): void {
        if (this.$vuetify.breakpoint.smAndDown) {
            this.nav = !this.nav;
        } else {
            this.mini = !this.mini;
        }
    }

    protected async onBtnBackClick(): Promise<void> {
        if (this.route?.goBackTo) {
            await this.$router.push({ name: this.route.goBackTo });
        }
    }
}
