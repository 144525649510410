






import abcValueStore from '@/store/modules/AbcValueStore';
import CellRenderer from '@/components/common/Table/cellRenderers/CellRenderer';
import { Component } from 'vue-property-decorator';

@Component
export default class AbcCellRenderer extends CellRenderer {
    protected get style(): Array<unknown> {
        const abcValue = abcValueStore.getOne(this.currentValue as string);
        return abcValue ? [{ 'background-color': abcValue.attributes.color }] : [];
    }
}
