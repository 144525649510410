export const CONSTANTS = Object.freeze({
    API: {
        CONFIG: 'config',
        ORDERS: 'orders',
        ORDER_EXPORTS: 'order-exports',
        SUPPLIER_ORDERS: 'supplier-orders',
        SUPPLIER_ORDER_EXPORTS: 'supplier-order-exports',
        SP_HISTORY: 'sp/history',
        SP_FORECAST: 'sp/forecast',
        USERS: 'users',
        STATES: 'order-states',
        SUPPLIERS: 'suppliers',
        PRODUCTS: 'products',
        FLAGS: 'order-flags',
        SP_CHANGES: 'sp-changes',
        SP_CHANGE_LISTS: 'sp-change-lists',
        SP_CHANGE_LIST_STATES: 'sp-change-list-states',
        SP_CHANGE_LIST_AUTHORS: 'sp-change-list-authors',
        SP_CUSTOMERS: 'sp-customers',
        SP_CATEGORIES: 'sp-categories',
        SP_CATEGORY_TYPES: 'sp-category-types',
        CUSTOMERS: 'customers',
        ABC_VALUES: 'abc-values',
        USER_SETTINGS: 'user-settings',
        PRODUCT_CATEGORIES: 'product-categories',
        WAREHOUSE_CATEGORIES: 'warehouse-categories',
        WAREHOUSES: 'warehouses',
        COMPUTE_STATUS: 'compute-status',
        USER_PERMISSIONS: 'ac/user-privileges',
        FILTERS: {
            SP_CHANGE_LIST_NAMES: '/sp-change-list/distinct-names',
            PRODUCT_SHORTCUTS_NAMES: '/product/distinct-shortcuts',
            PRODUCTS_NAMES: '/product/distinct-names',
            PRODUCT_UNITS_NAMES: '/product-unit/distinct-names',
            SKU_IDS: '/sku/ids',
            PRODUCT_SHORTCUTS: '/product/distinct-shortcuts',
            WAREHOUSES_NAMES: '/warehouse/distinct-names',
            CURRENCIES: '/currency/distinct-names'
        },
        OPTIONS: {
            AMOUNT: 'amount',
            AMOUNT_NON_PROMO: 'amount-non-promo',
            CUSTOMER: 'customer',
            CUSTOMER_NONPROMO: 'customer-non-promo',
            CUSTOMER_PRODUCT: 'customer-product',
            CUSTOMER_PRODUCT_NONPROMO: 'customer-product-non-promo'
        }
    },
    FILTERS: {
        FILTER_OPTIONS: {
            WITH_NULL: 'withNull',
            WITHOUT_NULL: 'withoutNull',
            NULL_ONLY: 'nullOnly'
        }
    },
    MODULES: {
        SP_CHANGES: 'spChanges',
        CHANGE_LIST_SUMMARY: 'changeListSummary',
        FORECAST: 'forecast',
        ORDERS_BY_ITEMS: 'ordersByItems',
        ORDERS_BY_SUPPLIERS: 'ordersBySuppliers',
        SALES_PLANNING: 'salesPlanning'
    },
    NUMBERS: {
        AUTOCOMPLETE_DEBOUNCE: 500,
        LAZY_LOADING_LIMIT: 50,
        LIMIT: 1000,
        TITLE_LIMIT: 100,
        Z_INDEX_BASE: 1000
    },
    ROUTES: {
        ROOT: {
            NAME: '',
            PATH: '/'
        },
        DASHBOARD: {
            NAME: 'dashboard',
            PATH: '/dashboard'
        },
        ORDERS: {
            NAME: 'orders',
            PATH: '/orders',
            CHILDREN: {
                BY_SUPPLIERS: { NAME: 'ordersBySuppliers', PATH: 'by-suppliers' },
                BY_ITEMS: { NAME: 'ordersByItems', PATH: 'by-items' }
            }
        },
        ORDERS_TILES: {
            NAME: 'ordersTiles',
            PATH: '/orders-tiles'
        },
        REDIRECT: {
            NAME: 'redirect',
            PATH: '/redirect/:from/:to'
        },
        STRUCTURE: {
            NAME: 'structure',
            PATH: '/structure'
        },
        SALES_PLANNING: {
            NAME: 'salesPlanning',
            PATH: '/sales-planning',
            CHILDREN: {
                FORECAST: {
                    NAME: 'forecast',
                    PATH: 'forecast'
                },
                CHANGE_LIST_SUMMARY: {
                    NAME: 'changeListSummary',
                    PATH: '/change-list-summary'
                }
            }
        },
        WAREHOUSES: {
            NAME: 'warehouses',
            PATH: '/warehouses'
        },
        NOT_FOUND: {
            NAME: 'pageNotFound',
            PATH: '*'
        }
    },
    PERMISSIONS: {
        APPLICATION: 'application',
        READ_MODULE_ORDERS: 'read-module-orders',
        READ_MODULE_SP: 'read-module-sp',
        UPDATE_ORDERS: 'update-orders',
        UPDATE_PRODUCTS: 'update-products',
        WRITE_SP_CHANGE_LIST: 'write-sp-change-lists'
    },
    ERRORS: {
        NOT_VALID_CONFIG: 'not_valid_config',
        NOT_AUTHENTICATED: 'not_authenticated',
        NO_ROLES: 'no_roles',
        NO_ACCESS: 'no_access',
        NO_PERMISSIONS: 'no_permissions',
        NO_MODULES: 'no_modules',
        PAGE_NOT_FOUND: 'page_not_found',
        REFRESH_TOKEN: 'refresh_token',
        KEYCLOAK: (error: Error) => `implementation: keycloak error ${error.message}`
    }
});
