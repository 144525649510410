






























import { Component, Vue } from 'vue-property-decorator';
import appStore from '@/store/modules/AppStore';
import ErrorService from '@/services/ErrorService/ErrorService';
import { MessageType } from '@/model/Enums/MessageType';
import type { RedirectData } from '@/model/Entity/RedirectData';

@Component
export default class Redirect extends Vue {
    // Lifecycle Hooks
    public async created(): Promise<void> {
        try {
            if (this.$route.params.from === 'pw2' && this.$route.params.to === 'pwng') {
                const response = await this.axios.get<RedirectData>('translate/filters-from-pw2', {
                    params: this.$route.query
                });
                const globalFilter = response.data.data.globalFilter;
                await appStore.changeGlobalFilter(globalFilter);
            } else {
                throw new Error('Not Supported Redirect');
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorRedirect'),
                messageType: MessageType.Notification
            });
        } finally {
            await this.$router.replace({ name: 'orders' });
        }
    }
}
