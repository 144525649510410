












































import { Component, Emit } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import UserViewsDialog from '@/components/common/Table/userViews/UserViewsDialog';

@Component({
    // eslint-disable-next-line no-undef
    components: { ConfirmationButtons }
})
export default class DeleteDialog extends UserViewsDialog {
    // Methods
    protected onCancel(): void {
        this.syncedActiveDialog = null;
    }

    // EventHandlers
    @Emit('deleteView')
    protected onConfirm(): void {
        this.onCancel();
    }
}
