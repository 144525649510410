import CompoundDocument, { ExpressionBuilder, PrettyExpressionBuilder, Resource } from '@bednic/json-api-client';
import axios from 'axios';
import { CONSTANTS } from '@/constants';
import ErrorService from '@/services/ErrorService/ErrorService';
import { MessageType } from '@/model/Enums/MessageType';

class AutocompleteFetchService {
    public async fetchDistinct<T>(url: string, value: string, offset: number): Promise<Array<T>> {
        const result = await axios.get<{ data: T[] }>(url, {
            params: {
                contains: value,
                'page[limit]': CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT,
                'page[offset]': offset
            }
        });

        return result.data.data;
    }

    public async fetchResource<T extends Resource>(
        url: string,
        value = '',
        offset = 0,
        fields: Array<string> = []
    ): Promise<Array<T>> {
        const doc = new CompoundDocument<T>(url, axios);
        doc.filter().setLimit(CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT);
        doc.filter().setOffset(offset);
        if (fields.length === 1) {
            doc.filter().where(
                PrettyExpressionBuilder.contains(
                    ExpressionBuilder.toLower(ExpressionBuilder.field(fields[0])).express(),
                    value.toLowerCase()
                )
            );
        } else if (fields.length > 1) {
            doc.filter().where(
                PrettyExpressionBuilder.or(
                    ...fields.map((field) =>
                        PrettyExpressionBuilder.contains(
                            ExpressionBuilder.toLower(ExpressionBuilder.field(field)).express(),
                            value.toLowerCase()
                        )
                    )
                )
            );
        }

        let data;
        try {
            data = (await doc.self()).data;
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: 'messages.errorFilterSearch', // TODO TRANSLATE
                messageType: MessageType.Notification
            });
        }
        return Array.isArray(data) ? data : data ? [data] : [];
    }
}

export default new AutocompleteFetchService();
