import type {
    DragDropPositionObject,
    PlotLineOptions,
    Point,
    PointDragDropObject,
    PointDragEventObject,
    PointerCoordinatesObject,
    Series
} from 'highcharts';
import type { Dictionary } from 'vue-router/types/router';

export enum DependencyTypes {
    Binded = 'binded',
    Polarized = 'polarized'
}

export interface PwngLineChartHideEvent {
    // eslint-disable-next-line @typescript-eslint/ban-types
    preventDefault: Function;
    target: Series;
    type: 'hide';
}

export interface PwngLineChartPoint {
    x: number;
    y: number;
}

export interface PwngLineChartSelectEvent {
    accumulate: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    preventDefault: Function;
    target: Point;
    type: 'select';
}

export interface PwngLineChartUnselectEvent {
    accumulate: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    preventDefault: Function;
    target: Point;
    type: 'unselect';
}

export interface PwngLineChartDragEvent extends PointDragEventObject, PointerCoordinatesObject {
    newPoint?: { x?: number; y?: number };
    newPointId?: string;
    numNewPoints: number;
}

export interface PwngLineChartDropEvent extends PointerCoordinatesObject {
    newPoint?: { x?: number; y?: number };
    newPointId?: string;
    newPoints: Dictionary<PointDragDropObject>;
    numNewPoints: number;
    origin: DragDropPositionObject;
    // eslint-disable-next-line @typescript-eslint/ban-types
    preventDefault: Function;
    target: Point;
    type: 'drop';
}

export interface PwngLineChartDependency {
    dependentSeriesId: string;
    type: DependencyTypes;
}

export type PwngLineChartSeries = PlotLineOptions & {
    custom?: {
        dependentSeries?: Array<PwngLineChartDependency>;
        originalSeries?: PlotLineOptions & { name: string };
    };
    data: Array<PwngLineChartPoint>;
    id: string;
    name: string;
    type: 'line';
};
