































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import type { DateFromTo } from '@/model/Interfaces/DateFromTo';
import type { Ordering } from '@/model/types/Ordering';
import type { Resource } from '@bednic/json-api-client';

@Component({})
export default class MenuFilter extends Vue {
    // Data
    @Prop({ default: 'text', required: false, type: String })
    protected dataType!: 'text' | 'number' | 'date';

    @Prop({ default: 'name', required: false, type: String })
    protected itemTextField!: string;

    @Prop({ default: false, required: false, type: Boolean })
    protected orderable!: boolean;

    @Prop({ default: null, required: false, type: String })
    protected ordering!: Ordering;

    @Prop({ default: '', required: true, type: String })
    protected placeholder!: string;

    @Prop({ default: [], required: true, type: [Array, Object] })
    protected selectedItems!: Array<Resource> | DateFromTo;

    // Computed
    protected get ascendingIcon(): string {
        if (this.dataType === 'text') {
            return 'mdi mdi-order-alphabetical-ascending';
        } else if (this.dataType === 'number') {
            return 'mdi mdi-order-numeric-ascending';
        } else {
            return 'mdi mdi-sort-calendar-ascending';
        }
    }

    protected get ascendingText(): string {
        if (this.dataType === 'text') {
            return this.$tc('logioTabulator.textFilter.orderAsc');
        } else if (this.dataType === 'number') {
            return this.$tc('logioTabulator.numberFilter.orderAsc');
        } else {
            return this.$tc('logioTabulator.dateFilter.orderDesc');
        }
    }

    protected get descendingIcon(): string {
        if (this.dataType === 'text') {
            return 'mdi mdi-order-alphabetical-descending';
        } else if (this.dataType === 'number') {
            return 'mdi mdi-order-numeric-descending';
        } else {
            return 'mdi mdi-sort-calendar-descending';
        }
    }

    protected get descendingText(): string {
        if (this.dataType === 'text') {
            return this.$tc('logioTabulator.textFilter.orderDesc');
        } else if (this.dataType === 'number') {
            return this.$tc('logioTabulator.numberFilter.orderDesc');
        } else {
            return this.$tc('logioTabulator.dateFilter.orderDesc');
        }
    }

    protected get hasFilterValue(): boolean {
        if (Array.isArray(this.selectedItems)) {
            return !!this.selectedItems.length;
        } else {
            return !!(this.selectedItems.from || this.selectedItems.to);
        }
    }

    protected get selectedItemsListed(): string {
        if (Array.isArray(this.selectedItems)) {
            return this.selectedItems.map<string>((item: Resource) => (item.attributes?.[this.itemTextField] || '') as string).join(', ');
        } else {
            let items = [];
            if (this.selectedItems.from) {
                items.push(
                    `${this.$tc('logioTabulator.commonFilters.from')}: ${this.$options.filters?.date(this.selectedItems.from) as string}`
                );
            }
            if (this.selectedItems.to) {
                items.push(
                    `${this.$tc('logioTabulator.commonFilters.to')}: ${this.$options.filters?.date(this.selectedItems.to) as string}`
                );
            }
            return items.join(', ');
        }
    }

    protected toggleOrdering(ordering?: Ordering): void {
        let result: Ordering;
        if (ordering) {
            result = ordering === this.ordering ? null : ordering;
        } else {
            if (this.ordering === 'asc') {
                result = 'desc';
            } else if (this.ordering === 'desc') {
                result = null;
            } else {
                result = 'asc';
            }
        }
        this.emitOrderingChange(result);
    }

    @Emit('reset-filter')
    protected emitResetFilter(): boolean {
        return true;
    }

    @Emit('ordering-change')
    protected emitOrderingChange(ordering: Ordering): Ordering {
        return ordering;
    }
}
