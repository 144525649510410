

















































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import type { Resource } from '@bednic/json-api-client';
import ScrollTrigger from '@/components/common/ScrollTrigger.vue';

@Component({
    components: { ScrollTrigger }
})
export default class CustomSelect extends Vue {
    // Data
    @Prop({ required: true, type: Function })
    protected fetchItems!: (offset: number) => Promise<Array<Resource> | undefined>;

    @Prop({ default: '', required: false, type: String })
    protected itemIconField!: string;

    @Prop({ default: 'name', required: false, type: String })
    protected itemTextField!: string;

    @Prop({ default: CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT, required: true, type: Number })
    protected lazyLoadingLimit!: number;

    @Prop({ default: false, required: false, type: Boolean })
    protected multiple!: boolean;

    @Prop({ default: '', required: true, type: String })
    protected placeholder!: string;

    @Prop({ default: 'mdi mdi-text-box-remove-outline', required: false, type: String })
    protected removeAllItemsIcon!: string;

    @Prop({ default: 'components.customAutocomplete.removeAllItems', required: false, type: String })
    protected removeAllItemsText!: string;

    protected isLoadedAll = false;

    protected isLoading = false;

    protected items: Array<Resource> = [];

    @PropSync('selectedItems')
    protected selectedItemsSync!: Array<Resource>;

    // Lifecycle Hooks
    public created(): void {
        void this.fetchData();
    }

    // Methods
    protected getItemIcon(item: Resource): string {
        if (item.attributes && typeof item.attributes[this.itemIconField] === 'string') {
            return item.attributes[this.itemIconField] as string;
        }
        return '';
    }

    protected getItemText(item: Resource): string {
        if (item.attributes && typeof item.attributes[this.itemTextField] === 'string') {
            return item.attributes[this.itemTextField] as string;
        }
        return '';
    }

    protected removeAllItems(): void {
        this.selectedItemsSync = [];
    }

    protected onItemsSelected(selectedValues: Array<Resource> | Resource): void {
        if (Array.isArray(selectedValues)) {
            this.selectedItemsSync = selectedValues;
        } else {
            this.selectedItemsSync = [selectedValues];
        }
    }

    protected removeItem(indexToRemove: number): void {
        this.selectedItemsSync = this.selectedItemsSync.filter((item, index) => index !== indexToRemove);
    }

    // Async Methods
    protected async fetchData(): Promise<void> {
        this.isLoading = true;
        const data = await this.fetchItems(this.items.length);
        if (data && data.length < this.lazyLoadingLimit) {
            this.isLoadedAll = true;
        }
        if (data) {
            this.items = [...this.items, ...data];
        }
        this.isLoading = false;
    }

    // Async Event Handlers
    protected onAutocompleteScrollToBottom(): void {
        void this.fetchData();
    }
}
