












































import { Component, Emit, PropSync } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import UserViewsDialog from '@/components/common/Table/userViews/UserViewsDialog';

@Component({
    // eslint-disable-next-line no-undef
    components: { ConfirmationButtons }
})
export default class UpdateDialog extends UserViewsDialog {
    // Data
    protected isFormValid = false;

    protected newName = '';

    @PropSync('selectedUserViewName', { required: true })
    protected syncedSelectedUserViewName!: string | null;

    // Event Handlers
    @Emit('updateView')
    protected onConfirm(): void {
        if (this.syncedActiveView && this.isFormValid) {
            if (this.syncedActiveView.name === this.syncedSelectedUserViewName) {
                this.syncedSelectedUserViewName = this.newName;
            }
            this.syncedActiveView.name = this.newName;
            this.onCancel();
        }
    }

    protected onCancel(): void {
        this.syncedActiveDialog = null;
    }
}
