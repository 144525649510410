












import { Component, Vue } from 'vue-property-decorator';
import colors from 'vuetify/lib/util/colors';

@Component
export default class SplashScreen extends Vue {
    protected color = colors.lightGreen.base;
}
