import { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import { TableHeader, TableHeaderFilterType } from '@/model/Interfaces/TableHeader';
import BooleanFilterClass from '@/model/Data/Filters/BooleanFilterClass';
import type { ColumnFilterType } from '@/model/Entity/ColumnFilterType';
import DateFilterClass from '@/model/Data/Filters/DateFilterClass';
import EnumFilterClass from '@/model/Data/Filters/EnumFilterClass';
import EnumMultipleFilterClass from '@/model/Data/Filters/EnumMultipleFilterClass';
import NumberFilterClass from '@/model/Data/Filters/NumberFilterClass';
import ResourceTextFilterClass from '@/model/Data/Filters/ResourceTextFilterClass';
import TextFilterClass from '@/model/Data/Filters/TextFilterClass';

class TableFilters {
    private _columnFilters: Record<string, ColumnFilterType | null> = {};

    private readonly _filterKeys: Array<string> = [];

    public constructor(public readonly headers: Array<TableHeader>) {
        this._filterKeys = headers
            .filter((header) => header.filterType)
            .map((header) => header.filterKey ? header.filterKey : header.value);
        this._filterKeys.forEach((key) => {
            this._columnFilters[key] = null;
        });
    }

    public get activeColumnFilterKeys(): Array<string> {
        const active: Array<string> = [];
        Object.keys(this._columnFilters).forEach((key) => {
            if (this._columnFilters[key]) {
                active.push(key);
            }
        });
        return active;
    }

    public get columnFiltersAsExpressions(): Array<Expression> {
        return this.objectValuesToArray(this._columnFilters);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get columnFiltersValues(): Record<string, any> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filters: Record<string, any> = {};
        this._filterKeys.forEach((key) => {
            const columnFilter = this.getColumnFilter(key);
            filters[key] = columnFilter ? columnFilter.value : null;
        });
        return filters;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public set columnFiltersValues(values: Record<string, any>) {
        this._filterKeys.forEach((key) => {
            const foundHeader = this.headers.find((header) =>
                header.filterKey ? header.filterKey === key : header.value === key
            );
            if (foundHeader && values[key]) {
                switch (foundHeader.filterType) {
                    case TableHeaderFilterType.Number:
                        this._columnFilters[key] = new NumberFilterClass(key, values[key]);
                        break;
                    case TableHeaderFilterType.Date:
                        this._columnFilters[key] = new DateFilterClass(key, values[key]);
                        break;
                    case TableHeaderFilterType.Text:
                        this._columnFilters[key] = new TextFilterClass(key, values[key]);
                        break;
                    case TableHeaderFilterType.ResourceText:
                        this._columnFilters[key] = new ResourceTextFilterClass(
                            foundHeader.resourceFilterOptions ? foundHeader.resourceFilterOptions.filterBy : key,
                            values[key]
                        );
                        break;
                    case TableHeaderFilterType.Enum:
                        this._columnFilters[key] = new EnumFilterClass(key, values[key]);
                        break;
                    case TableHeaderFilterType.EnumMultiple:
                        this._columnFilters[key] = new EnumMultipleFilterClass(key, values[key]);
                        break;
                    case TableHeaderFilterType.Boolean:
                        this._columnFilters[key] = new BooleanFilterClass(key, values[key]);
                        break;
                    default:
                        this._columnFilters[key] = null;
                }
            }
        });
    }

    public get columnFilters(): Record<string, ColumnFilterType | null> {
        return this._columnFilters;
    }

    public set columnFilters(columnFilters: Record<string, ColumnFilterType | null>) {
        this._filterKeys.forEach((key) => {
            if (typeof columnFilters[key] !== 'undefined') {
                this._columnFilters[key] = columnFilters[key];
            }
        });
    }

    public getColumnFilter(key: string): ColumnFilterType | null {
        return this._columnFilters[key];
    }

    public add(key: string, filter: ColumnFilterType | null): void {
        this._columnFilters = {
            ...this._columnFilters,
            [key]: filter
        };
    }

    public remove(key: string): void {
        this._columnFilters = {
            ...this._columnFilters,
            [key]: null
        };
    }

    public removeAll(): void {
        const filters: Record<string, null> = {};
        Object.keys(this._columnFilters).forEach((key) => filters[key] = null);
        this._columnFilters = filters;
    }

    public express(): string {
        const filters = this.objectValuesToArray(this._columnFilters);
        return filters.length === 1
            ? filters[0].express()
            : filters.length > 1
                ? PrettyExpressionBuilder.and(...filters).express()
                : '';
    }

    protected objectValuesToArray(object: Record<string, ColumnFilterType | null>): Array<Expression> {
        const filters: Array<Expression> = [];
        Object.values(object).forEach((columnFilter) => {
            if (columnFilter) {
                filters.push(columnFilter);
            }
        });
        return filters;
    }
}

export default TableFilters;
