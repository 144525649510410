


















































import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';

@Component({
    // eslint-disable-next-line no-undef
    components: { ConfirmationButtons },
    name: 'action-dialog'
})
export default class TableActionDialog extends Vue {
    @Prop({ default: 'primary', required: false, type: String })
    protected confirmBtnColor!: string;

    // Data
    @Prop({ default: false })
    protected confirmBtnDisabled!: boolean;

    @Prop({ required: false, type: String })
    protected confirmBtnText!: string;

    @Prop({ default: false })
    protected isUpdating!: boolean;

    @Prop({ default: '' })
    protected title!: string;

    @PropSync('isDialogOpen', { default: false })
    protected isDialogOn!: boolean;

    // Event Handlers
    @Emit('cancel')
    protected onCancel(): boolean {
        this.isDialogOn = false;
        return true;
    }

    @Emit('secondary')
    protected onSecondary(): boolean {
        this.isDialogOn = false;
        return true;
    }

    @Emit('confirm')
    protected onConfirm(): boolean {
        return true;
    }
}
