







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SvgIcon extends Vue {
    @Prop({ default: 'currentColor' })
    protected readonly color!: string;

    @Prop({ default: '100%' })
    protected readonly height!: string;

    @Prop()
    protected readonly icon!: string;

    @Prop({ default: '0 0 100 100' })
    protected readonly viewBox!: string;

    @Prop({ default: '100%' })
    protected readonly width!: string;

    @Prop({ default: '0px' })
    protected readonly x!: string;

    @Prop({ default: '0px' })
    protected readonly y!: string;
}
