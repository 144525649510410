import { Component, Vue, Watch } from 'vue-property-decorator';
import appStore from '@/store/modules/AppStore';
import { Message } from '@/model/Classes/Message';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import type { SwEvent } from '@/model/Interfaces/SwEvent';

@Component
export default class UpdateApp extends Vue {
    // Data
    protected delayUpdateInterval = 500000;

    protected refreshing = false;

    protected updateInterval!: number;

    protected updateIntervalValue = 60000;

    protected get swEvent(): SwEvent | null {
        return appStore.activeSwEvent;
    }

    protected checkForUpdates(registration: ServiceWorkerRegistration): void {
        if (this.updateInterval) {
            window.clearInterval(this.updateInterval);
        }
        this.updateInterval = window.setInterval(() => {
            registration.update().then(() => {
                window.clearInterval(this.updateInterval);
            }).catch(() => {
                window.clearInterval(this.updateInterval);
            });
        }, this.updateIntervalValue);
    }

    @Watch('swEvent', { deep: true, immediate: true })
    protected onSwEvent(swEvent?: SwEvent): void {
        if (swEvent) {
            const event = { ...swEvent };
            if (event.name === 'updated' && event.detail instanceof ServiceWorkerRegistration) {
                this.updateAvailable(event.detail);
            } else {
                if (event.detail instanceof ServiceWorkerRegistration) {
                    this.checkForUpdates(event.detail);
                }
                appStore.resolveSwEvent(event.uid);
            }
        }
    }

    protected refreshApp(registration: ServiceWorkerRegistration): void {
        if (registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            window.location.reload();
        }
    }

    // Async Methods
    protected updateAvailable(registration: ServiceWorkerRegistration): void {
        const message = new Message(this.$tc('pwa.updateAvailable'), undefined, {
            actions: {
                cancelBtnText: this.$tc('pwa.cancel'),
                cancelFunc: (): void => {
                    window.setTimeout(() => {
                        void this.updateAvailable(registration);
                    }, this.delayUpdateInterval);
                },
                confirmBtnText: this.$tc('pwa.update'),
                confirmFunc: (): void => {
                    void this.refreshApp(registration);
                }
            },
            messageLevel: MessageLevel.Success,
            messageType: MessageType.Dialog
        });
        messagesStore.sendMessage(message);
    }
}
