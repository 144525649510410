import { Expression, PrettyExpressionBuilder, Resource } from '@bednic/json-api-client';
import ColumnFilterClass from '@/model/Data/Filters/ColumnFilterClass';

interface ResourceTextFilterValue {
    resources: Array<Resource>;
}

class ResourceTextFilterClass extends ColumnFilterClass<ResourceTextFilterValue> {
    public value: ResourceTextFilterValue = {
        resources: []
    };

    public constructor(public readonly key: string, filter?: ResourceTextFilterClass | ResourceTextFilterValue) {
        super(key);
        if (filter instanceof ResourceTextFilterClass) {
            this.value = { ...filter.value };
        } else if (filter) {
            this.value = { ...filter };
        }
    }

    // Filter requires Resource to have attribute "name"
    public get filter(): Expression | null {
        if (this.value.resources.length) {
            const expressions = this.value.resources.map((item) => PrettyExpressionBuilder.equal(this.key, item.id));
            return PrettyExpressionBuilder.or(...expressions);
        } else {
            return null;
        }
    }

    public clone(): ResourceTextFilterClass {
        return new ResourceTextFilterClass(this.key, this);
    }
}

export default ResourceTextFilterClass;
