

























































































/* eslint-disable no-undef */
import { Component, Emit, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import messagesStore from '@/store/modules/MessagesStore';
import type { TreeCategory } from '@/model/Entity/TreeCategory';
@Component({
    components: { ConfirmationButtons }
})
export default class TreeView extends Vue {
    // Data
    @Ref('cardActivatorRef')
    protected cardActivatorRef!: Vue;

    @Prop({ default: true, required: false, type: Boolean })
    protected canBeChanged!: boolean;

    @Prop({ default: false, required: false, type: Boolean })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected fetchChildren!: (category: TreeCategory) => Promise<boolean>;

    @Prop({ default: false, required: false, type: Boolean })
    protected isLoading!: boolean;

    @Prop({ default: '', required: false, type: String })
    protected placeholder!: string;

    @Prop({ required: true, type: Array })
    protected tree!: Array<TreeCategory>;

    @PropSync('activeCategories', { required: true, type: Array })
    protected active!: Array<TreeCategory>;

    protected isDialogOpened = false;

    @PropSync('openCategories', { required: true, type: Array })
    protected open!: Array<TreeCategory>;

    @PropSync('selectedCategories', { required: true, type: Array })
    protected selected!: Array<TreeCategory>;

    // Computed
    protected get activeStringify(): string {
        if (this.active.length) {
            let active = this.active[0];
            active = {
                ...active,
                children: [],
                path: active.path.map((cat) => ({ ...cat, children: [], path: [] }))
            };
            return JSON.stringify(active);
        }
        return '';
    }

    protected get isDirty(): boolean {
        return this.activeStringify !== this.selectedStringify;
    }

    protected get selectedStringify(): string {
        if (this.selected.length) {
            let activePath = this.selected[0];
            activePath = {
                ...activePath,
                children: [],
                path: activePath.path.map((cat) => ({ ...cat, children: [], path: [] }))
            };
            return JSON.stringify(activePath);
        }
        return '';
    }

    // Sync Methods
    protected onConfirm(): void {
        this.onClose();
        if (this.canBeChanged) {
            this.selected = [...this.active];
            this.select(this.active);
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    this.selected = [...this.active];
                    this.select(this.active);
                }
            });
        }
    }

    protected onCancel(): void {
        this.active = [...this.selected];
        this.onClose();
    }

    protected onClear(): void {
        this.onClose();
        if (this.canBeChanged) {
            this.active = [];
            this.selected = [];
            this.select([]);
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    this.active = [];
                    this.selected = [];
                    this.select([]);
                }
            });
        }
    }

    @Emit('select')
    protected select(value: Array<TreeCategory>): Array<TreeCategory> {
        return value;
    }

    protected scrollToTreeCategory(categoryKey: string): void {
        window.setTimeout(() => {
            const node = this.$refs[categoryKey];
            if (typeof node !== 'undefined') {
                (node as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }, 100);
    }

    protected openDialog(event: MouseEvent, categoryKey: string): void {
        event.stopPropagation();
        this.isDialogOpened = true;
        if (categoryKey) {
            this.scrollToTreeCategory(categoryKey);
        } else if (this.selected.length) {
            this.scrollToTreeCategory(this.selected[0].key);
        }
    }

    protected onClose(): void {
        this.isDialogOpened = false;
    }
}
