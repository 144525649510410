















































import { Component, Vue } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import type { Message } from '@/model/Classes/Message';
import messagesStore from '@/store/modules/MessagesStore';

@Component({
    // eslint-disable-next-line no-undef
    components: { ConfirmationButtons }
})
export default class MessageDialog extends Vue {
    protected get dialog(): Message | null {
        return messagesStore.activeDialog;
    }

    protected async onCancel(): Promise<void> {
        if (this.dialog?.options?.actions?.cancelFunc) {
            this.dialog.options.actions.cancelFunc();
        }
        if (this.dialog) {
            await messagesStore.resolveMessage(this.dialog.id);
        }
    }

    protected async onConfirm(): Promise<void> {
        if (this.dialog?.options?.actions?.confirmFunc) {
            this.dialog.options.actions.confirmFunc();
        }
        if (this.dialog) {
            await messagesStore.resolveMessage(this.dialog.id);
        }
    }

    protected async onSecondary(): Promise<void> {
        if (this.dialog?.options?.actions?.secondaryFunc) {
            this.dialog.options.actions.secondaryFunc();
        }
        if (this.dialog) {
            await messagesStore.resolveMessage(this.dialog.id);
        }
    }
}
