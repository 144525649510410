


















































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import ErrorService from '@/services/ErrorService/ErrorService';
import { MessageType } from '@/model/Enums/MessageType';
import ScrollTrigger from '@/components/common/ScrollTrigger.vue';
import type { TableHeader } from '@/model/Interfaces/TableHeader';
import type TextFilterClass from '@/model/Data/Filters/TextFilterClass';

@Component({
    components: { ScrollTrigger }
})
export default class TextFilter extends Vue {
    // Props
    @Prop({ required: true })
    protected currentFilter!: TextFilterClass;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected header!: TableHeader;

    // Data
    protected isLoadedAll = false;

    protected isLoading = false;

    protected items: Array<string> = [];

    protected lazyLoadingLimit = CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT;

    protected search = '';

    protected searchDebounce = 0;

    protected visibleChipLimit = 8;

    // Lifecycle Hooks
    public mounted(): void {
        this.filterChanged();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.currentFilter.value.texts) {
            this.items.push(...this.currentFilter.value.texts);
        }
    }

    // Sync Methods
    protected removeItem(value: string): void {
        const index = this.currentFilter.value.texts.findIndex((item) => item === value);
        if (index > -1) {
            const texts = [...this.currentFilter.value.texts];
            texts.splice(index, 1);
            this.currentFilter.value.texts = [...texts];
        }
        this.filterChanged();
    }

    protected resetSearchInput(): void {
        this.search = '';
    }

    protected toggleSelected(value: string): void {
        const clickedItem = this.currentFilter.value.texts.find((item) => item === value);
        if (clickedItem) {
            this.currentFilter.value.texts = this.currentFilter.value.texts.filter((item) => item !== value);
        } else {
            this.currentFilter.value.texts = [...this.currentFilter.value.texts, value];
        }
        this.filterChanged();
    }

    // Emitters
    @Emit()
    protected filterChanged(): TextFilterClass {
        return this.currentFilter;
    }

    // Event Handlers
    protected onFilterChanged(): void {
        this.filterChanged();
    }

    // Async Methods
    protected async fetchItems(search?: string): Promise<void> {
        this.isLoading = true;
        try {
            const params = {
                contains: search ? search.toLowerCase() : '',
                ['page[limit]']: this.lazyLoadingLimit,
                ['page[offset]']: this.items.length
            };
            if (this.header.filterAutocompleteURL) {
                const response = await this.axios.get(this.header.filterAutocompleteURL, { params });
                if (response.data.data.length < this.lazyLoadingLimit) {
                    this.isLoadedAll = true;
                }
                this.items = [...this.items, ...response.data.data];
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorCategoriesLoading'),
                messageType: MessageType.Notification
            });
        } finally {
            this.isLoading = false;
        }
    }

    @Watch('search', { immediate: true })
    protected async onSearchChange(value: string): Promise<void> {
        this.isLoadedAll = false;
        if (this.searchDebounce) {
            clearTimeout(this.searchDebounce);
        }
        this.items = [...this.currentFilter.value.texts];
        if (value) {
            this.isLoading = true;
            this.searchDebounce = window.setTimeout(() => {
                void this.fetchItems(value)
                    .then(() => {
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            }, CONSTANTS.NUMBERS.AUTOCOMPLETE_DEBOUNCE);
        } else {
            this.isLoading = false;
        }
    }
}
