





















































import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import AppStore from '@/store/modules/AppStore';
import CellRenderer from '@/components/common/Table/cellRenderers/CellRenderer';
import type { Flag } from '@/model/Entity/Flag';
import flagStore from '@/store/modules/FlagStore';

@Component({
    name: 'flag-renderer'
})
export default class FlagCellRenderer extends CellRenderer {
    // Data

    protected flagRendererWidth = 0;

    protected flagsElementsWidth = 0;

    @PropSync('isAnyOverviewOpen')
    protected isAnyFlagOverviewOpen!: boolean;

    protected isDots = false;

    protected isOverviewOpen = false;

    protected x = 0;

    protected y = 0;

    // Computed
    protected get filteredFlags(): Record<string, Flag> {
        const filteredFlags: Record<string, Flag> = {};
        flagStore.items.forEach((flag: Flag) => {
            if ((this.currentValue as string).includes(flag.id)) {
                if (flag.id) {
                    filteredFlags[flag.id] = { ...flag };
                }
            }
        });
        return filteredFlags;
    }

    protected get tooltipDelay(): number {
        return AppStore.tooltipDelay;
    }

    // Sync Methods
    protected closeOverview(): void {
        this.isOverviewOpen = false;
    }

    protected fitFlags(): void {
        this.$nextTick(() => {
            this.flagRendererWidth = 0;
            if (typeof this.$refs.flagRenderer !== 'undefined') {
                this.flagRendererWidth = (this.$refs.flagRenderer as Element).clientWidth;
            }
            this.flagsElementsWidth = 0;
            for (const key in this.$refs) {
                const [flag, id] = key.split('-');
                if (flag === 'flag' && id) {
                    if (typeof this.$refs[key] !== 'undefined' && (this.$refs[key] as Array<Vue>).length) {
                        this.flagsElementsWidth += (this.$refs[key] as Array<Vue>)[0].$el.clientWidth + 6;
                        if (this.flagRendererWidth <= this.flagsElementsWidth) {
                            this.isDots = true;
                            break;
                        }
                    }
                }
            }
        });
    }

    @Watch('filteredFlags', { deep: true, immediate: true })
    protected onFilteredFlagsChange(filteredFlags: Record<string, Flag> | undefined): void {
        if (filteredFlags && Object.keys(filteredFlags).length) {
            this.$nextTick(() => {
                this.fitFlags();
            });
        }
    }

    @Watch('isOverviewOpen')
    protected onIsOverviewOpenChanged(value: boolean): void {
        if (!value) {
            this.isAnyFlagOverviewOpen = false;
        }
    }

    protected onFlagCellRendererClicked(e: MouseEvent): void {
        if (this.isDots && !this.isAnyFlagOverviewOpen) {
            this.isOverviewOpen = true;
            this.isAnyFlagOverviewOpen = true;
            this.x = e.clientX;
            this.y = e.clientY;
        }
    }
}
