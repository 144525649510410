





















































































import { Component, Vue } from 'vue-property-decorator';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';
import { CONSTANTS } from '@/constants';
import type { DataCurrentChangeNotification } from '@/model/Notifications/DataCurrentChangeNotification';
import ErrorService from '@/services/ErrorService/ErrorService';
import type { ImportEndNotification } from '@/model/Notifications/ImportEndNotification';
import type { ImportStartNotification } from '@/model/Notifications/ImportStartNotification';
import { Message } from '@/model/Classes/Message';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import { Notifications } from '@/model/Enums/Notifications';
import type { PwComputeEndNotification } from '@/model/Notifications/PwComputeEndNotification';
import type { PwComputeStartNotification } from '@/model/Notifications/PwComputeStartNotification';
import type { SystemStatus } from '@/model/Entity/SystemStatus';

@Component
export default class SystemStatusMenu extends Vue {
    // Data
    protected max = 15;

    protected millis = 1000;

    protected min = 8;

    protected previousIsDataCurrent = true;

    protected systemStatus: SystemStatus = {
        computeEndDate: '',
        computeStartDate: '',
        importEndDate: '',
        importStartDate: '',
        isComputing: false,
        isDataCurrent: false,
        isLastComputeError: false,
        isLastLoadError: false,
        isLoading: false,
        lastComputeFinished: '',
        lastLoadFinished: ''
    };

    protected timeout = 0;

    // Lifecycle hooks
    public mounted(): void {
        void this.fetchSystemStatus();
        this.addSocketEventListeners();
    }

    public destroyed(): void {
        this.removeSocketEventListeners();
    }

    protected get iconSize(): number {
        return appStore.elements.logoSize;
    }

    protected get mini(): boolean {
        return this.$vuetify.breakpoint.mdAndUp && appStore.mini;
    }

    // Computed
    protected get nudgeRight(): number {
        return appStore.elements.navigationWidth / 2;
    }

    protected get isOpen(): boolean {
        return appStore.activeMenu(ActiveMenus.SystemStatus);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [ActiveMenus.SystemStatus]: value });
    }

    // Sync Methods
    protected addSocketEventListeners(): void {
        this.$socket.on(Notifications.DataCurrentChange, (payload) => this.dataCurrentChangeEventListener(payload));
        this.$socket.on(Notifications.PwComputeStart, (payload) => this.pwComputeStartEventListener(payload));
        this.$socket.on(Notifications.PwComputeEnd, (payload) => this.pwComputeEndEventListener(payload));
        this.$socket.on(Notifications.ImportStart, (payload) => this.importStartEventListener(payload));
        this.$socket.on(Notifications.ImportEnd, (payload) => this.importEndEventListener(payload));
    }

    protected removeSocketEventListeners(): void {
        this.$socket.off(Notifications.DataCurrentChange, (payload: DataCurrentChangeNotification) =>
            this.dataCurrentChangeEventListener(payload)
        );
        this.$socket.off(Notifications.PwComputeStart, (payload: PwComputeStartNotification) => this.pwComputeStartEventListener(payload));
        this.$socket.off(Notifications.PwComputeEnd, (payload: PwComputeEndNotification) => this.pwComputeEndEventListener(payload));
        this.$socket.off(Notifications.ImportStart, (payload: ImportStartNotification) => this.importStartEventListener(payload));
        this.$socket.off(Notifications.ImportEnd, (payload: ImportEndNotification) => this.importEndEventListener(payload));
    }

    protected dataCurrentChangeEventListener(payload: DataCurrentChangeNotification): void {
        this.systemStatus.isDataCurrent = payload.isDataCurrent;
        this.updateReadonlyMode();
        void this.sendSystemStatusMessage();
    }

    protected pwComputeStartEventListener(payload: PwComputeStartNotification): void {
        this.systemStatus.isComputing = true;
        this.systemStatus.computeStartDate = payload.timestamp;
        void this.sendSystemStatusMessage();
    }

    protected pwComputeEndEventListener(payload: PwComputeEndNotification): void {
        this.systemStatus.isComputing = false;
        this.systemStatus.lastComputeFinished = payload.timestamp;
        this.systemStatus.isLastComputeError = payload.isError;
        void this.sendSystemStatusMessage();
    }

    protected importStartEventListener(payload: ImportStartNotification): void {
        this.systemStatus.isLoading = true;
        this.systemStatus.importStartDate = payload.timestamp;
        void this.sendSystemStatusMessage();
    }

    protected importEndEventListener(payload: ImportEndNotification): void {
        this.systemStatus.isLoading = false;
        this.systemStatus.lastLoadFinished = payload.timestamp;
        this.systemStatus.isLastLoadError = payload.isError;
        this.systemStatus.importEndDate = payload.timestamp;
        void this.sendSystemStatusMessage();
    }

    protected loop(): void {
        window.clearTimeout(this.timeout);
        const randomInterval = this.getRandomNumber() * this.millis;
        this.timeout = window.setTimeout((): void => {
            void this.fetchSystemStatus().then(() => {
                this.sendSystemStatusMessage();
                this.loop();
            });
        }, randomInterval);
    }

    protected getRandomNumber(): number {
        return Math.floor(Math.random() * (this.max - this.min) + this.min);
    }

    protected updateReadonlyMode(): void {
        if (this.systemStatus.isDataCurrent) {
            appStore.setIsReadOnlyMode(false);
        } else {
            if (appStore.isEditMode) {
                messagesStore.sendMessage(
                    new Message(this.$tc('messages.warningEditModeCanceled'), undefined, {
                        messageLevel: MessageLevel.Warning,
                        messageType: MessageType.Notification
                    })
                );
                appStore.setIsReadOnlyMode(true);
                appStore.setIsEditMode(false);
                appStore.setValidations({});
            }
        }
    }

    protected sendSystemStatusMessage(): void {
        if (this.previousIsDataCurrent === this.systemStatus.isDataCurrent) {
            return;
        } else {
            this.previousIsDataCurrent = this.systemStatus.isDataCurrent;
            if (!this.systemStatus.isDataCurrent) {
                let message = this.$tc('messages.warningTheDataIsNotUpToDate');
                if (this.systemStatus.isComputing) {
                    message = this.$tc('messages.warningTheDataIsBeingComputed');
                } else if (this.systemStatus.isLoading) {
                    message = this.$tc('messages.warningTheDataIsBeingCopied');
                } else if (this.systemStatus.isLastComputeError) {
                    message = this.$tc('messages.warningDataComputingError');
                } else if (this.systemStatus.isLastLoadError) {
                    message = this.$tc('messages.warningDataCopyingError');
                }
                messagesStore.sendMessage(
                    new Message(message, undefined, {
                        messageLevel: MessageLevel.Warning,
                        messageType: MessageType.Notification,
                        timeout: 5000
                    })
                );
            } else {
                messagesStore.sendMessage(
                    new Message(this.$tc('messages.successTheDataIsUpToDate'), undefined, {
                        messageLevel: MessageLevel.Success,
                        messageType: MessageType.Notification,
                        timeout: 5000
                    })
                );
            }
        }
    }

    // Async methods
    protected async fetchSystemStatus(): Promise<void> {
        try {
            const response = await this.axios.get<{ data: SystemStatus }>(CONSTANTS.API.COMPUTE_STATUS);
            this.systemStatus = response.data.data;
            this.updateReadonlyMode();
            this.sendSystemStatusMessage();
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorSystemStatus'),
                messageType: MessageType.Notification
            });
        }
    }
}
