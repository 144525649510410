
import { Component, Emit } from 'vue-property-decorator';
import { DefaultTextFilter, DefaultTextFilterValue } from '@logio/vue2-tabulator';
import { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import type { TabulatorFilterResult } from '@/model/types/tabulator/TabulatorFilterResult';

type FilterValueWithExpression = DefaultTextFilterValue & TabulatorFilterResult;

/**
 * Wrapper component for DefaultTextFilter which adds emitting Expression to the object value.
 */
@Component
export default class TextMenu extends DefaultTextFilter {
    @Emit('on-filter-value-change')
    protected onFilterValueChange(filterValue: Partial<DefaultTextFilterValue> | null): FilterValueWithExpression {
        const resolvedValue = super.resolveFilterValueChange(filterValue) as DefaultTextFilterValue;
        return {
            ...resolvedValue,
            expression: this.transformToTextFilterExpression(resolvedValue)
        };
    }

    protected transformToTextFilterExpression(filterValue: Partial<DefaultTextFilterValue> | null): Expression | null {
        // If any items are selected by autocomplete
        if (filterValue?.selectedItems?.length) {
            // Determine field to be used for filter Expression.
            const filterField = (this.columnMenuOptions?.filterField || this.column.field) as string;
            if (filterField) {
                // Combine SelectedItem values into single Expression
                return PrettyExpressionBuilder.in(
                    filterField,
                    filterValue.selectedItems.map((item) => this.getItemValue(item))
                );
            }
        }
        // If no items are selected by autocomplete then return no Expression.
        return null;
    }
}
