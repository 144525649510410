

















































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import type { State } from '@/model/Entity/State';
import stateStore from '@/store/modules/StateStore';

@Component({
    name: 'update-state'
})
export default class UpdateState extends Vue {
    // Data
    @Prop({ default: null })
    protected selectedOrdersCount!: number;

    @PropSync('selectedItem', { default: null })
    protected selectedState!: State | null;

    // Lifecycle hooks
    public mounted(): void {
        if (this.selectableStateItems.length) {
            this.selectedState = this.selectableStateItems[0];
        }
    }

    // Computed
    protected get isStateSelected(): boolean {
        return !!this.selectedState;
    }

    protected get selectableStateItems(): Array<State> {
        return this.stateItems.filter((state) => {
            return state.attributes.setByUser;
        });
    }

    protected get stateItems(): Array<State> {
        return stateStore.items;
    }
}
