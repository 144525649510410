import { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import { CONSTANTS } from '@/constants';
import type { DateFilterValue } from '@/model/Data/Filters/DateFilterClass';
import type { NumberFilterValue } from '@/model/Data/Filters/NumberFilterClass';

abstract class ColumnFilterClass<T> {
    protected constructor(public readonly key: string) {}

    public abstract get filter(): Expression | null;

    public abstract value: T;

    public abstract clone(): ColumnFilterClass<T>;

    public express(): string {
        return this.filter ? this.filter.express() : '';
    }

    protected getFilter(value: DateFilterValue | NumberFilterValue, from: string, to: string): Expression | null {
        const filterArray = [];
        if (from.length) {
            filterArray.push(PrettyExpressionBuilder.greaterThenOrEqual(this.key, from));
        }
        if (to.length) {
            filterArray.push(PrettyExpressionBuilder.lowerThenOrEqual(this.key, to));
        }
        const filter = PrettyExpressionBuilder.and(...filterArray);
        const nullOnlyExpressions = value.emptyValues.map((emptyValue) =>
            PrettyExpressionBuilder.equal(this.key, emptyValue)
        );
        const notNullExpressions = value.emptyValues.map((emptyValue) =>
            PrettyExpressionBuilder.notEqual(this.key, emptyValue)
        );
        const nullOnly = PrettyExpressionBuilder.or(...nullOnlyExpressions);
        const notNull = PrettyExpressionBuilder.and(...notNullExpressions);

        if (value.filterNull === CONSTANTS.FILTERS.FILTER_OPTIONS.NULL_ONLY) {
            return nullOnly;
        } else if (value.filterNull === CONSTANTS.FILTERS.FILTER_OPTIONS.WITHOUT_NULL) {
            return PrettyExpressionBuilder.and(filter, notNull);
        } else if (value.filterNull === CONSTANTS.FILTERS.FILTER_OPTIONS.WITH_NULL) {
            return PrettyExpressionBuilder.or(filter, nullOnly);
        } else {
            return null;
        }
    }
}

export default ColumnFilterClass;
