import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import type { CompoundDocument, Resource, ResourceIdentifier } from '@bednic/json-api-client';
import ErrorService from '@/services/ErrorService/ErrorService';
import { MessageType } from '@/model/Enums/MessageType';
import type { TableHeader } from '@/model/Interfaces/TableHeader';

interface ErrorType {
    detail: string;
    title: string;
}

@Component
export default class CellEditor extends Vue {
    // Data
    @Ref('input')
    protected scrollTarget!: Vue;

    @Prop({ required: false, type: Function })
    protected fetchRowFunction!: (args: unknown) => Promise<CompoundDocument<ResourceIdentifier>>;

    @Prop({ default: false, required: true, type: Object })
    protected header!: TableHeader;

    @Prop({ default: false, required: true, type: Object })
    protected item!: Resource;

    @Prop({ required: false, type: Function })
    protected updateFunction!: (payload: {
        confirmFunc?: () => Promise<void>;
        header: TableHeader;
        item: unknown;
        original: unknown;
        secondaryFunc?: () => void;
        value: unknown;
    }) => Promise<unknown>;

    protected error: ErrorType | null = null;

    protected inputValue: unknown = null;

    protected isInputValid = true;

    protected isValid = true;

    protected original: unknown;

    protected previousValue: unknown = null;

    protected type!: string;

    protected updateFailed = false;

    protected updatePending = false;

    protected updateSuccess = false;

    protected mounted(): void {
        this.inputValue = this.cloneValue(this.currentValue);
    }

    // Computed
    protected get alignClass(): string {
        switch (this.header.contentAlign) {
            case 'left':
                return 'text-left justify-start';
            case 'center':
                return 'text-center justify-center';
            case 'right':
                return 'text-right justify-end';
            default: {
                if (this.header.renderer === 'number') {
                    return 'text-right justify-end';
                } else {
                    return 'text-left justify-start';
                }
            }
        }
    }

    protected get currentValue(): unknown {
        if (this.item.attributes) {
            return this.item.attributes[this.header.value] as string;
        }
        return null;
    }

    protected get id(): string {
        return `cell_${this.item.id}_${this.header.value}`;
    }

    protected get inputBackgroundColor(): string {
        if (this.updateSuccess) {
            return 'success';
        }
        if (this.updateFailed) {
            return 'error';
        }
        if (this.updatePending) {
            return 'warning';
        }
        return 'default';
    }

    protected get restoreIsVisible(): boolean {
        return !!this.header.restoreIsVisibleFunction && this.header.restoreIsVisibleFunction(this.item);
    }

    // Sync Methods
    protected isDirty(): boolean {
        return this.previousValue !== this.cloneValue(this.inputValue);
    }

    /**
     * Sets right to value based on input type
     * @param source
     * @protected
     */
    protected cloneValue(source: unknown): unknown {
        let value: unknown;
        switch (this.type) {
            case 'string': {
                value = String(source).valueOf();
                break;
            }
            case 'boolean': {
                value = Boolean(source).valueOf();
                break;
            }
            case 'number': {
                value = Number(source).valueOf();
                break;
            }
            case 'undefined': {
                value = undefined;
                break;
            }
            case 'object': {
                if (Array.isArray(source)) {
                    value = Object.assign([], source);
                } else if (source === null) {
                    value = null;
                } else {
                    value = Object.assign({}, source);
                }
                break;
            }
        }
        return value;
    }

    protected getScrollRef(): { target: Vue } {
        return { target: this.scrollTarget };
    }

    @Emit('refreshRow')
    protected refreshRow(item: unknown): unknown {
        return item;
    }

    @Emit('validateCell')
    protected validateCell(valid: boolean): Record<string, boolean> {
        return { [`${this.item.id}_${this.header.value}`]: valid };
    }

    protected cancel(): void {
        this.inputValue = this.cloneValue(this.previousValue);
    }

    protected focus(): void {
        (this.$refs.input as HTMLFormElement).focus();
    }

    // Event Handlers
    @Watch('currentValue', { deep: true, immediate: true })
    protected onColumnPropsChange(currentValue: unknown): void {
        this.type = typeof currentValue;
        this.previousValue = this.cloneValue(currentValue);
    }

    @Emit('onEnter')
    protected onEnter(itemId: string | number, columnName: string): { columnName: string; itemId: string | number } {
        return { columnName, itemId };
    }

    protected scrollToCellFunc(): void {
        this.scrollTarget.$el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }

    protected resetUpdateStatus(): void {
        this.updateSuccess = false;
        this.updateFailed = false;
        this.updatePending = false;
    }

    protected async fetchRow(): Promise<void> {
        try {
            const response = await this.fetchRowFunction({ itemId: this.item.id });
            const data = response.data;
            if (Array.isArray(data)) {
                this.refreshRow(data[0]);
            } else {
                this.refreshRow(data);
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorRowNotUpdated'),
                messageType: MessageType.Notification
            });
        }
    }

    // Async Methods
    protected async leave(): Promise<void> {
        if (this.isDirty() && this.isValid && !this.updatePending && !this.error) {
            await this.onUpdate();
        } else if (!this.isValid) {
            this.cancel();
        }
    }

    protected async restore(event?: MouseEvent): Promise<void> {
        if (this.header.restoreValue) {
            this.inputValue = this.cloneValue(this.header.restoreValue(this.item));
            await this.onUpdate(event);
        }
    }

    protected async update(): Promise<void> {
        if (this.inputValue === '' && this.type === 'number') {
            this.inputValue = 0;
        }
        this.previousValue = this.cloneValue(this.inputValue);

        try {
            this.error = null;
            this.updateSuccess = false;
            this.updateFailed = false;
            this.updatePending = true;
            this.validateCell(false);
            this.original = this.item.attributes;
            await this.updateFunction({
                confirmFunc: (): Promise<void> => this.update(),
                header: this.header,
                item: this.item,
                original: this.original,
                secondaryFunc: () => this.scrollToCellFunc(),
                value: this.cloneValue(this.inputValue)
            });
            this.error = null;
            this.updateSuccess = true;
            this.validateCell(true);
        } catch (error) {
            const er = error as { data?: { currentEntity?: { attributes: unknown } } };
            if (er.data?.currentEntity) {
                this.original = er.data.currentEntity.attributes;
            }
            this.error = error as ErrorType; // WTF
            this.validateCell(false);
            this.updateFailed = true;
        } finally {
            await this.fetchRow();
            this.updatePending = false;
        }
    }

    protected async onUpdate(event?: KeyboardEvent | MouseEvent): Promise<void> {
        if (event && event instanceof KeyboardEvent && event.key === 'Enter') {
            event.preventDefault();
            if (this.item.id) {
                this.onEnter(this.item.id, this.header.value);
            }
        }
        if (this.error || this.isDirty() && this.isValid) {
            await this.update();
        }
    }
}
