import ColumnFilterClass from '@/model/Data/Filters/ColumnFilterClass';
import { CONSTANTS } from '@/constants';
import type { Expression } from '@bednic/json-api-client';

export interface DateFilterValue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emptyValues: Array<any>;
    filterNull: string;
    from: string;
    to: string;
}

class DateFilterClass extends ColumnFilterClass<DateFilterValue> {
    public value: DateFilterValue = {
        emptyValues: [null],
        filterNull: CONSTANTS.FILTERS.FILTER_OPTIONS.WITH_NULL,
        from: '',
        to: ''
    };

    public constructor(key: string, filter?: DateFilterClass | DateFilterValue) {
        super(key);
        if (filter instanceof DateFilterClass) {
            this.value = { ...filter.value };
        } else if (filter) {
            this.value = { ...filter };
        }
    }

    public get filter(): Expression | null {
        return this.getFilter(this.value, this.value.from, this.value.to);
    }

    public clone(): DateFilterClass {
        return new DateFilterClass(this.key, this);
    }
}

export default DateFilterClass;
