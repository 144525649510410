

























/* eslint-disable no-undef */
import { Component, Ref, Vue } from 'vue-property-decorator';
import abcValueStore from '@/store/modules/AbcValueStore';
import appStore from '@/store/modules/AppStore';
import flagStore from '@/store/modules/FlagStore';
import GlobalFilter from '@/components/filters/GlobalFilter.vue';
import type { OrdersSemiGlobalFilter } from '@/model/Interfaces/OrdersSemiGlobalFilter';
import SemiGlobalDateFilter from '@/components/filters/SemiGlobalDateFilter.vue';
import stateStore from '@/store/modules/StateStore';
import userStore from '@/store/modules/UserStore';

@Component({
    components: {
        GlobalFilter,
        SemiGlobalDateFilter
    }
})
export default class Orders extends Vue {
    @Ref('globalFiltersRef')
    protected globalFiltersRef!: HTMLElement;

    @Ref('mainContainerRef')
    private readonly mainContainerRef!: HTMLElement;

    public mounted(): void {
        this.setElements();
    }

    // Lifecycle Hooks
    public async beforeCreate(): Promise<void> {
        await Promise.all([flagStore.fetchAll(), userStore.fetchAll(), stateStore.fetchAll(), abcValueStore.fetchAll()]);
    }

    // Computed
    protected get canLeaveEditMode(): boolean {
        return appStore.canLeaveEditMode;
    }

    protected get ordersSemiGlobalFilter(): Array<string> {
        return appStore.ordersSemiGlobalFilter.dateRange;
    }

    protected get pwDateRange(): Array<string> {
        return [appStore.pwDate, appStore.pwDate];
    }

    // Sync Methods
    protected setElements(): void {
        this.$nextTick(() => {
            const paddingTop = parseInt(window.getComputedStyle(this.mainContainerRef).getPropertyValue('padding-top'));
            const paddingBottom = parseInt(window.getComputedStyle(this.mainContainerRef).getPropertyValue('padding-bottom'));
            appStore.setElements({
                globalFilterHeight: this.globalFiltersRef.clientHeight,
                mainContainerPadding: paddingTop + paddingBottom
            });
        });
    }

    // Async Methods
    protected async onChangeSemiGlobalDateFilter(dateFilter: OrdersSemiGlobalFilter): Promise<void> {
        await appStore.changeOrdersSemiGlobalFilter(dateFilter);
    }
}
