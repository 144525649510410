/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call,
@typescript-eslint/no-unsafe-assignment */
import { ErrorActions, ErrorHandler } from '@/services/ErrorService/ErrorService';
import ApplicationError from '@/model/Classes/ApplicationError';
import type { AxiosError } from 'axios';
import { i18n } from '@/plugins/i18n';
import type { JsonApiError } from '@/model/Interfaces/JsonApiError';
import type { JsonApiErrorResponse } from '@/model/Interfaces/JsonApiErrorResponse';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import { MessageType } from '@/model/Enums/MessageType';
import type { TableHeader } from '@/model/Interfaces/TableHeader';
import userStore from '@/store/modules/UserStore';
export class NotUpToDateErrorHandler extends ErrorHandler {
    public constructor(
        public readonly messageKey: string,
        public readonly headers: Array<TableHeader>,
        public readonly value: unknown,
        public readonly header: TableHeader,
        public readonly confirmFunc: () => void,
        public readonly secondaryFunc: () => void
    ) {
        super();
    }

    public accept(error: AxiosError & { isAxiosError : boolean }): boolean {
        return !!(
            error.isAxiosError &&
            error.response?.data?.errors?.some(
                (responseError: { status: number, code: string }) => Number(responseError.status) === 409 && responseError.code === 'NotUpToDate'
            )
        );
    }

    public handle(
        error: AxiosError<JsonApiError>,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): Array<ApplicationError> {
        const applicationErrors: Array<ApplicationError> = [];
        if (error.response?.data) {
            const response = error.response.data as JsonApiErrorResponse;
            for (const responseError of response.errors) {
                if (this.isNotUpToDate(responseError)) {
                    const changesArray: Array<string> = Object.entries(responseError.meta.changes as Record<string, { user: string, value: string }>).map(
                        (forecastChangeWrapper) => {
                            const [key, change] = forecastChangeWrapper as [string, { user: string, value: string }];
                            const user = userStore.getByUsername(change.user)?.attributes.name || change.user;
                            const foundHeader = this.headers.find((header) => header.value === key);
                            const translatedColumnName = i18n.tc(foundHeader?.text || '');
                            const value = this.getValue(change, foundHeader);

                            return i18n.tc('messages.errorEditConflictChange', 0, {
                                translatedColumnName,
                                user,
                                value
                            });
                        }
                    );
                    const editedColumnName = i18n.tc(this.header.text);
                    const count = responseError.meta?.actual as number;
                    const title = i18n.tc(this.messageKey);
                    const detail = changesArray.join('</br>');
                    applicationErrors.push(
                        new ApplicationError(title, detail, {
                            actions: {
                                actionText: i18n.tc(`${this.messageKey}ActionText`, count, {
                                    column: editedColumnName,
                                    value: this.value
                                }),
                                confirmFunc: this.confirmFunc,
                                secondaryFunc: this.secondaryFunc
                            },
                            data: responseError.meta,
                            error,
                            messageLevel: MessageLevel.Error,
                            messageType: messageType || MessageType.Console
                        })
                    );
                } else {
                    const title = this.getTitle(responseError, context);
                    const detail = this.getDetail(responseError, context);
                    applicationErrors.push(
                        new ApplicationError(title, detail, {
                            actions,
                            data: responseError.meta,
                            error,
                            messageLevel: MessageLevel.Error,
                            messageType: messageType || MessageType.Console
                        })
                    );
                }
            }
        }
        return applicationErrors;
    }

    protected getTitle(responseError: JsonApiError, context?: string): string {
        return context || `${responseError.title as string}`;
    }

    protected getDetail(responseError: JsonApiError, context?: string): string {
        return (
            (context && responseError.detail
                ? `${responseError.title as string}: ${responseError.detail}`
                : context
                    ? responseError.title
                    : responseError.detail) || ''
        );
    }

    protected getValue(change: { value: string }, header?: TableHeader): string {
        let value = change.value;
        if (header && header.filterStoreInit) {
            const item = header.filterStoreInit().getOne(value);
            if (item && header.filterEnumText) {
                value =
                    header.filterEnumText === 'id'
                        ? item.id
                        : item.attributes[header.filterEnumText.replaceAll('attributes.', '')];
            }
        }
        return value;
    }

    protected isNotUpToDate(responseError: JsonApiError): boolean {
        return Number(responseError.status) === 409 && responseError.code === 'NotUpToDate' && !!responseError.meta;
    }
}
