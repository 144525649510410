import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import type { DialogType } from '@/model/Enums/DialogType';
import type { LocaleMessages } from 'vue-i18n';
import type { UserView } from '@/model/Interfaces/UserView';

type RulesReturnType = string | boolean | LocaleMessages;

@Component
export default class UserViewsDialog extends Vue {
    @Prop()
    protected savedViews!: Array<UserView>;

    // Data
    public rules = {
        required: (value: string): RulesReturnType =>
            !!value || this.$tc('components.validationMessages.nameCannotBeEmpty'),
        tooLong: (value: string): RulesReturnType => {
            return value && value.length <= 32 || this.$tc('components.validationMessages.nameIsTooLong');
        },
        unique: (value: string): RulesReturnType => {
            return (
                !this.savedViews.some((element) => element.name === value) ||
                this.$tc('components.validationMessages.unique')
            );
        }
    };

    @PropSync('activeDialog', { required: true })
    protected syncedActiveDialog!: DialogType | null;

    @PropSync('activeView')
    protected syncedActiveView!: UserView | null;

    // Computed getters and setters
    protected get isDialogOn(): boolean {
        return !!this.syncedActiveDialog;
    }

    protected set isDialogOn(value: boolean) {
        if (!value) {
            this.syncedActiveDialog = null;
        }
    }
}
