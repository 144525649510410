/* eslint-disable sort-keys */
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';

if (process.env.VUE_APP_MODE === 'production') {
    Sentry.init({
        // @ts-expect-error-error
        Vue,
        autoSessionTracking: true,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_MODE,
        integrations: [new Integrations.BrowserTracing()],
        release: process.env.VUE_APP_VERSION,
        tracesSampleRate: 0.5
    });
}

export default Sentry;
