var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"autofocus":"","multiple":"","chips":"","dense":"","outlined":"","hide-details":"","item-text":_vm.filterEnumText,"item-value":"id","return-object":"","items":_vm.items,"label":_vm.$tc('components.filters.enum.value'),"disabled":_vm.disabled || !_vm.items.length},on:{"change":_vm.onFilterChanged},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"text-color":_vm.getItemColorFg(item),"color":_vm.getItemColorBg(item),"close":"","small":""},on:{"click:close":function($event){return parent.selectItem(item)}}},'v-chip',attrs,false),[(item.icon)?_c('v-icon',[_vm._v(" "+_vm._s(item.attributes.icon)+" ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.filterEnumText === 'id' ? item.id : item.attributes[_vm.filterEnumText.replaceAll('attributes.', '')])+" ")])],1)]}},{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-simple-checkbox',{staticClass:"mr-8",attrs:{"value":attrs.inputValue},on:{"click":function($event){return _vm.toggleSelected(item)}}}),(item.attributes.icon === null)?[_c('span',[_vm._v(" "+_vm._s(_vm.filterEnumText === 'id' ? item.id : item.attributes[_vm.filterEnumText.replaceAll('attributes.', '')])+" ")])]:[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.attributes.icon)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.filterEnumText === 'id' ? item.id : item.attributes[_vm.filterEnumText.replaceAll('attributes.', '')])+" ")])]]}}],null,true),model:{value:(_vm.currentFilter.value.enums),callback:function ($$v) {_vm.$set(_vm.currentFilter.value, "enums", $$v)},expression:"currentFilter.value.enums"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }