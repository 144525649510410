/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types,
@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
import { ErrorActions, ErrorHandler } from '@/services/ErrorService/ErrorService';
import type { ActionType } from '@/model/Enums/ActionType';
import ApplicationError from '@/model/Classes/ApplicationError';
import { i18n } from '@/plugins/i18n';
import type { JsonApiError } from '@/model/Interfaces/JsonApiError';
import type { JsonApiErrorResponse } from '@/model/Interfaces/JsonApiErrorResponse';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import { MessageType } from '@/model/Enums/MessageType';
import type { Resource } from '@bednic/json-api-client';

export class InvalidCheckoutErrorHandler extends ErrorHandler {
    public constructor(
        public readonly messageKey: string,
        public readonly value: any,
        public readonly confirmFunc: (payload: {
            actionType: ActionType;
            count: number;
            updatedResource: Resource;
        }) => Promise<void>,
        public readonly actionType: ActionType,
        public readonly updatedResource: Resource
    ) {
        super();
    }

    public handle(
        error: any,
        context?: string,
        messageType?: MessageType,
        actions?: ErrorActions
    ): Array<ApplicationError> {
        const applicationErrors: Array<ApplicationError> = [];
        if (error.response?.data) {
            const response = error.response.data as JsonApiErrorResponse;
            for (const responseError of response.errors) {
                const title = this.getTitle(responseError, context);
                const detail = this.getDetail(responseError, context);
                if (Number(responseError.status) === 409 && responseError.code === 'InvalidCheckCount') {
                    const count = responseError.meta.actual as number;
                    applicationErrors.push(
                        new ApplicationError(title, detail, {
                            actions: {
                                actionText: i18n.tc(`${this.messageKey}ActionText`, count, {
                                    count,
                                    value: this.value
                                }),
                                confirmFunc: async (): Promise<void> => {
                                    await this.confirmFunc({
                                        actionType: this.actionType,
                                        count,
                                        updatedResource: this.updatedResource
                                    });
                                }
                            },
                            data: responseError.meta,
                            error,
                            messageLevel: MessageLevel.Error,
                            messageType: messageType
                        })
                    );
                } else {
                    applicationErrors.push(
                        new ApplicationError(title, detail, {
                            actions,
                            data: responseError.meta,
                            error,
                            messageLevel: MessageLevel.Error,
                            messageType: messageType || MessageType.Console
                        })
                    );
                }
            }
        }
        return applicationErrors;
    }

    public accept(error: any): boolean {
        return !!(
            error.isAxiosError &&
            error.response?.data?.errors.some(
                (responseError: any) =>
                    Number(responseError.status) === 409 && responseError.code === 'InvalidCheckCount'
            )
        );
    }

    protected getTitle(responseError: JsonApiError, context?: string): string {
        return context || `${responseError.title as string}`;
    }

    protected getDetail(responseError: JsonApiError, context?: string): string {
        return (
            (context && responseError.detail
                ? `${responseError.title as string}: ${responseError.detail}`
                : context
                    ? responseError.title
                    : responseError.detail) || ''
        );
    }
}
