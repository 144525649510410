

















































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import CompoundDocument, { ExpressionBuilder, Meta, PrettyExpressionBuilder, Resource } from '@bednic/json-api-client';
import appStore from '@/store/modules/AppStore';
import { CONSTANTS } from '@/constants';
import CustomAutocomplete from '@/components/common/CustomAutocomplete.vue';
import CustomSelect from '@/components/common/CustomSelect.vue';
import type { DateFromTo } from '@/model/Interfaces/DateFromTo';
import { Debounce } from 'vue-debounce-decorator';
import DoubleDatePicker from '@/components/common/DoubleDatePicker.vue';
import ErrorService from '@/services/ErrorService/ErrorService';
import MenuFilter from '@/components/filters/MenuFilter.vue';
import { MessageType } from '@/model/Enums/MessageType';
import moment from 'moment';
import type { OrderByItems } from '@/model/Entity/OrderByItems';
import type { Ordering } from '@/model/types/Ordering';
import OrderTile from '@/components/orders/OrderTile.vue';
import StackedProgressBar from '@/components/common/StackedProgressBar.vue';
import type { State } from '@/model/Entity/State';
import StateStore from '@/store/modules/StateStore';
import type { Supplier } from '@/model/Entity/Supplier';
import type { User } from '@/model/Entity/User';
import UserStore from '@/store/modules/UserStore';

@Component({
    components: { CustomAutocomplete, CustomSelect, DoubleDatePicker, MenuFilter, OrderTile, StackedProgressBar }
})
export default class OrdersTiles extends Vue {
    @Ref('mainContainerRef')
    private readonly mainContainerRef!: HTMLElement;

    protected dateOrdering: Ordering = null;

    protected isLoadingNextOrders = false;

    protected lazyLoadingLimit = CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT;

    protected orders: Array<OrderByItems> = [];

    protected ordersMeta!: Meta | undefined;

    protected selectedDate: DateFromTo = { from: null, to: null };

    protected selectedStates: Array<State> = [];

    protected selectedSuppliers: Array<Supplier> = [];

    protected selectedUsers: Array<User> = [];

    protected stateOrdering: Ordering = null;

    protected readonly stateStore = StateStore;

    protected supplierOrdering: Ordering = null;

    protected userOrdering: Ordering = null;

    protected readonly userStore = UserStore;

    public mounted(): void {
        this.setElements();
    }

    protected async created(): Promise<void> {
        // Set Selected User To Active User
        this.selectedUsers = this.activeUser ? [this.activeUser] : [];

        // Set Selected Date Filter For Today
        this.selectedDate = { from: this.todayDate, to: this.todayDate };
        // Load State store
        await Promise.all([this.stateStore.fetchAll(), this.userStore.fetchAll()]);

        // Load Orders
        this.orders = await this.loadOrders();
    }

    protected get activeUser(): User | undefined {
        return appStore.authService?.user;
    }

    // Computed
    protected get selectedDateTitle(): string {
        let items: Array<string> = [];
        if (this.selectedDate.from) {
            const from = this.$options.filters?.date(this.selectedDate.from) as string;
            items.push(`${this.$tc('logioTabulator.commonFilters.from')}: ${from}`);
        }
        if (this.selectedDate.to) {
            const to = this.$options.filters?.date(this.selectedDate.to) as string;
            items.push(`${this.$tc('logioTabulator.commonFilters.to')}: ${to}`);
        }
        return `${this.$tc('routes.titles.orders')}: ${
            items.length ? items.join(', ') : this.$tc('components.ordersTiles.orderDateUnlimited')
        }`;
    }

    /**
     * TODO: In future this will be probably handled by a new endpoint.
     * Generates aggregated results for StackedProgressBar component based on currently displayed Orders.
     * */
    protected get stateStatistics(): Array<{ amount: number; color: string; label: string }> {
        const struct: Record<string, number> = {};

        this.orders.forEach((order) => {
            const id = order.attributes.stateId as string;

            if (!struct[id]) {
                struct[id] = 1;
            } else {
                struct[id]++;
            }
        });

        const result: Array<{ amount: number; color: string; label: string }> = [];

        Object.keys(struct).forEach((stateId) => {
            const state = this.stateStore.getOne(stateId);
            result.push({
                amount: struct[stateId],
                color: state?.attributes.color as string,
                label: state?.attributes.name as string
            });
        });
        return result;
    }

    protected get todayDate(): string {
        return moment().format('YYYY-MM-DD');
    }

    // Sync Methods
    protected setElements(): void {
        this.$nextTick(() => {
            const paddingTop = parseInt(window.getComputedStyle(this.mainContainerRef).getPropertyValue('padding-top'));
            const paddingBottom = parseInt(window.getComputedStyle(this.mainContainerRef).getPropertyValue('padding-bottom'));
            appStore.setElements({
                mainContainerPadding: paddingTop + paddingBottom
            });
        });
    }

    // Event Handler
    protected onResetDateFilter(): void {
        this.selectedDate.from = null;
        this.selectedDate.to = null;
    }

    protected onResetSupplierFilter(): void {
        this.selectedSuppliers = [];
    }

    protected onResetStateFilter(): void {
        this.selectedStates = [];
    }

    protected onResetUserFilter(): void {
        this.selectedUsers = [];
    }

    protected onUserOrderingChange(ordering: Ordering): void {
        this.userOrdering = ordering;
        this.supplierOrdering = this.dateOrdering = this.stateOrdering = null;
        void this.fetchOrdersDebounced();
    }

    protected onSupplierOrderingChange(ordering: Ordering): void {
        this.supplierOrdering = ordering;
        this.userOrdering = this.dateOrdering = this.stateOrdering = null;
        void this.fetchOrdersDebounced();
    }

    protected onDateOrderingChange(ordering: Ordering): void {
        this.dateOrdering = ordering;
        this.userOrdering = this.supplierOrdering = this.stateOrdering = null;
        void this.fetchOrdersDebounced();
    }

    protected onStateOrderingChange(ordering: Ordering): void {
        this.stateOrdering = ordering;
        this.userOrdering = this.dateOrdering = this.supplierOrdering = null;
        void this.fetchOrdersDebounced();
    }

    // Async Methods
    protected async fetchStates(offset: number): Promise<Array<State> | undefined> {
        const doc = new CompoundDocument<State>(CONSTANTS.API.STATES, this.axios);
        doc.filter().setLimit(CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT);
        doc.filter().setOffset(offset);
        try {
            await doc.self();
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorSupplierLoading'),
                messageType: MessageType.Notification
            });
        }
        return doc.data as Array<State> | undefined;
    }

    protected async fetchSuppliers(search: string, offset: number): Promise<Array<Supplier> | undefined> {
        const doc = new CompoundDocument<Supplier>(CONSTANTS.API.SUPPLIERS, this.axios);
        let data;
        try {
            data = await this.fetchSearchedData(search, offset, doc);
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorSupplierLoading'),
                messageType: MessageType.Notification
            });
        }
        return data as Array<Supplier> | undefined;
    }

    protected async fetchUsers(search: string, offset: number): Promise<Array<User> | undefined> {
        const doc = new CompoundDocument<User>(CONSTANTS.API.USERS, this.axios);
        let data;
        try {
            data = await this.fetchSearchedData(search, offset, doc);
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorUsersLoading'),
                messageType: MessageType.Notification
            });
        }
        return data as Array<User> | undefined;
    }

    protected async fetchSearchedData(
        search: string,
        offset: number,
        doc: CompoundDocument<Resource>
    ): Promise<Array<Resource> | undefined> {
        const filter = PrettyExpressionBuilder.contains(
            ExpressionBuilder.toLower(ExpressionBuilder.field('name')).express(),
            search ? search.toLowerCase() : ''
        ).express();
        doc.addCustomQueryParam('filter', filter);
        doc.filter().setLimit(CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT);
        doc.filter().setOffset(offset);
        await doc.self();
        return doc.data as Array<Resource> | undefined;
    }

    @Debounce(700)
    protected async fetchOrdersDebounced(): Promise<void> {
        this.orders = await this.loadOrders();
    }

    protected async loadOrders(offset = 0): Promise<Array<OrderByItems>> {
        const orders = new CompoundDocument<OrderByItems>(CONSTANTS.API.ORDERS, this.axios);
        const filterArray = [];
        if (this.selectedUsers.length) {
            const expressionArray = this.selectedUsers.map((user) => PrettyExpressionBuilder.equal('assignedTo', user.id));
            filterArray.push(PrettyExpressionBuilder.or(...expressionArray));
        }
        if (this.selectedSuppliers.length) {
            const expressionArray = this.selectedSuppliers.map((supplier) => PrettyExpressionBuilder.equal('supplierId', supplier.id));
            filterArray.push(PrettyExpressionBuilder.or(...expressionArray));
        }
        if (this.selectedDate.from) {
            const fromFilter = PrettyExpressionBuilder.greaterThenOrEqual('orderDate', moment(this.selectedDate.from).format());
            filterArray.push(fromFilter);
        }
        if (this.selectedDate.to) {
            const toFilter = PrettyExpressionBuilder.lowerThenOrEqual('orderDate', moment(this.selectedDate.to).format());
            filterArray.push(toFilter);
        }
        if (this.selectedStates.length) {
            const expressionArray = this.selectedStates.map((state) => PrettyExpressionBuilder.equal('stateId', state.id));
            filterArray.push(PrettyExpressionBuilder.or(...expressionArray));
        }
        if (filterArray.length) {
            orders.addCustomQueryParam('filter', PrettyExpressionBuilder.and(...filterArray).express());
        }
        if (this.userOrdering) {
            orders.filter().addSortBy('assignedTo', this.userOrdering === 'desc');
        }
        if (this.supplierOrdering) {
            orders.filter().addSortBy('supplierName', this.supplierOrdering === 'desc');
        }
        if (this.dateOrdering) {
            orders.filter().addSortBy('orderDate', this.dateOrdering === 'desc');
        }
        if (this.stateOrdering) {
            orders.filter().addSortBy('stateId', this.stateOrdering === 'desc');
        }
        orders.filter().setLimit(60).setOffset(offset); // the number should be dividable by 6
        try {
            await orders.self();
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorOrdersLoading'),
                messageType: MessageType.Notification
            });
        }

        this.ordersMeta = orders.meta;
        return orders.data as Array<OrderByItems>;
    }

    protected async loadNextOrders(): Promise<void> {
        const orders = await this.loadOrders(this.orders.length);

        this.orders.push(...orders);
    }

    // Async Event Handlers
    @Debounce(150)
    protected async onOrdersScroll(): Promise<void> {
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const scrollTop = (document.querySelector('html')?.scrollTop || 0) + windowHeight;

        if (!this.isLoadingNextOrders && scrollTop > document.body.clientHeight - 600 && this.ordersMeta?.total > this.orders.length) {
            this.isLoadingNextOrders = true;
            await this.loadNextOrders();
            this.isLoadingNextOrders = false;
        }
    }

    @Watch('selectedUsers')
    @Watch('selectedSuppliers')
    @Watch('selectedDate', { deep: true })
    @Watch('selectedStates')
    protected onFilterChange(): void {
        void this.fetchOrdersDebounced();
    }
}
