import CompoundDocument, { Resource } from '@bednic/json-api-client';
import axios from 'axios';
import ErrorService from '@/services/ErrorService/ErrorService';
import type { ExportEndNotification } from '@/model/Notifications/ExportEndNotification';
import type { ExportStartNotification } from '@/model/Notifications/ExportStartNotification';
import type { ExportUpdateNotification } from '@/model/Notifications/ExportUpdateNotification';
import { i18n } from '@/plugins/i18n';
import { Message } from '@/model/Classes/Message';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import { Notifications } from '@/model/Enums/Notifications';
import { socket } from '@/plugins/socket';
import { v4 as uid } from 'uuid';

export enum ExportState {
    Created = 'created',
    Finished = 'finished',
    Idle = 'idle',
    Running = 'running'
}

export interface ExportConfig {
    data?: TableExportResource;
    filename?: string;
    filter?: ExportTableFilter;
    format?: string;
    url?: string;
}

export interface ExportTableFilter {
    columns: Array<string>;
    filter: string;
    globalFilter?: string;
    sort: string;
}

export interface TableExportResource extends Resource {
    attributes: {
        columns: Array<string>;
        error?: string | null;
        filter: string;
        format: string;
        globalFilter?: string;
        progress?: number;
        sort: string;
        state?: ExportState;
    };
}

export class TableExport {
    public readonly id: string;

    public messageId!: string | null;

    public progressId!: string | null;

    public constructor(
        public data: TableExportResource,
        public url: string,
        public filename: string,
        public format: string
    ) {
        this.id = uid();
        if (!this.data.type) {
            this.data.type = this.url;
        }
        if (!this.data.attributes.progress) {
            this.data.attributes.progress = 0;
        }
        if (!this.data.attributes.state) {
            this.data.attributes.state = ExportState.Idle;
        }
        if (!this.data.attributes.format) {
            this.data.attributes.format = format;
        }
    }
}

class ExportTableService {
    protected readonly localStorageKey = 'tableExports';

    protected tableExports: Array<TableExport> = [];

    public constructor() {
        this.loadTableExports();
    }

    public addEventListeners(): void {
        // FIXME event listeners are inline arrow function or this wont work
        socket.on(Notifications.ExportStart, (payload: ExportStartNotification): void => {
            this.tableExports.forEach((item) => {
                if (item.progressId === String(payload.id)) {
                    item.data.attributes.state = ExportState.Created;
                    void this.setMessageProgress(item);
                }
            });
            this.saveTableExports();
        });
        socket.on(Notifications.ExportUpdate, (payload: ExportUpdateNotification): void => {
            this.tableExports.forEach((item) => {
                if (item.progressId === String(payload.id)) {
                    item.data.attributes.progress = this.beautifyProgress(payload.progress);
                    void this.setMessageProgress(item);
                }
            });
            this.saveTableExports();
        });
        socket.on(Notifications.ExportEnd, (payload: ExportEndNotification): void => {
            this.tableExports.forEach((item) => {
                if (item.progressId === String(payload.id)) {
                    void this.setMessageProgress(item);
                    void this.finishTableExport(item);
                }
            });
        });
    }

    protected loadTableExports(): void {
        try {
            const saved = window.localStorage.getItem(this.localStorageKey);
            if (saved) {
                this.tableExports = JSON.parse(saved) as Array<TableExport>;
                this.tableExports.forEach((tableExport) => {
                    void this.finishTableExport(tableExport);
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    protected saveTableExports(): void {
        window.localStorage.setItem(this.localStorageKey, JSON.stringify(this.tableExports));
    }

    protected beautifyProgress(progress: number | undefined): number {
        if (typeof progress === 'undefined') {
            return 0;
        } else {
            const progressTable = [0, 20, 40, 60, 80, 100];
            progress *= 100;
            progress = Math.floor(progress);
            let foundProgress = 0;
            if (progress >= progressTable[progressTable.length - 1]) {
                return progressTable[progressTable.length - 1];
            } else {
                for (let i = 0; i < progressTable.length; i++) {
                    if (progress >= progressTable[i] && progress < progressTable[i + 1]) {
                        foundProgress = progressTable[i];
                    }
                }
                return foundProgress;
            }
        }
    }

    protected getProgressId(tableExport: TableExport): string {
        if (!tableExport?.progressId) {
            throw new Error('No progressId received');
        }
        return tableExport.progressId;
    }

    // public socketExportStartEventListener(payload: ExportStartNotification): void {
    //     this.tableExports.forEach((item) => {
    //         if (item.progressId === String(payload.id)) {
    //             console.log('export start', payload.id, item);
    //             item.data.attributes.state = ExportState.Created;
    //             this.setMessageProgress(item);
    //         }
    //     });
    //     this.saveTableExports();
    // }

    // public socketExportUpdateEventListener(payload: ExportUpdateNotification): void {
    //     this.tableExports.forEach((item) => {
    //         if (item.progressId === String(payload.id)) {
    //             console.log('export update', payload.id, payload.progress, item);
    //             item.data.attributes.progress = this.beautifyProgress(payload.progress);
    //             this.setMessageProgress(item);
    //         }
    //     });
    //     this.saveTableExports();
    // }

    // public socketExportEndEventListener(payload: ExportEndNotification): void {
    //     this.tableExports.forEach((item) => {
    //         if (item.progressId === String(payload.id)) {
    //             console.log('export end', payload.id, item);
    //             this.setMessageProgress(item);
    //             this.finishTableExport(item);
    //         }
    //     });
    //     console.log('here', this.tableExports);
    // }

    protected reloadPageEventListener(event: BeforeUnloadEvent): void {
        event.returnValue = `${i18n.tc('components.confirmBox.leaveExport').toString()}`;
    }

    protected removeExport(id: string): void {
        this.tableExports = this.tableExports.filter((item) => item.id !== id);
    }

    protected async downloadFile(tableExport: TableExport): Promise<void> {
        try {
            const progressId = this.getProgressId(tableExport);
            const fileResponse = await axios.get(`export/${progressId}/download`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', tableExport.filename || 'export');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            await this.createErrorMessage(error);
        }
    }

    protected async finishTableExport(tableExport: TableExport): Promise<void> {
        await this.fetchExport(tableExport);
        if (tableExport.data.attributes.state === ExportState.Finished) {
            window.setTimeout(() => {
                void this.deleteMessage(tableExport);
            }, 1500);
            if (tableExport.data.attributes.error) {
                await ErrorService.dispatch(tableExport.data.attributes.error, {
                    context: i18n.tc('messages.errorExportFailed'),
                    messageType: MessageType.Notification
                });
            } else {
                await this.downloadFile(tableExport);
            }
            this.removeExport(tableExport.id);
            this.saveTableExports();
        }
    }

    public async createExport(tableExport: TableExport): Promise<void> {
        try {
            this.createMessage(tableExport);
            this.tableExports = [...this.tableExports, tableExport];
            const doc = new CompoundDocument<TableExportResource>(tableExport.url, axios);
            await doc.create(tableExport.data);
            const data = doc.data;
            if (data && !Array.isArray(data)) {
                tableExport.progressId = data.id;
                tableExport.data.attributes = data.attributes;
                this.saveTableExports();
            }
        } catch (error) {
            await this.deleteMessage(tableExport);
            await this.createErrorMessage(error);
        }
    }

    protected async fetchExport(tableExport: TableExport): Promise<void> {
        try {
            const progressId = this.getProgressId(tableExport);
            const doc = new CompoundDocument<TableExportResource>(
                `${tableExport.url}/${progressId}`,
                axios
            );
            await doc.self();
            const data = doc.data;
            if (data && !Array.isArray(data)) {
                tableExport.data.attributes = data.attributes;
            }
        } catch (error) {
            await this.deleteMessage(tableExport);
            await this.createErrorMessage(error);
        }
    }

    protected createMessage(tableExport: TableExport): void {
        const message = new Message(i18n.tc('messages.infoExportPreparing'), tableExport.filename, {
            actions: {
                secondaryBtnText: 'mdi mdi-stop',
                secondaryFunc: (): void => {
                    this.removeExport(tableExport.id);
                }
            },
            messageLevel: MessageLevel.Info,
            messageType: MessageType.Notification,
            progress: tableExport.data.attributes.progress
        });
        tableExport.messageId = message.id;
        messagesStore.sendMessage(message);
    }

    protected setMessageProgress(tableExport: TableExport): void {
        if (tableExport.messageId) {
            messagesStore.setMessageProgress({
                messageId: tableExport.messageId,
                progress: tableExport.data.attributes.progress
            });
        } else {
            this.createMessage(tableExport);
        }
    }

    protected async createErrorMessage(error: unknown): Promise<void> {
        await ErrorService.dispatch(error, {
            context: i18n.tc('messages.errorExportFailed'),
            messageType: MessageType.Notification
        });
    }

    protected async deleteMessage(tableExport: TableExport): Promise<void> {
        if (tableExport.messageId) {
            await messagesStore.resolveMessage(tableExport.messageId);
        }
    }
}

export default new ExportTableService();
