






import CellRenderer from '@/components/common/Table/cellRenderers/CellRenderer';
import { Component } from 'vue-property-decorator';
import userStore from '@/store/modules/UserStore';

@Component
export default class UserCellRenderer extends CellRenderer {
    // Computed
    protected get users(): string {
        const userNames: Array<string> = [];
        const currentValue = Array.isArray(this.currentValue) ? this.currentValue : [this.currentValue];
        currentValue.forEach((userId) => {
            const user = userStore.getOne(String(userId));
            if (user) {
                userNames.push(user.attributes.name);
            }
        });
        return userNames.join(', ');
    }
}
