import CompoundDocument, { Resource } from '@bednic/json-api-client';
import axios from 'axios';
import { CONSTANTS } from '@/constants';
import type { JsonApiRequestConfig } from '@/model/Interfaces/JsonApiRequestConfig';

export default class EntityService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public doc!: CompoundDocument<any>;

    private readonly limit = CONSTANTS.NUMBERS.LIMIT;

    public constructor(public readonly url: string) {}

    public cancelFetch(): void {
        this.doc.abort();
    }

    public async create<T extends Resource>(item: T): Promise<T | null> {
        const doc = new CompoundDocument<T>(this.url, axios);
        await doc.create(item);
        const data = doc.data;
        if (!data) {
            return null;
        } else if (Array.isArray(data)) {
            return data[0];
        } else {
            return data;
        }
    }

    public async delete<T extends Resource>(id: string): Promise<void> {
        const doc = new CompoundDocument<T>(`${this.url}/${id}`, axios);
        return doc.delete();
    }

    public async fetch<T extends Resource>(config: JsonApiRequestConfig): Promise<Array<T> | T | null> {
        if (typeof config.limit !== 'undefined') {
            this.doc.filter().setLimit(config.limit);
        }
        if (typeof config.offset !== 'undefined') {
            this.doc.filter().setOffset(config.offset);
        }
        if (typeof config.sort !== 'undefined' && config.sort !== null) {
            this.doc.filter().addSortBy(config.sort.field, config.sort.desc);
        }
        if (typeof config.params !== 'undefined') {
            Object.keys(config.params).forEach((key) => {
                if (typeof config.params !== 'undefined' && typeof config.params[key] !== 'undefined') {
                    this.doc.addCustomQueryParam(key, config.params[key]);
                }
            });
        }
        if (typeof config.sparseFields !== 'undefined' && config.sparseFields.length) {
            this.doc.filter().sparseFieldsFor(this.url, config.sparseFields);
        }
        if (typeof config.filter !== 'undefined') {
            this.doc.filter().where(config.filter);
        }
        return (await this.doc.self()).data as T;
    }

    public async fetchAll<T extends Resource>(url?: string): Promise<Array<T>> {
        url = url ? url : this.url;
        const doc = new CompoundDocument<T>(url, axios);
        doc.filter().setLimit(this.limit.valueOf());
        await doc.self();
        const data = doc.data;
        const meta = doc.meta;
        const items: Array<T> = [];
        if (Array.isArray(data) && data.length) {
            items.push(...data);
        }
        if (meta && meta.limit < meta.total) {
            const limit = meta.total as number;
            const total = meta.total as number;
            const totalPages = Math.ceil(total / limit);
            const promises = [];
            for (let page = 1; page <= totalPages; page++) {
                const otherDoc = new CompoundDocument<T>(url, axios);
                otherDoc
                    .filter()
                    .setLimit(limit)
                    .setOffset((page - 1) * limit);
                promises.push(otherDoc.self());
            }
            const documents = await Promise.all(promises);
            documents.forEach((doc) => {
                const data = doc.data;
                if (Array.isArray(data)) {
                    items.push(...data);
                }
            });
        }
        return items;
    }

    public async fetchOne<T extends Resource>(id: string): Promise<T | null> {
        const doc = new CompoundDocument<T>(`${this.url}/${id}`, axios);
        await doc.self();
        const data = doc.data;
        if (!data) {
            return null;
        } else if (Array.isArray(data)) {
            return data[0];
        } else {
            return data;
        }
    }

    public async fetchRelationship<T extends Resource>(id: string, relationshipUrl: string): Promise<Array<T>> {
        return this.fetchAll(`${this.url}/${id}/${relationshipUrl}`);
    }

    public async update<T extends Resource>(id: string, item: T): Promise<T | null> {
        const doc = new CompoundDocument<T>(`${this.url}/${id}`, axios);
        await doc.update(item);
        const data = doc.data;
        if (!data) {
            return null;
        } else if (Array.isArray(data)) {
            return data[0];
        } else {
            return data;
        }
    }
}
