






















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import messagesStore from '@/store/modules/MessagesStore';

@Component
export default class PageSizeSelect extends Vue {
    // Data
    @Prop({ default: true, required: false })
    protected canBeChanged!: boolean;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected pageSizes!: number;

    @Prop({ default: 0, required: true })
    protected total!: number;

    @PropSync('pageSize', { required: true, type: Number })
    protected syncedPageSize!: number;

    protected valueSetFromInside = false;

    // Sync Methods
    protected changePageSize(value: number): void {
        if (this.canBeChanged) {
            this.$emit('update:pageSize', value);
        } else {
            if (this.valueSetFromInside) {
                this.valueSetFromInside = false;
            } else {
                void messagesStore.dispatchLeaveUpdateConfirm({
                    cancelCallbackFunc: () => {
                        // returns value of select to default value
                        this.valueSetFromInside = true;
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (this.$refs.pageSizeSelect as { setValue: (size: number) => void } & Vue).setValue(this.syncedPageSize);
                    },
                    confirmCallbackFunc: () => {
                        this.$emit('update:pageSize', value);
                    }
                });
            }
        }
    }
}
