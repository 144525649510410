













































import { Component, Prop, Vue } from 'vue-property-decorator';
import ActionDialog from '@/components/common/Table/actions/ActionDialog.vue';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import Delegate from '@/components/common/Table/actions/Delegate.vue';
import UpdateState from '@/components/common/Table/actions/UpdateState.vue';

@Component({
    // eslint-disable-next-line no-undef
    components: { ActionDialog, ConfirmationButtons, Delegate, UpdateState }
})
export default class TableActions extends Vue {
    // Props
    @Prop({ default: true, required: false, type: Boolean })
    protected bulkActionsEnabled!: boolean;

    @Prop({ default: false, required: false, type: Boolean })
    protected disabled!: boolean;

    @Prop({ required: true, type: Array })
    protected selectedItems!: Array<Record<string, unknown>>;

    @Prop({ required: true, type: Number })
    protected totalItemsCount!: number;

    // Computed getters
    protected get isDisabled(): boolean {
        return appStore.isReadOnlyMode || this.disabled;
    }

    protected get selectedItemsCount(): number {
        if (this.selectedItems.length) {
            return this.selectedItems.length;
        }
        if (this.bulkActionsEnabled) {
            return this.totalItemsCount;
        }
        return 0;
    }

    // Computed getters and setters
    protected get isOpen(): boolean {
        return appStore.activeMenu(ActiveMenus.Actions);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [ActiveMenus.Actions]: value });
    }
}
