import type { NavigationRouteConfig, RouteSettingsSingleView } from './model/NavigationRouteConfig';
import ac from '@/plugins/AccessControlService';
import appStore from '@/store/modules/AppStore';
import { CONSTANTS } from '@/constants';
import ErrorComponent from '@/components/ErrorComponent.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import OrdersModule from '@/router/OrdersModule';
import OrdersTilesModule from '@/router/OrdersTilesModule';
import Redirect from '@/components/Redirect.vue';
import SalesPlanningModule from '@/router/SalesPlanningModule';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

class AppRouter {
    public static registeredRoutes: Array<NavigationRouteConfig> = [];

    public router: VueRouter;

    protected routesDefinitions: Record<string, NavigationRouteConfig> = {
        [CONSTANTS.ROUTES.ROOT.NAME]: {
            name: CONSTANTS.ROUTES.ROOT.NAME,
            path: CONSTANTS.ROUTES.ROOT.PATH,
            redirect: () => this.activeRoute,
            requiredPermissions: []
        },
        ...OrdersTilesModule,
        ...SalesPlanningModule,
        ...OrdersModule,
        [CONSTANTS.ROUTES.REDIRECT.NAME]: {
            component: Redirect,
            name: CONSTANTS.ROUTES.REDIRECT.NAME,
            path: CONSTANTS.ROUTES.REDIRECT.PATH,
            requiredPermissions: []
        },
        [CONSTANTS.ROUTES.NOT_FOUND.NAME]: {
            component: ErrorComponent,
            name: CONSTANTS.ROUTES.NOT_FOUND.NAME,
            path: CONSTANTS.ROUTES.NOT_FOUND.PATH,
            props: { error: new Error(CONSTANTS.ERRORS.PAGE_NOT_FOUND) },
            requiredPermissions: []
        }
    };

    public constructor() {
        this.registerRoutes();
        this.router = this.createRouter();
        this.createAfterEachHook();
    }

    protected get activeModulesFromConfig(): Array<string> {
        return appStore.activeModules;
    }

    protected get activeRoute(): { name: string; path: string } {
        if (appStore.activeRoute && AppRouter.isRouteExists(appStore.activeRoute, AppRouter.registeredRoutes)) {
            return appStore.activeRoute;
        } else {
            const firstRegisteredRoute = this.getFirstRegisteredRoute();
            if (firstRegisteredRoute) {
                appStore.setActiveRoute({ name: String(firstRegisteredRoute.name), path: firstRegisteredRoute.path });
                return { name: String(firstRegisteredRoute.name), path: firstRegisteredRoute.path };
            } else {
                return { name: '', path: '' };
            }
        }
    }

    protected get hasPermissionForAnyModule(): boolean {
        return !!this.getFirstRegisteredRoute();
    }

    public static isRouteExists(
        searchedRoute: { name: string; path: string },
        registeredRoutes: Array<NavigationRouteConfig>
    ): boolean {
        let exists = false;
        for (const registeredRoute of registeredRoutes) {
            if (searchedRoute.name === registeredRoute.name && searchedRoute.path === registeredRoute.path) {
                exists = true;
                break;
            }
            if (registeredRoute.children?.length) {
                exists = AppRouter.isRouteExists(searchedRoute, registeredRoute.children);
            }
        }
        return exists;
    }

    protected createRouter(): VueRouter {
        return new VueRouter({
            base: process.env.BASE_URL,
            mode: 'history',
            routes: AppRouter.registeredRoutes
        });
    }

    protected createAfterEachHook(): void {
        this.router.afterEach((to, from) => {
            if (to.name && from.name) {
                appStore.setActiveRoute({ name: to.name, path: to.path });
                void appStore.saveUserSettings();
            }
        });
    }

    protected getFirstRegisteredRoute(): NavigationRouteConfig | undefined {
        return AppRouter.registeredRoutes.find(
            (route) =>
                route.registered &&
                route.name !== CONSTANTS.ROUTES.ROOT.NAME &&
                route.name !== CONSTANTS.ROUTES.REDIRECT.NAME &&
                route.path !== CONSTANTS.ROUTES.NOT_FOUND.PATH
        );
    }

    public static activeChildRoute(parentPath: string, defaultRoute: { name: string; path: string }): { name: string } {
        if (
            appStore.activeRoute &&
            AppRouter.isRouteExists(appStore.activeRoute, AppRouter.registeredRoutes) &&
            appStore.activeRoute.path.includes(parentPath) &&
            appStore.activeRoute.path !== parentPath
        ) {
            return appStore.activeRoute;
        } else {
            return defaultRoute;
        }
    }

    protected registerRoutes(): void {
        this.registerModule(CONSTANTS.ROUTES.ROOT.NAME);
        if (this.activeModulesFromConfig.length) {
            this.activeModulesFromConfig.forEach((module) => {
                this.registerModule(module);
            });
        } else {
            throw new Error(CONSTANTS.ERRORS.NO_MODULES);
        }
        if (!this.hasPermissionForAnyModule) {
            throw new Error(CONSTANTS.ERRORS.NO_ACCESS);
        }
        this.registerModule(CONSTANTS.ROUTES.REDIRECT.NAME);
        this.registerModule(CONSTANTS.ROUTES.NOT_FOUND.NAME);
    }

    protected registerModule(module: string): void {
        if (module in this.routesDefinitions) {
            const routeDefinition = this.routesDefinitions[module];
            this.registerRoute(routeDefinition, []);
            AppRouter.registeredRoutes.push(routeDefinition);
        }
    }

    protected registerRoute(routeDefinition: RouteSettingsSingleView, parentPermissions: Array<string>): void {
        if (routeDefinition.requiredPermissions.length) {
            if (ac.can(...parentPermissions, ...routeDefinition.requiredPermissions)) {
                routeDefinition.registered = true;
            } else {
                routeDefinition.registered = false;
                if (routeDefinition.component) {
                    routeDefinition.component = ErrorPage;
                    routeDefinition.props = { error: new Error(CONSTANTS.ERRORS.NO_PERMISSIONS) };
                }
            }
        } else {
            routeDefinition.registered = true;
        }
        if (routeDefinition.children) {
            routeDefinition.children.forEach((child) => {
                this.registerRoute(child, routeDefinition.requiredPermissions);
            });
        }
    }
}

export { AppRouter };
