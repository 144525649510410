













































import type { ColumnDefinition, Orderable, TableOptions } from '@logio/vue2-tabulator';
import { LogioTabulator } from '@logio/vue2-tabulator';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CompoundDocument, { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import type { ForecastChange, ForecastOperandType } from '@/model/Entity/ForecastChange';
import AutocompleteFetchService from '@/services/AutocompleteFetchService';
import type { Category } from '@/model/Entity/Category';
import type { CategoryType } from '@/model/Entity/CategoryType';
import CategoryTypeStore from '@/store/modules/CategoryTypeStore';
import type { ChangeList } from '@/model/Entity/ChangeList';
import { CONSTANTS } from '@/constants';
import DateMenu from '@/components/common/TabulatorTable/menus/DateMenu.vue';
import { Debounce } from 'vue-debounce-decorator';
import ErrorService from '@/services/ErrorService/ErrorService';
import { MessageType } from '@/model/Enums/MessageType';
import NumberMenu from '@/components/common/TabulatorTable/menus/NumberMenu.vue';
import PageSizeSelect from '@/components/common/TabulatorTable/PageSizeSelect.vue';
import Pagination from '@/components/common/TabulatorTable/Pagination.vue';
import type { PaginationType } from '@/model/Interfaces/PaginationType';
import TextMenu from '@/components/common/TabulatorTable/menus/TextMenu.vue';
import VueFormatters from '@/filters';
import type { Tabulator } from '@logio/vue2-tabulator';

const operandFormatter = (cell: Tabulator.CellComponent): string => {
    const type = (cell.getData() as ForecastChange).attributes.kind;
    const value = (cell.getValue() as number) * (type === 'percentage' ? 100 : 1);

    let symbol;
    if (type === 'absolute') {
        symbol = '=';
    } else {
        symbol = value > 0 ? '+' : '';
    }

    const unit = type === ('percentage' as ForecastOperandType) ? '%' : '';
    return `${symbol}${VueFormatters.number(value)}${unit}`;
};

const categoryTypeFormatter = (cell: Tabulator.CellComponent): string => {
    const categoryType: CategoryType | null = CategoryTypeStore.getOne(cell.getValue());
    return ((categoryType && categoryType.attributes.name) || cell.getValue()) as string;
};

interface UnsavedForecastChange extends Omit<ForecastChange, 'id' | 'attributes'> {
    // id is not required for unsaved changes
    attributes: Omit<ForecastChange['attributes'], 'updatedAt'>;
}

interface FieldOrdering {
    columnId: string;
    filterField: string;
    ordering: 'asc' | 'desc' | null;
}

@Component({
    components: { LogioTabulator, PageSizeSelect, Pagination }
})
export default class ForecastChanges extends Vue {
    @Prop({ required: false, type: Object })
    protected selectedCategory!: Category | null;

    @Prop({ required: false, type: Object })
    protected selectedChangeList!: ChangeList | null;

    @Prop({ default: () => [], required: true, type: Array })
    protected unsavedChanges!: Array<UnsavedForecastChange>;

    protected autocompleteFetchService = AutocompleteFetchService;

    protected loadingSavedChanges = false;

    protected pagination: PaginationType = {
        page: 1,
        pageSize: 20,
        pageSizes: [10, 20, 30, 40, 50],
        total: 0
    };

    protected savedChanges: Array<ForecastChange> = [];

    protected savedChangesFilters: Record<string, Orderable & { expression?: Expression }> = {};

    protected savedChangesOrdering: FieldOrdering | null = null;

    // Data
    protected savedChangesTableOptions: TableOptions = {
        autoResize: false,
        formatters: VueFormatters,
        height: '100%',
        hideableColumns: false,
        //layout: 'fitColumns',
        layoutColumnsOnNewData: true,
        locale: this.$i18n.locale,
        movableColumns: true,
        orderable: true,
        placeholder: this.$tc('components.salesPlanning.noData')
    };

    protected savedColumnDefinitions: Array<ColumnDefinition> = [
        {
            field: 'attributes.categoryTypeId',
            filter: {
                component: TextMenu,
                options: {
                    autocomplete: {
                        fetch: (value: string, offset: number): Promise<Array<CategoryType>> =>
                            Promise.resolve(
                                CategoryTypeStore.items
                                    .filter(({ attributes }) =>
                                        attributes.name.toLocaleLowerCase().includes((value || '').toLocaleLowerCase())
                                    )
                                    .slice(offset, offset + CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT)
                            ),
                        itemTextField: 'attributes.name',
                        itemValueField: 'id'
                    },
                    filterField: 'categoryTypeId'
                }
            },
            formatter: categoryTypeFormatter,
            title: this.$tc('tables.spChanges.categoryType')
        },
        {
            field: 'attributes.categoryName',
            filter: {
                component: TextMenu,
                options: {
                    autocomplete: {
                        fetch: (value: string, offset: number): Promise<unknown> => this.fetchCategory(value, offset),
                        itemTextAdditionalFields: ['categoryTypeName'],
                        itemTextField: 'attributes.name',
                        itemValueField: 'id'
                    },
                    filterField: 'categoryId'
                }
            },
            title: this.$tc('tables.spChanges.entity'),
            width: 150
        },
        {
            field: 'attributes.updatedAt',
            filter: {
                component: DateMenu,
                options: {
                    filterField: 'updatedAt'
                }
            },
            formatter: 'date',
            hozAlign: 'right',
            title: this.$tc('tables.spChanges.updatedAt'),
            width: 105
        },
        {
            field: 'attributes.date',
            filter: {
                component: DateMenu,
                options: {
                    filterField: 'date',
                    type: 'month'
                }
            },
            formatter: 'datetime',
            formatterParams: { month: 'long', year: '2-digit' },
            hozAlign: 'right',
            title: this.$tc('tables.spChanges.date'),
            width: 110
        },
        {
            field: 'attributes.changedAmount',
            filter: {
                component: NumberMenu,
                options: {
                    filterField: 'changedAmount'
                }
            },
            formatter: 'number',
            formatterParams: {
                maximumFractionDigits: 2
            },
            hozAlign: 'right',
            title: this.$tc('tables.spChanges.changedAmount'),
            width: 110
        },
        {
            field: 'attributes.originalAmount',
            filter: {
                component: NumberMenu,
                options: {
                    filterField: 'originalAmount'
                }
            },
            formatter: 'number',
            hozAlign: 'right',
            title: this.$tc('tables.spChanges.originalAmount'),
            width: 115
        },
        {
            field: 'attributes.amount',
            formatter: operandFormatter,
            hozAlign: 'right',
            title: this.$tc('tables.spChanges.amount'),
            width: 110
        },
        {
            field: 'attributes.pwAmount',
            formatter: 'number',
            hozAlign: 'right',
            title: this.$tc('tables.spChanges.pwAmount'),
            width: 110
        }
    ];

    protected unsavedChangesTableOptions: TableOptions = {
        autoResize: false,
        formatters: VueFormatters,
        height: '100%',
        hideableColumns: false,
        locale: this.$i18n.locale,
        movableColumns: true,
        orderable: false,
        placeholder: this.$tc('components.salesPlanning.noData'),
        rowFormatter: (row: Tabulator.RowComponent): void => {
            row.getElement().classList.add('tabulator-selected');
        }
    };

    protected url = CONSTANTS.API.SP_CHANGES;

    protected async mounted(): Promise<void> {
        await this.onChangeListChange();
    }

    protected async beforeMount(): Promise<void> {
        // Fetch all categories to display in table
        await CategoryTypeStore.fetchAll();
    }

    protected get unsavedColumnDefinitions(): Array<ColumnDefinition> {
        return [
            {
                field: 'attributes.categoryTypeId',
                formatter: categoryTypeFormatter,
                title: this.$tc('tables.spChanges.categoryType')
            },
            {
                field: 'attributes.categoryId',
                formatter: (): string => {
                    return this.selectedCategory?.attributes.name || '';
                },
                title: this.$tc('tables.spChanges.entity')
            },
            {
                field: 'attributes.date',
                formatter: 'datetime',
                formatterParams: { month: 'long', year: '2-digit' },
                hozAlign: 'right',
                title: this.$tc('tables.spChanges.date'),
                width: 85
            },
            {
                field: 'attributes.changedAmount',
                formatter: 'number',
                formatterParams: {
                    maximumFractionDigits: 2
                },
                hozAlign: 'right',
                title: this.$tc('tables.spChanges.changedAmount'),
                width: 95
            },
            {
                field: 'attributes.originalAmount',
                formatter: 'number',
                formatterParams: {
                    maximumFractionDigits: 2
                },
                hozAlign: 'right',
                title: this.$tc('tables.spChanges.originalAmount'),
                width: 95
            },
            {
                field: 'attributes.amount',
                formatter: operandFormatter,
                hozAlign: 'right',
                title: this.$tc('tables.spChanges.amount'),
                width: 110
            },
            {
                field: 'attributes.pwAmount',
                formatter: 'number',
                hozAlign: 'right',
                title: this.$tc('tables.spChanges.pwAmount'),
                width: 95
            }
        ];
    }

    @Watch('pagination.pageSize')
    protected async onPageSizeChanged(): Promise<void> {
        this.pagination.page = 1;
        await this.fetchChanges();
    }

    @Watch('selectedChangeList')
    protected async onChangeListChange(): Promise<void> {
        this.savedChangesFilters = {};

        await this.fetchChanges();
    }

    public async fetchCategory(value: string, offset: number): Promise<Array<Category & { categoryTypeName: string | undefined }>> {
        const selectedChangeListId = this.selectedChangeList?.id as string;
        return this.autocompleteFetchService
            .fetchResource<Category & { categoryTypeName: string }>(
                `/sp-change-lists/${selectedChangeListId}/spCategories`,
                value,
            offset,
            ['name']
        )
            .then((categories) =>
                categories.map((category) => {
                    // Adds category type name to the additional field
                    const categoryTypeId = category.attributes.typeId;
                    const categoryType: CategoryType | null = CategoryTypeStore.getOne(categoryTypeId);
                    return {
                        ...category,
                        categoryTypeName: categoryType?.attributes.name
                    };
                })
            );
    }

    /**
     * This method takes care about PWNG single column ordering limitation
     * */
    protected async onFilterChange(lastFilterChange?: {
        columnId: string;
        filterField: string;
        filterValue: { ordering?: 'desc' | 'asc' };
    }): Promise<void> {
        // Previous Ordering columns
        if (lastFilterChange) {
            const { columnId, filterField, filterValue } = lastFilterChange;
            const { ordering = null } = filterValue;

            if (ordering) {
                // If ordering is set, remove ordering from other Filter Values
                Object.keys(this.savedChangesFilters).forEach((otherColumnId) => {
                    if (otherColumnId !== columnId && this.savedChangesFilters[otherColumnId]) {
                        (this.savedChangesFilters[otherColumnId] as Orderable).ordering = null;
                    }
                });
                this.savedChangesOrdering = {
                    columnId,
                    filterField,
                    ordering
                };
            } else if (this.savedChangesOrdering) {
                // If ordering is not set, check if latest saved ordering was from the same column
                if (columnId === this.savedChangesOrdering.columnId) {
                    // Sets ordering to null
                    this.savedChangesOrdering = null;
                }
                // Otherwise no changes
            }
        }

        await this.fetchChanges();
    }

    public async renewChanges(): Promise<void> {
        await Promise.all([CategoryTypeStore.fetchAll(), this.fetchChanges()]);
    }

    @Watch('pagination.page')
    @Debounce(CONSTANTS.NUMBERS.AUTOCOMPLETE_DEBOUNCE)
    public async fetchChanges(): Promise<void> {
        this.loadingSavedChanges = true; // Should already be true, this is just for case of calling method directly.

        if (!this.selectedChangeList) {
            // If no ChangeList selected
            this.savedChanges = [];
            this.loadingSavedChanges = false;
            return;
        }

        const compoundDocument = new CompoundDocument<ForecastChange>(CONSTANTS.API.SP_CHANGES, this.axios);
        compoundDocument
            .filter()
            .setOffset((this.pagination.page - 1) * this.pagination.pageSize)
            .setLimit(this.pagination.pageSize);

        const filterExpressions: Array<Expression> = [PrettyExpressionBuilder.equal('changeListId', this.selectedChangeList.id)];

        // User table filters as Expressions
        filterExpressions.push(
            ...(Object.values<{ expression?: Expression }>(this.savedChangesFilters)
                .filter((filter) => !!filter.expression)
                .map((filter) => filter.expression) as Array<Expression>)
        );

        // Apply filters on Compound Document.
        if (filterExpressions.length === 1) {
            // Only ChangeList filter
            const [expression] = filterExpressions;
            compoundDocument.filter().where(expression);
        } else if (filterExpressions.length > 1) {
            // ChangeList filter + user Table filters
            compoundDocument.filter().where(PrettyExpressionBuilder.and(...filterExpressions));
        }

        // Ordering
        if (this.savedChangesOrdering) {
            const { filterField, ordering } = this.savedChangesOrdering;
            compoundDocument.filter().addSortBy(filterField, ordering === 'desc');
        }

        try {
            const compoundResult = await compoundDocument.self();
            this.savedChanges = compoundResult.data as Array<ForecastChange>;
            this.pagination.total = (compoundDocument.meta?.total as number) || 0;
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorChangesLoading'),
                messageType: MessageType.Notification
            });
        } finally {
            this.loadingSavedChanges = false;
        }
    }
}
