import { Expression, ExpressionBuilder, PrettyExpressionBuilder } from '@bednic/json-api-client';
import ColumnFilterClass from '@/model/Data/Filters/ColumnFilterClass';

interface EnumMultipleFilterValue {
    enums: Array<{ id: string }>;
    enumsAll: Array<{ id: string }>;
    options: string;
}

class EnumMultipleFilterClass extends ColumnFilterClass<EnumMultipleFilterValue> {
    public value: EnumMultipleFilterValue = {
        enums: [],
        enumsAll: [],
        options: 'atLeastOneSelected'
    };

    public constructor(key: string, filter?: EnumMultipleFilterClass | EnumMultipleFilterValue) {
        super(key);
        if (filter instanceof EnumMultipleFilterClass) {
            this.value = { ...filter.value };
        } else if (filter) {
            this.value = { ...filter };
        }
    }

    public get filter(): Expression | null {
        if (this.value.options === 'emptyOnly') {
            const formattedArray = this.value.enumsAll.map((item) => {
                return PrettyExpressionBuilder.has(`${this.key}`, item.id);
            });
            return ExpressionBuilder.not(PrettyExpressionBuilder.or(...formattedArray));
        } else if (this.value.enums.length) {
            if (this.value.options === 'allButSelected') {
                const formattedArray = this.value.enums.map((item) => {
                    return ExpressionBuilder.not(PrettyExpressionBuilder.has(`${this.key}`, item.id));
                });
                return PrettyExpressionBuilder.and(...formattedArray);
            } else {
                const formattedArray = this.value.enums.map((item) => {
                    return PrettyExpressionBuilder.has(`${this.key}`, item.id);
                });
                if (this.value.options === 'atLeastOneSelected') {
                    return PrettyExpressionBuilder.or(...formattedArray);
                } else if (this.value.options === 'allSelected') {
                    return PrettyExpressionBuilder.and(...formattedArray);
                } else {
                    return null;
                }
            }
        }
        return null;
    }

    public clone(): EnumMultipleFilterClass {
        return new EnumMultipleFilterClass(this.key, this);
    }
}

export default EnumMultipleFilterClass;
