













































import { TableHeader, TableHeaderFilterType, TableHeaderRenderer } from '@/model/Interfaces/TableHeader';
import appStore from '@/store/modules/AppStore';
import type { CategoryType } from '@/model/Entity/CategoryType';
import categoryTypeStore from '@/store/modules/CategoryTypeStore';
import { Component } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import type { DataStore } from '@/store/DataStore';
import type { ForecastChange } from '@/model/Entity/ForecastChange';
import LTable from '@/components/common/Table/Table.vue';
import { mixins } from 'vue-class-component';
import PageSizeSelect from '@/components/common/Table/PageSizeSelect.vue';
import Pagination from '@/components/common/Table/Pagination.vue';
import TableMixin from '@/mixins/TableMixin';

enum ColDefs {
    Category = 'category',
    CategoryType = 'categoryType',
    Created = 'created',
    Date = 'date',
    FinalValue = 'finalValue',
    Operand = 'operand',
    OriginalValue = 'originalValue'
}

const module = CONSTANTS.MODULES.SP_CHANGES;

@Component({
    components: { LTable, PageSizeSelect, Pagination }
})
export default class ForecastChanges extends mixins<TableMixin<ForecastChange>>(TableMixin) {
    // Data
    protected headers: Array<TableHeader> = [
        {
            default: true,
            filterEnumText: 'attributes.name',
            filterStoreInit: (): DataStore<CategoryType> => categoryTypeStore,
            filterType: TableHeaderFilterType.Enum,
            renderer: TableHeaderRenderer.Text,
            sortable: true,
            text: `tables.${module}.${ColDefs.CategoryType}`,
            value: ColDefs.CategoryType,
            width: 120
        },
        {
            default: true,
            filterAutocompleteURL: CONSTANTS.API.SP_CATEGORIES,
            filterType: TableHeaderFilterType.ResourceText,
            renderer: TableHeaderRenderer.Text,
            resourceFilterOptions: {
                additionalFields: [],
                containsKey: 'name',
                filterBy: 'id'
            },
            sortable: true,
            text: `tables.${module}.${ColDefs.Category}`,
            value: ColDefs.Category,
            width: 120
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Date,
            renderer: TableHeaderRenderer.Date,
            sortable: true,
            text: `tables.${module}.${ColDefs.Date}`,
            value: ColDefs.Date,
            width: 80
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Number,
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.OriginalValue}`,
            value: ColDefs.OriginalValue,
            width: 60
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Text,
            renderer: TableHeaderRenderer.Text,
            sortable: true,
            text: `tables.${module}.${ColDefs.Operand}`,
            value: ColDefs.Operand,
            width: 60
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Number,
            renderer: TableHeaderRenderer.Number,
            sortable: true,
            text: `tables.${module}.${ColDefs.FinalValue}`,
            value: ColDefs.FinalValue,
            width: 60
        }
    ];

    protected items: Array<ForecastChange> = [];

    protected tableOptions: unknown = {
        calculateWidths: false,
        class: '',
        dense: true,
        disableFiltering: true,
        disableSort: true,
        fixedHeader: true,
        footerProps: {
            itemsPerPageOptions: [10, 20, 50, 100]
        },
        hideDefaultFooter: true,
        hideDefaultHeader: true,
        itemKey: 'id',
        showSelect: true,
        singleSelect: false
    };

    // Getters
    protected get changeListHeight(): number {
        const changeListPadding = 24;
        const changeListMarginTop = 8;
        return (
            (this.$vuetify.breakpoint.height -
                (appStore.elements.mainContainerPadding +
                    appStore.elements.toolbarHeight +
                    appStore.elements.changeListSelectHeight +
                    changeListPadding +
                    changeListMarginTop)) /
            2
        );
    }

    protected get tableHeight(): number {
        const tableCardPadding = 0;
        const tableHeight =
            this.$vuetify.breakpoint.height +
            (this.tableMarginTop - this.tableMarginTop * 2) -
            (appStore.elements.mainContainerPadding +
                appStore.elements.globalFilterHeight +
                appStore.elements.toolbarHeight +
                appStore.elements.tableActionButtonsHeight +
                appStore.elements.tableFooterHeight +
                tableCardPadding);
        return tableHeight < appStore.elements.minTableHeight ? appStore.elements.minTableHeight : tableHeight / 2;
    }
}
