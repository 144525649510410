












































































































import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import CreateDialog from '@/components/common/Table/userViews/CreateDialog.vue';
import DeleteDialog from '@/components/common/Table/userViews/DeleteDialog.vue';
import { DialogType } from '@/model/Enums/DialogType';
import draggable from 'vuedraggable';
import messagesStore from '@/store/modules/MessagesStore';
import UpdateDialog from '@/components/common/Table/userViews/UpdateDialog.vue';
import type { UserView } from '@/model/Interfaces/UserView';
import type { ViewValue } from '@/model/Interfaces/ViewValue';

@Component({
    // eslint-disable-next-line no-undef
    components: { ConfirmationButtons, CreateDialog, DeleteDialog, UpdateDialog, draggable }
})
export default class UserViews extends Vue {
    // Props
    @Prop({ default: true, required: false })
    protected canBeChanged!: boolean;

    @Prop({ required: true })
    protected savedViews!: Array<UserView>;

    // Data
    protected activeDialog: DialogType.Create | DialogType.Update | DialogType.Delete | null = null;

    protected activeView: UserView | null = null;

    protected currentViews: Array<UserView> = [];

    protected dialogType = DialogType;

    @PropSync('selectedUserViewName', { required: true })
    protected selected!: string | null;

    protected get isOpen(): boolean {
        return appStore.activeMenu(ActiveMenus.UserViews);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [ActiveMenus.UserViews]: value });
    }

    // Sync Methods
    public resetSelected(): void {
        this.selected = null;
    }

    protected changeUserView(view: UserView | null): void {
        if (this.canBeChanged) {
            this.switchToUserView(view);
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    this.switchToUserView(view);
                }
            });
        }
    }

    @Emit('switchToUserView')
    protected switchToUserView(view: UserView | null): ViewValue | null {
        this.onClose();
        if (view !== null) {
            this.selected = view.name;
            return view.value;
        } else {
            this.selected = '';
            return null;
        }
    }

    @Emit('saveUserViews')
    protected saveUserViews(): Array<UserView> {
        return this.currentViews;
    }

    // Event Handlers
    protected onSort(): void {
        this.saveUserViews();
    }

    protected onCreateClick(): void {
        this.activeDialog = DialogType.Create;
        this.$nextTick(() => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (this.$refs.nameInput) {
                (this.$refs.nameInput as HTMLFormElement).reset();
            }
        });
    }

    protected onDeleteClick(view: UserView): void {
        this.activeDialog = DialogType.Delete;
        this.activeView = view;
    }

    protected onUpdateClick(view: UserView): void {
        this.activeDialog = DialogType.Update;
        this.activeView = view;
        this.$nextTick(() => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (this.$refs.nameInput) {
                (this.$refs.nameInput as HTMLFormElement).reset();
            }
        });
    }

    @Emit('createUserView')
    protected onCreateView(newName: string): string {
        this.selected = newName;
        return newName;
    }

    protected onDeleteView(): void {
        this.currentViews = this.currentViews.filter((item) => item !== this.activeView);
        this.saveUserViews();
    }

    protected onUpdateView(): void {
        this.saveUserViews();
    }

    protected onClose(): void {
        this.isOpen = false;
    }

    @Watch('savedViews', { deep: true, immediate: true })
    public onSavedViewsChange(savedViews?: Array<UserView>): void {
        if (typeof savedViews !== 'undefined') {
            this.currentViews = savedViews.map((item) => ({
                name: item.name,
                value: item.value
            }));
        }
    }
}
