






























































import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import ScrollTrigger from '@/components/common/ScrollTrigger.vue';

interface LazyLoadingProp {
    isLoadedAll: boolean;
    useLazyLoading: boolean;
}

@Component({ components: { ScrollTrigger } })
export default class AutocompleteSelect extends Vue {
    // Props
    @Prop({ default: false, required: false, type: Boolean })
    protected isLoading!: boolean;

    @Prop({ required: true, type: Array })
    protected items!: Array<unknown>;

    @Prop({ default: 'name', required: false, type: String })
    protected itemText!: string;

    @Prop({ default: '', required: false, type: String })
    protected label!: string;

    @Prop({ default: { isLoadedAll: false, useLazyLoading: false }, type: Object })
    protected lazyLoading!: LazyLoadingProp;

    @Prop({ required: true, type: String })
    protected type!: 'autocomplete' | 'select';

    @Prop({ required: true })
    protected value!: unknown;

    @PropSync('searchValue')
    protected search!: string;

    protected searchDebounce = 0;

    // Computed
    protected get valueSync(): unknown {
        return this.value;
    }

    protected set valueSync(value: unknown) {
        this.onValueChange(value);
    }

    // Event Handlers
    protected onChange(value: unknown): void {
        if (value) {
            this.emitSearchChangeDebounced('');
        }
    }

    @Watch('search', { immediate: false })
    protected onSearchChange(search: string): void {
        if (this.searchDebounce && !this.valueSync) {
            this.emitSearchChange();
            clearTimeout(this.searchDebounce);
        }
        if (!this.valueSync) {
            this.searchDebounce = window.setTimeout(() => {
                this.emitSearchChangeDebounced(search);
            }, CONSTANTS.NUMBERS.AUTOCOMPLETE_DEBOUNCE);
        }
    }

    @Emit('value-change')
    protected onValueChange(value: unknown): unknown {
        return value;
    }

    @Emit('scroll-to-bottom')
    protected onScrollToBottom(): boolean {
        return true;
    }

    @Emit('search-change')
    protected emitSearchChange(): boolean {
        return true;
    }

    @Emit('search-change-debounced')
    protected emitSearchChangeDebounced(search: string): string {
        return search;
    }
}
