import { Action, Mutation, VuexModule } from 'vuex-module-decorators';
import type EntityService from '@/services/EntityService';
import type { JsonApiRequestConfig } from '@/model/Interfaces/JsonApiRequestConfig';
import type { Resource } from '@bednic/json-api-client';

export class DataStore<T extends Resource> extends VuexModule {
    public entityService!: EntityService;

    public items: Array<T> = [];

    public get getOne(): (id: string) => T | null {
        return (id: string): T | null => {
            return this.items.find((item) => item.id === id) || null;
        };
    }

    @Mutation
    public addItem(item: T): void {
        this.items = [...this.items, item];
    }

    @Mutation
    public setItem(item: T): void {
        let found = false;
        this.items = this.items.map((original) => {
            if (original.id === item.id) {
                original = item;
                found = true;
            }
            return original;
        });
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!found) {
            this.items = [...this.items, item];
        }
    }

    @Mutation
    public setItems(items: Array<T>): void {
        this.items = items;
    }

    @Mutation
    public removeItem(item: T): void {
        this.items = this.items.filter((original) => original.id !== item.id);
    }

    @Action({ rawError: true })
    public async fetchAll(): Promise<void> {
        const items = await this.entityService.fetchAll<T>();
        this.context.commit('setItems', items);
    }

    @Action({ rawError: true })
    public async fetch(config: JsonApiRequestConfig): Promise<void> {
        const items = await this.entityService.fetch<T>(config);
        if (Array.isArray(items)) {
            this.context.commit('setItems', items);
        } else {
            this.context.commit('setItems', [items]);
        }
    }

    @Action({ rawError: true })
    public async fetchOne(id: string): Promise<void> {
        const item = await this.entityService.fetchOne<T>(id);
        if (Array.isArray(item)) {
            this.context.commit('setItem', item[0]);
        } else {
            this.context.commit('setItem', item);
        }
    }

    @Action({ rawError: true })
    public async fetchRelationship(payload: {
        id: string;
        relationshipKey: string;
        relationshipUrl: string;
    }): Promise<void> {
        const item = this.getOne(payload.id);
        if (item && item.attributes) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            item.attributes[payload.relationshipKey] = await this.entityService.fetchRelationship<any>(
                payload.id,
                payload.relationshipUrl
            );
            this.context.commit('setItem', item);
        }
    }
}
