import { CONSTANTS } from '@/constants';
import { i18n } from '@/plugins/i18n';
import Vue from 'vue';

const INVALID_DATE = 'Invalid Date';

const text = (value: unknown): string => {
    if (!(typeof value === 'undefined' || value === null)) {
        if (typeof value === 'string') {
            return value;
        } else if (Array.isArray(value)) {
            return value.map((v) => text(v)).join(', ');
        } else if (typeof value === 'object') {
            return JSON.stringify(value);
        } else if (typeof value === 'number') {
            return String(value);
        }
    }
    return '';
};

const shortenText = (value: string, length: number): string => {
    if (value.length <= length) {
        return value;
    } else {
        return value.substr(0, length).concat('...');
    }
};

const title = (value: unknown): string => {
    const ret = text(value);
    return shortenText(ret, CONSTANTS.NUMBERS.TITLE_LIMIT);
};

const number = (value: unknown, options?: Intl.NumberFormatOptions): string => {
    return !(typeof value === 'undefined' || value === null) ? Number(value).toLocaleString(i18n.locale, options) : '';
};

const date = (value: unknown): string => {
    if (value === null || value === undefined) {
        return '';
    }
    if (Array.isArray(value) && value.length && !value.every((item) => date(item) === INVALID_DATE)) {
        return value.map((item) => date(item)).join(', ');
    }
    if (typeof value === 'number' || typeof value === 'string' || value instanceof Date) {
        return new Date(value).toLocaleDateString(i18n.locale);
    }
    return INVALID_DATE;
};

const datetime = (value: unknown, options?: Intl.DateTimeFormatOptions): string => {
    if (value === null || value === undefined) {
        return '';
    }
    if (Array.isArray(value)) {
        return value.map((item) => datetime(item, options)).join(', ');
    }
    if (typeof value === 'number' || typeof value === 'string' || value instanceof Date) {
        return new Date(value).toLocaleString(i18n.locale, options);
    }
    return INVALID_DATE;
};

const dateRange = (value: unknown): string => {
    if (value === null || value === undefined) {
        return '';
    }
    if (Array.isArray(value) && value.length && value.every((item) => date(item) !== INVALID_DATE)) {
        const earliest = new Date(Math.min(...value));
        const latest = new Date(Math.max(...value));
        return `${date(earliest)} - ${date(latest)}`;
    }
    if (date(value) !== INVALID_DATE) {
        return `${date(value)} - ${date(value)}`;
    }
    return INVALID_DATE;
};

const datetimeRange = (value: unknown): string => {
    if (value === null || value === undefined) {
        return '';
    }
    if (Array.isArray(value) && value.length && value.every((item) => datetime(item) !== INVALID_DATE)) {
        const earliest = new Date(Math.min(...value));
        const latest = new Date(Math.max(...value));
        return `${datetime(earliest)} - ${datetime(latest)}`;
    }
    if (datetime(value) !== INVALID_DATE) {
        return `${datetime(value)} - ${datetime(value)}`;
    }
    return INVALID_DATE;
};

const registerFilters = (): void => {
    Vue.filter('text', text);
    Vue.filter('title', title);
    Vue.filter('number', number);
    Vue.filter('date', date);
    Vue.filter('datetime', datetime);
    Vue.filter('dateRange', dateRange);
    Vue.filter('datetimeRange', datetimeRange);
};

registerFilters();

export const filters = {
    date,
    dateRange,
    datetime,
    datetimeRange,
    number,
    text,
    title
};

export default filters;
