




















































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import { Debounce } from 'vue-debounce-decorator';
import type { Resource } from '@bednic/json-api-client';
import ScrollTrigger from '@/components/common/ScrollTrigger.vue';

@Component({
    components: { ScrollTrigger }
})
export default class CustomAutocomplete extends Vue {
    // Props
    @Prop({ required: true, type: Function })
    protected fetchItems!: (search: string, offset: number) => Promise<Array<Resource> | undefined>;

    @Prop({ default: 'name', required: false, type: String })
    protected itemTextField!: string;

    @Prop({ default: CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT, required: true, type: Number })
    protected lazyLoadingLimit!: number;

    @Prop({ default: false, required: false, type: Boolean })
    protected multiple!: boolean;

    @Prop({ default: 'mdi mdi-text-box-remove-outline', required: false, type: String })
    protected removeAllItemsIcon!: string;

    @Prop({ default: 'components.customAutocomplete.removeAllItems', required: false, type: String })
    protected removeAllItemsText!: string;

    // Data
    protected autocompleteValue = '';

    protected isLoadedAll = false;

    protected isLoading = false;

    protected items: Array<Resource> = [];

    // Synced Props
    @PropSync('selectedItems')
    protected selectedItemsSync!: Array<Resource>;

    // Methods
    protected getItemText(item: Resource): string {
        if (item.attributes && typeof item.attributes[this.itemTextField] === 'string') {
            return item.attributes[this.itemTextField] as string;
        }
        return '';
    }

    protected removeAllItems(): void {
        this.selectedItemsSync = [];
    }

    protected onItemsSelected(selectedValues: Array<Resource> | Resource): void {
        if (Array.isArray(selectedValues)) {
            this.selectedItemsSync = selectedValues;
        } else {
            this.selectedItemsSync = [selectedValues];
        }
    }

    protected removeItem(indexToRemove: number): void {
        this.selectedItemsSync = this.selectedItemsSync.filter((item, index) => index !== indexToRemove);
    }

    // Event Handlers
    protected onAutocompleteBlur(): void {
        if (this.autocompleteValue) {
            this.autocompleteValue = '';
            this.onSearchInput(this.autocompleteValue);
        }
    }

    protected onSearchInput(value: string): void {
        this.autocompleteValue = value;
        this.items = [];
        this.isLoading = true;
        void this.searchItem(value);
    }

    // Async Methods
    @Debounce(CONSTANTS.NUMBERS.AUTOCOMPLETE_DEBOUNCE)
    protected async searchItem(valueToSearch: string): Promise<void> {
        this.isLoading = true;
        const data = await this.fetchItems(String(valueToSearch || '').trim(), this.items.length);
        if (data && data.length < this.lazyLoadingLimit) {
            this.isLoadedAll = true;
        }
        if (data) {
            this.items = [...this.items, ...data];
        }
        this.isLoading = false;
    }

    // Async Event Handlers
    protected async onAutocompleteScrollToBottom(): Promise<void> {
        await this.searchItem(this.autocompleteValue);
    }
}
