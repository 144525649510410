




























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import type { User } from '@/model/Entity/User';
import userStore from '@/store/modules/UserStore';

@Component({
    name: 'delegate'
})
export default class Delegate extends Vue {
    // Data
    @Prop({ default: null })
    protected selectedOrdersCount!: number;

    @PropSync('selectedItem', { default: null })
    protected selectedUser!: User | null;

    // Lifecycle hooks
    public mounted(): void {
        if (this.users.length) {
            this.selectedUser = this.users[0];
        }
    }

    // Computed
    protected get isUserSelected(): boolean {
        return !!this.selectedUser;
    }

    protected get users(): Array<User> {
        return userStore.items;
    }
}
