/* eslint-disable max-lines-per-function */
import Ajv, { ErrorObject, JSONSchemaType } from 'ajv';
import axios from 'axios';
import type { Config } from '@/model/Interfaces/Config';
import { CONSTANTS } from '@/constants';
import Vue from 'vue';

let config: Config | null = null;
const ajv = new Ajv({ allErrors: true });

const schema: JSONSchemaType<Config> = {
    additionalProperties: false,
    properties: {
        connections: {
            items: {
                minLength: 1,
                properties: {
                    api: { minLength: 1, type: 'string' },
                    host: { minLength: 1, type: 'string' },
                    keycloak: {
                        additionalProperties: false,
                        properties: {
                            'auth-server-url': { minLength: 1, type: 'string' },
                            'confidential-port': { type: 'number' },
                            'public-client': { type: 'boolean' },
                            realm: { minLength: 1, type: 'string' },
                            resource: { minLength: 1, type: 'string' },
                            'ssl-required': { minLength: 1, type: 'string' },
                            'use-resource-role-mappings': { type: 'boolean' },
                            'verify-token-audience': { type: 'boolean' }
                        },
                        required: [
                            'auth-server-url',
                            'confidential-port',
                            'public-client',
                            'realm',
                            'resource',
                            'ssl-required',
                            'use-resource-role-mappings',
                            'verify-token-audience'
                        ],
                        type: 'object'
                    },
                    messenger: {
                        additionalProperties: false,
                        properties: {
                            path: { minLength: 1, type: 'string' },
                            url: { minLength: 1, type: 'string' }
                        },
                        required: ['path', 'url'],
                        type: 'object'
                    }
                },
                required: ['api', 'keycloak'],
                type: 'object'
            },
            minItems: 1,
            type: 'array'
        },
        defaultLocale: { minLength: 1, type: 'string' },
        name: { minLength: 1, type: 'string' }
    },
    required: ['connections', 'defaultLocale', 'name'],
    type: 'object'
};

const validate = ajv.compile(schema);

const fetch = async (fileName: string): Promise<Config> => {
    try {
        const configResponse = await axios.get<Config>(`${window.location.origin}/${fileName}?t=${Date.now()}`);
        return configResponse.data;
    } catch (error: unknown) {
        return Promise.reject(new Error(`${CONSTANTS.ERRORS.NOT_VALID_CONFIG}: missing ${fileName}`));
    }
};

const fetchConfig = async (): Promise<Config> => {
    config = await fetch(String(process.env.VUE_APP_CONFIG));
    const valid = validate(config);
    if (valid) {
        Vue.use(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Vue.prototype.$config = config;
        });
        return config;
    }
    if (validate.errors) {
        // eslint-disable-next-line max-len
        return Promise.reject(
            new Error(
                `${CONSTANTS.ERRORS.NOT_VALID_CONFIG}: config.json ${validate.errors
                    .map((error) => createMsg(error))
                    .join(', ')}`
            )
        );
    }
    return Promise.reject(new Error(CONSTANTS.ERRORS.NOT_VALID_CONFIG));
};

const createMsg = (error: ErrorObject): string => {
    if (error.instancePath) {
        return `${error.instancePath} ${String(error.message)}`;
    } else {
        return String(error.message);
    }
};

export { fetchConfig, config };
