



























































































import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import type DateFilterClass from '@/model/Data/Filters/DateFilterClass';
import type { TableHeader } from '@/model/Interfaces/TableHeader';

@Component
export default class DateFilter extends Vue {
    // Data
    @Prop()
    protected confirmBtnDisabled!: boolean;

    @Prop({ required: true })
    protected currentFilter!: DateFilterClass;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected header!: TableHeader;

    protected filterOptions = CONSTANTS.FILTERS.FILTER_OPTIONS;

    protected menu1 = false;

    protected menu2 = false;

    @PropSync('isValid', { required: true, type: Boolean })
    protected valid!: boolean;

    // Lifecycle Hooks
    public mounted(): void {
        this.onFilterChanged();
    }

    // Computed
    protected get validations(): Record<string, unknown> {
        return {
            from: [
                (value: string): string | boolean => {
                    return (
                        !value ||
                        (!this.currentFilter.value.to.length && !!value.length) ||
                        (this.currentFilter.value.to.length &&
                        new Date(this.currentFilter.value.from).getTime() <= new Date(this.currentFilter.value.to).getTime()) ||
                        this.translate('components.filters.date.validationFrom')
                    );
                }
            ],
            to: [
                (value: string): string | boolean => {
                    return (
                        !value ||
                        (!this.currentFilter.value.from.length && !!value.length) ||
                        (this.currentFilter.value.from.length &&
                        new Date(this.currentFilter.value.to).getTime() >= new Date(this.currentFilter.value.from).getTime()) ||
                        this.translate('components.filters.date.validationTo')
                    );
                }
            ]
        };
    }

    // Sync Methods
    protected translate(key: string): string {
        return this.$te(key) ? this.$tc(key).toString() : 'Not valid';
    }

    protected toLocaleDate(date: string): string {
        return date !== '' ? new Date(date).toLocaleDateString(this.$i18n.locale) : '';
    }

    protected clearFilter(filter: string): void {
        switch (filter) {
            case 'from':
                this.currentFilter.value.from = '';
                break;
            case 'to':
                this.currentFilter.value.to = '';
                break;
        }
        (this.$refs.form as { validate: () => void } & Vue).validate();
        this.onFilterChanged();
    }

    // Emitters
    @Emit()
    protected filterChanged(): DateFilterClass {
        return this.currentFilter;
    }

    // Event Handlers
    protected onDateSelected(menuId: number): void {
        const textInput = document.getElementById(`textField${menuId}`);

        if (menuId === 1) {
            this.menu1 = false;
        } else if (menuId === 2) {
            this.menu2 = false;
        }

        if (textInput) {
            textInput.focus();
        }

        this.onFilterChanged();
    }

    protected onEnterPressed(): void {
        if (!this.confirmBtnDisabled) {
            this.$emit('confirm');
        }
    }

    protected onFilterChanged(): void {
        this.filterChanged();
    }

    protected onRadioClicked(id: number): void {
        const radio = document.getElementById(`radio${id}`);
        if (radio) {
            radio.focus();
        }
    }
}
