/* eslint-disable @typescript-eslint/ban-types */
import type { DataStore } from '@/store/DataStore';
import type { DataTableHeader } from 'vuetify';

export interface TableHeader extends DataTableHeader {
    appendIcon?: string;
    appendIconFunction?: () => unknown;
    backgroundColor?: string;
    clearable?: boolean;
    columnPropName?: string;
    contentAlign?: 'left' | 'center' | 'right';
    default?: boolean;
    editable?: boolean;
    filterAutocompleteURL?: string;
    filterBooleanOptions?: {
        falsyText: string;
        truthyText: string;
        type: 'boolean' | 'number' | 'string';
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterEmptyValues?: Array<any>;
    filterEnumText?: string;
    filterKey?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterStoreInit?: () => DataStore<any>;
    filterType?: TableHeaderFilterType;
    inputAppendIcon?: string;
    inputAppendIconFunction?: () => unknown;
    inputPrependIcon?: string;
    inputPrependIconFunction?: () => unknown;
    max?: number;
    min?: number;
    numberOptions?: Intl.NumberFormatOptions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClickLink?: (item: any) => void;
    prependIcon?: string;
    prependIconFunction?: () => unknown;
    renderer?: TableHeaderRenderer;
    resourceFilterOptions?: {
        additionalFields: Array<string>;
        containsKey: string;
        filterBy: string;
    };
    restorable?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    restoreIsVisibleFunction?: (item: any) => boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    restoreValue?: (item: any) => unknown;
    rules?: () => Array<(value: string) => string | boolean>;
    showCurrency?: boolean;
    tooltip?: string | null;
    width?: number | 'auto';
}

export enum TableHeaderFilterType {
    Boolean = 'boolean',
    Date = 'date',
    Enum = 'enum',
    EnumMultiple = 'enumMultiple',
    Number = 'number',
    ResourceText = 'resourceText',
    Text = 'text'
}

export enum TableHeaderRenderer {
    Abc = 'abc',
    Date = 'date',
    DelayLength = 'delayLength',
    Flag = 'flag',
    Number = 'number',
    ProductNote = 'productNote',
    State = 'state',
    StockoutDate = 'stockoutDate',
    Text = 'text',
    User = 'user'
}
