












import { Component, Ref, Vue } from 'vue-property-decorator';
import appStore from '@/store/modules/AppStore';

@Component({})
export default class SalesPlanningWrapper extends Vue {
    @Ref('mainContainerRef')
    private readonly mainContainerRef!: HTMLElement;

    public mounted(): void {
        this.setElements();
    }

    // Sync Methods
    protected setElements(): void {
        this.$nextTick(() => {
            const paddingTop = parseInt(window.getComputedStyle(this.mainContainerRef).getPropertyValue('padding-top'));
            const paddingBottom = parseInt(window.getComputedStyle(this.mainContainerRef).getPropertyValue('padding-bottom'));
            appStore.setElements({
                globalFilterHeight: 0,
                mainContainerPadding: paddingTop + paddingBottom
            });
        });
    }
}
