import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import axios from 'axios';
import moment from 'moment';
import Vue from 'vue';

Vue.use(VueI18n);

const setLocale = (locale: string): void => {
    i18n.locale = locale;
    moment.locale(locale);
    const html = document.querySelector('html');
    if (html) {
        html.setAttribute('lang', locale);
    }
};

const fetchLocale = async (locale: string): Promise<void> => {
    try {
        const response = await axios.get<LocaleMessageObject>(`/i18n/${locale}.json`);
        if (isValidJSON(response.data)) {
            i18n.setLocaleMessage(locale, response.data);
            setLocale(locale);
        } else {
            throw new Error('NotValid');
        }
    } catch (error) {
        if ((error as Error)?.message === 'NotValid') {
            throw new Error(`An error occurred while loading locale. Not valid ${locale}.json!`);
        } else {
            throw new Error(`An error occurred while loading locale ${locale}.json!`);
        }
    }
};

const setI18n = async (locale: string, defaultLocale: string): Promise<void> => {
    try {
        locale = locale ? locale : navigator.language.split('-')[0];
        await fetchLocale(locale);
    } catch (error) {
        await fetchLocale(defaultLocale);
    }
};

const isValidJSON = (data: unknown): boolean => {
    try {
        switch (typeof data) {
            case 'string':
                return !!JSON.parse(data);
            case 'object':
                return !!JSON.parse(JSON.stringify(data));
        }
    } catch (e) {
        return false;
    }
    return false;
};

const i18n = new VueI18n({
    pluralizationRules: {
        cs: (count: number): number => {
            if (count < 0) {
                count = Math.abs(count);
            }
            if (count === 0) {
                return 0;
            } else if (count === 1) {
                return 1;
            } else if (count > 1 && count <= 4) {
                return 2;
            } else {
                return 3;
            }
        }
    }
});

export { i18n, fetchLocale, setI18n };
