












































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import messagesStore from '@/store/modules/MessagesStore';
import type { PaginationType } from '@/model/Interfaces/PaginationType';
import type { AnyValidateFunction } from 'ajv/dist/core';

@Component
export default class Pagination extends Vue {
    // Data
    @Prop({ default: true, required: false })
    protected canBeChanged!: boolean;

    @Prop({ default: false, required: false })
    protected dense!: boolean;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    protected originalPage!: number;

    @PropSync('pagination', { required: true, type: Object })
    protected paginationSync!: PaginationType;

    // Computed
    protected get canGoBack(): boolean {
        return !this.disabled && this.paginationSync.page > 1;
    }

    protected get canGoForward(): boolean {
        return !this.disabled && this.paginationSync.page < this.totalPages;
    }

    protected get totalPages(): number {
        return Math.ceil(this.paginationSync.total / this.paginationSync.pageSize) || 1;
    }

    protected onPageConfirm(event: any): void {
        const target = event.target as Event & { value: string };
        const page = parseInt(target.value) || 1;
        this.goTo(page);
        this.originalPage = page;
    }

    protected onPageEdit(): void {
        this.originalPage = this.paginationSync.page;
    }

    protected onPageEditInterrupt(): void {
        this.$set(this.paginationSync, 'page', this.originalPage);
    }

    /**
     * Sets new value for current page
     * page: x, relative: false = specific page
     * page: x, relative: true = current page + x
     * page: 1, relative: false = first page
     * page: 1, relative: true = next page
     * page: -1, relative: true = previous page
     * page: -1, relative: false = last page
     */
    protected goTo(page: number, relative = false): void {
        const paginate = (): void => {
            const pageIndex = relative ? this.paginationSync.page + page : page < 0 ? this.totalPages + 1 + page : page;
            if (pageIndex !== this.paginationSync.page) {
                const val = Math.max(1, Math.min(this.totalPages, pageIndex || 1));
                this.$set(this.paginationSync, 'page', val);
            }
        };
        if (this.canBeChanged) {
            paginate();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({ confirmCallbackFunc: paginate });
        }
    }
}
