












































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import type EnumMultipleFilterClass from '@/model/Data/Filters/EnumMultipleFilterClass';
import type { Resource } from '@bednic/json-api-client';
import type { TableHeader } from '@/model/Interfaces/TableHeader';

@Component
export default class EnumMultipleFilter extends Vue {
    // Data
    @Prop()
    protected confirmBtnDisabled!: boolean;

    @Prop({ required: true })
    protected currentFilter!: EnumMultipleFilterClass;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected header!: TableHeader;

    // Lifecycle Hooks
    public mounted(): void {
        this.onFilterChanged();
    }

    protected get filterEnumText(): string {
        return `${this.header.filterEnumText as string}`;
    }

    // Computed
    protected get items(): Array<Resource> {
        return ((this.header.filterStoreInit && this.header.filterStoreInit().items) || []) as Array<Resource>;
    }

    protected getItemColorFg(item?: Resource): string {
        return (item?.attributes?.colorFg as string) || 'table-filter-on';
    }

    protected getItemColorBg(item?: Resource): string {
        return (item?.attributes?.color as string) || (item?.attributes?.colorBg as string) || 'default';
    }

    // Emitters
    @Emit()
    protected filterChanged(): EnumMultipleFilterClass {
        return this.currentFilter;
    }

    // Event Handlers
    protected onEnterPressed(): void {
        if (!this.confirmBtnDisabled) {
            this.$emit('confirm');
        }
    }

    protected onFilterChanged(): void {
        this.filterChanged();
    }

    protected onRadioClicked(id: number): void {
        const radio = document.getElementById(`radio${id}`);
        if (id === 4) {
            this.currentFilter.value.enumsAll = this.items;
        }
        if (radio) {
            radio.focus();
        }
    }
}
