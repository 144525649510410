import { getModule, Module } from 'vuex-module-decorators';
import { CONSTANTS } from '@/constants';
import { DataStore } from '@/store/DataStore';
import EntityService from '@/services/EntityService';
import store from '@/store';
import type { User } from '@/model/Entity/User';

const module = 'userStore';

export interface UserStoreState {
    entityService: EntityService;
    items: Array<User>;
}

@Module({
    dynamic: true,
    name: module,
    namespaced: true,
    store
})
class UserStore extends DataStore<User> implements UserStoreState {
    public entityService = new EntityService(CONSTANTS.API.USERS);

    public get getByUsername(): (username: string) => User | null {
        return (username: string): User | null => {
            return this.items.find((item) => item.attributes.username === username) || null;
        };
    }
}

export default getModule(UserStore);
