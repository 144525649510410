









































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';

@Component({
    // eslint-disable-next-line no-undef
    components: { ConfirmationButtons },
    name: 'action-dialog'
})
export default class ActionDialog extends Vue {
    // Data
    @Prop({ default: false })
    protected confirmBtnDisabled!: boolean;

    @Prop({ default: '' })
    protected header!: string;

    @Prop({ default: false })
    protected isUpdating!: boolean;

    @PropSync('isDialogOpen', { default: false })
    protected isDialogOn!: boolean;

    // Event Handlers
    protected onCancel(): void {
        this.isDialogOn = false;
    }

    protected onConfirm(): void {
        this.$emit('confirm');
    }
}
