


























































import CellEditor from '@/components/common/Table/cellEditors/CellEditor';
import { Component } from 'vue-property-decorator'; // nosonar

@Component
export default class TextCellEditor extends CellEditor {}
