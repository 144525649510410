import { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import ColumnFilterClass from '@/model/Data/Filters/ColumnFilterClass';

interface EnumFilterValue {
    enums: Array<unknown>;
}

class EnumFilterClass extends ColumnFilterClass<EnumFilterValue> {
    public value: EnumFilterValue = {
        enums: []
    };

    public constructor(key: string, filter?: EnumFilterClass | EnumFilterValue) {
        super(key);
        if (filter instanceof EnumFilterClass) {
            this.value = { ...filter.value };
        } else if (filter) {
            this.value = { ...filter };
        }
    }

    public get filter(): Expression | null {
        if (this.value.enums.length) {
            const formattedArray = this.value.enums.map((item) => {
                return PrettyExpressionBuilder.equal(this.key, (item as { id: string }).id);
            });
            return PrettyExpressionBuilder.or(...formattedArray);
        } else {
            return null;
        }
    }

    public clone(): EnumFilterClass {
        return new EnumFilterClass(this.key, this);
    }
}

export default EnumFilterClass;
