








































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import ConfirmationButtons from '@/components/common/ConfirmationButtons.vue';
import messagesStore from '@/store/modules/MessagesStore';
import _ from 'lodash';

enum InputType {
    DateFrom = 'dateFrom',
    DateTo = 'dateTo'
}

@Component({ components: { ConfirmationButtons } })
export default class SemiGlobalDateFilter extends Vue {
    // Props
    @Prop({ default: () => true, required: false })
    protected canBeChanged!: boolean;

    @Prop({ default: false, required: true })
    protected dateRange!: Array<string>;

    @Prop({ default: false, required: true })
    protected defaultDateRange!: Array<string>;

    @Prop({ default: () => false, required: false, type: Boolean })
    protected disabled!: boolean;

    // Data
    protected activeInput: InputType = InputType.DateFrom;

    protected currentValue: Array<string> = [];

    protected inputType = InputType;

    protected isDialogOpened = false;

    protected shownMonth = '';

    // Computed
    protected get formattedDateRange(): Array<Date> {
        return this.dateRange.map((date: string) => {
            return new Date(date);
        });
    }

    protected get isDefaultDateSelected(): boolean {
        return _.isEqual(this.currentValue, this.defaultDateRange);
    }

    protected get isDirty(): boolean {
        return !_.isEqual(this.dateRange, this.currentValue);
    }

    protected get isValid(): boolean {
        return this.currentValue.length === 2 && this.currentValue.every((item) => item);
    }

    // Sync Methods
    protected setDefaultDate(): void {
        this.currentValue = this.defaultDateRange;
        this.dateRangeSelected();
    }

    protected changeShownMonth(isoDate: string): void {
        this.shownMonth = isoDate.slice(0, 7);
    }

    protected dateRangeSelected(): void {
        this.sortDateOrder();
        this.changeShownMonth(this.currentValue[0]);
    }

    protected sortDateOrder(): void {
        if (this.currentValue[0] > this.currentValue[1]) {
            this.currentValue = [this.currentValue[1], this.currentValue[0]];
        }
    }

    // Emitters
    @Emit('changeDateFilter')
    protected changeDateFilter(): { dateRange: Array<string>; isDefault: boolean } {
        return { dateRange: this.currentValue, isDefault: this.isDefaultDateSelected };
    }

    // Event Handlers
    protected onChangeToPwDate(): void {
        this.setDefaultDate();
    }

    protected onPickDate(date: string): void {
        if (this.activeInput === this.inputType.DateFrom) {
            this.currentValue = [];
            this.currentValue[0] = date;
            (this.$refs.dateToInput as { focus: () => void } & Vue).focus();
            this.activeInput = this.inputType.DateTo;
        } else {
            // Reset of dateTo as workaround for bug - changing this.dateRange as well
            const dateTo = this.currentValue[0];
            this.currentValue = [];
            this.currentValue[0] = dateTo;

            this.currentValue[1] = date;
            (this.$refs.dateFromInput as { focus: () => void } & Vue).focus();
            this.activeInput = this.inputType.DateFrom;
            this.$nextTick(() => this.dateRangeSelected());
        }
    }

    @Watch('dateRange', { immediate: true })
    protected onDateRangeChange(dateRange?: Array<string>): void {
        if (typeof dateRange !== 'undefined') {
            if (dateRange.length) {
                this.currentValue = dateRange;
            } else {
                this.currentValue = this.defaultDateRange;
            }
        }
    }

    protected onConfirm(): void {
        if (this.canBeChanged) {
            this.changeDateFilter();
            this.onClose();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    this.changeDateFilter();
                    this.onClose();
                }
            });
        }
    }

    protected onDateFromInputFocus(): void {
        this.activeInput = InputType.DateFrom;
    }

    protected onDateToInputFocus(): void {
        this.activeInput = InputType.DateTo;
    }

    protected onOpen(): void {
        this.isDialogOpened = true;
    }

    protected onCancel(): void {
        this.currentValue = this.dateRange;
        this.onClose();
    }

    protected onClose(): void {
        this.isDialogOpened = false;
    }
}
