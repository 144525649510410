import { getModule, Module } from 'vuex-module-decorators';
import type { ChangeListState } from '@/model/Entity/ChangeListState';
import { CONSTANTS } from '@/constants';
import { DataStore } from '@/store/DataStore';
import EntityService from '@/services/EntityService';
import store from '@/store';

const module = 'changeListStateStore';

export interface ChangeListStateStoreState {
    entityService: EntityService;
    items: Array<ChangeListState>;
}

@Module({
    dynamic: true,
    name: module,
    namespaced: true,
    store
})
class ChangeListStateStore extends DataStore<ChangeListState> implements ChangeListStateStoreState {
    public entityService = new EntityService(CONSTANTS.API.SP_CHANGE_LIST_STATES);
}

export default getModule(ChangeListStateStore);
