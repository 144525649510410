import ColumnFilterClass from '@/model/Data/Filters/ColumnFilterClass';
import type { Expression } from '@bednic/json-api-client';

export interface NumberFilterValue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emptyValues: Array<any>;
    filterNull: string;
    from: string;
    isPercent: boolean;
    to: string;
}

class NumberFilterClass extends ColumnFilterClass<NumberFilterValue> {
    public value: NumberFilterValue = {
        emptyValues: [null],
        filterNull: 'withNull',
        from: '',
        isPercent: false,
        to: ''
    };

    public constructor(key: string, filter?: NumberFilterClass | NumberFilterValue) {
        super(key);
        if (filter instanceof NumberFilterClass) {
            this.value = { ...filter.value };
        } else if (filter) {
            this.value = { ...filter };
        }
    }

    public get filter(): Expression | null {
        const from = this.transformToTrueNumber(this.value.from);
        const to = this.transformToTrueNumber(this.value.to);
        return this.getFilter(this.value, from, to);
    }

    public clone(): NumberFilterClass {
        return new NumberFilterClass(this.key, this);
    }

    protected transformToTrueNumber(number: string): string {
        if (number.length) {
            return this.value.isPercent ? String(Number(number) / 100) : number;
        } else {
            return number;
        }
    }
}

export default NumberFilterClass;
