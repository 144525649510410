


































































































































































































































import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import CompoundDocument, { ExpressionBuilder, Meta, PrettyExpressionBuilder, ResourceIdentifier } from '@bednic/json-api-client';
import { CONSTANTS } from '@/constants';
import ErrorService from '@/services/ErrorService/ErrorService';
import { i18n } from '@/plugins/i18n';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import type { NonTreeCategory } from '@/model/Entity/NonTreeCategory';
import type { Product } from '@/model/Entity/Product';
import type { SkuOrdering } from '@/model/Entity/SkuOrdering';
import type { Supplier } from '@/model/Entity/Supplier';
import userStore from '@/store/modules/UserStore';
import type { Warehouse } from '@/model/Entity/Warehouse';

@Component
export default class ProductCard extends Vue {
    // Data
    protected categories: Array<NonTreeCategory> = [];

    protected filteredWarehouseDebouncer = 0;

    protected filteredWarehouseMeta: Meta | undefined;

    protected filteredWarehouseName = '';

    protected isFilteredWarehouseLoading = false;

    protected isLoading = false;

    protected isNoteSaved = false;

    protected isOpen = false;

    protected isUpdatingProductNote = false;

    protected numberOfDisplayedSuppliers = 20;

    protected numberOfDisplayedWarehouses = 20;

    protected product: Product | null = null;

    protected productDocument: CompoundDocument<Product> | null = null;

    protected productNote = '';

    protected relationshipsLimit = 1000;

    protected skuOrderings: Array<SkuOrdering> = [];

    protected suppliers: Array<Supplier> = [];

    protected unfilteredWarehousesTotal = 0;

    protected warehouses: Array<Warehouse> = [];

    protected get canEditProducts(): boolean {
        return this.$ac.can(CONSTANTS.PERMISSIONS.UPDATE_PRODUCTS);
    }

    protected get categoriesList(): string {
        return this.categories.map((category) => category.attributes.name).join(' > ');
    }

    protected get isNoteDirty(): boolean {
        return !!this.product && this.product.attributes.note !== this.productNote;
    }

    protected get isNoteSavedMessage(): boolean {
        return !this.isNoteDirty && this.isNoteSaved;
    }

    protected get lowestCategoryName(): string {
        const levels = this.categories.map((category) => category.attributes.typeId);
        const lowestLevel = Math.max(...levels);
        const lowestCategory = this.categories.find((category) => category.attributes.typeId === lowestLevel);
        return lowestCategory ? lowestCategory.attributes.name : '';
    }

    protected get packageSizes(): string {
        return this.skuOrderings.map((skuOrdering) => skuOrdering.attributes.minimalQuantitySet).join(', ');
    }

    protected get suppliersList(): string {
        const names = this.suppliers.map((supplier) => supplier.attributes.name);
        return this.createShortenedList(names, this.numberOfDisplayedSuppliers);
    }

    protected get title(): string {
        return this.product
            ? `${this.$tc('components.productCard.title')}: ${this.product.attributes.shortcut as string} ${
                  this.product.attributes.name as string
              }`
            : '';
    }

    @Watch('isNoteDirty')
    protected onIsNoteDirtyChanged(isNoteDirty: boolean): void {
        if (isNoteDirty) {
            this.isNoteSaved = false;
            window.addEventListener('beforeunload', (event) => this.confirmLooseOfNoteChanges(event), { capture: true });
        } else {
            window.removeEventListener('beforeunload', (event) => this.confirmLooseOfNoteChanges(event), { capture: true });
        }
    }

    // Computed getters
    protected getUserName(userId: string): string {
        const user = userStore.getOne(userId);
        return user ? user.attributes.name : '';
    }

    // Synced methods
    public openProductInfo(productId: string): void {
        this.isOpen = true;
        void this.fetchData(productId);
    }

    protected createShortenedList(inputArray: Array<unknown>, maxItems: number): string {
        if (inputArray.length > maxItems) {
            inputArray.splice(maxItems);
            return `${inputArray.join(', ')}...`;
        } else {
            return inputArray.join(', ');
        }
    }

    protected confirmLooseOfNoteChanges(event: BeforeUnloadEvent): void {
        event.returnValue = `${i18n.tc('components.confirmBox.looseChanges').toString()}`;
    }

    @Emit()
    protected productUpdated(): boolean {
        return true;
    }

    // Event Handlers
    protected onCancel(): void {
        const cancelProductCard = (): void => {
            this.isOpen = false;
            this.product = null;
            this.isNoteSaved = false;
            this.productNote = '';
            this.filteredWarehouseName = '';
        };

        if (this.isNoteDirty) {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    cancelProductCard();
                }
            });
        } else {
            cancelProductCard();
        }
    }

    protected inputWarehouse(): void {
        clearTimeout(this.filteredWarehouseDebouncer);
        this.isFilteredWarehouseLoading = true;
        this.filteredWarehouseDebouncer = window.setTimeout(() => {
            void this.fetchFilteredWarehouses().then((warehouses) => {
                this.warehouses = warehouses;
            });
        }, 300);
    }

    protected async fetchFilteredWarehouses(): Promise<Array<Warehouse>> {
        if (this.product?.relationships.warehouses?.links?.related) {
            const otherDoc = new CompoundDocument<Warehouse>(this.product.relationships.warehouses.links.related as string, this.axios);
            otherDoc
                .filter()
                .where(
                    PrettyExpressionBuilder.contains(
                        ExpressionBuilder.toLower(ExpressionBuilder.field('name')).express(),
                        this.filteredWarehouseName ? this.filteredWarehouseName.toLowerCase() : ''
                    )
                )
                .addSortBy('name', false)
                .setLimit(50);
            await otherDoc.self();
            const { data, meta } = otherDoc;
            if (this.filteredWarehouseName === '' && meta?.total != null) {
                this.unfilteredWarehousesTotal = meta.total as number;
            }
            this.filteredWarehouseMeta = meta;
            this.isFilteredWarehouseLoading = false;

            return Array.isArray(data) ? data : data == null ? [] : [data];
        }
        return [];
    }

    // Async Methods
    protected async fetchProduct(productId: string): Promise<Product | null> {
        this.productDocument = new CompoundDocument<Product>(`${CONSTANTS.API.PRODUCTS}/${productId}`, this.axios);
        await this.productDocument.self();
        const data = this.productDocument.data;
        return Array.isArray(data) ? data[0] : data ? data : null;
    }

    protected async fetchRelationships<T extends ResourceIdentifier>(url: string): Promise<Array<T>> {
        const doc = new CompoundDocument<T>(url, this.axios);
        doc.filter().setLimit(this.relationshipsLimit);
        await doc.self();
        const data = doc.data;
        const meta = doc.meta;
        const items: Array<T> = [];
        if (Array.isArray(data) && data.length) {
            items.push(...data);
        }
        if (meta && meta.limit < meta.total) {
            const limit = meta.total as number;
            const total = meta.total as number;
            const totalPages = Math.ceil(total / limit);
            const promises = [];
            for (let page = 1; page <= totalPages; page++) {
                const otherDoc = new CompoundDocument<T>(url, this.axios);
                otherDoc
                    .filter()
                    .setLimit(limit)
                    .setOffset((page - 1) * limit);
                promises.push(otherDoc.self());
            }
            const documents = await Promise.all(promises);
            documents.forEach((doc) => {
                const data = doc.data;
                if (Array.isArray(data)) {
                    items.push(...data);
                }
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return Array.isArray(items) ? items : items ? [items] : [];
    }

    protected async fetchData(productId: string): Promise<void> {
        try {
            this.isLoading = true;
            this.product = await this.fetchProduct(productId);
            if (this.product) {
                this.productNote = this.product.attributes.note || '';
                this.categories = await this.fetchRelationships<NonTreeCategory>(
                    this.product.relationships.categories?.links?.related as string
                );
                this.skuOrderings = await this.fetchRelationships<SkuOrdering>(
                    this.product.relationships.skuOrderings?.links?.related as string
                );
                this.suppliers = await this.fetchRelationships<Supplier>(this.product.relationships.suppliers?.links?.related as string);
                this.filteredWarehouseName = '';
                this.warehouses = await this.fetchFilteredWarehouses();
                this.isFilteredWarehouseLoading = false;
            }
        } catch (error) {
            this.onCancel();
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorProductCard'),
                messageType: MessageType.Notification
            });
        } finally {
            this.isLoading = false;
        }
    }

    protected async fetchLink(productId: string, selectedWarehouseId: string): Promise<string> {
        const response = await this.axios.get<{ data: { link: string } }>('/translate/product-warehouse-to-pw2', {
            params: { product_id: productId, warehouse_id: selectedWarehouseId }
        });
        return response.data.data.link;
    }

    protected async onClickLink(warehouse: Warehouse): Promise<void> {
        try {
            if (this.product?.id && warehouse.id) {
                const finalLink = await this.fetchLink(this.product.id, warehouse.id);
                window.open(finalLink, '_blank');
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorFetchProductDetailLink'),
                messageType: MessageType.Notification
            });
        }
    }

    protected async updateNote(): Promise<void> {
        try {
            this.isUpdatingProductNote = true;
            this.productNote = this.productNote || '';
            if (this.product && this.productDocument) {
                const product: Product = {
                    ...this.product,
                    attributes: {
                        note: this.productNote
                    }
                };
                await this.productDocument.update(product);
                const data = this.productDocument.data;
                this.product = Array.isArray(data) ? data[0] : data ? data : null;
                this.isNoteSaved = true;
                this.productUpdated();
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorUpdateProductNote'),
                messageType: MessageType.Notification
            });
        } finally {
            this.isUpdatingProductNote = false;
        }
    }
}
