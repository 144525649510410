import Vuex, { createLogger, Plugin } from 'vuex';
import type { AbcValueStoreState } from '@/store/modules/AbcValueStore';
import type { AppStoreState } from '@/store/modules/AppStore';
import type { AuthorStoreState } from '@/store/modules/AuthorStore';
import type { ChangeListStateStoreState } from '@/store/modules/ChangeListStateStore';
import type { CustomerStoreState } from './modules/CustomerStore';
import type { FlagStoreState } from '@/store/modules/FlagStore';
import type { MessagesStoreState } from './modules/MessagesStore';
import type { ProductStoreState } from '@/store/modules/ProductStore';
import type { StateStoreState } from '@/store/modules/StateStore';
import type { UserStoreState } from '@/store/modules/UserStore';
import Vue from 'vue';

Vue.use(Vuex);

export interface RootState {
    abcValueStore: AbcValueStoreState;
    appStore: AppStoreState;
    authorStore: AuthorStoreState;
    changeListStateStore: ChangeListStateStoreState;
    customerStore: CustomerStoreState;
    flagStore: FlagStoreState;
    messagesStore: MessagesStoreState;
    productStore: ProductStoreState;
    stateStore: StateStoreState;
    userStore: UserStoreState;
}

const debugPlugins = [createLogger()];
const defaultPlugins: Array<Plugin<RootState>> = [];

export default new Vuex.Store<RootState>({
    plugins: process.env.VUE_APP_MODE !== 'production' ? [...debugPlugins, ...defaultPlugins] : defaultPlugins,
    strict: process.env.VUE_APP_MODE !== 'production'
});
