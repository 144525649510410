
























































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Chart, Point } from 'highcharts';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import CompoundDocument, { ExpressionBuilder, PrettyExpressionBuilder } from '@bednic/json-api-client';
import type { PwngLineChartSeries } from '@/model/types/PwngLineChart';
import { DependencyTypes } from '@/model/types/PwngLineChart';
import type { ForecastChange, ForecastOperandType } from '@/model/Entity/ForecastChange';
import type { NavigationGuardNext, Route } from 'vue-router';
import _ from 'lodash';
import appStore from '@/store/modules/AppStore';
import AutocompleteSelect from '@/components/common/AutocompleteSelect.vue';
import type { AxiosResponse } from 'axios';
import type { Category } from '@/model/Entity/Category';
import type { CategoryType } from '@/model/Entity/CategoryType';
import type { ChangeList } from '@/model/Entity/ChangeList';
import ChartEditingForm from '@/components/charts/ChartEditingForm.vue';
import type { ChartOptions } from '@/components/charts/ChartOptions';
import colors from 'vuetify/lib/util/colors';
import { CONSTANTS } from '@/constants';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import { Debounce } from 'vue-debounce-decorator';
import ErrorService from '@/services/ErrorService/ErrorService';
import filters from '@/filters/index';
import ForecastChanges from '@/components/salesPlanning/ForecastChanges.vue';
import ForecastNewChanges from '@/components/salesPlanning/ForecastNewChanges.vue';
import { i18n } from '@/plugins/i18n';
import LineChart from '@/components/charts/LineChart.vue';
import type { LocaleMessages } from 'vue-i18n';
import { Message } from '@/model/Classes/Message';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import moment from 'moment';
import type { RecursivePartial } from '@/model/types/PartialResource';
import type { UnsavedForecastChange } from '@/model/Entity/UnsavedForecastChange';
import UserSettingsService from '@/services/UserSettingsService';

Component.registerHooks(['beforeRouteLeave']);

enum SeriesIds {
    Forecast = 'forecast',
    History = 'history',
    NonPromoForecast = 'nonPromoForecast'
}

interface CurrentSettings {
    selectedCategory: Category | null;
    selectedCategoryType: CategoryType | null;
    selectedDateRange: Array<string>;
    selectedForecastOption: { text: string; value: string };
}

const module = CONSTANTS.MODULES.FORECAST;

@Component({
    components: {
        AutocompleteSelect,
        ChartEditingForm,
        DateRangePicker,
        ForecastChanges,
        ForecastNewChanges,
        LineChart
    }
})
export default class Forecast extends Vue {
    // Data
    @Ref('changeListSelectRef')
    protected changeListSelectRef!: HTMLElement;

    @Ref('chartEditingForm')
    protected chartEditingForm!: ChartEditingForm;

    @Ref('chart')
    protected chartReference!: { hideLoading: () => void; showLoading: () => void } & LineChart;

    @Ref('dateRangePicker')
    protected dateRangePicker!: { closeDialog: () => void } & DateRangePicker;

    @Ref('forecastChangesComponent')
    protected forecastChangesComponent!: { renewChanges: () => Promise<void> } & ForecastChanges;

    @Ref('mainContainerRef')
    private readonly mainContainerRef!: HTMLElement;

    @Ref('nameInputForm')
    protected nameInputForm!: { resetValidation: () => void } & Vue;

    @Ref('stepFiltersRef')
    protected stepFiltersRef!: HTMLElement;

    protected categories: Array<Category> = [];

    protected categoriesSearchValue = '';

    protected categoryTypes: Array<CategoryType> = [];

    protected changeLists: Array<ChangeList> = [];

    protected changeListsSearchValue = '';

    protected changes: Array<ForecastChange> = [];

    protected chart!: Chart | undefined;

    protected finalValue: number | string | null = 0;

    protected forecastOptions: Array<{ text: string; value: string }> = [
        {
            text: this.$tc(`components.salesPlanning.options.${CONSTANTS.API.OPTIONS.AMOUNT}`),
            value: CONSTANTS.API.OPTIONS.AMOUNT
        },
        {
            text: this.$tc(`components.salesPlanning.options.${CONSTANTS.API.OPTIONS.AMOUNT_NON_PROMO}`),
            value: CONSTANTS.API.OPTIONS.AMOUNT_NON_PROMO
        }
    ];

    protected isCategoriesLoadedAll = false;

    protected isCategoriesLoading = false;

    protected isCategoryTypesLoadedAll = false;

    protected isCategoryTypesLoading = false;

    protected isChangeListsLoadedAll = false;

    protected isChangeListsLoading = false;

    protected isChangeSending = false;

    protected isChartLoading = false;

    protected isCreateChangeListDialogOpen = false;

    protected isCreateChangeListFormValid = false;

    protected isCreateChangeListOnDragActivated = false;

    protected isCreateNewChangeListPending = false;

    protected lazyLoadingLimit = CONSTANTS.NUMBERS.LAZY_LOADING_LIMIT;

    protected newChangeListName = '';

    protected newSelectedCategory: Category | null | undefined;

    protected newSelectedCategoryType: CategoryType | null = null;

    protected newSelectedChangeList: ChangeList | null = null;

    protected newSelectedDateRange: Array<string> = [];

    protected newSelectedForecastOption: { text: string; value: string } | null = null;

    protected provisionalChange: Partial<UnsavedForecastChange['attributes']> | null = null;

    protected pwAmountForecast: Array<{ date: string; value: number }> = [];

    protected rules = {
        isUnique: (): string | boolean | LocaleMessages => {
            return this.uniqueValidationMessage;
        },
        maxLength: (value: string): string | boolean | LocaleMessages => {
            return (value && value.trim().length <= 100) || this.$tc('components.validationMessages.nameIsTooLong', 0, { value: 100 });
        },
        required: (value?: string): string | boolean | LocaleMessages =>
            (!!value && !!value.trim()) || this.$tc('components.validationMessages.nameCannotBeEmpty')
    };

    protected selectedCategory: Category | null = null;

    protected selectedCategoryType: CategoryType | null = null;

    protected selectedChangeList: ChangeList | null = null;

    protected selectedDateRange: Array<string> = [];

    protected selectedForecastOption: { text: string; value: string } = {
        text: this.$tc(`components.salesPlanning.options.${CONSTANTS.API.OPTIONS.AMOUNT}`),
        value: CONSTANTS.API.OPTIONS.AMOUNT
    };

    protected selectedPoints: Array<Point> = [];

    protected series: Array<PwngLineChartSeries> = [];

    protected seriesSetup: Array<PwngLineChartSeries> = [];

    protected seriesTemplates: Record<string, PwngLineChartSeries> = {
        forecast: {
            allowPointSelect: false,
            color: colors.indigo.base,
            custom: {
                originalSeries: {
                    allowPointSelect: false,
                    dashStyle: 'Dash',
                    dragDrop: {
                        dragMinY: 0,
                        draggableY: false
                    },
                    marker: {
                        symbol: 'diamond'
                    },
                    name: this.$tc('components.salesPlanning.forecastOriginal'),
                    zIndex: 4
                }
            },
            data: [],
            dragDrop: {
                dragMinY: 0,
                draggableY: false
            },
            id: SeriesIds.Forecast,
            marker: {
                symbol: 'square'
            },
            name: this.$tc('components.salesPlanning.forecastEdited'),
            type: 'line',
            zIndex: 7
        },
        history: {
            allowPointSelect: false,
            color: colors.cyan.base,
            data: [],
            dragDrop: {
                dragMinY: 0,
                draggableY: false
            },
            id: SeriesIds.History,
            marker: {
                symbol: 'circle'
            },
            name: this.$tc('components.salesPlanning.history'),
            type: 'line',
            zIndex: 1
        },
        nonPromoForecast: {
            allowPointSelect: false,
            color: colors.lightBlue.base,
            custom: {
                dependentSeries: [
                    {
                        dependentSeriesId: SeriesIds.Forecast,
                        type: DependencyTypes.Binded
                    }
                ],
                originalSeries: {
                    allowPointSelect: false,
                    dashStyle: 'Dash',
                    dragDrop: {
                        dragMinY: 0,
                        draggableY: false
                    },
                    marker: {
                        symbol: 'diamond'
                    },
                    name: this.$tc('components.salesPlanning.nonPromoForecastOriginal'),
                    zIndex: 3
                }
            },
            data: [],
            dragDrop: {
                dragMinY: 0,
                dragPrecisionY: 1,
                dragSensitivity: 1,
                draggableY: true
            },
            id: SeriesIds.NonPromoForecast,
            marker: {
                symbol: 'square'
            },
            name: this.$tc('components.salesPlanning.nonPromoForecastEdited'),
            type: 'line',
            zIndex: 8
        }
    };

    protected uniqueValidationMessage = '';

    protected unsavedChanges: Array<UnsavedForecastChange> = [];

    protected userSettingsManager = new UserSettingsService(this.axios);

    // Lifecycle Hooks
    public async created(): Promise<void> {
        await this.initSettings();
        await this.fetchCategoryTypes();
    }

    public mounted(): void {
        this.setElements();
    }

    // Navigation Guards
    public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Forecast>): void {
        if (this.isSeriesDirty) {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    next();
                }
            });
        } else {
            next();
        }
    }

    // Computed getters
    protected get changeListHeight(): number {
        const changeListMarginTop = 8;
        const betweenRowsMargin = 8;
        return (
            this.$vuetify.breakpoint.height -
            (appStore.elements.mainContainerPadding +
                appStore.elements.toolbarHeight +
                appStore.elements.changeListSelectHeight +
                changeListMarginTop +
                betweenRowsMargin)
        );
    }

    protected get changeListSelectHeight(): number {
        return appStore.elements.changeListSelectHeight;
    }

    protected get chartHeight(): number {
        const chartCardPadding = 24;
        const chartEditingFormHeight = this.selectedChangeList ? 56 : 0;
        return (
            this.$vuetify.breakpoint.height -
            (appStore.elements.mainContainerPadding +
                appStore.elements.toolbarHeight +
                appStore.elements.stepFilterHeight +
                chartEditingFormHeight +
                chartCardPadding)
        );
    }

    protected get chartOptions(): ChartOptions {
        return {
            chart: {
                height: this.chartHeight
            },
            export: true,
            legend: {
                align: 'right',
                layout: 'vertical',
                verticalAlign: 'middle'
            },
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    dragDrop: {
                        draggableY: true
                    },
                    marker: {
                        radius: 5
                    },
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },
            series: [],
            title: {
                text: this.$tc('components.salesPlanning.forecast')
            },
            tooltip: {
                formatter(options): string {
                    const localizedDate = filters.datetime(this.x, { month: 'long', year: '2-digit' });
                    const formattedValue = filters.number(this.y, { maximumFractionDigits: 2 });

                    const xAxisLabel = options.chart.xAxis[0].userOptions.title?.text as string;
                    const yAxisLabel = options.chart.yAxis[0].userOptions.title?.text as string;
                    return `${xAxisLabel}: <b>${localizedDate}</b><br/>` + `${yAxisLabel}: <b>${formattedValue}</b>`;
                }
            },
            xAxis: {
                dateTimeLabelFormats: {
                    month: {
                        main: '%B %Y'
                    }
                },
                plotLines: [
                    {
                        color: 'red',
                        label: {
                            text: this.$tc('components.salesPlanning.systemDate')
                        },
                        value: this.pwDateLine,
                        width: 2
                    }
                ],
                softMax: this.softMax,
                softMin: this.softMin,
                title: {
                    text: this.$tc('components.salesPlanning.date')
                },
                type: 'datetime'
            },
            yAxis: {
                allowDecimals: true,
                min: 0,
                softMax: 1000,
                softMin: 0,
                title: {
                    text: this.$tc('components.salesPlanning.units'),
                    textAlign: 'left'
                }
            }
        };
    }

    protected get currentSettings(): CurrentSettings {
        return {
            selectedCategory: this.selectedCategory,
            selectedCategoryType: this.selectedCategoryType,
            selectedDateRange: this.selectedDateRange,
            selectedForecastOption: this.selectedForecastOption
        };
    }

    /**
     * Returns 2 years range from pwDate
     */
    protected get defaultDateRange(): Array<string> {
        let from = '';
        let to = '';
        if (this.pwDate) {
            from = this.getFirstDayOfMonth(moment(this.pwDate).subtract(1, 'years').format('YYYY-MM-DD'));
            to = this.getLastDayOfMonth(moment(this.pwDate).add(1, 'years').format('YYYY-MM-DD'));
        }
        return [from, to];
    }

    protected get forecastDateRange(): { from: string; to: string } {
        let from = '';
        let to = '';

        if (this.selectedDateRange.length === 2 && this.pwDate) {
            if (moment(this.selectedDateRange[0]).isAfter(this.pwDate)) {
                from = this.getFirstDayOfMonth(this.selectedDateRange[0]);
                to = this.getLastDayOfMonth(this.selectedDateRange[1]);
            } else {
                from = this.getFirstDayOfMonth(this.pwDate);
                to = this.getLastDayOfMonth(this.selectedDateRange[1]);
            }
        }

        return { from, to };
    }

    /**
     * Returns selected date range with days - first day of month and last day of month
     */
    protected get formatedDateRange(): { from: string; to: string } {
        if (this.selectedDateRange.length === 2) {
            return {
                from: this.getFirstDayOfMonth(this.selectedDateRange[0]),
                to: this.getLastDayOfMonth(this.selectedDateRange[1])
            };
        }
        return { from: '', to: '' };
    }

    protected get globalFilterHeight(): number {
        return appStore.elements.globalFilterHeight;
    }

    protected get historyDateRange(): { from: string; to: string } {
        if (this.selectedDateRange.length === 2 && this.pwDate) {
            if (new Date(this.selectedDateRange[1]) < new Date(this.pwDate)) {
                return {
                    from: this.getFirstDayOfMonth(this.selectedDateRange[0]),
                    to: this.getLastDayOfMonth(this.selectedDateRange[1])
                };
            }
            return {
                from: this.getFirstDayOfMonth(this.selectedDateRange[0]),
                to: this.getLastDayOfMonth(this.pwDate[0])
            };
        }
        return { from: '', to: '' };
    }

    protected get isSeriesDirty(): boolean {
        return this.getIsDirtyBySeriesId(SeriesIds.Forecast) || this.getIsDirtyBySeriesId(SeriesIds.NonPromoForecast);
    }

    protected get originalValue(): number {
        if (this.selectedPoints.length && this.chart) {
            const values = this.selectedPoints.map((point: Point): number => {
                const foundSeries = this.getSeriesById(point.series.options.id as string, true);
                if (foundSeries && point?.index) {
                    const value = foundSeries.data[point.index].y;
                    return value ? value : 0;
                }
                return 0;
            });
            return values.reduce((total: number, num: number) => total + num);
        }
        return 0;
    }

    protected get pwDate(): string {
        return appStore.pwDate;
    }

    protected get pwDateLine(): number {
        return new Date(`${this.pwDate.substring(0, 8)}01`).getTime();
    }

    protected get softMax(): number {
        if (this.seriesSetup.length) {
            return (
                Math.max(...this.seriesSetup.map((series) => Math.max(...series.data.map((data) => data.x)))) ||
                new Date(this.formatedDateRange.from).getTime()
            );
        }
        return new Date(this.formatedDateRange.to).getTime();
    }

    protected get softMin(): number {
        if (this.seriesSetup.length) {
            return (
                Math.min(...this.seriesSetup.map((series) => Math.min(...series.data.map((data) => data.x)))) ||
                new Date(this.formatedDateRange.from).getTime()
            );
        }
        return new Date(this.formatedDateRange.from).getTime();
    }

    // Sync Methods
    protected changeFilter(): void {
        this.unselectAllPoints();
        this.unsavedChanges = [];
        this.selectedCategoryType = this.newSelectedCategoryType || this.selectedCategoryType;
        this.selectedCategory = this.newSelectedCategory === null ? null : this.newSelectedCategory || this.selectedCategory;
        this.selectedForecastOption = this.newSelectedForecastOption || this.selectedForecastOption;
        this.selectedDateRange = this.newSelectedDateRange.length ? this.newSelectedDateRange : this.selectedDateRange;
        void this.saveUserSettings();

        this.removeSeriesByIds([SeriesIds.Forecast, SeriesIds.History]);
        if (this.selectedCategoryType && this.selectedCategory && this.selectedDateRange.length === 2) {
            void this.fetchChartData(this.selectedCategory.id);
        } else {
            this.resetChartOptions();
        }
    }

    protected createChangeListObject(name: string): RecursivePartial<ChangeList> {
        return {
            attributes: {
                name
            },
            type: CONSTANTS.API.SP_CHANGE_LISTS
        };
    }

    protected addOrUpdateUnsavedChange(unsavedChangeAttributes: Partial<UnsavedForecastChange['attributes']>): void {
        const pwAmount = this.pwAmountForecast.find((pwAmount) => moment(pwAmount.date).isSame(unsavedChangeAttributes.date))?.value;

        const unsavedChange = {
            attributes: {
                categoryId: this.selectedCategory?.id,
                categoryTypeId: this.selectedCategory?.attributes.typeId,
                changeListId: this.selectedChangeList?.id,
                pwAmount,
                ...unsavedChangeAttributes
            },
            type: 'sp-changes'
        } as UnsavedForecastChange;

        // Filter out previous unsaved change for the same point
        const duplicityIndex = this.unsavedChanges.findIndex((change) =>
            moment(change.attributes.date).isSame(unsavedChange.attributes.date)
        );

        if (duplicityIndex >= 0) {
            this.unsavedChanges.splice(duplicityIndex, 1);
        }

        if (this.selectedCategory && this.selectedChangeList) {
            this.unsavedChanges.unshift(unsavedChange);
        }
    }

    protected getFirstDayOfMonth(date: string): string {
        return moment(date).startOf('month').format('YYYY-MM-DD');
    }

    protected getLastDayOfMonth(date: string): string {
        return moment(date).endOf('month').format('YYYY-MM-DD');
    }

    protected getIsDirtyBySeriesId(seriesId: SeriesIds): boolean {
        const editedSeries = this.getSeriesById(seriesId);
        const originalSeries = this.getSeriesById(seriesId, true);
        let isDirty = false;
        if (editedSeries && originalSeries) {
            isDirty = JSON.stringify(editedSeries.data) !== JSON.stringify(originalSeries.data);
        }
        return isDirty;
    }

    protected getSeriesById(id: string, isOriginal?: boolean): PwngLineChartSeries | undefined {
        if (isOriginal) {
            return this.series.find((series) => series.id === `${id}Original`);
        }
        return this.series.find((series) => series.id === id);
    }

    protected resetChartOptions(): void {
        this.seriesSetup = [];
    }

    protected removeSeriesByIds(seriesIds: Array<string>): void {
        seriesIds.forEach((id) => {
            this.seriesSetup = this.seriesSetup.filter((series) => series.id !== id);
        });
    }

    protected resetSelectedPointsToOriginal(): void {
        this.selectedPoints.forEach((point: Point): void => {
            const foundSeries = this.getSeriesById(point?.series.options.id as string);
            const originalSeries = this.getSeriesById(point?.series.options.id as string, true);
            if (foundSeries && originalSeries?.data) {
                foundSeries.data[point.index].y = _.cloneDeep(originalSeries.data[point.index].y);
                this.removeUnsavedChange(point.x);
            }
        });
    }

    protected restoreAllEditedSeriesToOriginal(): void {
        Object.values(SeriesIds).forEach((id) => {
            this.restoreEditedSeriesToOriginal(id);
        });
    }

    protected restoreEditedSeriesToOriginal(seriesId: string): void {
        const forecastEdited = this.getSeriesById(seriesId);
        const forecastOriginal = this.getSeriesById(seriesId, true);
        if (forecastEdited && forecastOriginal) {
            forecastEdited.data = _.cloneDeep(forecastOriginal.data);
        }
    }

    protected roundValueToTwoDecimals(value: number): number {
        return Math.round(value * 100) / 100;
    }

    protected setElements(): void {
        this.$nextTick(() => {
            appStore.setElements({
                changeListSelect: this.changeListSelectRef.clientHeight,
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                chartEditingFormHeight: !this.chartEditingForm ? 0 : this.chartEditingForm.$el.clientHeight,
                globalFilterHeight: this.stepFiltersRef.clientHeight
            });
        });
    }

    protected setIsSeriesEditable(value: boolean): void {
        this.selectedChangeList = this.newSelectedChangeList;
        if (!value) {
            this.unselectAllPoints();
            this.restoreAllEditedSeriesToOriginal();
            this.unsavedChanges = [];
        }

        if (this.seriesTemplates.nonPromoForecast) {
            this.seriesTemplates.nonPromoForecast.allowPointSelect = value;
        }
        this.seriesSetup = this.seriesSetup.concat([this.seriesTemplates.history, this.seriesTemplates.forecast]);
    }

    protected updateSinglePoint(changeType: ForecastOperandType, operand?: number): void {
        if (this.selectedPoints.length === 1 && this.selectedChangeList) {
            let result = 0;
            if (this.finalValue && this.finalValue > 0) {
                result = Number(this.finalValue);
            } else if (this.finalValue === null || this.finalValue === '') {
                result = this.originalValue;
            }
            const foundSeries = this.getSeriesById(this.selectedPoints[0].series.options.id as string);
            if (foundSeries) {
                foundSeries.data[this.selectedPoints[0].index].y = result;
            }
            let amount = Number(this.finalValue);
            if (operand) {
                amount = operand;
            }
            if (amount && this.finalValue && this.selectedCategory) {
                this.addOrUpdateUnsavedChange({
                    amount,
                    amountType: 'non-promo',
                    changedAmount: Number(this.finalValue),
                    date: moment(this.selectedPoints[0].x).format(),
                    kind: changeType,
                    originalAmount: 0
                });
            }
        }
    }

    protected removeUnsavedChange(date: number): void {
        const stringDate = moment(date).format();
        this.unsavedChanges = this.unsavedChanges.filter((change) => {
            return !(change.attributes.categoryId === this.selectedCategory?.id && change.attributes.date === stringDate);
        });
    }

    protected changeSinglePointByPercentageOrNumerical(operand: number, changeType: 'numerical' | 'percentage'): void {
        const foundSeries = this.getSeriesById(this.selectedPoints[0].series.options.id as string);
        if (foundSeries && this.selectedChangeList && this.selectedCategory) {
            const foundPoint = foundSeries.data[this.selectedPoints[0].index];
            if (!operand) {
                // Absolute
                this.removeUnsavedChange(foundPoint.x);
                foundPoint.y = this.originalValue;
            } else {
                let result = 0;
                let amount = operand;
                if (changeType === 'percentage') {
                    // Percentage
                    if (this.finalValue && this.finalValue >= 0) {
                        result = this.originalValue * (1 + amount / 100);
                        amount = operand / 100;
                    } else {
                        amount = -1; // = -100%
                    }
                } else {
                    // Numeric
                    if (this.finalValue && this.finalValue >= 0) {
                        result = this.originalValue + operand;
                    } else {
                        amount = -this.originalValue;
                    }
                }
                foundPoint.y = result;

                this.addOrUpdateUnsavedChange({
                    amount,
                    amountType: 'non-promo',
                    changedAmount: result,
                    date: moment(this.selectedPoints[0].x).format(),
                    kind: changeType,
                    originalAmount: this.originalValue
                });
            }
        }
    }

    protected changeSinglePointToAbsolute(): void {
        const foundSeries = this.getSeriesById(this.selectedPoints[0].series.options.id as string);
        if (foundSeries && this.selectedChangeList && this.selectedCategory) {
            const foundPoint = foundSeries.data[this.selectedPoints[0].index];
            if (this.finalValue === null || this.finalValue === '' || this.finalValue == this.originalValue) {
                foundPoint.y = this.originalValue;
                this.removeUnsavedChange(foundPoint.x);
            } else {
                let result = 0;
                if (this.finalValue && this.finalValue >= 0) {
                    result = Number(this.finalValue);
                }
                foundPoint.y = result;
                this.addOrUpdateUnsavedChange({
                    amount: Number(result),
                    amountType: 'non-promo',
                    changedAmount: Number(result),
                    date: moment(foundPoint.x).format(),
                    kind: 'absolute',
                    originalAmount: this.originalValue
                });
            }
        }
    }

    protected changeMultiplePointsByPercentage(operand: number): void {
        this.selectedPoints.forEach((point: Point) => {
            const id = point.series.options.id as string;
            const foundSeries = this.getSeriesById(id);
            const originalSeries = this.getSeriesById(id, true);

            if (foundSeries && originalSeries && this.selectedChangeList && this.selectedCategory) {
                const foundPoint = foundSeries.data[point.index];
                const originalPoint = originalSeries.data[point.index];
                if (!operand) {
                    foundPoint.y = originalPoint.y;
                    this.removeUnsavedChange(foundPoint.x);
                } else {
                    let amount = operand / 100;
                    if (this.finalValue && this.finalValue >= 0) {
                        foundPoint.y = originalPoint.y + originalPoint.y * amount;
                    } else {
                        foundPoint.y = 0;
                        amount = -1;
                    }

                    const changedPointIndex = this.selectedPoints.findIndex((point) => moment(point.x).isSame(foundPoint.x));

                    this.addOrUpdateUnsavedChange({
                        amount,
                        amountType: 'non-promo',
                        changedAmount: foundPoint.y,
                        date: moment(this.selectedPoints[changedPointIndex].x).format(),
                        kind: 'percentage',
                        originalAmount: originalPoint.y
                    });
                }
            }
        });
    }

    protected changeMultiplePointsByNumericalOrToAbsolute(operand?: number): void {
        const difference = operand ? operand : Number(this.finalValue) - this.originalValue;
        const fairShare = difference / this.selectedPoints.length;
        const originalValues = this.selectedPoints.map((point: Point): number => {
            const foundOriginalSeries = this.getSeriesById(point.series.options.id as string, true);
            if (foundOriginalSeries) {
                const value = foundOriginalSeries.data[point.index].y;
                return value ? Number(value) : 0;
            }
            return 0;
        });
        const sum = originalValues.reduce((a: number, b: number): number => a + b);
        this.selectedPoints.forEach((point) => {
            const foundSeries = this.getSeriesById(point.series.options.id as string);
            const originalSeries = this.getSeriesById(point.series.options.id as string, true);
            if (foundSeries && originalSeries && this.selectedCategory && this.selectedChangeList) {
                const foundPoint = foundSeries.data[point.index];
                const originalPoint = originalSeries.data[point.index];
                if (!difference) {
                    foundPoint.y = originalPoint.y;
                    this.removeUnsavedChange(foundPoint.x);
                } else {
                    const ratio = originalPoint.y / sum;
                    const proportionalShare = difference * ratio;
                    const share = originalValues.every((value) => value === 0) ? fairShare : proportionalShare;
                    let amount = share;
                    if (this.finalValue && this.finalValue >= 0) {
                        foundPoint.y = originalPoint.y + share;
                        if (!operand) {
                            amount = foundPoint.y;
                        }
                    } else {
                        foundPoint.y = 0;
                        if (!operand) {
                            amount = 0;
                        } else {
                            amount = -originalPoint.y;
                        }
                    }
                    this.addOrUpdateUnsavedChange({
                        amount,
                        amountType: 'non-promo',
                        changedAmount: foundPoint.y,
                        date: moment(point.x).format(),
                        kind: operand ? 'numerical' : 'absolute',
                        originalAmount: originalPoint.y
                    });
                }
            }
        });
    }

    protected resetForm(): void {
        this.uniqueValidationMessage = '';
        this.newChangeListName = '';
        (this.nameInputForm as any).reset();
        (this.nameInputForm as any).resetValidation();
    }

    protected unselectAllPoints(): void {
        this.selectedPoints.forEach((point: Point) => {
            point.select(false, false);
        });
    }

    // Event Handlers
    protected onCategoryTypesScrollToBottom(): void {
        this.fetchCategoryTypes();
    }

    protected onCategoriesScrollToBottom(): void {
        this.fetchCategories(this.categoriesSearchValue);
    }

    protected onChangeListsScrollToBottom(): void {
        this.fetchChangeLists(this.changeListsSearchValue);
    }

    protected onDragDrop(): void {
        if (!this.selectedChangeList) {
            this.isCreateChangeListOnDragActivated = true;
            this.isCreateChangeListDialogOpen = true;
            if (this.selectedCategory) {
                this.provisionalChange = {
                    amount: Number(this.finalValue),
                    amountType: 'non-promo',
                    changedAmount: Number(this.finalValue),
                    date: moment(this.selectedPoints[0].x).format(),
                    kind: 'absolute',
                    originalAmount: this.originalValue
                };
            }
        } else {
            this.changeSinglePointToAbsolute();
        }
    }

    protected onCreateChangeListDialogCancel(): void {
        this.isCreateChangeListDialogOpen = false;
        this.resetForm();
        if (this.isCreateChangeListOnDragActivated) {
            this.restoreAllEditedSeriesToOriginal();
            this.unselectAllPoints();
            this.provisionalChange = null;
            this.isCreateChangeListOnDragActivated = false;
        }
    }

    protected onDateRangeChange(value: Array<string>): void {
        this.newSelectedDateRange = [this.getFirstDayOfMonth(value[0]), this.getLastDayOfMonth(value[1])];
        this.onFilterChange();
    }

    protected onFilterChange(): void {
        if (this.isSeriesDirty) {
            void messagesStore.dispatchLeaveUpdateConfirm({
                cancelCallbackFunc: (): void => {
                    // Force Update
                    this.selectedCategoryType = { ...this.selectedCategoryType } as CategoryType;
                    this.selectedCategory = { ...this.selectedCategory } as Category;
                    this.selectedForecastOption = { ...this.selectedForecastOption } as { text: string; value: string };
                    this.selectedDateRange = [...this.selectedDateRange] as Array<string>;
                },
                confirmCallbackFunc: (): void => {
                    this.changeFilter();
                }
            });
        } else {
            this.changeFilter();
        }
    }

    protected onPointDrag(value: number): void {
        this.chartEditingForm?.resetOperand();
        this.finalValue = this.roundValueToTwoDecimals(value);
    }

    protected onRendered(chart: Chart): void {
        this.chart = chart;
    }

    @Watch('selectedPoints')
    protected onSelectedPointsChanged(): void {
        if (this.selectedPoints.length) {
            const values = this.selectedPoints.map((point: Point): number => {
                const foundSeries = this.series.find((series) => point.series.options.id === series.id);
                if (foundSeries) {
                    const value = foundSeries.data[point.index].y;
                    return value ? value : 0;
                }
                return 0;
            });
            this.finalValue = this.roundValueToTwoDecimals(values.reduce((total: number, num: number) => total + num));
        } else {
            this.finalValue = 0;
        }
    }

    protected onRestoreAllPoints(): void {
        messagesStore.sendMessage(
            new Message(this.$tc('components.confirmBox.restoreAllPointsTitle'), this.$tc('components.confirmBox.restoreAllPointsDetail'), {
                actions: {
                    confirmBtnColor: 'warning',
                    confirmBtnText: this.$tc('components.confirmBox.restoreAllPointsConfirm'),
                    confirmFunc: (): void => {
                        this.unselectAllPoints();
                        this.restoreAllEditedSeriesToOriginal();
                        this.unsavedChanges = [];
                    }
                },
                messageLevel: MessageLevel.Warning,
                messageType: MessageType.Dialog
            })
        );
    }

    protected onRestoreSelectedPoints(): void {
        this.resetSelectedPointsToOriginal();
    }

    protected onCategoriesSearchChange(): void {
        if (!this.selectedCategory) {
            this.categories = [];
            this.isCategoriesLoadedAll = false;
            this.isCategoriesLoading = true;
        }
    }

    protected onChangeListsSearchChange(): void {
        if (!this.selectedChangeList) {
            this.changeLists = [];
            this.isChangeListsLoadedAll = false;
            this.isChangeListsLoading = true;
        }
    }

    protected onCategoriesSearchDebouncedChange(search: string): void {
        void this.fetchCategories(search);
    }

    protected onChangeListsSearchDebouncedChange(search: string): void {
        void this.fetchChangeLists(search);
    }

    protected onDefaultDateBtnClick(): void {
        this.onDateRangeChange(this.defaultDateRange);
        this.dateRangePicker.closeDialog();
    }

    protected onSelectedCategoryChange(value: Category): void {
        this.newSelectedCategory = value;
        this.onFilterChange();
    }

    protected onPromoSelectChange(value: { text: string; value: string }): void {
        this.newSelectedForecastOption = value;
        this.onFilterChange();
    }

    protected onFinalValueEdited(): void {
        if (this.selectedPoints.length > 1) {
            this.changeMultiplePointsByNumericalOrToAbsolute();
        } else if (this.selectedPoints.length === 1) {
            this.changeSinglePointToAbsolute();
        }
    }

    protected onEditingFormChanged(params: { operand: number; type: 'numerical' | 'percentage' }): void {
        if (this.selectedPoints.length > 1) {
            if (params.type === 'percentage') {
                this.changeMultiplePointsByPercentage(params.operand);
            } else {
                this.changeMultiplePointsByNumericalOrToAbsolute(params.operand);
            }
        } else if (this.selectedPoints.length === 1) {
            this.changeSinglePointByPercentageOrNumerical(params.operand, params.type);
        }
    }

    protected onSelectedCategoryTypeChange(value: CategoryType): void {
        this.newSelectedCategoryType = value;
        this.newSelectedCategory = null;
        this.categories = [];
        this.isCategoriesLoadedAll = false;
        this.onFilterChange();
        void this.fetchCategories();
    }

    protected onSelectedChangeListChange(value: ChangeList | null): void {
        if (value) {
            this.newSelectedChangeList = value;
            if (this.isCreateChangeListOnDragActivated) {
                this.isCreateChangeListOnDragActivated = false;
                this.setIsSeriesEditable(true);
            } else {
                // fetch changeList data
                if (this.isSeriesDirty) {
                    void messagesStore.dispatchLeaveUpdateConfirm({
                        cancelCallbackFunc: (): void => {
                            // Force Update
                            this.selectedChangeList = { ...this.selectedChangeList } as ChangeList;
                        },
                        confirmCallbackFunc: (): void => {
                            this.unsavedChanges = [];
                            this.setIsSeriesEditable(true);
                            // Refresh Forecast
                            void this.fetchChartData(this.selectedCategory?.id);
                        }
                    });
                } else {
                    this.setIsSeriesEditable(true);
                    // Refresh Forecast
                    void this.fetchChartData(this.selectedCategory?.id);
                }
            }
        } else {
            this.newSelectedChangeList = null;
            if (this.isSeriesDirty) {
                void messagesStore.dispatchLeaveUpdateConfirm({
                    cancelCallbackFunc: (): void => {
                        // Force Update
                        this.selectedChangeList = { ...this.selectedChangeList } as ChangeList;
                    },
                    confirmCallbackFunc: (): void => {
                        // Refresh Forecast
                        this.setIsSeriesEditable(false);
                        void this.fetchChartData(this.selectedCategory?.id);
                    }
                });
            } else {
                // Refresh Forecast
                this.setIsSeriesEditable(false);
                void this.fetchChartData(this.selectedCategory?.id);
            }
        }
    }

    @Debounce(CONSTANTS.NUMBERS.AUTOCOMPLETE_DEBOUNCE)
    protected async checkChangeListName(value: string): Promise<void> {
        const doc = new CompoundDocument(CONSTANTS.API.SP_CHANGE_LISTS, this.axios);
        doc.filter().where(PrettyExpressionBuilder.equal('name', value));
        try {
            await doc.self();
            const isValid = Array.isArray(doc.data) && doc.data.length === 0;
            if (isValid) {
                this.uniqueValidationMessage = '';
            } else {
                this.uniqueValidationMessage = this.$tc('components.validationMessages.unique');
            }
        } catch (error) {
            this.uniqueValidationMessage = '';
        }
    }

    protected async onConfirmChanges(): Promise<void> {
        if (!this.selectedChangeList?.id || !this.selectedCategory?.id) {
            return;
        }

        this.isChangeSending = true;
        if (this.seriesTemplates.nonPromoForecast.dragDrop) {
            this.seriesTemplates.nonPromoForecast.dragDrop.draggableY = false;
        }

        const savedChangesToCompare = new CompoundDocument(CONSTANTS.API.SP_CHANGES, this.axios);

        savedChangesToCompare
            .filter()
            .where(PrettyExpressionBuilder.equal('changeListId', this.selectedChangeList.id))
            .andWhere(PrettyExpressionBuilder.equal('categoryId', this.selectedCategory.id))
            .andWhere(
                PrettyExpressionBuilder.or(
                    ...this.unsavedChanges.map(({ attributes }) => PrettyExpressionBuilder.equal('date', moment(attributes.date).format()))
                )
            );

        // Fetch all already created Changes that collide with with unsavedChanges
        const alreadySavedChanges = (await savedChangesToCompare.self()).data as Array<ForecastChange>;

        // Determine which Changes will be patched
        const willBeUpdated = alreadySavedChanges.filter(({ attributes }) =>
            this.unsavedChanges.some((unsavedChange) => moment(unsavedChange.attributes.date).isSame(moment(attributes.date)))
        );

        this.seriesTemplates.nonPromoForecast.allowPointSelect = false;

        await Promise.allSettled(
            // CREATE NEW CHANGES
            this.unsavedChanges.map(async (unsavedChange) => {
                const savedChange = willBeUpdated.find(({ attributes }) => moment(attributes.date).isSame(unsavedChange.attributes.date));

                if (savedChange && Math.abs(unsavedChange.attributes.changedAmount - savedChange.attributes.pwAmount) < 0.01) {
                    // Delete Change
                    await this.deleteChange(savedChange).catch(async (error) => {
                        await ErrorService.dispatch(error, {
                            context: this.$tc('messages.errorChangesDelete'),
                            messageType: MessageType.Notification
                        });
                    });
                } else if (savedChange) {
                    // Update Change
                    await this.updateChange(savedChange, unsavedChange).catch(async (error) => {
                        await ErrorService.dispatch(error, {
                            context: this.$tc('messages.errorChangesUpdate'),
                            messageType: MessageType.Notification
                        });
                    });
                } else {
                    // Create Change
                    await this.createChange(unsavedChange).catch(async (error) => {
                        await ErrorService.dispatch(error, {
                            context: this.$tc('messages.errorChangesCreate'),
                            messageType: MessageType.Notification
                        });
                    });
                }
            })
        );

        // Refresh Forecast
        await this.fetchChartData(this.selectedCategory.id);

        this.isChangeSending = false;
        if (this.seriesTemplates.nonPromoForecast.dragDrop) {
            this.seriesTemplates.nonPromoForecast.dragDrop.draggableY = true;
        }

        // Renew Selected ChangeList
        await this.forecastChangesComponent.renewChanges();
    }

    protected async createChange(unsavedChange: UnsavedForecastChange): Promise<void> {
        const unsavedChangeDocument = new CompoundDocument<ForecastChange>(`${CONSTANTS.API.SP_CHANGES}`, this.axios);

        const createChange = {
            attributes: _.pick(
                unsavedChange.attributes,
                'amount',
                'amountType',
                'categoryId',
                'changeListId',
                'date',
                'originalAmount',
                'kind'
            ),
            type: 'sp-changes'
        };

        return unsavedChangeDocument.create(createChange as ForecastChange).then((unsavedChange) => {
            const index = this.unsavedChanges.findIndex((change) => {
                return moment(change.attributes.date).isSame((unsavedChange.data as ForecastChange).attributes.date);
            });
            this.unsavedChanges.splice(index, 1);
        });
    }

    protected async updateChange(savedChange: ForecastChange, unsavedChange: UnsavedForecastChange): Promise<void> {
        const unsavedChangeDocument = new CompoundDocument<ForecastChange>(`${CONSTANTS.API.SP_CHANGES}/${savedChange.id}`, this.axios);

        const updateChange = {
            attributes: _.pick(unsavedChange.attributes, 'amount', 'kind'),
            id: savedChange.id,
            type: 'sp-changes'
        };

        await unsavedChangeDocument.update(updateChange as ForecastChange).then((unsavedChange) => {
            const index = this.unsavedChanges.findIndex((change) =>
                moment(change.attributes.date).isSame((unsavedChange.data as ForecastChange).attributes.date)
            );
            this.unsavedChanges.splice(index, 1);
        });
    }

    protected async deleteChange(savedChange: ForecastChange): Promise<void> {
        const unsavedChangeDocument = new CompoundDocument<ForecastChange>(`${CONSTANTS.API.SP_CHANGES}/${savedChange.id}`, this.axios);
        await unsavedChangeDocument.delete();
        const index = this.unsavedChanges.findIndex((change) => {
            return moment(change.attributes.date).isSame(savedChange.attributes.date);
        });
        this.unsavedChanges.splice(index, 1);
    }

    // Async Methods
    protected async fetchCategories(search?: string): Promise<void> {
        if (this.selectedCategoryType) {
            try {
                this.isCategoriesLoading = true;
                const doc = new CompoundDocument<Category>(CONSTANTS.API.SP_CATEGORIES, this.axios);
                doc.filter().setLimit(this.lazyLoadingLimit);
                doc.filter().setOffset(this.categories.length);
                const filter = PrettyExpressionBuilder.and(
                    ...[
                        PrettyExpressionBuilder.equal('typeId', this.selectedCategoryType.id),
                        PrettyExpressionBuilder.contains(
                            ExpressionBuilder.toLower(ExpressionBuilder.field('name')).express(),
                            search ? search.toLowerCase() : ''
                        )
                    ]
                );
                doc.addCustomQueryParam('filter', filter.express());
                await doc.self();
                if (Array.isArray(doc.data) && doc.data.length < this.lazyLoadingLimit) {
                    this.isCategoriesLoadedAll = true;
                }
                if (Array.isArray(doc.data)) {
                    this.categories = [...this.categories, ...doc.data];
                }
            } catch (error) {
                await ErrorService.dispatch(error, {
                    context: this.$tc('messages.errorCategoriesLoading'),
                    messageType: MessageType.Notification
                });
            } finally {
                this.isCategoriesLoading = false;
            }
        }
    }

    protected async fetchChangeLists(search?: string): Promise<void> {
        try {
            this.isChangeListsLoading = true;
            const doc = new CompoundDocument<ChangeList>(CONSTANTS.API.SP_CHANGE_LISTS, this.axios);
            doc.filter().setLimit(this.lazyLoadingLimit);
            doc.filter().setOffset(this.categories.length);
            const autocomplteFilter = PrettyExpressionBuilder.contains(
                ExpressionBuilder.toLower(ExpressionBuilder.field('name')).express(),
                search ? search.toLowerCase() : ''
            );
            const filterUser = PrettyExpressionBuilder.equal('authorId', appStore.user?.id);
            doc.addCustomQueryParam('filter', PrettyExpressionBuilder.and(...[autocomplteFilter, filterUser]).express());
            await doc.self();
            if (Array.isArray(doc.data) && doc.data.length < this.lazyLoadingLimit) {
                this.isChangeListsLoadedAll = true;
            }
            if (Array.isArray(doc.data)) {
                this.changeLists = [...this.changeLists, ...doc.data];
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorChangeListsLoading'),
                messageType: MessageType.Notification
            });
        } finally {
            this.isChangeListsLoading = false;
        }
    }

    protected async fetchCategoryTypes(): Promise<void> {
        try {
            this.isCategoryTypesLoading = true;
            const doc = new CompoundDocument<CategoryType>(CONSTANTS.API.SP_CATEGORY_TYPES, this.axios);
            doc.filter().setLimit(this.lazyLoadingLimit);
            doc.filter().setOffset(this.categoryTypes.length);
            await doc.self();
            if (Array.isArray(doc.data) && doc.data.length < this.lazyLoadingLimit) {
                this.isCategoryTypesLoadedAll = true;
            }
            if (Array.isArray(doc.data)) {
                this.categoryTypes = [...this.categoryTypes, ...doc.data];
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorCategoryTypesLoading'),
                messageType: MessageType.Notification
            });
        } finally {
            this.isCategoryTypesLoading = false;
        }
    }

    protected async fetchChartData(categoryId?: string): Promise<void> {
        if (!categoryId) {
            return;
        }
        try {
            this.chartReference.showLoading();
            this.isChartLoading = true;
            this.seriesSetup = [];
            this.seriesTemplates.history.data = [];
            this.seriesTemplates.forecast.data = [];
            this.unselectAllPoints();
            this.seriesTemplates.nonPromoForecast.data = [];
            const shouldFetchHistory = new Date(this.selectedDateRange[0]) < new Date(this.pwDate);
            const shouldFetchForecast = new Date(this.selectedDateRange[1]) > new Date(this.pwDate);
            if (shouldFetchHistory) {
                const historyResponse = await this.fetchHistory(categoryId);
                this.seriesTemplates.history.data = historyResponse.data.data.map((item: { date: string; value: number }) => {
                    return { x: new Date(item.date).getTime(), y: item.value };
                });
            } else {
                this.seriesTemplates.history.data = [];
            }
            this.seriesSetup = this.seriesSetup.concat([this.seriesTemplates.history]);
            if (shouldFetchForecast) {
                // NON-PROMO
                if (this.selectedForecastOption.value === CONSTANTS.API.OPTIONS.AMOUNT_NON_PROMO) {
                    const nonPromoForecastResponse = await this.fetchForecast(
                        categoryId,
                        CONSTANTS.API.OPTIONS.AMOUNT_NON_PROMO,
                        this.selectedChangeList
                    );
                    this.seriesTemplates.nonPromoForecast.data = nonPromoForecastResponse.data.data.map(
                        (item: { date: string; value: number }) => ({ x: new Date(item.date).getTime(), y: item.value })
                    );
                    this.seriesSetup = this.seriesSetup.concat([this.seriesTemplates.nonPromoForecast]);
                }

                // PW original suggestion - this could be a new serie in future
                this.pwAmountForecast = (await this.fetchForecast(categoryId, CONSTANTS.API.OPTIONS.AMOUNT_NON_PROMO)).data.data;

                if (this.selectedForecastOption.value === CONSTANTS.API.OPTIONS.AMOUNT) {
                    const forecastResponse = await this.fetchForecast(categoryId, CONSTANTS.API.OPTIONS.AMOUNT, this.selectedChangeList);
                    this.seriesTemplates.forecast.data = forecastResponse.data.data.map((item: { date: string; value: number }) => {
                        return { x: new Date(item.date).getTime(), y: item.value };
                    });
                    const nonPromoForecastResponse = (
                        await this.fetchForecast(categoryId, CONSTANTS.API.OPTIONS.AMOUNT_NON_PROMO, this.selectedChangeList)
                    ).data;

                    this.seriesTemplates.nonPromoForecast.data = nonPromoForecastResponse.data.map(
                        (item: { date: string; value: number }) => ({ x: new Date(item.date).getTime(), y: item.value })
                    );
                    this.seriesSetup = this.seriesSetup.concat([this.seriesTemplates.nonPromoForecast, this.seriesTemplates.forecast]);
                }
            } else {
                this.seriesTemplates.forecast.data = [];
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.errorFetchForecast'),
                messageType: MessageType.Notification
            });
        } finally {
            this.isChartLoading = false;
            this.chartReference.hideLoading();
        }
    }

    protected async fetchHistory(categoryId: string): Promise<AxiosResponse<{ data: Array<{ date: string; value: number }> }>> {
        const params = this.historyDateRange;
        return this.axios.get<{ data: Array<{ date: string; value: number }> }>(`${CONSTANTS.API.SP_HISTORY}/${categoryId}`, {
            params: params
        });
    }

    protected async fetchForecast(
        categoryId: string,
        option: string,
        selectedChangeList?: ChangeList | null
    ): Promise<AxiosResponse<{ data: Array<{ date: string; value: number }> }>> {
        return this.axios.get<{ data: Array<{ date: string; value: number }> }>(`${CONSTANTS.API.SP_FORECAST}/${option}/${categoryId}`, {
            params: {
                ...this.forecastDateRange,
                change_list_id: selectedChangeList?.id || undefined
            }
        });
    }

    protected async initSettings(): Promise<void> {
        try {
            this.isChartLoading = true;
            const currentSettings = await this.userSettingsManager.get<CurrentSettings>(module);
            if (currentSettings) {
                this.selectedForecastOption = currentSettings.selectedForecastOption;
                this.selectedCategoryType = currentSettings.selectedCategoryType;
                this.selectedCategory = currentSettings.selectedCategory;
                this.selectedDateRange = currentSettings.selectedDateRange;

                if (this.selectedCategoryType && this.selectedCategory && this.selectedDateRange.length === 2) {
                    await this.fetchChartData(this.selectedCategory.id);
                }
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningTableUserSettingsNotFound'),
                messageType: MessageType.Console
            });
            this.selectedDateRange = this.defaultDateRange;
            await this.createUserSettings();
        } finally {
            this.isChartLoading = false;
        }
    }

    protected async createUserSettings(): Promise<CurrentSettings | null> {
        try {
            return await this.userSettingsManager.create<CurrentSettings>(`${module}`, this.currentSettings);
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningUserSettingsNotSaved'),
                messageType: MessageType.Console
            });
            return null;
        }
    }

    protected async onCreateChangeListDialogConfirm(): Promise<void> {
        if (this.isCreateChangeListFormValid) {
            try {
                this.isCreateNewChangeListPending = true;
                const doc = new CompoundDocument<ChangeList>(CONSTANTS.API.SP_CHANGE_LISTS, this.axios);
                const createResponse = await doc.create(this.createChangeListObject(this.newChangeListName) as Partial<ChangeList>);
                const newChangeList = createResponse.data as ChangeList;
                await this.fetchChangeLists();
                const foundChangeList = this.changeLists.find((changeList) => changeList.id === newChangeList.id);
                if (foundChangeList) {
                    this.onSelectedChangeListChange(foundChangeList);
                } else {
                    this.changeLists.unshift(newChangeList);
                    this.onSelectedChangeListChange(newChangeList);
                }

                if (this.selectedChangeList && this.provisionalChange) {
                    this.addOrUpdateUnsavedChange(this.provisionalChange);
                }
            } catch (error) {
                await ErrorService.dispatch(error, {
                    context: this.$tc('messages.errorChangeListCreate'),
                    messageType: MessageType.Notification
                });
                if (this.isCreateChangeListOnDragActivated) {
                    this.restoreAllEditedSeriesToOriginal();
                    this.unselectAllPoints();
                    this.isCreateChangeListOnDragActivated = false;
                }
            } finally {
                this.isCreateNewChangeListPending = false;
                this.isCreateChangeListDialogOpen = false;
                this.provisionalChange = null;
                this.resetForm();
            }
        }
    }

    protected async saveUserSettings(): Promise<CurrentSettings | null> {
        try {
            return await this.userSettingsManager.createOrUpdate<CurrentSettings>(`${module}`, this.currentSettings);
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: i18n.tc('messages.warningUserSettingsNotSaved'),
                messageType: MessageType.Console
            });
            return null;
        }
    }
}
