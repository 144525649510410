


















































import { Component, PropSync, Vue } from 'vue-property-decorator';
import type { DateFromTo } from '@/model/Interfaces/DateFromTo';

@Component({})
export default class DoubleDatePicker extends Vue {
    protected isDatePickerFromOn = false;

    protected isDatePickerToOn = false;

    @PropSync('value')
    protected valueSync!: DateFromTo;
}
