






/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ScrollTrigger extends Vue {
    // Props
    @Prop({ required: true, type: Boolean })
    protected isLoadedAll!: boolean;

    @Prop({ default: true, required: false, type: Boolean })
    protected shouldRender!: boolean;

    // Methods
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected onIntersect(entries: any, observer: any, isIntersecting: any): void {
        if (isIntersecting) {
            this.scrollToBottom();
        }
    }

    // Emiters
    @Emit()
    protected scrollToBottom(): boolean {
        return true;
    }
}
