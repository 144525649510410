/* eslint-disable no-console */
import appStore from '@/store/modules/AppStore';
import { register } from 'register-service-worker';

const registerServiceWorker = (): void => {
    if (process.env.VUE_APP_MODE !== 'development') {
        register(`${process.env.BASE_URL as string}service-worker.js`, {
            cached(reg) {
                appStore.pushSwEvent({ detail: reg, name: 'cached' });
                console.log('Content has been cached for offline use.');
            },
            error(error) {
                appStore.pushSwEvent({ detail: error, name: 'error' });
                console.error('Error during service worker registration:', error);
            },
            offline() {
                appStore.pushSwEvent({ detail: null, name: 'offline' });
                console.log('No internet connection found. App is running in offline mode.');
            },
            ready(reg) {
                appStore.pushSwEvent({ detail: reg, name: 'ready' });
                console.log('App is being served from cache by a service worker.');
            },
            registered(reg) {
                appStore.pushSwEvent({ detail: reg, name: 'registered' });
                console.log('Service worker has been registered.');
            },
            updated(reg) {
                appStore.pushSwEvent({ detail: reg, name: 'updated' });
                console.log('New content is available; please refresh.');
            },
            updatefound(reg) {
                appStore.pushSwEvent({ detail: reg, name: 'updatefound' });
                console.log('New content is downloading.');
            }
        });
    }
};

export default registerServiceWorker;
