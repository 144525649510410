

































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import CompoundDocument, { ExpressionBuilder, PrettyExpressionBuilder, Resource } from '@bednic/json-api-client';
import { CONSTANTS } from '@/constants';
import ErrorService from '@/services/ErrorService/ErrorService';
import { MessageType } from '@/model/Enums/MessageType';
import type ResourceTextFilterClass from '@/model/Data/Filters/ResourceTextFilterClass';
import type { TableHeader } from '@/model/Interfaces/TableHeader';

@Component({
    name: 'resource-text-filter'
})
export default class ResourceTextFilter extends Vue {
    // Data
    @Prop({ required: true })
    protected currentFilter!: ResourceTextFilterClass;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected header!: TableHeader;

    protected isLoading = false;

    protected items: Array<Resource> = [];

    protected search = null;

    protected searchDebounce = 0;

    // Lifecycle Hooks
    public mounted(): void {
        this.filterChanged();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.currentFilter.value.resources) {
            this.items.push(...this.currentFilter.value.resources);
        }
    }

    // Computed
    protected get additionalFields(): Array<string> {
        return this.header.resourceFilterOptions ? this.header.resourceFilterOptions.additionalFields : [];
    }

    protected get containsKey(): string {
        return this.header.resourceFilterOptions ? this.header.resourceFilterOptions.containsKey : '';
    }

    protected get filterBy(): string {
        return this.header.resourceFilterOptions ? this.header.resourceFilterOptions.filterBy : '';
    }

    // Sync Methods
    protected removeItem(value: Resource): void {
        const index = this.currentFilter.value.resources.findIndex((item) => item === value);
        if (index > -1) {
            const resources = [...this.currentFilter.value.resources];
            resources.splice(index, 1);
            this.currentFilter.value.resources = [...resources];
        }
        this.filterChanged();
    }

    // Emitters
    @Emit()
    protected filterChanged(): ResourceTextFilterClass {
        return this.currentFilter;
    }

    // Event Handlers
    @Watch('search', { immediate: true })
    protected onSearchChange(value: string): void {
        if (this.searchDebounce) {
            clearTimeout(this.searchDebounce);
        }
        if (value) {
            this.isLoading = true;
            this.searchDebounce = window.setTimeout(() => {
                void this.fetchAllWithDebounce(value).then(() => {
                    this.isLoading = false;
                });
            }, 1000);
        }
    }

    protected onFilterChanged(): void {
        this.filterChanged();
    }

    // Async Methods
    protected async fetchAllWithDebounce(value: string): Promise<void> {
        const url = this.header.filterAutocompleteURL ? this.header.filterAutocompleteURL : '';
        const additionalFields = this.additionalFields;
        const doc = new CompoundDocument<Resource>(url, this.axios);
        doc.filter().setLimit(CONSTANTS.NUMBERS.LIMIT);
        doc.filter().setOffset(0);
        doc.filter().sparseFieldsFor(url, [...additionalFields, this.containsKey]);
        const filter = PrettyExpressionBuilder.contains(
            ExpressionBuilder.toLower(ExpressionBuilder.field(this.containsKey)).express(),
            value.toLowerCase()
        );
        doc.addCustomQueryParam('filter', filter.express());
        try {
            await doc.self();
            const data = doc.data;
            if (data && Array.isArray(data)) {
                data.forEach((resource) => {
                    if (!this.items.some((item) => item.id === resource.id)) {
                        this.items.push(resource);
                    }
                });
            } else if (data) {
                if (!this.items.some((item) => JSON.stringify(item) === JSON.stringify(data))) {
                    this.items.push(data);
                }
            }
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorFilterSearch'),
                messageType: MessageType.Notification
            });
        }
    }
}
