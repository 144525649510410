






























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import type EnumFilterClass from '@/model/Data/Filters/EnumFilterClass';
import type { Resource } from '@bednic/json-api-client';
import type { TableHeader } from '@/model/Interfaces/TableHeader';

@Component
export default class EnumFilter extends Vue {
    // Data
    @Prop({ required: true })
    protected currentFilter!: EnumFilterClass;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected header!: TableHeader;

    // Lifecycle Hooks
    public mounted(): void {
        this.onFilterChanged();
    }

    protected get filterEnumText(): string {
        return `${this.header.filterEnumText}`;
    }

    // Computed
    protected get items(): Array<Resource> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.header.filterStoreInit as any)().items;
    }

    // Sync Methods
    protected toggleSelected(value: unknown): void {
        const clickedItem = this.currentFilter.value.enums.find((item) => item === value);
        if (clickedItem) {
            this.currentFilter.value.enums = this.currentFilter.value.enums.filter((item) => item !== value);
        } else {
            this.currentFilter.value.enums.push(value);
        }
        this.filterChanged();
    }

    protected getItemColorFg(item?: Resource): string {
        return item?.attributes?.colorFg || 'default';
    }

    protected getItemColorBg(item?: Resource): string {
        return item?.attributes?.color || item?.attributes?.colorBg || 'default';
    }

    // Emitters
    @Emit()
    protected filterChanged(): EnumFilterClass {
        return this.currentFilter;
    }

    // Event Handlers
    protected onFilterChanged(): void {
        this.filterChanged();
    }
}
