
















































import { Component, Vue } from 'vue-property-decorator';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';
import type { ClientKeycloakTokenParsed } from '@/model/Interfaces/ClientKeycloakTokenParsed';
import Gravatar from 'vue-gravatar';

@Component({
    components: { Gravatar }
})
export default class UserMenu extends Vue {
    protected userMenu = false;

    protected get avatarName(): string {
        const splitName = this.token?.name ? this.token.name.split(' ') : [];
        return splitName.length === 1
            ? `${splitName[0].charAt(0)}`
            : splitName.length > 1
            ? `${splitName[0].charAt(0)}${splitName[splitName.length - 1].charAt(0)}`
            : '';
    }

    protected get email(): string | null {
        return this.token?.email || null;
    }

    protected get iconSize(): number {
        return appStore.elements.logoSize;
    }

    protected get mini(): boolean {
        return this.$vuetify.breakpoint.mdAndUp && appStore.mini;
    }

    protected get nudgeRight(): number {
        return appStore.elements.navigationWidth / 2;
    }

    protected get roles(): string {
        if (appStore.authService) {
            const translatedRoles = appStore.authService.userRoles.map((role) => this.$tc(`roles.${role}`));
            return translatedRoles.join(', ');
        }
        return '';
    }

    protected get token(): ClientKeycloakTokenParsed | null {
        return this.$keycloak?.tokenParsed as ClientKeycloakTokenParsed;
    }

    protected get username(): string {
        return this.token?.name || 'Username';
    }

    protected get isOpen(): boolean {
        return appStore.activeMenu(ActiveMenus.UserMenu);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [ActiveMenus.UserMenu]: value });
    }

    protected logout(): void {
        this.$keycloak?.logout();
    }
}
