import { getModule, Module } from 'vuex-module-decorators';
import { CONSTANTS } from '@/constants';
import { DataStore } from '@/store/DataStore';
import EntityService from '@/services/EntityService';
import type { Flag } from '@/model/Entity/Flag';
import store from '@/store';

const module = 'flagStore';

export interface FlagStoreState {
    entityService: EntityService;
    items: Array<Flag>;
}

@Module({
    dynamic: true,
    name: module,
    namespaced: true,
    store
})
class FlagStore extends DataStore<Flag> implements FlagStoreState {
    public entityService = new EntityService(CONSTANTS.API.FLAGS);
}

export default getModule(FlagStore);
