export interface TreeCategory {
    children?: Array<TreeCategory>;
    childrenLink: string;
    id: string;
    isLeaf: boolean;
    isRoot: boolean;
    key: string;
    name: string;
    path: Array<TreeCategory>;
    type: TreeCategoryType;
    typeId?: number;
}

export enum TreeCategoryType {
    ProductCategories = 'product-categories',
    Products = 'products',
    WarehouseCategories = 'warehouse-categories',
    Warehouses = 'warehouses'
}
