import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { updateTokenOnRequestFailed } from '@/plugins/keycloak';
import Vue from 'vue';
import VueAxios from 'vue-axios';

const jsonApiHeaders = 'application/vnd.api+json';
let axiosRequestInterceptorId: number | null = null;

export const setHttpHeaders = (): void => {
    axios.defaults.headers = {
        Accept: jsonApiHeaders,
        'Content-Type': jsonApiHeaders,
        common: {
            Accept: jsonApiHeaders,
            'Content-Type': jsonApiHeaders
        }
    };
};

export const setHttpBaseUrl = (baseUrl: string): void => {
    axios.defaults.baseURL = baseUrl;
};

export const makeInterceptor = (bearer: string): void => {
    if (axiosRequestInterceptorId !== null) {
        axios.interceptors.request.eject(axiosRequestInterceptorId);
    }
    axiosRequestInterceptorId = axios.interceptors.request.use(
        (config) => {
            config.headers = {
                ...config.headers,
                Authorization:`Bearer ${bearer}`
            } as Record<string, unknown>;
            return config;
        },
        async (error: Error) => {
            return Promise.reject(error);
        }
    );
};

export const setHttp = (url: string, bearer: string): void => {
    setHttpHeaders();
    setHttpBaseUrl(url);
    makeInterceptor(bearer);
};

axios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async (error) => {
        const errorResponse = error as Omit<AxiosError<AxiosResponse>, 'config'> & { config: AxiosRequestConfig & { retry?: boolean } };
        const originalRequest = errorResponse.config;
        if (
            errorResponse.message !== 'Network Error' &&
            errorResponse.response &&
            Number(errorResponse.response.status) === 401 &&
            !originalRequest.retry
        ) {
            originalRequest.retry = true;
            return updateTokenOnRequestFailed(originalRequest);
        } else {
            return Promise.reject(error);
        }
    }
);

Vue.use(VueAxios, axios);

export default axios;
