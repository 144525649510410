var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"order-tiles__item",style:(("border-left: 5px solid " + _vm.color)),attrs:{"elevation":"2","link":""},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',[_c('v-card-text',[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-h5 text-overflow"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.order.attributes.supplierName)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.order.attributes.supplierName))])]),_c('v-row',{staticClass:"d-flex",attrs:{"dense":""}},[_c('v-col',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"d-flex mt text-h7"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm._f("date")(_vm.order.attributes.orderDate)))]),(_vm.order.id % 2)?_c('span',{staticClass:"text-overflow"},[_c('span',{staticClass:"font-italic text--secondary"},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-timer-outline")]),_vm._v(" "+_vm._s(_vm._f("date")(new Date(new Date(_vm.order.attributes.orderDate).getTime() + 7 * 24 * 3600000)))+" ")],1)]):_vm._e()]),_c('div',{staticClass:"my-1 text-overflow"},[_c('v-icon',{attrs:{"small":"","disabled":!_vm.order.attributes.assignedTo}},[_vm._v(" mdi-account ")]),_c('span',{staticClass:"text--secondary text-overflow"},[(_vm.order.attributes.assignedTo)?_c('span',[_vm._v(" "+_vm._s(_vm.userStore.getOne(_vm.order.attributes.assignedTo).attributes.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$tc('components.ordersTile.notAssigned'))+" ")])])],1),_c('div',{staticClass:"align-center d-flex my-1 text-overflow"},[_c('span',{staticClass:"mr-2 text-h6"},[_vm._v(" "+_vm._s(_vm._f("number")(_vm.order.attributes.salePrice))+" "+_vm._s(_vm.order.attributes.supplierCurrency)+" ")]),_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clipboard-list-outline")]),_vm._v(" "+_vm._s(_vm._f("number")(_vm.order.attributes.orderedAmount))+" ")],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc('components.ordersTile.orderedAmount'))+" ")])])],1)]),_c('v-col',{staticClass:"align-center d-flex flex-grow-0 justify-end"},[_c('v-avatar',{attrs:{"color":(!_vm.imgSrc && _vm.avatarColor) || undefined}},[(!_vm.imgSrc)?_c('span',{staticClass:"text-h5 white--text"},[_vm._v(_vm._s(_vm.abbreviation))]):_vm._e(),(_vm.imgSrc)?_c('img',{staticClass:"order-tiles__item__img",attrs:{"src":_vm.imgSrc,"alt":""}}):_vm._e()])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"flex-grow-1"},[_c('v-chip',{staticClass:"mr-2 px-2",attrs:{"label":"","color":_vm.color,"dark":"","small":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.stateStore.getOne(_vm.order.attributes.stateId).attributes.icon)+" ")]),_c('div',{staticClass:"ml-1 text-overflow"},[_vm._v(" "+_vm._s(_vm.stateStore.getOne(_vm.order.attributes.stateId).attributes.name)+" ")])],1)],1),_c('v-col',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"align-center d-flex"},[_c('v-icon',{staticClass:"mr-1 text-overflow"},[_vm._v("mdi-warehouse")]),_c('span',{staticClass:"text--secondary text-overflow"},[_vm._v(_vm._s(_vm.order.attributes.warehouseName))])],1)]),(_vm.order.id % 2)?_c('v-col',{staticClass:"flex-grow-0 text-right"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-call-split")])]}}],null,false,2337851343)},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc('components.ordersTile.redistribution'))+" ")])])],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }