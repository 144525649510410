














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import type BooleanFilterClass from '@/model/Data/Filters/BooleanFilterClass';
import type { TableHeader } from '@/model/Interfaces/TableHeader';

@Component
export default class BooleanFilter extends Vue {
    // Data
    @Prop({ required: true })
    protected currentFilter!: BooleanFilterClass;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ required: true })
    protected header!: TableHeader;

    // Computed
    protected get options(): Array<string> {
        return (
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            [
                this.header.filterBooleanOptions?.truthyText || this.$tc('components.filters.boolean.truthyText'),
                this.header.filterBooleanOptions?.falsyText || this.$tc('components.filters.boolean.falsyText')
            ] || []
        );
    }

    protected get value(): number | null {
        return this.currentFilter.value.value === null ? null : this.currentFilter.value.value ? 0 : 1;
    }

    // Emitters
    @Emit()
    protected filterChanged(value: number | boolean): BooleanFilterClass {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        this.currentFilter.value.value = value === null ? null : value === 0;
        return this.currentFilter;
    }
}
