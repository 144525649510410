


















































































































import CompoundDocument, { Expression, PrettyExpressionBuilder } from '@bednic/json-api-client';
import type { AxiosResponse } from 'axios';
import type { NavigationGuardNext, Route } from 'vue-router';
import { TableHeader, TableHeaderFilterType, TableHeaderRenderer } from '@/model/Interfaces/TableHeader';
import ActiveFilters from '@/components/common/Table/ActiveFilters.vue';
import appStore from '@/store/modules/AppStore';
import ColumnView from '@/components/common/Table/ColumnViews.vue';
import { Component } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import type { DataStore } from '@/store/DataStore';
import ErrorService from '@/services/ErrorService/ErrorService';
import type { ExportConfig } from '@/services/ExportTableService';
import ExportTable from '@/components/common/Table/ExportTable.vue';
import type { Flag } from '@/model/Entity/Flag';
import flagStore from '@/store/modules/FlagStore';
import LTable from '@/components/common/Table/Table.vue';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import { mixins } from 'vue-class-component';
import { NotUpToDateErrorHandler } from '@/services/ErrorService/ErrorHandlers/NotUpToDateErrorHandler';
import type { OrderByItems } from '@/model/Entity/OrderByItems';
import type { OrderBySuppliers } from '@/model/Entity/OrderBySuppliers';
import PageSizeSelect from '@/components/common/Table/PageSizeSelect.vue';
import Pagination from '@/components/common/Table/Pagination.vue';
import Summary from '@/components/common/Table/Summary.vue';
import type { Supplier } from '@/model/Entity/Supplier';
import TableMixin from '@/mixins/TableMixin';
import type { User } from '@/model/Entity/User';
import userStore from '@/store/modules/UserStore';
import UserViews from '@/components/common/Table/userViews/UserViews.vue';

enum ColDefs {
    AssignedTo = 'assignedTo',
    FlagIds = 'flagIds',
    SupplierName = 'supplierName',
    TotalPrice = 'totalPrice'
}

const module = CONSTANTS.MODULES.ORDERS_BY_SUPPLIERS;

Component.registerHooks(['beforeRouteLeave']);

@Component({
    // eslint-disable-next-line no-undef
    components: {
        ActiveFilters,
        ColumnView,
        ExportTable,
        LTable,
        PageSizeSelect,
        Pagination,
        Summary,
        UserViews
    }
})
export default class OrdersBySuppliers extends mixins<TableMixin<OrderBySuppliers>>(TableMixin) {
    // Data
    protected headers: Array<TableHeader> = [
        {
            default: true,
            filterAutocompleteURL: CONSTANTS.API.SUPPLIERS,
            filterType: TableHeaderFilterType.ResourceText,
            onClickLink: (item: OrderBySuppliers): Promise<void> => this.routeToOrdersByItems(item),
            renderer: TableHeaderRenderer.Text,
            resourceFilterOptions: {
                additionalFields: ['shortcut', 'ico', 'address'],
                containsKey: 'name',
                filterBy: 'id'
            },
            sortable: true,
            text: `tables.${module}.${ColDefs.SupplierName}`,
            value: ColDefs.SupplierName,
            width: 420
        },
        {
            default: true,
            filterType: TableHeaderFilterType.Number,
            numberOptions: { maximumFractionDigits: 2, minimumFractionDigits: 2 },
            renderer: TableHeaderRenderer.Number,
            showCurrency: true,
            sortable: true,
            text: `tables.${module}.${ColDefs.TotalPrice}`,
            tooltip: `tables.${module}.${ColDefs.TotalPrice}Tooltip`,
            value: ColDefs.TotalPrice,
            width: 140
        },
        {
            default: true,
            filterEnumText: 'attributes.label',
            filterKey: 'flagIds',
            filterStoreInit: (): DataStore<Flag> => flagStore,
            filterType: TableHeaderFilterType.EnumMultiple,
            renderer: TableHeaderRenderer.Flag,
            sortable: false,
            text: `tables.${module}.${ColDefs.FlagIds}`,
            value: ColDefs.FlagIds,
            width: 350
        },
        {
            default: true,
            filterEnumText: 'attributes.name',
            filterStoreInit: (): DataStore<User> => userStore,
            filterType: TableHeaderFilterType.EnumMultiple,
            renderer: TableHeaderRenderer.User,
            sortable: false,
            text: `tables.${module}.${ColDefs.AssignedTo}`,
            value: ColDefs.AssignedTo,
            width: 350
        }
    ];

    protected items: Array<OrderBySuppliers> = [];

    protected requireSummary = true;

    protected tableKey = module;

    protected url = CONSTANTS.API.SUPPLIER_ORDERS;

    // Navigation Guards
    public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<OrdersBySuppliers>): void {
        const leaveRoute = (): void => {
            this.items = [];
            this.isEditMode = false;
            next();
        };
        if (this.canLeaveEditMode) {
            leaveRoute();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    leaveRoute();
                }
            });
        }
    }

    // Computed Getters
    protected get exportConfig(): ExportConfig {
        const sorts: Array<string> = [];
        Object.keys(this.sort).forEach((key) => {
            if (this.sort[key] !== null) {
                sorts.push(`${this.sort[key] ? '-' : ''}${key}`);
            }
        });
        const sort = sorts.join(',');
        return {
            filename: `export_objednavky_po_dodavatelich_${this.ordersSemiGlobalFilter.join('_')}`,
            filter: {
                columns: this.selectedColumns.filter((column) => column.selected).map((column) => column.columnName),
                filter: this.tableFilters.express(),
                globalFilter: PrettyExpressionBuilder.and(...this.globalFilterAsExpressions).express(),
                sort: sorts.length ? sort : ''
            },
            url: CONSTANTS.API.SUPPLIER_ORDER_EXPORTS
        };
    }

    protected get ordersSemiGlobalFilter(): Array<string> {
        return appStore.ordersSemiGlobalFilter.dateRange;
    }

    // MIXIN OVERRIDE - filter separated to filter and globalFilter
    protected get params(): Record<string, string> {
        return {
            filter: this.tableFilters.express(),
            globalFilter: PrettyExpressionBuilder.and(...this.globalFilterAsExpressions).express()
        };
    }

    protected get semiGlobalFilter(): Expression | null {
        if (this.ordersSemiGlobalFilter.length) {
            return PrettyExpressionBuilder.and(
                PrettyExpressionBuilder.greaterThenOrEqual('orderDate', this.ordersSemiGlobalFilter[0]),
                PrettyExpressionBuilder.lowerThenOrEqual('orderDate', this.ordersSemiGlobalFilter[1])
            );
        } else {
            return null;
        }
    }

    protected async routeToOrdersByItems(item: OrderBySuppliers): Promise<void> {
        const doc = new CompoundDocument<Supplier>(CONSTANTS.API.SUPPLIERS, this.axios);
        const filter = PrettyExpressionBuilder.equal('id', item.id);
        doc.addCustomQueryParam('filter', filter.express());

        let suppliers: Array<Supplier> | null = null;
        try {
            const result = (await doc.self()).data;
            suppliers = result ? (Array.isArray(result) ? result : [result]) : null;
        } catch (error) {
            await ErrorService.dispatch(error, {
                context: this.$tc('messages.errorFilterSearch'),
                messageType: MessageType.Notification
            });
        }

        if (suppliers) {
            const tableFilters: string = JSON.stringify({
                [CONSTANTS.MODULES.ORDERS_BY_ITEMS]: {
                    supplierName: {
                        resources: suppliers
                    }
                }
            });
            await this.$router.push({
                name: CONSTANTS.ROUTES.ORDERS.CHILDREN.BY_ITEMS.NAME,
                params: { tableFilters },
                path: CONSTANTS.ROUTES.ORDERS.CHILDREN.BY_ITEMS.PATH
            });
        } else {
            await ErrorService.dispatch('', {
                context: this.$tc('messages.errorSupplierNotFound'),
                messageType: MessageType.Notification
            });
        }
    }

    protected async updateItemFunction(payload: {
        confirmFunc: () => void;
        header: TableHeader;
        item: OrderByItems;
        original: OrderByItems;
        secondaryFunc: () => void;
        value: unknown;
    }): Promise<AxiosResponse<OrderByItems>> {
        try {
            const headerValue: string = payload.header.value;
            return await this.axios.patch<OrderByItems>(`${this.url}/${payload.item.id}`, {
                data: { [headerValue]: payload.value },
                meta: { original: payload.original }
            });
        } catch (error) {
            const messageKey = 'messages.errorEditConflict';
            const notUpToDateErrorHandler = new NotUpToDateErrorHandler(
                messageKey,
                this.headers,
                payload.value,
                payload.header,
                payload.confirmFunc,
                payload.secondaryFunc
            );
            const errors = await ErrorService.dispatch(error, {
                actions: {
                    actionText: this.$tc('messages.errorEditFailedActionText'),
                    confirmFunc: payload.confirmFunc,
                    secondaryFunc: payload.secondaryFunc
                },
                context: this.$tc('messages.errorEditFailed'),
                customHandlers: [notUpToDateErrorHandler],
                messageType: MessageType.Dialog
            });
            return Promise.reject(errors[0]);
        }
    }
}
