

























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ActiveMenus } from '@/model/Enums/ActiveMenus';
import appStore from '@/store/modules/AppStore';
import messagesStore from '@/store/modules/MessagesStore';

@Component({})
export default class ActiveFilters extends Vue {
    // Props
    @Prop({ required: true, type: Array })
    protected activeColumnFilterKeys!: Array<string>;

    @Prop({ default: true, required: false, type: Boolean })
    protected canBeChanged!: boolean;

    @Prop({ default: '', required: false, type: String })
    protected propClass!: string;

    @Prop({ required: true, type: String })
    protected tabKey!: string;

    // Computed
    protected get isAnyFilterActive(): boolean {
        return !!this.activeColumnFilterKeys.length;
    }

    protected get isOpen(): boolean {
        return appStore.activeMenu(ActiveMenus.ActiveFilters);
    }

    protected set isOpen(value: boolean) {
        appStore.setActiveMenus({ [ActiveMenus.ActiveFilters]: value });
    }

    protected onOpen(): void {
        this.isOpen = true;
    }

    protected onClose(): void {
        this.isOpen = false;
    }

    // Emitters
    @Emit('resetAllColumnFilters')
    protected resetAllColumnFilters(): boolean {
        return true;
    }

    // Event handlers
    protected onClickResetButton(): void {
        const actionCalled = (): void => {
            this.onClose();
            this.resetAllColumnFilters();
        };
        if (this.canBeChanged) {
            actionCalled();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    actionCalled();
                }
            });
        }
    }
}
