/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { Actions } from '@/model/Interfaces/Actions';
import type { ErrorInterface } from '@/services/ErrorService/ErrorService';
import getUuidByString from 'uuid-by-string';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import { MessageType } from '@/model/Enums/MessageType';
import { v4 as uid } from 'uuid';

/**
 * Interface which all errors handled by application should apply
 */
export default class ApplicationError implements ErrorInterface {
    public error: Error | undefined;

    public id: string;

    public isResolved: boolean;

    public messageLevel: MessageLevel;

    public messageType: MessageType;

    public timestamp: Date;

    public constructor(
        public title: string,
        public detail?: string,
        public options?: {
            actions?: Actions;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data?: Record<string, any>;
            error?: Error;
            messageLevel?: MessageLevel;
            messageType?: MessageType;
            progress?: number;
        }
    ) {
        this.error = options?.error as Error || new Error(title);
        this.messageType = options?.messageType || MessageType.Console;
        this.messageLevel = options?.messageLevel || MessageLevel.Error;
        this.isResolved = false;
        this.timestamp = new Date();
        if (typeof this.options?.progress !== 'undefined') {
            this.id = uid();
        } else {
            this.id = ApplicationError.getIdByString(title, this.messageType, this.detail, this.error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static getIdByString(title: string, messageType: MessageType, detail?: string, error?: any): string {
        let identifier = '';
        identifier += error?.status || '';
        identifier += error?.code || '';
        identifier += title;
        identifier += detail;
        identifier += messageType;
        return getUuidByString(identifier);
    }
}
