





















import { Provide, Ref, Vue } from 'vue-property-decorator';
import appStore from '@/store/modules/AppStore';
import Component from 'vue-class-component';
import ExportTableService from '@/services/ExportTableService';
import MessageDialog from '@/components/common/MessageDialog.vue';
import Navigation from '@/components/Navigation.vue';
import Notifications from '@/components/Notifications.vue';
import Toolbar from '@/components/Toolbar.vue';
import UpdateApp from '@/components/UpdateApp';

@Component({
    // eslint-disable-next-line no-undef
    components: {
        MessageDialog,
        Navigation,
        Notifications,
        Toolbar
    }
})
export default class App extends UpdateApp {
    @Ref('navigationRef')
    private readonly navigationRef!: Vue;

    @Ref('toolbarRef')
    private readonly toolbarRef!: Vue;

    @Provide('exportTableService')
    private readonly exportTableService = ExportTableService;

    public mounted(): void {
        this.setElements();
        this.exportTableService.addEventListeners();
    }

    protected setElements(): void {
        const navigationWidth = this.navigationRef.$el.clientWidth;
        const toolbarHeight = this.toolbarRef.$el.clientHeight;
        appStore.setElements({ navigationWidth, toolbarHeight });
    }
}
