import appStore from '@/store/modules/AppStore';
import Vue from 'vue';

export class AccessControlService {
    public get permissions(): Array<string> {
        return appStore.authService ? appStore.authService.permissions : [];
    }

    public get roles(): Array<string> {
        return appStore.authService ? appStore.authService.userRoles : [];
    }

    public can(...permissions: Array<string>): boolean {
        let result = true;
        for (const permission of permissions) {
            result &&= this.permissions.includes(permission);
        }
        return result;
    }
}

const ac = new AccessControlService();

Vue.use(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    Vue.prototype.$ac = ac;
});

export default ac;
