import { io, Socket } from 'socket.io-client';
import ErrorService from '@/services/ErrorService/ErrorService';
import { i18n } from '@/plugins/i18n';
import { Message } from '@/model/Classes/Message';
import { MessageLevel } from '@/model/Enums/MessageLevel';
import messagesStore from '@/store/modules/MessagesStore';
import { MessageType } from '@/model/Enums/MessageType';
import Vue from 'vue';

let socket!: Socket;

const socketCreate = (config: { path: string; url: string }, bearer: string): void => {
    socket = io(config.url, {
        autoConnect: false,
        extraHeaders: {
            Authorization: bearer
        },
        path: config.path,
        reconnection: false
    });
    Vue.use((_Vue) => {
        (_Vue.prototype as { $socket: unknown } & typeof Vue).$socket = socket;
    });
    socket.on('connect', () => {
        messagesStore.sendMessage(
            new Message(i18n.tc('messages.successSocketConnect', 0, { hostname: socket.io.opts.hostname }), undefined, {
                messageLevel: MessageLevel.Success,
                messageType: MessageType.Console
            })
        );
    });
    socket.on('connect_error', (error): void => {
        void ErrorService.dispatch(error, {
            context: i18n.tc('messages.errorSocketConnect', 0, { hostname: socket.io.opts.hostname }),
            messageType: MessageType.Console
        });
    });
    socket.on('disconnect', (reason): void => {
        messagesStore.sendMessage(
            new Message(i18n.tc('messages.errorSocketDisconnect', 0, { hostname: socket.io.opts.hostname }), reason, {
                messageLevel: MessageLevel.Error,
                messageType: MessageType.Console
            })
        );
    });
    socket.connect();
};

const socketUpdateBearer = (bearer: string): void => {
    socket.io.opts.extraHeaders = {
        Authorization: bearer
    };
};

export { socket, socketCreate, socketUpdateBearer };
