























































import { Component, Prop, Vue } from 'vue-property-decorator';
import appStore from '@/store/modules/AppStore';
import type { ClientKeycloakTokenParsed } from '@/model/Interfaces/ClientKeycloakTokenParsed';
import colors from 'vuetify/lib/util/colors';
import { CONSTANTS } from '@/constants';
import VGravatar from 'vue-gravatar';

interface ErrorPageData {
    description: string;
    icon: string;
    title: string;
}

@Component({
    components: { VGravatar }
})
export default class ErrorComponent extends Vue {
    @Prop({ required: true })
    protected error!: { message?: string };

    protected color = colors.lightGreen.base;

    protected readonly DEFAULT_ERROR: ErrorPageData = {
        description: this.tcd('errorPage.default.description', 'Try updating the page. If the app does not load, contact support.'),
        icon: '$vuetify.icons.error',
        title: this.tcd('errorPage.default.title', 'Sorry, something went wrong on our side')
    };

    protected readonly errors = CONSTANTS.ERRORS;

    protected readonly ERRORS: Record<string, Partial<ErrorPageData>> = {
        [CONSTANTS.ERRORS.NO_ACCESS]: {
            description: this.tcd('errorPage.noAccess.description', 'You do not have sufficient user rights.'),
            icon: '$vuetify.icons.noAccess',
            title: this.tcd('errorPage.noAccess.title', 'You do not have the right to enter the application')
        },
        [CONSTANTS.ERRORS.NO_PERMISSIONS]: {
            description: this.tcd('errorPage.noAccess.description', 'You do not have sufficient user rights.'),
            icon: '$vuetify.icons.noAccess',
            title: this.tcd('errorPage.noPermissions.title', 'You do not have permission to view this page')
        },
        [CONSTANTS.ERRORS.NO_ROLES]: {
            description: this.tcd('errorPage.noRoles.description', 'You have no user roles assigned.'),
            icon: '$vuetify.icons.noRoles',
            title: this.tcd('errorPage.noRoles.title', 'You do not have permission to access the application')
        },
        [CONSTANTS.ERRORS.NOT_AUTHENTICATED]: {
            description: this.tcd('errorPage.notAuthenticated.description', 'You have no user roles assigned.'),
            icon: '$vuetify.icons.noAccess',
            title: this.tcd('errorPage.notAuthenticated.title', 'You do not have permission to access the application')
        },
        [CONSTANTS.ERRORS.PAGE_NOT_FOUND]: {
            description: this.tcd('errorPage.pageNotFound.description', 'Wrong URL address or page does not exist anymore.'),
            title: this.tcd('errorPage.pageNotFound.title', 'Page not found')
        }
    };

    protected get avatarName(): string {
        const splitName = this.token?.name ? this.token.name.split(' ') : [];
        return splitName.length === 1
            ? `${splitName[0].charAt(0)}`
            : splitName.length > 1
            ? `${splitName[0].charAt(0)}${splitName[splitName.length - 1].charAt(0)}`
            : '';
    }

    protected get errorData(): ErrorPageData {
        const [, knownError] = Object.entries(this.ERRORS).find(([errorId]) => (this.error as Error).message.startsWith(errorId)) || [
            null,
            {}
        ];

        return {
            ...this.DEFAULT_ERROR,
            ...knownError
        };
    }

    protected get isLoggedIn(): boolean {
        return !!this.token?.preferred_username;
    }

    protected get token(): ClientKeycloakTokenParsed | null {
        return this.$keycloak?.tokenParsed as ClientKeycloakTokenParsed;
    }

    protected get username(): string {
        return this.token?.name || 'Unknown user';
    }

    protected hasError(...errorMessages: Array<string>): boolean {
        if (this.error instanceof Error) {
            return errorMessages.some((errorMessage) => this.error.message?.startsWith(errorMessage));
        } else {
            return false;
        }
    }

    /**
     * Translate with default value (for case that translations have not been loaded yet)
     * Inspiration from https://github.com/kazupon/vue-i18n/issues/444#issuecomment-430853066
     * */
    protected tcd(translatePath: string, defaultValue: string): string {
        const translated = this.$tc(translatePath);
        return translated === translatePath ? defaultValue : translated;
    }

    protected async logout(): Promise<void> {
        await this.$keycloak?.logout();
    }

    protected async onRedirect(): Promise<void> {
        appStore.setActiveRoute(null);
        await appStore.saveUserSettings();
        // This could be misleading. Generally the homepage does not have to be on location.origin. We should keep
        // homepage uri in configuration.
        window.location.href = window.location.origin;
    }

    protected async onRefresh(): Promise<void> {
        if ('serviceWorker' in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            const unRegistrations = registrations.map(async (registration) => registration.unregister());
            const clearCaches = Object.keys(window.caches).map(async (cacheKey) => window.caches.delete(cacheKey));
            await Promise.all([...unRegistrations, ...clearCaches]);
        }
        window.location.href = window.location.href.toString();
    }
}
