





















































































































import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import type { Point } from 'highcharts';

enum OperandUnits {
    MJ = 'mj',
    Percent = 'percent'
}

enum Operator {
    Minus = 'minus',
    Plus = 'plus'
}

@Component({})
export default class ChartEditingForm extends Vue {
    // Data
    @Ref('finalValueInput')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected finalValueInput!: any;

    @Ref('operandInput')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected operandInput!: any;

    @Prop({ default: false, required: false, type: Boolean })
    protected disabled!: boolean;

    @Prop({ default: false, required: true, type: Boolean })
    protected isEditedSeriesDirty!: boolean;

    @Prop({ default: 0, required: false, type: Number })
    protected originalValue!: number;

    @Prop({ default: [], required: true, type: Array })
    protected selectedPoints!: Array<Point>;

    @PropSync('finalValue')
    protected finalValueSync!: number | null;

    protected numberInputRules = {
        maxTwoDecimals: (v: number | string | null): string | boolean =>
            // possitive or negative number with max. two decimal places
            /^[+|-]?[0-9]*\.?[0-9]{0,2}$/g.test(String(v)) ||
            // or empty string
            /^.{0}$/g.test(String(v)) ||
            // or null
            v === null ||
            this.$tc('validationMessages.maxTwoDecimals'),
        noExponent: (v: number | string | null): string | boolean =>
            !this.containsExponent(v) || this.$tc('validationMessages.cannotUseExponents')
    };

    protected operand: number | string | null = null;

    protected selectedOperandUnits = OperandUnits.MJ;

    protected selectedOperator = Operator.Plus;

    protected beforeDestroy(): void {
        window.removeEventListener('beforeunload', (event) => this.dirtyEditedSeriesListener(event));
    }

    // Computed
    protected get isFinalValueValid(): boolean {
        return !(
            this.finalValueSync === null ||
            this.checkIfInputHasInvalidValue(this.finalValueInput) ||
            typeof this.numberInputRules.noExponent(this.finalValueSync) === 'string' ||
            typeof this.numberInputRules.maxTwoDecimals(this.finalValueSync) === 'string'
        );
    }

    protected get isOperandValid(): boolean {
        return !(
            this.operand === null ||
            // this.checkIfInputHasInvalidValue(this.operandInput) ||
            typeof this.numberInputRules.noExponent(this.operand) === 'string' ||
            typeof this.numberInputRules.maxTwoDecimals(this.operand) === 'string'
        );
    }

    protected get originalValueRounded(): number {
        return this.roundValueToTwoDecimals(this.originalValue);
    }

    protected get reactiveFinalValueLabel(): string {
        if (this.checkIfInputHasInvalidValue(this.finalValueInput)) {
            return this.$tc('validationMessages.notAValidNumber');
        } else if (this.finalValueSync && typeof this.numberInputRules.noExponent(this.finalValueSync) === 'string') {
            return this.numberInputRules.noExponent(this.finalValueSync) as string;
        } else if (this.finalValueSync && typeof this.numberInputRules.maxTwoDecimals(this.finalValueSync) === 'string') {
            return this.numberInputRules.maxTwoDecimals(this.finalValueSync) as string;
        } else if (this.selectedPoints.length <= 1) {
            return this.$tc('components.salesPlanning.newValue');
        } else {
            return this.$tc('components.salesPlanning.newSumOfValues');
        }
    }

    protected get reactiveOperandLabel(): string {
        if (this.checkIfInputHasInvalidValue(this.operandInput)) {
            return this.$tc('validationMessages.notAValidNumber');
        } else if (this.operand && typeof this.numberInputRules.noExponent(this.operand) === 'string') {
            return this.numberInputRules.noExponent(this.operand) as string;
        } else if (this.operand && typeof this.numberInputRules.maxTwoDecimals(this.operand) === 'string') {
            return this.numberInputRules.maxTwoDecimals(this.operand) as string;
        } else {
            return this.$tc('components.salesPlanning.operand');
        }
    }

    // Sync Methods
    protected checkIfInputHasInvalidValue(ref?: { badInput: unknown }): boolean {
        return !!ref?.badInput;
    }

    protected computeFinalValue(): number {
        let operand = this.operand;
        let result = this.originalValueRounded;
        if (operand && this.isOperandValid) {
            if (this.selectedOperandUnits === OperandUnits.Percent) {
                operand = (Number(operand) / 100) * this.originalValue;
            }
            if (this.selectedOperator === Operator.Plus) {
                result = this.roundValueToTwoDecimals(Number(this.originalValue) + Number(operand));
            } else {
                result = this.roundValueToTwoDecimals(Number(this.originalValue) - Number(operand));
            }
            if (result < 0) {
                result = 0;
            }
        }
        return result;
    }

    protected containsExponent(value: number | string | null): boolean {
        if (value) {
            return /[Ee]/g.test(String(value));
        } else {
            return false;
        }
    }

    public resetOperand(): void {
        this.operand = null;
        const operandInput = this.operandInput as Vue;
        operandInput.$el.getElementsByTagName('input')[0].value = '';
        (operandInput as { validate: (validation: boolean) => void } & Vue).validate(false);
    }

    protected roundValueToTwoDecimals(value: number): number {
        return Math.round(value * 100) / 100;
    }

    protected toggleOperandUnits(): void {
        if (this.selectedOperandUnits === OperandUnits.MJ) {
            this.selectedOperandUnits = OperandUnits.Percent;
        } else {
            this.selectedOperandUnits = OperandUnits.MJ;
        }
        this.onEditingFormChanged();
    }

    protected toggleOperator(): void {
        if (this.selectedOperator === Operator.Plus) {
            this.selectedOperator = Operator.Minus;
        } else {
            this.selectedOperator = Operator.Plus;
        }
        this.onEditingFormChanged();
    }

    // Event Handlers
    protected onRestoreSelectedPointsClicked(): void {
        this.operand = null;
        this.restoreSelectedPoints();
        this.finalValueSync = this.computeFinalValue();
    }

    protected onEditingFormChanged(): void {
        this.finalValueSync = this.computeFinalValue();
        if (this.isFinalValueValid) {
            this.editingFormChanged();
        } else {
            this.restoreSelectedPoints();
        }
    }

    protected onFinalValueInput(): void {
        this.resetOperand();
        // without nextTick input sometimes doesn't change to valid on time to valid input
        this.$nextTick(() => {
            if (this.isFinalValueValid) {
                this.finalValueEdited();
            } else {
                this.restoreSelectedPoints();
            }
        });
    }

    protected dirtyEditedSeriesListener(event: BeforeUnloadEvent): void {
        event.returnValue = `${this.$tc('components.confirmBox.restoreAllPointsTitle')} ${this.$tc(
            'components.confirmBox.restoreAllPointsDetail'
        )}`;
    }

    @Emit('restore-all-points')
    protected onRestoreAllPointsClicked(): boolean {
        return true;
    }

    @Emit('restore-selected-points')
    protected restoreSelectedPoints(): boolean {
        return true;
    }

    @Emit('editing-form-changed')
    protected editingFormChanged(): { operand: number | string | null; type: 'numerical' | 'percentage' } {
        let operand = Number(this.operand);
        if (this.selectedOperator === Operator.Minus) {
            operand = -operand;
        }
        if (this.selectedOperandUnits === OperandUnits.MJ) {
            return { operand: operand, type: 'numerical' };
        } else {
            return { operand: operand, type: 'percentage' };
        }
    }

    @Emit('final-value-edited')
    protected finalValueEdited(): boolean {
        return true;
    }

    @Watch('selectedPoints')
    protected onSelectedPointsChanged(): void {
        this.resetOperand();
    }

    @Watch('isEditedSeriesDirty', { immediate: true })
    protected onIsEditedSeriesDirtyChanged(isEditedSeriesDirty: boolean): void {
        if (isEditedSeriesDirty) {
            window.addEventListener('beforeunload', (event) => this.dirtyEditedSeriesListener(event));
        } else {
            window.removeEventListener('beforeunload', (event) => this.dirtyEditedSeriesListener(event));
        }
    }

    @Emit('confirm-changes')
    protected onConfirmChanges(): boolean {
        return true;
    }
}
