














































































import { Component, Vue } from 'vue-property-decorator';
import { CONSTANTS } from '@/constants';
import type { Message } from '@/model/Classes/Message';
import messagesStore from '@/store/modules/MessagesStore';

@Component
export default class Notifications extends Vue {
    // Computed
    protected get notifications(): Array<Message> {
        return messagesStore.notifications;
    }

    // Sync Methods
    protected getSnackbarStyle(index: number): unknown {
        const bottomMargin = 14;
        const snackbarHeight = 68;
        const padding = 4;
        return {
            paddingBottom: !index ? `${bottomMargin}px` : `${bottomMargin + index * (snackbarHeight + padding)}px`,
            paddingRight: '14px',
            'z-index': `${this.notifications.length + CONSTANTS.NUMBERS.Z_INDEX_BASE - index}`
        };
    }

    protected async onConfirm(notification: Message): Promise<void> {
        if (notification.options?.actions && notification.options.actions.confirmFunc) {
            notification.options.actions.confirmFunc();
        }
        await messagesStore.resolveMessage(notification.id);
    }

    protected async onCancel(notification: Message): Promise<void> {
        if (notification.options?.actions && notification.options.actions.cancelFunc) {
            notification.options.actions.cancelFunc();
        }
        await messagesStore.resolveMessage(notification.id);
    }

    protected async onSecondary(notification: Message): Promise<void> {
        if (notification.options?.actions && notification.options.actions.secondaryFunc) {
            notification.options.actions.secondaryFunc();
        }
        await messagesStore.resolveMessage(notification.id);
    }
}
