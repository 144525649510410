














































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import messagesStore from '@/store/modules/MessagesStore';

@Component
export default class Pagination extends Vue {
    // Data
    @Prop({ default: true, required: false })
    protected canBeChanged!: boolean;

    @Prop({ default: false, required: false })
    protected disabled!: boolean;

    @Prop({ default: 0, required: true })
    protected total!: number;

    protected isJumpToPageActive = false;

    protected newPageValue = 1;

    @PropSync('currentPage', { required: true, type: Number })
    protected syncedCurrentPage!: number;

    @PropSync('pageSize', { required: true, type: Number })
    protected syncedPageSize!: number;

    protected valueSetFromInside = false;

    // Computed
    protected get cannotGoBack(): boolean {
        return this.disabled || this.syncedCurrentPage <= 1 || this.disabled;
    }

    protected get cannotGoForward(): boolean {
        return this.disabled || this.syncedCurrentPage >= this.totalPages || this.disabled;
    }

    protected get totalPages(): number {
        return Math.ceil(this.total / this.syncedPageSize);
    }

    // Sync Methods
    protected goToPrevious(): void {
        const paginate = (): void => {
            if (this.syncedCurrentPage > 1) {
                this.syncedCurrentPage -= 1;
            }
        };
        if (this.canBeChanged) {
            paginate();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({ confirmCallbackFunc: paginate });
        }
    }

    protected goToNext(): void {
        const paginate = (): void => {
            if (this.syncedCurrentPage < this.totalPages) {
                this.syncedCurrentPage += 1;
            }
        };
        if (this.canBeChanged) {
            paginate();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({ confirmCallbackFunc: paginate });
        }
    }

    protected goToFirst(): void {
        const paginate = (): void => {
            if (this.syncedCurrentPage > 1) {
                this.syncedCurrentPage = 1;
            }
        };
        if (this.canBeChanged) {
            paginate();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({ confirmCallbackFunc: paginate });
        }
    }

    protected goToLast(): void {
        const paginate = (): void => {
            if (this.syncedCurrentPage < this.totalPages) {
                this.syncedCurrentPage = this.totalPages;
            }
        };
        if (this.canBeChanged) {
            paginate();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({ confirmCallbackFunc: paginate });
        }
    }

    protected editCurrentPage(): void {
        this.newPageValue = this.syncedCurrentPage;
        this.isJumpToPageActive = true;
        this.$nextTick(() => {
            const jumpToPageInput = this.$refs.jumpToPageInput as HTMLInputElement;
            jumpToPageInput.select();
        });
    }

    protected jumpToPage(value: number): void {
        const paginate = (): void => {
            if (value > this.totalPages) {
                this.syncedCurrentPage = this.totalPages;
            } else if (value < 1) {
                this.syncedCurrentPage = 1;
            } else {
                this.syncedCurrentPage = Number(value);
            }
            this.isJumpToPageActive = false;
        };
        if (this.canBeChanged) {
            paginate();
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({ confirmCallbackFunc: paginate });
        }
    }
}
