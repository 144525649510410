












































































































































































































































import { Component, Emit, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import type { CompoundDocument, Resource, ResourceIdentifier } from '@bednic/json-api-client';
import { TableHeader, TableHeaderRenderer } from '@/model/Interfaces/TableHeader';
import AbcCellRenderer from '@/components/common/Table/cellRenderers/AbcCellRenderer.vue';
import appStore from '@/store/modules/AppStore';
import ColumnFilter from '@/components/common/Table/filters/ColumnFilter.vue';
import type { ColumnFilterType } from '@/model/Entity/ColumnFilterType';
import type { DataOptions } from 'vuetify';
import DateCellEditor from '@/components/common/Table/cellEditors/DateCellEditor.vue';
import DateCellRenderer from '@/components/common/Table/cellRenderers/DateCellRenderer.vue';
import DelayLengthCellRenderer from '@/components/common/Table/cellRenderers/DelayLengthCellRenderer.vue';
import FlagCellRenderer from '@/components/common/Table/cellRenderers/FlagCellRenderer.vue';
import messagesStore from '@/store/modules/MessagesStore';
import NumberCellEditor from '@/components/common/Table/cellEditors/NumberCellEditor.vue';
import NumberCellRenderer from '@/components/common/Table/cellRenderers/NumberCellRenderer.vue';
import type { OrderByItems } from '@/model/Entity/OrderByItems';
import ProductNoteCellRenderer from '@/components/common/Table/cellRenderers/ProductNoteCellRenderer.vue';
import StateCellRenderer from '@/components/common/Table/cellRenderers/StateCellRenderer.vue';
import StockoutDateCellRenderer from '@/components/common/Table/cellRenderers/StockoutDateCellRenderer.vue';
import type TableFilters from '@/model/Data/Filters/TableFilters';
import TextCellEditor from '@/components/common/Table/cellEditors/TextCellEditor.vue';
import TextCellRenderer from '@/components/common/Table/cellRenderers/TextCellRenderer.vue';
import UserCellRenderer from '@/components/common/Table/cellRenderers/UserCellRenderer.vue';

@Component({
    // eslint-disable-next-line no-undef
    components: {
        AbcCellRenderer,
        ColumnFilter,
        DateCellEditor,
        DateCellRenderer,
        DelayLengthCellRenderer,
        FlagCellRenderer,
        NumberCellEditor,
        NumberCellRenderer,
        ProductNoteCellRenderer,
        StateCellRenderer,
        StockoutDateCellRenderer,
        TextCellEditor,
        TextCellRenderer,
        UserCellRenderer
    }
})
export default class Table<T extends Resource> extends Vue {
    // Refs
    @Ref('tableRef')
    protected tableRef!: Vue;

    @Ref('theadRef')
    protected theadRef!: HTMLElement;

    // Data
    @Prop({ default: async () => Promise.resolve(true), required: false, type: Function })
    protected beforeSelectItem!: (itemId: string) => Promise<boolean>;

    @Prop({ default: true, required: false })
    protected canBeFiltersChanged!: boolean;

    @Prop({ default: false, required: false })
    protected canBeOnlyOneSelected!: boolean;

    @Prop({ default: false, required: false })
    protected columnFiltersDisabled!: boolean;

    @Prop({ default: false, required: false, type: Boolean })
    protected edit!: boolean;

    @Prop({ required: false, type: Function })
    protected fetchRowFunction!: (args: unknown) => Promise<CompoundDocument<ResourceIdentifier>>;

    @Prop()
    protected gridOptions!: Record<string, unknown>;

    @Prop({ default: false, required: false, type: Boolean })
    protected hasNoFilters!: boolean;

    @Prop({ required: true })
    protected headers!: Array<TableHeader>;

    @Prop({ default: 100, required: true, type: Number })
    protected height!: number;

    @Prop({ default: true, required: true, type: Boolean })
    protected isLoadingData!: boolean;

    @Prop({ default: true, required: true, type: Boolean })
    protected isLoadingSettings!: boolean;

    @Prop()
    protected items!: Array<T>;

    @Prop({ required: true, type: String })
    protected noFiltersMessage!: TableFilters;

    @Prop()
    protected pageSize!: number;

    @Prop()
    protected sort!: Record<string, boolean | null>;

    @Prop({ required: true })
    protected tableFilters!: TableFilters;

    @Prop({ required: false, type: Function })
    protected updateFunction!: (payload: {
        header: TableHeader;
        item: OrderByItems;
        original: OrderByItems;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any;
    }) => Promise<unknown>;

    protected calculateWidths = false;

    protected horizontalScrollPosition = 0;

    @PropSync('isAnyOverviewOpen')
    protected isAnyFlagOverviewOpen!: boolean;

    @PropSync('options', { type: Object })
    protected optionsProp!: DataOptions;

    protected scrollbarWidth = 16;

    @PropSync('selected', { type: Array })
    protected selectedItemsIds!: Array<string>;

    protected selectWidth = 34;

    protected tableHeaderHeight = 48;

    protected tableHeaderRenderer = TableHeaderRenderer;

    protected tablePadding = 8;

    protected tableVisibleWidth = 0;

    protected tableWidth = 0;

    // Lifecycle Hooks
    public mounted(): void {
        this.addCloseFiltersOnScroll();
        this.setElementsStyle();
    }

    // Computed
    protected get currency(): string {
        return appStore.currency;
    }

    protected get isAllSelected(): boolean {
        return this.items.every((item) => this.selectedItemsIds.some((id) => id === item.id)) && this.items.length !== 0;
    }

    protected get isAnySelected(): boolean {
        const someSelected = this.items.some((item) => {
            return this.selectedItemsIds.some((id) => id === item.id);
        });
        return !this.isAllSelected && this.selectedItemsIds.length !== 0 && someSelected;
    }

    protected get minTableWidth(): number {
        let width = this.selectWidth;
        this.headers.forEach((header) => {
            if (typeof header.width === 'number') {
                width += Number(header.width);
            }
        });
        return width;
    }

    protected get noDataCardStyle(): Record<string, string> {
        return {
            minWidth: `${this.tableVisibleWidth}px`,
            position: 'absolute'
        };
    }

    protected get tableContentHeight(): number {
        return this.height - (this.tableHeaderHeight + this.tablePadding + this.scrollbarWidth);
    }

    protected get tableItems(): Array<T> {
        if (!this.headers.length) {
            return [];
        } else {
            return this.items;
        }
    }

    protected get tooltipDelay(): number {
        return appStore.tooltipDelay;
    }

    public onScrollCard(event: WheelEvent): void {
        if (event.shiftKey) {
            const tableWrapperRef = (document.getElementsByClassName('v-data-table__wrapper') as HTMLCollection)[0];
            tableWrapperRef.scrollBy({ left: -1 * event.deltaY });
        }
    }

    protected isSpacerVisible(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!this.tableRef) {
            return true;
        } else {
            const tableWidth = this.tableRef.$el.clientWidth;
            const hasAutoWidth = this.headers.some((header) => typeof header.width === 'undefined' || header.width === 'auto');
            return this.minTableWidth < tableWidth && !hasAutoWidth;
        }
    }

    protected setElementsStyle(): void {
        this.$nextTick(() => {
            const tablePadding = parseInt(window.getComputedStyle(this.tableRef.$el).getPropertyValue('padding'));
            this.tablePadding = tablePadding * 2;
            this.tableHeaderHeight = this.theadRef.clientHeight;
            const table = this.tableRef.$el.querySelector('table');
            const tableWidth = this.tableRef.$el.clientWidth;
            if (table) {
                table.style.tableLayout = 'fixed';
                this.tableWidth =
                    this.minTableWidth < tableWidth ? tableWidth - this.scrollbarWidth : this.minTableWidth - this.scrollbarWidth;
                table.style.width = `${this.tableWidth}px`;
            }
            const tableParenElement = this.tableRef.$el.parentElement;
            if (tableParenElement) {
                this.tableVisibleWidth = tableParenElement.clientWidth - this.tablePadding;
            }
        });
    }

    // Sync Methods
    protected getColumnRefId(itemId: string | null, columnName: string): string {
        return `cell_${itemId as string}_${columnName}`;
    }

    protected getHeaderPadding(header: TableHeader): string {
        let padding = 1;
        if (header.filterType) {
            padding += 4;
        }
        if (typeof this.sort[header.value] !== 'undefined' && this.sort[header.value] !== null) {
            padding += 4;
        }
        return `mr-${padding}`;
    }

    protected closeAllFilters(): void {
        Object.keys(this.$refs).forEach((key) => {
            if (key.startsWith('columnFilter')) {
                if ((this.$refs[key] as Array<unknown>).length && (this.$refs[key] as Array<{ isOpen: boolean } & Vue>)[0].isOpen) {
                    (this.$refs[key] as Array<{ closeFilter: () => void } & Vue>)[0].closeFilter();
                }
            }
        });
    }

    protected navigateToNextRow(payload: { columnName: string; itemId: string | number }): void {
        const index = this.items.findIndex((el) => el.id === payload.itemId);
        if (index > -1) {
            const firstItem = this.items[0];
            const nextItem = this.items[index + 1];

            const tableRefChildren = this.tableRef.$children[0].$children[0].$children as Array<Vue & { id: string }>;

            if (nextItem?.id) {
                const foundCellEditor = tableRefChildren.find(
                    (cellEditor) => cellEditor.id === this.getColumnRefId(nextItem.id, payload.columnName)
                );
                if (foundCellEditor) {
                    (foundCellEditor.$refs.input as HTMLInputElement).focus();
                }
            } else if (typeof firstItem !== 'undefined' && firstItem.id) {
                const foundCellEditor = tableRefChildren.find(
                    (cellEditor) => cellEditor.id === this.getColumnRefId(firstItem.id, payload.columnName)
                );
                if (foundCellEditor) {
                    (foundCellEditor.$refs.input as HTMLInputElement).focus();
                }
            }
        }
    }

    @Emit('closeAllFlagOverviews')
    protected closeAllFlagOverviews(): void {
        return;
    }

    protected addCloseFiltersOnScroll(): void {
        const tableWrapperRef = (document.getElementsByClassName('v-data-table__wrapper') as HTMLCollection)[0];
        if (typeof tableWrapperRef !== 'undefined') {
            tableWrapperRef.addEventListener('scroll', (event: Event) => {
                if (this.isAnyFlagOverviewOpen) {
                    this.closeAllFlagOverviews();
                }
                const horizontal = (event.currentTarget as Element).scrollLeft as number;
                if (this.horizontalScrollPosition !== horizontal) {
                    this.horizontalScrollPosition = horizontal;
                    if (Object.keys(appStore.activeMenus).some((key: string) => key.startsWith('columnFilter'))) {
                        this.closeAllFilters();
                    }
                }
            });
        }
    }

    public resetColumnFilter(columnName: string): void {
        const columnFilters = this.$refs[`columnFilter-${columnName}`] as Array<{ resetFilter: () => void } & Vue>;
        if (columnFilters.length) {
            columnFilters[0].resetFilter();
        }
    }

    protected isItemSelected(itemId: string): boolean {
        return this.selectedItemsIds.some((id) => itemId === id);
    }

    protected selectItem(itemId: string): void {
        void this.beforeSelectItem(itemId).then((shouldContinueInSelection: boolean) => {
            if (!shouldContinueInSelection) return;
            if (this.isItemSelected(itemId)) {
                if (this.canBeOnlyOneSelected) {
                    this.selectedItemsIds = [];
                } else {
                    this.selectedItemsIds.splice(this.selectedItemsIds.indexOf(itemId), 1);
                }
            } else {
                if (this.canBeOnlyOneSelected) {
                    this.selectedItemsIds = [itemId];
                } else {
                    this.selectedItemsIds.push(itemId);
                }
            }
        });
    }

    protected sortColumn(header: TableHeader): void {
        if (header.sortable && !this.columnFiltersDisabled) {
            this.toggleColumnSort(header);
        }
    }

    // Emitters

    @Emit('toggleColumnSort')
    protected toggleColumnSort(header: TableHeader): TableHeader {
        return header;
    }

    @Emit('changeColumnFilter')
    protected changeColumnFilter(currentFilter: { filter: ColumnFilterType | null; key: string }): {
        filter: ColumnFilterType | null;
        key: string;
    } {
        return currentFilter;
    }

    protected validateCell(validation: Record<string, boolean>): void {
        appStore.setValidation(validation);
    }

    // Event Handlers
    protected onToggleSelected(): void {
        if (!this.isAllSelected && !this.isAnySelected) {
            if (!this.canBeOnlyOneSelected) {
                this.items.forEach((item) => {
                    if (item.id) {
                        this.selectedItemsIds.push(item.id);
                    }
                });
            }
        } else {
            this.items.forEach((item) => {
                this.selectedItemsIds.forEach((id) => {
                    if (id === item.id) {
                        this.selectedItemsIds.splice(this.selectedItemsIds.indexOf(id), 1);
                    }
                });
            });
        }
    }

    protected onChangeFilter(currentFilter: { filter: ColumnFilterType | null; key: string }): void {
        this.changeColumnFilter(currentFilter);
    }

    protected onHeaderClick(header: TableHeader): void {
        if (this.canBeFiltersChanged) {
            this.sortColumn(header);
        } else {
            void messagesStore.dispatchLeaveUpdateConfirm({
                confirmCallbackFunc: () => {
                    this.sortColumn(header);
                }
            });
        }
    }

    @Watch('headers', { immediate: true })
    protected onChangeHeaders(headers: Array<TableHeader> | undefined): void {
        if (headers) {
            this.setElementsStyle();
        }
    }

    @Emit('refreshRow')
    protected onRefreshRow(item: unknown): unknown {
        return item;
    }
}
