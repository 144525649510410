export enum Notifications {
    DataCurrentChange = 'data_current_change',
    ExportEnd = 'export_end',
    ExportStart = 'export_start',
    ExportUpdate = 'export_update',
    ImportEnd = 'import_end',
    ImportStart = 'import_start',
    PwComputeEnd = 'pw_compute_end',
    PwComputeStart = 'pw_compute_start'
}
